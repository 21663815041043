import React, { useEffect, useState } from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack, useToast, Spinner, Box } from '@chakra-ui/react';
import Dashdetails from '../../components/Dashboard/Dashdetails';
import Listowners from '../../components/Dashboard/Listowners';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import Venderlist from '../../components/Dashboard/Venderlist';
import RecentReports from '../../components/Dashboard/RecentReports';
import InventoryDetails from '../../components/Manager/Dashboard/InventoryDetails';
import InventoryList from '../../components/Dashboard/InventoryList';

export default function HomeDashboard() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.value);
  const [home, sethome] = useState('');

  useEffect(() => {
    if (user) {
      getvenderlist();
    }
  }, [user]);

  const getvenderlist = async () => {
    setIsLoading(true);
    console.log('vender Hit');
    let HomeData = await POST(
      `/users/dashboard`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log('coupon Hit', HomeData);
    sethome(HomeData);
    console.log('coupon state', home);
    setIsLoading(false);
  };

  return (
    <>
      <MainDashboard>
        {isLoading ? (
          <Box
            display={'flex'}
            height={'80vh'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Spinner color="#fff" />
          </Box>
        ) : (
          <Stack px={4}>
            <Stack mb={4}>
              <Dashdetails
                ownerCount={home?.ownerCount}
                jobCount={home.jobCount}
                inventoryCount={home.inventoryCount}
                customerCount={home.customerCount}
              />
            </Stack>
            <Stack direction={'row'} gap={6}>
              <Stack w={'50%'}>
                <Listowners Owner={home.ownerData} />
              </Stack>
              <Stack w={'50%'}>
                <RecentReports ReportData={home.reportData} />
              </Stack>
            </Stack>
            {/* <Stack direction={'row'} gap={6}>
              <Stack w={'50%'}>
                <Venderlist Vendor={home.vendorData} />
              </Stack>
              <Stack w={'50%'}>
                <InventoryList Items={home.inventoryData} />
              </Stack>
            </Stack> */}
          </Stack>
        )}
      </MainDashboard>
    </>
  );
}
