import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  Input,
  Select,
  Button,
  Icon,
  Link
} from '@chakra-ui/react';
import React from 'react';
import { RiAddFill } from 'react-icons/ri';
import { Link as ReactLink  } from 'react-router-dom';

export default function JobDetails() {
  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>Jobs Details</Heading>
      </Stack>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
        <Stack direction={'row'} pb={4} borderBottom={'1px solid #2e3035'}>
          <Stack w={'100%'} className="m4-h m6-h p2">
            <Heading mb={5} as={'h4'}>
              Vehicle Details
            </Heading>
            <TableContainer>
              <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                <Tbody className="m6-h p2">
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Make
                      </Heading>
                    </Td>
                    <Td>
                      <Text>Honda</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Year
                      </Heading>
                    </Td>
                    <Td>
                      <Text>2017</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Car Type
                      </Heading>
                    </Td>
                    <Td>
                      <Text>Civic 1597 cc</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Service Area
                      </Heading>
                    </Td>
                    <Td>
                      <Text>Maintenance</Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack w={'100%'} className="m4-h">
            <Heading as={'h4'} mb={5}>
              Customer Details
            </Heading>
            <TableContainer>
              <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                <Tbody className="m6-h p2">
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Customer Name
                      </Heading>
                    </Td>
                    <Td>
                      <Text>Theresa Webb</Text>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Address
                      </Heading>
                    </Td>
                    <Td>
                      <Text> xyz , Now York city USA</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Contact
                      </Heading>
                    </Td>
                    <Td>
                      <Text>(4230) 3233 532</Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
        <Stack pb={8} borderBottom={'1px solid #2e3035'} className="m4-h">
          <Heading mb={5} as={'h4'}>
            List of Services
          </Heading>
          <SimpleGrid pb={8} columns={5} spacing={2}>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
            
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
          </SimpleGrid>
          <Stack>
            <Stack w={'50%'} mb={4} direction={'row'}>
              <Stack w={'100%'} className="Ifiled icnfiled">
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Select Services
                </FormLabel>
                <Box>
                  <Select color={'#fff'}>
                    <option style={{ display: 'none' }} value="">
                      Select Option
                    </option>
                    <option value="clockIn">clock In</option>
                    <option value="clockOut">clock Out</option>
                  </Select>
                </Box>
              </Stack>
              <Stack w={'100%'} className="Ifiled icnfiled">
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Select Services Price
                </FormLabel>
                <Box>
                  <Select color={'#fff'}>
                    <option style={{ display: 'none' }} value="">
                      Select Option
                    </option>
                    <option value="clockIn">clock In</option>
                    <option value="clockOut">clock Out</option>
                  </Select>
                </Box>
              </Stack>
            </Stack>
            <Button
              padding={'10px 30px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
            >
              Add More Services{' '}
              <Icon color={'#fff'} pl={2} fontSize={'26px'} as={RiAddFill} />
            </Button>
          </Stack>
        </Stack>
        <Stack pb={8} borderBottom={'1px solid #2e3035'} className="m4-h">
          <Heading mb={5} as={'h4'}>
          List of Parts
          </Heading>
          <SimpleGrid pb={8} columns={5} spacing={2}>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
            <Box
              bg={'#2baf598c'}
              border={'2px solid #2BAF59 '}
              borderRadius={'25px'}
              padding={2}
              textAlign={'center'}
            >
              <Text color={'#fff'}>Under Bonnet - $10.00</Text>
            </Box>
           
          </SimpleGrid>
          <Stack>
            <Stack w={'50%'} mb={4} direction={'row'}>
              <Stack w={'100%'} className="Ifiled icnfiled">
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Select Part
                </FormLabel>
                <Box>
                  <Select color={'#fff'}>
                    <option style={{ display: 'none' }} value="">
                      Select Option
                    </option>
                    <option value="clockIn">clock In</option>
                    <option value="clockOut">clock Out</option>
                  </Select>
                </Box>
              </Stack>
              <Stack w={'100%'} className="Ifiled icnfiled">
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Select Item Price
                </FormLabel>
                <Box>
                  <Select color={'#fff'}>
                    <option style={{ display: 'none' }} value="">
                      Select Option
                    </option>
                    <option value="clockIn">clock In</option>
                    <option value="clockOut">clock Out</option>
                  </Select>
                </Box>
              </Stack>
            </Stack>
            <Button
              padding={'10px 30px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
            >
              Add More Parts{' '}
              <Icon color={'#fff'} pl={2} fontSize={'26px'} as={RiAddFill} />
            </Button>
          </Stack>
        </Stack>
        <Stack textAlign={'right'} >
        <Button
              padding={'10px 30px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
              margin={'0 0 0 auto'}
            >
              Send to CSR
            </Button>
        </Stack>
        <Stack direction={'row'} textAlign={'right'} >
        <Link
        display={'block'}
              padding={'10px 30px'}
              bg={
                '#4D4D4D'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
              margin={'0 0 0 auto'}
              to={''}
            >
             Edit Details
            </Link>
        <Link
              padding={'10px 30px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
              margin={'0 0 0 auto'}
              to={'/jobinvoice'}
              as={ReactLink}
            >
             Complete
            </Link>
        </Stack>
      </Stack>
    </>
  );
}
