import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import OpenProcedure from '../../../components/csr/CashDrawer/OpenProcedure';

export default function CashOpening() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <OpenProcedure />
        </Stack>
      </MainDashboard>
    </>
  );
}
