import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  Checkbox,
  UnorderedList,
  ListItem,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import JobCard from './JobCard';

export default function ListOfjob() {
  const toast = useToast();
  const user = useSelector(state => state?.value);
  const [selectedList, setSelectedList] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState({
    pendingData: [],
    completeData: [],
    invoiceData: [],
  });

  const getJobList = async () => {
    setIsLoading(true);
    try {
      let res = await POST(
        `/job/getJob`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        console.log('Job List', res?.data);
        let pendingData = res?.all?.pending;
        let completeData = res?.all?.completed;
        let invoiceData = res?.all?.paid;
        console.log('Pending Data', pendingData);
        // res?.data?.map(val =>
        //   val?.status === 'pending'
        //     ? pendingData.push(val)
        //     : val?.status === 'complete'
        //     ? completeData.push(val)
        //     : invoiceData.push(val)
        // );
        setListData({
          pendingData,
          completeData,
          invoiceData,
        });
        setSelectedList('pending');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (user) getJobList();
  }, [user]);

  return (
    <>
      <Stack
        className={'bread m3-h'}
        direction="row"
        alignItems="center"
        gap="20px"
      >
        <Heading as={'h3'}>List of Jobs</Heading>
        {isLoading && <Spinner color="primaryGreen.100" />}
      </Stack>
      <Stack>
        <UnorderedList gap={6} display={'flex'} py={5} listStyleType={'none'}>
          <ListItem>
            <Button
              onClick={() => setSelectedList('pending')}
              isDisabled={listData.pendingData.length === 0}
              padding={'10px 50px'}
              fontWeight={'500'}
              color={selectedList === 'pending' ? '#fff' : '#fff'}
              lineHeight={'inherit'}
              border={'2px solid #fff'}
              borderRadius={12}
              borderColor={selectedList === 'pending' ? '#2BAF59' : '#fff'}
              bgImage={
                selectedList === 'pending'
                  ? 'linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%)'
                  : 'none'
              }
              bgColor={'transparent'}
              textTransform={'capitalize'}
              w={'fit-content'}
            >
              Pending
            </Button>
          </ListItem>
          <ListItem>
            <Button
              onClick={() => setSelectedList('complete')}
              isDisabled={listData.completeData.length === 0}
              padding={'10px 50px'}
              fontWeight={'500'}
              color={selectedList === 'complete' ? '#fff' : '#fff'}
              lineHeight={'inherit'}
              border={'2px solid #fff'}
              borderRadius={12}
              borderColor={selectedList === 'complete' ? '#2BAF59' : '#fff'}
              bgImage={
                selectedList === 'complete'
                  ? 'linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%)'
                  : 'none'
              }
              bgColor={'transparent'}
              textTransform={'capitalize'}
              w={'fit-content'}
            >
              Completed
            </Button>
          </ListItem>
          <ListItem>
            <Button
              onClick={() => setSelectedList('invoice')}
              isDisabled={listData.invoiceData.length === 0}
              padding={'10px 50px'}
              fontWeight={'500'}
              color={selectedList === 'invoice' ? '#fff' : '#fff'}
              lineHeight={'inherit'}
              border={'2px solid #fff'}
              borderRadius={12}
              borderColor={selectedList === 'invoice' ? '#2BAF59' : '#fff'}
              bgImage={
                selectedList === 'invoice'
                  ? 'linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%)'
                  : 'none'
              }
              bgColor={'transparent'}
              textTransform={'capitalize'}
              w={'fit-content'}
            >
              Paid
            </Button>
          </ListItem>
        </UnorderedList>
        <Stack className="list-ser">
          <Stack
            display={selectedList === '' ? 'none' : 'flex'}
            py={6}
            direction={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack className="p1">
              <Text>
                Total Job:{' '}
                <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                  {selectedList === 'pending'
                    ? listData.pendingData.length
                    : selectedList === 'complete'
                    ? listData.completeData.length
                    : selectedList === 'invoice'
                    ? listData.invoiceData.length
                    : 0}
                </Text>
              </Text>
            </Stack>
          </Stack>
          <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
            <SimpleGrid
              columns={{
                base: '1',
                sm: '1',
                md: '1',
                lg: '2',
                xl: '2',
                '2xl': '2',
              }}
              spacingX="40px"
              spacingY="20px"
            >
              {selectedList === 'pending' ? (
                listData.pendingData.length === 0 ? (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Jobs Found </Heading>
                  </Stack>
                ) : (
                  listData.pendingData
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map(val => (
                      <JobCard key={val?._id} {...val} update={getJobList} />
                    ))
                )
              ) : selectedList === 'complete' ? (
                listData.completeData.length === 0 ? (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Jobs Found </Heading>
                  </Stack>
                ) : (
                  listData.completeData
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map(val => (
                      <JobCard key={val?._id} {...val} update={getJobList} />
                    ))
                )
              ) : selectedList === 'invoice' ? (
                listData.invoiceData.length === 0 ? (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Jobs Found </Heading>
                  </Stack>
                ) : (
                  listData.invoiceData
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map(val => <JobCard key={val?._id} {...val} />)
                )
              ) : (
                <Stack className={'bread m5-h'}>
                  <Heading as={'h5'}>No Jobs Found </Heading>
                </Stack>
              )}
            </SimpleGrid>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
