import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  FormLabel,
  Input,
  Icon,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../utilities/ApiProvider';

export default function NewVender() {
  const user = useSelector(state => state.value);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [addven, setaddven] = useState({
    name: '',
    phone: '',
    email: '',
    uploadPicture: '',
  });

  const EmpSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();

    Object.keys(addven).forEach(key => {
      formData.append(key, addven[key]);
    });

    try {
      let res = await POST(`/vendor/createVendor`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('add vender responce ', res);

      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
      if (res.status === 'success') {
        navigate('/vendors ');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Add New Vendor /{' '}
            <Link to={'/vendors'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} gap={8} py={12} borderRadius={8}>
        <SimpleGrid
          columns={{
            base: '1',
            sm: '1',
            md: '1',
            lg: '1',
            xl: '2',
            '2xl': '2',
          }}
          spacingX="40px"
          spacingY="20px"
        >
          <Stack className="adser" gap={6}>
            <Stack>
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Enter Vendor Name
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="text"
                  value={addven.name}
                  onChange={e => setaddven({ ...addven, name: e.target.value })}
                  placeholder="Enter Vendor Name "
                />
              </Box>
            </Stack>
            <Stack>
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Contact Number
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  value={addven.phone}
                  onChange={e =>
                    setaddven({ ...addven, phone: e.target.value })
                  }
                  placeholder="Enter Phone "
                />
              </Box>
            </Stack>
            <Stack>
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Email Address
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="email"
                  value={addven.email}
                  onChange={e =>
                    setaddven({ ...addven, email: e.target.value })
                  }
                  placeholder="Enter Your Email "
                />
              </Box>
            </Stack>
          </Stack>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Uplaod Picture*
            </FormLabel>
            <Box className="ufiled">
              <Input
                type="file"
                onChange={e =>
                  setaddven({ ...addven, uploadPicture: e.target.files[0] })
                }
              />
            </Box>
          </Stack>
        </SimpleGrid>
        <Stack>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={EmpSubmit}
          >
            Add Vendor
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
