import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  Input,
  Select,
  Button,
  Icon,
  Link,
  Badge,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiAddFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';

export default function JobInvoice() {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');

  const [cdData, setCdData] = useState({
    coupon: null,
    discount: null,
  });
  const [usedCD, setUsedCD] = useState({
    coupon: null,
    discount: null,
  });
  // const [servicesList, setServicesList] = useState([]);
  // const [technicianList, setTechnicianList] = useState([]);
  // const [newService, setNewService] = useState(null);

  const getSingleInvoice = async () => {
    setIsLoading(true);
    try {
      console.log('Fetching invoice...'); // Add this

      let res = await GET(`/invoice/${params?.id}`, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('API call completed:', res); // Add this

      if (res?.status === 'success') {
        console.log(res, 'HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH');
        setInvoiceData(res?.data);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  // Api to get coupons and discounts
  const getUserCnD = async () => {
    try {
      let Coupons = await POST(
        `/coupon/getCoupon`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      let Discounts = await POST(
        `/discount/getDiscount`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (Coupons?.status && Discounts?.status === 'success') {
        setCdData({
          coupon: Coupons?.data,
          discount: Discounts?.data,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const updateInvoice = async () => {
    const payload = {
      discount: [],
      //  usedCD.coupon &&
      // {
      //     product: usedCD.coupon?.id || '',
      //     price: usedCD.coupon?.percentage,
      //     type: 'coupon'
      //   },
      //   usedCD.discount &&
      // {
      //     product: usedCD.discount?.id || '',
      //     price: usedCD.discount?.percentage,
      //     type: 'discount'
      //   },
      totalPrice: Math.round(calculateTotal()),
    };
    console.log('SUED CS', usedCD);
    if (usedCD.coupon)
      payload.discount.push({
        product: usedCD.coupon?.id || '',
        price: usedCD.coupon?.percentage,
        type: 'coupon',
      });
    if (usedCD.discount)
      payload.discount.push({
        product: usedCD.discount?.id || '',
        price: usedCD.discount?.percentage,
        type: 'discount',
      });
    console.log(payload);
    try {
      let res = await PUT(`/invoice/${params?.id}/update`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res?.status === 'success') {
        console.log('Upd Invoice Data', res?.data);
        // toast({
        //   title: res?.message,
        //   status: 'success',
        //   duration: 9000,
        //   isClosable: true,
        // });
        // navigate('/listofjob');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const calculateDiscount = (price, percentage) => {
    return price * (percentage / 100);
  };

  const calculateTotal = () => {
    let subtotal = invoiceData?.totalPrice;
    let tax = subtotal * 0.1;
    let coupon = usedCD?.coupon
      ? calculateDiscount(subtotal, usedCD.coupon.percentage)
      : 0;
    let discount = usedCD?.discount
      ? calculateDiscount(subtotal, usedCD.discount.percentage)
      : 0;
    let total = subtotal + tax - coupon - discount;
    return total;
  };

  const markPaid = async () => {
    const payload = {
      status: 'paid',
      paymentMethod: paymentMethod,
    };
    await updateInvoice();
    try {
      let response = await PUT(
        `/job/jobStatus/${invoiceData?.jobId?._id}`,
        payload,
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response.status === 'success') {
        toast({
          title: 'Job Marked As Paid',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/listofjob');
      } else {
        toast({
          title: 'Checkout Failed',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleChangePaymentMethod = event => {
    setPaymentMethod(event.target.value);
  };

  useEffect(() => {
    if (user) {
      getSingleInvoice();
      getUserCnD();
    }

    return () => {};
  }, [user]);

  useEffect(() => {
    console.log('Coupon Data', usedCD.coupon);
    console.log('Discount Data', usedCD.discount);
  }, [usedCD]);

  useEffect(() => {
    console.log('job invoice', invoiceData);
  }, [invoiceData]);

  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>Jobs Invoice</Heading>
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
          <Stack direction={'row'} justifyContent={'space-between'} pb={4}>
            <Stack className="p1 m4-h">
              <Badge
                textAlign={'center'}
                py={2}
                px={6}
                borderRadius={6}
                variant="solid"
                colorScheme={
                  invoiceData?.paymentStatus === 'paid' ? 'green' : 'red'
                }
              >
                {invoiceData?.paymentStatus}
              </Badge>
            </Stack>
            <Stack direction={'row'} className="p1">
              <Text fontFamily={'Orbitron'}>
                Date issued:
                <Text as={'span'} color={'#fff'} fontFamily={'Orbitron'}>
                  {' '}
                  {new Date(invoiceData?.createdAt).toLocaleDateString()}
                </Text>
              </Text>
            </Stack>
          </Stack>
          <Stack w={'100%'} className="m4-h">
            <Heading as={'h4'} mb={5}>
              Vehicle Details:
            </Heading>
            <TableContainer>
              <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                <Tbody className="m6-h p2">
                  <Tr>
                    <Td>
                      <Heading
                        fontFamily={'Orbitron'}
                        fontSize={'16px'}
                        color={'#2BAF59'}
                      >
                        car Make
                      </Heading>
                    </Td>
                    <Td>
                      <Text fontFamily={'Orbitron'}>
                        {invoiceData?.vehicleId?.make}
                      </Text>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                      <Heading
                        fontFamily={'Orbitron'}
                        fontSize={'16px'}
                        color={'#2BAF59'}
                      >
                        License
                      </Heading>
                    </Td>
                    <Td>
                      <Text fontFamily={'Orbitron'}>
                        {invoiceData?.vehicleId?.license || 'N/A'}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading
                        fontFamily={'Orbitron'}
                        fontSize={'16px'}
                        color={'#2BAF59'}
                      >
                        Vin Number
                      </Heading>
                    </Td>
                    <Td>
                      <Text fontFamily={'Orbitron'}>
                        {invoiceData?.vehicleId?.vinNumber}
                      </Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack direction={'row'} pb={4} borderBottom={'1px solid #2e3035'}>
            <Stack w={'100%'} className="m4-h">
              <Heading as={'h4'} mb={5}>
                From
              </Heading>
              <TableContainer>
                <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                  <Tbody className="m6-h p2">
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Job Creator Name
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.userId?.fullName}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Email
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.userId?.email}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Role
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.userId?.role}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Technician Name
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.assignedTechnician?.fullName}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Technician Email
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.assignedTechnician?.email}
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack w={'100%'} className="m4-h">
              <Heading as={'h4'} mb={5}>
                Bill to
              </Heading>
              <TableContainer>
                <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                  <Tbody className="m6-h p2">
                    <Tr>
                      <Td>
                        <Heading
                          fontSize={'16px'}
                          color={'#2BAF59'}
                          fontFamily={'Orbitron'}
                        >
                          Customer Name
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.customerId?.firstName}{' '}
                          {invoiceData?.customerId?.lastName}
                        </Text>
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Heading
                          fontSize={'16px'}
                          color={'#2BAF59'}
                          fontFamily={'Orbitron'}
                        >
                          Address
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.customerId?.address}
                        </Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading
                          fontSize={'16px'}
                          color={'#2BAF59'}
                          fontFamily={'Orbitron'}
                        >
                          Contact
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {invoiceData?.customerId?.number}
                        </Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
          {invoiceData?.service?.length > 0 &&
            invoiceData?.service?.map((v, k) => {
              return (
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  py={5}
                  borderRadius={6}
                  bg={'#2e3035'}
                  px={4}
                  className="att-list"
                  key={k}
                >
                  <Box className="m5-h p1" width={'20%'}>
                    <Text fontFamily={'Orbitron'}>Service</Text>
                    <Heading as={'h5'}>{v?.id?.name}</Heading>
                  </Box>
                  <Box className="m5-h p1" width={'20%'}>
                    <Text fontFamily={'Orbitron'}>Category</Text>
                    <Heading as={'h5'}>{v?.id?.category || 'product'}</Heading>
                  </Box>
                  <Box className="m5-h p1" width={'20%'}>
                    <Text fontFamily={'Orbitron'}>Qty</Text>
                    {console.log(v?.qty, 'quantity')}
                    <Heading as={'h5'}>{v?.qty}</Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text fontFamily={'Orbitron'}>Price</Text>
                    <Heading as={'h5'}>${v?.price}</Heading>
                  </Box>
                </Stack>
              );
            })}
          {invoiceData?.jobId?.package.length > 0 &&
            invoiceData?.jobId?.package?.map((v, k) => {
              return (
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  py={5}
                  borderRadius={6}
                  bg={'#2e3035'}
                  px={4}
                  className="att-list"
                  key={k}
                >
                  <Box className="m5-h p1" width={'20%'}>
                    <Text>Package</Text>
                    <Heading as={'h5'}>{v?.id?.name}</Heading>
                  </Box>
                  <Box className="m5-h p1" width={'20%'}>
                    <Text>Category</Text>
                    <Heading as={'h5'}>{v?.id?.type || 'Package'}</Heading>
                  </Box>
                  <Box className="m5-h p1" width={'20%'}>
                    <Text>Services Included</Text>
                    {v?.id?.services?.map((val, index) => (
                      <Heading key={index} as={'h5'}>
                        {val?.name}
                      </Heading>
                    ))}
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Price</Text>
                    <Heading as={'h5'}>${v?.id?.cost}</Heading>
                  </Box>
                </Stack>
              );
            })}
          <SimpleGrid
            columns={{
              base: '1',
              sm: '1',
              md: '1',
              lg: '1',
              xl: '2',
              '2xl': '2',
            }}
            spacingX="40px"
            spacingY="20px"
          >
            <Box
              direction={'row'}
              justify={'space-between'}
              alignItems={'center'}
              className="m4-h"
            >
              <Heading as={'h4'} mb={5}>
                Coupon
              </Heading>
              <Stack w={'100%'} className="Ifiled icnfiled">
                {cdData?.coupon?.filter(val => val.active)?.length > 0 ? (
                  <Box className="Ifiled">
                    <Select
                      color={'#fff'}
                      onChange={e =>
                        setUsedCD({
                          ...usedCD,
                          coupon: JSON.parse(e.target.value),
                        })
                      }
                    >
                      <option style={{ display: 'none' }} value="">
                        Select Coupon
                      </option>

                      {cdData?.coupon
                        ?.filter(val => val.active)
                        ?.map((val, index) => (
                          <option
                            key={index}
                            value={JSON.stringify({
                              name: val?.name,
                              id: val?.productId,
                              percentage: val?.percentage,
                            })}
                          >
                            {val.name}
                          </option>
                        ))}
                    </Select>
                  </Box>
                ) : (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Coupons Available</Heading>
                  </Stack>
                )}
              </Stack>
            </Box>
            <Box
              direction={'row'}
              justify={'space-between'}
              alignItems={'center'}
              className="m4-h"
            >
              <Heading as={'h4'} mb={5}>
                Discount
              </Heading>
              <Stack w={'100%'} className="Ifiled icnfiled">
                {cdData?.discount?.filter(val => val.active)?.length > 0 ? (
                  <Box className="Ifiled">
                    <Select
                      color={'#fff'}
                      onChange={e =>
                        setUsedCD({
                          ...usedCD,
                          discount: JSON.parse(e.target.value),
                        })
                      }
                    >
                      <option style={{ display: 'none' }} value="">
                        Select Discount
                      </option>

                      {cdData?.discount
                        ?.filter(val => val.active)
                        ?.map((val, index) => (
                          <option
                            key={index}
                            value={JSON.stringify({
                              name: val?.name,
                              id: val?.productId,
                              percentage: val?.percentage,
                            })}
                          >
                            {val.name}
                          </option>
                        ))}
                    </Select>
                  </Box>
                ) : (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Discounts Available </Heading>
                  </Stack>
                )}
              </Stack>
            </Box>
          </SimpleGrid>
          <Stack w={'100%'} className="p2" py={6}>
            {/* <Text>All payment are in USD, All fee is upto buyer</Text> */}
            <TableContainer>
              <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                <Tbody className="m6-h p2">
                  <Tr>
                    <Td>
                      <Heading
                        fontFamily={'Orbitron'}
                        fontSize={'16px'}
                        color={'#2BAF59'}
                      >
                        Sub Total :
                      </Heading>
                    </Td>
                    <Td>
                      <Text fontFamily={'Orbitron'}>
                        ${invoiceData?.totalPrice}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading
                        fontFamily={'Orbitron'}
                        fontSize={'16px'}
                        color={'#2BAF59'}
                      >
                        Tax 10% :
                      </Heading>
                    </Td>
                    <Td>
                      <Text fontFamily={'Orbitron'}>
                        ${(invoiceData?.totalPrice * 0.1).toFixed(2)}
                      </Text>
                    </Td>
                  </Tr>
                  {usedCD?.coupon && (
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Coupon : {usedCD?.coupon?.name}
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {usedCD?.coupon?.percentage}%
                        </Text>
                      </Td>
                    </Tr>
                  )}
                  {usedCD?.discount && (
                    <Tr>
                      <Td>
                        <Heading
                          fontFamily={'Orbitron'}
                          fontSize={'16px'}
                          color={'#2BAF59'}
                        >
                          Discount : {usedCD?.discount?.name}
                        </Heading>
                      </Td>
                      <Td>
                        <Text fontFamily={'Orbitron'}>
                          {usedCD?.discount?.percentage}%
                        </Text>
                      </Td>
                    </Tr>
                  )}
                  <Tr>
                    <Td>
                      <Heading
                        fontFamily={'Orbitron'}
                        fontSize={'16px'}
                        color={'#2BAF59'}
                      >
                        Total :
                      </Heading>
                    </Td>
                    <Td>
                      {/* <Text>
                        {invoiceData?.totalPrice +
                          invoiceData?.totalPrice * 0.1 -
                          (usedCD?.coupon?.value || 0) -
                          (usedCD?.discount?.value || 0)}
                      </Text> */}
                      <Text fontFamily={'Orbitron'}>
                        ${Math.round(calculateTotal())}
                      </Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack
            display={'flex'}
            direction={'row'}
            alignItems={'center'}
            pb={6}
            pt={10}
            justifyContent={'flex-end'}
          >
            {/* <Stack display={'flex'} direction={'row'}>
              <Button
                padding={'10px 50px'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                border={'1px solid #4D4D4D'}
                color={'#ffff'}
                bg={'rgba(32, 33, 37, 0.60)'}
              >
                Print
              </Button>
              <Button
                padding={'10px 50px'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                border={'1px solid #4D4D4D'}
                color={'#ffff'}
                bg={'rgba(32, 33, 37, 0.60)'}
              >
                Download
              </Button>
              <Button
                padding={'10px 50px'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                onClick={() => updateInvoice()}
              >
                Save
              </Button>
            </Stack> */}
            {/* <Link
              padding={'10px 30px'}
              bg={'#DF2234'}
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
              margin={'0 0 0 auto'}
              onClick={() => navigate(-1)}
            >
              Back to Pending
            </Link> */}
            <Box className="Ifiled" w={'70%'}>
              <Select
                placeholder="Select Payment Method"
                // padding={'10px 30px'}

                bg={'#202125'}
                // bg={'#DF2234'}
                // color={'#000'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                // _hover={{
                //   color: '#000',
                // }}
                w={'fit-content'}
                alignItems={'center'}
                margin={'0 0 0 auto'}
                value={paymentMethod} // Set the selected value
                onChange={handleChangePaymentMethod} // Handle change event
              >
                <option value="cash">Cash Payment</option>
                <option value="credit">Credit Card Payment</option>
              </Select>
            </Box>
            {/* {paymentMethod && <Button>Proceed</Button>} */}

            {paymentMethod && (
              <Button
                // padding:;
                padding={' 26px 52px'}
                fontFamily={'Orbitron'}
                bg={'#2BAF59'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                w={'fit-content'}
                alignItems={'center'}
                onClick={() => markPaid()}
              >
                Proceed with {paymentMethod}
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
