import React, { useEffect, useState } from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack, useToast, Spinner, Box } from '@chakra-ui/react';
import Dashdetails from '../../components/Manager/Dashboard/DashDetails';
import ListJobs from '../../components/Manager/Dashboard/ListJobs';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import JobHistory from '../../components/Manager/Dashboard/JobHistory';
import RecentReports from '../../components/Manager/Dashboard/RecentReports';
import InventoryDetails from '../../components/Manager/Dashboard/InventoryDetails';

export default function DashboardManager() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.value);
  const [home, sethome] = useState(null);

  useEffect(() => {
    if (user) {
      getManagerData();
    }
  }, [user]);

  const getManagerData = async () => {
    setIsLoading(true);
    let HomeData = await POST(
      `/users/managerDashboard`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log('Api Hit', HomeData);
    if (HomeData) {
      sethome(HomeData);
    }
    console.log('coupon state', home);
    setIsLoading(false);
  };

  return (
    <>
      <MainDashboard>
        {isLoading ? (
          <Box
            display={'flex'}
            height={'80vh'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Spinner color="#fff" />
          </Box>
        ) : (
          <Stack px={4} pb={3}>
            <Stack mb={4}>
              <Dashdetails
                ownerCount={home?.employeesCount}
                jobCount={home?.jobCount}
                inventoryCount={home?.inventoryCount}
                nt={home?.inventoryCount}
                customerCount={home?.customerCount}
              />
            </Stack>
            <Stack direction={'row'} gap={6}>
              <Stack w={'50%'}>
                <ListJobs Jobs={home?.jobs} />
              </Stack>
              <Stack w={'50%'}>
                <RecentReports ReportData={home?.chartData} />
              </Stack>
            </Stack>
            <Stack direction={'row'} gap={6}>
              <Stack w={'50%'}>
                <JobHistory Job={home?.jobs} />
              </Stack>
              <Stack w={'50%'}>
                <InventoryDetails Items={home?.inventoryData} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </MainDashboard>
    </>
  );
}
