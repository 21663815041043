import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Stack,
  Heading,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

const InvoicePrint = ({ data }) => {
  const user = useSelector(state => state?.value);
  const location = useLocation();
  const Params = useParams();
  const [invoiceDetail, setinvoiceDetail] = useState(null);

  const getUserFields = async () => {
    let res = await GET(`/invoice/${Params.id}`, {
      Authorization: `Bearer ${user?.verificationToken}`,
    });
    if (res?.status === 'success') {
      setinvoiceDetail(res?.data);
      return res?.data;
    }
  };

  useEffect(() => {
    console.log({ user });
    async function fetchData() {
      if (user) {
        const data = await getUserFields();
        setinvoiceDetail(data);

        if (data) {
          console.log({ data }, 'platformdata');
          setTimeout(() => {
            const printContents =
              document?.getElementById('divToPrint')?.innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            // print close action
            const handleAfterPrint = () => {
              window.close();
            };

            window.addEventListener('afterprint', handleAfterPrint);
            window.close();

            document.body.innerHTML = originalContents;
          }, 1000);
        }
      }
    }
    fetchData();
  }, [user]);

  return (
    <>
      <Stack background={'white'} height={'100vh'}>
        {invoiceDetail && (
          <Stack id="divToPrint" gap={6}>
            <Heading textAlign={'center'}>Customer Invoice</Heading>
            <Stack>
              <Heading size={'md'}>Vehicle Details</Heading>
              <TableContainer>
                <Table variant="simple" border={'none'} size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Make:</Td>
                      <Td>{invoiceDetail?.vehicleId?.make}</Td>
                    </Tr>
                    <Tr>
                      <Td>Model:</Td>
                      <Td>{invoiceDetail?.vehicleId?.model}</Td>
                    </Tr>
                    <Tr>
                      <Td>Sub Model:</Td>
                      <Td>{invoiceDetail?.vehicleId?.subModel}</Td>
                    </Tr>
                    <Tr>
                      <Td>Year:</Td>
                      <Td>{invoiceDetail?.vehicleId?.year}</Td>
                    </Tr>
                    <Tr>
                      <Td>Vin #:</Td>
                      <Td>{invoiceDetail?.vehicleId?.vinNumber}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack>
              <Heading size={'md'}>Customer Details</Heading>
              <TableContainer>
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Name:</Td>
                      <Td>{invoiceDetail?.customerId?.firstName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Last Name:</Td>
                      <Td>{invoiceDetail?.customerId?.lastName}</Td>
                    </Tr>
                    <Tr>
                      <Td>Email:</Td>
                      <Td>{invoiceDetail?.customerId?.email}</Td>
                    </Tr>
                    <Tr>
                      <Td>Phone:</Td>
                      <Td>{invoiceDetail?.customerId?.number}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack>
              <Heading size={'md'}>Services</Heading>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Service/Parts</Th>
                      <Th isNumeric textAlign={'center !important'}>
                        Quantity
                      </Th>
                      <Th isNumeric textAlign={'center !important'}>
                        Price
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invoiceDetail?.service?.map((service, index) => (
                      <Tr key={index}>
                        <Td>{service?.id?.name}</Td>
                        <Td textAlign={'center'}>1</Td>
                        <Td textAlign={'center'}>${service?.price}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              {/* <Heading>Services</Heading>
              <table>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceData?.map((service, index) => (
                    <tr key={index}>
                      <td>{service.service}</td>
                      <td>{service.price}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </Stack>
            <Stack>
              <Heading size={'md'}>Recommended Services/Parts</Heading>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Service/Parts</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invoiceDetail?.jobId?.recommendedService?.map(
                      (service, index) => (
                        <Tr key={index}>
                          <Td>{service?.name}</Td>
                        </Tr>
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack>
              <Heading size={'md'}>Total</Heading>
              <TableContainer>
                <Table size="sm">
                  <Tbody>
                    <Tr>
                      <Td>Subtotal:</Td>
                      <Td>${invoiceDetail?.jobId?.totalPrice}</Td>
                    </Tr>
                    <Tr>
                      <Td>Tax:</Td>
                      <Td>
                        ${(invoiceDetail?.jobId?.totalPrice * 0.1).toFixed(2)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>Total:</Td>
                      <Td>${invoiceDetail?.totalPrice}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default InvoicePrint;
