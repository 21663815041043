import {
  Avatar,
  Box,
  Heading,
  Link,
  Stack,
  Text,
  Button,
} from '@chakra-ui/react';
import { React, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Listowners({ Owner }) {
  const navigate = useNavigate();

  return (
    <>
      <Stack>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="m4-h">
            <Heading as={'h4'}>List Of Owners</Heading>
          </Stack>
          <Stack>
            <Link as={ReactLink} to={'/vendors'} color={'#7D7D7D'}>
              View All
            </Link>
          </Stack>
          {/* <Stack>
            <Link as={ReactLink} to={'/'} color={'#7D7D7D'}>
              View All
            </Link>
          </Stack> */}
        </Box>
        <Stack borderRadius={6} bg={'#202125'} gap={2} p={3}>
          {Owner?.length > 0 &&
            Owner.slice(0, 5).map((v, e) => {
              return (
                <Stack
                  direction={'row'}
                  py={4}
                  px={2}
                  borderRadius={6}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  bg={'#2E3035'}
                  key={e}
                >
                  <Box display={'flex'} gap={3}>
                    <Avatar name="Kent Dodds" src={v.avtar} />
                    <Box className="venbox p1 m3-h">
                      <Text>ID: {v._id.slice(0, 5)}</Text>
                      <Heading as={'h3'}>{v.fullName}</Heading>
                    </Box>
                  </Box>
                  <Stack className="venbox p1 m3-h">
                    <Button
                      padding={{ md: '10px 15px', '2xl': '10px 50px' }}
                      fontSize={{ md: '13px', '2xl': '16px' }}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'100%'}
                      textAlign={'center'}
                      onClick={() => navigate(`/ownerdetail/${v?._id}`)}
                    >
                      View Full Details
                    </Button>
                  </Stack>
                </Stack>
              );
            })}
        </Stack>
      </Stack>
    </>
  );
}
