import {
  Stack,
  Heading,
  Link,
  Icon,
  Text,
  Box,
  Button,
  Image,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

import { AiOutlineLock } from 'react-icons/ai';
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import defaultPic from '../../assets/images/defaultPic.svg';

import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST, PUT } from '../../utilities/ApiProvider.js';
import { ImgUrl } from '../../utilities/config.js';
import {
  loadUserName,
  loadStoreLogo,
  loadAdminPic,
} from '../../reducers/useReducers.js';

export default function HelpWrap() {
  const toast = useToast();
  const imgReg = useRef(null);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showpass, setshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [username, setUsername] = useState({
    fullName: '',
    phone: '',
  });
  const [pass, setPass] = useState({
    password: '',
    confirmPassword: '',
  });
  const user = useSelector(state => state?.value);

  const updateUser = async () => {
    setIsLoading(true);
    try {
      let res = await PUT(`/users/updateUser`, username, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('add generl responce ', res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  const uploadProfilePic = async () => {
    setUploadingImage(true);
    try {
      let form = document.getElementById('profilePicForm');
      let data = new FormData(form);
      let res = await PUT('/users/updateUser', data, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('upload pic ', res);
      dispatch(loadAdminPic(res?.data?.profile_picture));
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        duration: 2500,
      });
    }
    setUploadingImage(false);
  };

  const updatePass = async () => {
    setIsLoading(true);
    try {
      let res = await PUT(`/users/newPassword`, pass, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('update pass ', res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
      onClose();
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setUsername({
      fullName: user?.fullName,
      phone: user?.phone,
    });
    console.log('userName', user);
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
        mb={6}
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Setting /{' '}
            <Link to={'/dashboard'} as={ReactLink} color={'#2BAF59'}>
              Home
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack
        overflowY="auto"
        margin={'0 !important'}
        gap={8}
        py={10}
        px={6}
        bg={'#202125'}
        borderRadius={6}
      >
        <Stack
          direction="row"
          alignItems="center"
          mr="60px !important"
          justifyContent="space-between"
        >
          <Stack></Stack>
          <Stack className="btn">
            <Button
              onClick={onOpen}
              padding={'10px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
            >
              <Text as={'span'}>Change Password</Text>
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap="30px">
          <Box
            p="5px"
            w="138px"
            h="138px"
            borderRadius="100%"
            border={'4px solid'}
            borderColor="primaryGreen.100"
            overflow="hidden"
          >
            <Image
              src={`${ImgUrl}${user?.profile_picture}` ?? defaultPic}
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </Box>
          <Box>
            <form id="profilePicForm">
              <Input
                onChange={uploadProfilePic}
                ref={imgReg}
                display={'none'}
                type="file"
                name="profile_picture"
              />
            </form>
            <Stack className="btn" mb="20px">
              <Button
                isLoading={uploadingImage}
                onClick={() => imgReg?.current.click()}
                padding={'10px 50px'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                w={'fit-content'}
              >
                <Text as={'span'}>Upload Photo</Text>
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <Box className="m4-h">
            <Heading as={'h4'} mb="30px">
              General Settings
            </Heading>
          </Box>
          <Stack
            maxW="85%"
            direction="row"
            flexWrap="wrap"
            spacing="0"
            alignItems="flex-end"
          >
            <Box w="48%">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Full Name
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="text"
                  value={username?.fullName}
                  onChange={e =>
                    setUsername({ ...username, fullName: e.target.value })
                  }
                />
              </Box>
            </Box>
            <Box w="48%" ml="20px !important">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Email Address
              </FormLabel>
              <Box className="Ifiled">
                <Input value={user?.email} readOnly cursor={'not-allowed'} />
              </Box>
            </Box>
            <Box w="48%" mt="30px !important">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Phone Number
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  value={username?.phone}
                  onChange={e =>
                    setUsername({ ...username, phone: e.target.value })
                  }
                  type="number"
                />
              </Box>
            </Box>
            <Box ml="40px !important">
              <Stack className="btn">
                <Button
                  isLoading={isLoading}
                  onClick={updateUser}
                  padding={'10px 50px'}
                  bg={
                    'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                  }
                  color={'#fff'}
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  borderRadius={12}
                  _hover={{
                    color: '#000',
                  }}
                  w={'fit-content'}
                >
                  <Text as={'span'}>Save</Text>
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent
          bgSize="cover"
          bgColor={'#242424'}
          bgPos="center"
          p="20px 40px"
        >
          <ModalHeader
            fontWeight="600"
            fontSize={'24px'}
            color={'#fff'}
            textAlign="center"
          >
            Change Password
          </ModalHeader>
          <ModalBody>
            <FormLabel color={'#fff'} mt="20px !important" fontWeight="600">
              New Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#2BAF59'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                value={pass.password}
                onChange={e =>
                  setPass({
                    ...pass,
                    password: e.target.value,
                  })
                }
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
            <FormLabel mt="20px !important" color={'#fff'} fontWeight="600">
              Re-type Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#2BAF59'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                placeholder="Enter here"
                value={pass.confirmPassword}
                onChange={e =>
                  setPass({
                    ...pass,
                    confirmPassword: e.target.value,
                  })
                }
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Stack className="btn">
              <Button
                onClick={updatePass}
                isLoading={isLoading}
                padding={'10px 50px'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                w={'fit-content'}
              >
                <Text as={'span'}>Save</Text>
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
