import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import CloseProcedure from '../../../components/csr/CashDrawer/CloseProcedure';
export default function CashClosing() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <CloseProcedure />
        </Stack>
      </MainDashboard>
    </>
  );
}
