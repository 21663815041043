import {
  Heading,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  useColorModeValue,
  Text,
  Container,
} from '@chakra-ui/react';
import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';

export default function FaqsList() {
  return (
    <>
      <Stack className={'bread m3-h p1'} mb={6}>
        <Heading as={'h3'}>Help & Contact</Heading>
        <Text>Frequently Asked Question</Text>
      </Stack>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
        <Accordion
          allowMultiple
          width="100%"
          maxW="lg"
          className="m5-h p2"
          rounded="lg"
        >
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Heading as={'h5'}>What is Chakra UI?</Heading>

              <ChevronDownIcon color={'#fff'} fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                Chakra UI is a simple and modular component library that gives
                developers the building blocks they need to create web
                applications.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Heading as={'h5'}>What advantages to use?</Heading>
              <ChevronDownIcon color={'#fff'} fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                Chakra UI offers a variety of advantages including ease of use,
                accessibility, and customization options. It also provides a
                comprehensive set of UI components and is fully compatible with
                React.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              p={4}
            >
              <Heading as={'h5'}>How to start using Chakra UI?</Heading>

              <ChevronDownIcon color={'#fff'} fontSize="24px" />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="gray.600">
                To get started with Chakra UI, you can install it via npm or
                yarn, and then import the components you need in your project.
                The Chakra UI documentation is also a great resource for getting
                started and learning more about the library.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Stack>
    </>
  );
}
