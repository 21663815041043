import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack, Text } from '@chakra-ui/react';
import CreateNewJob from '../../components/Createjob/CreateNewJob';


export default function Createjob() {
  return (
    <>
    <MainDashboard>
        <Stack px={4}>
        <CreateNewJob/>
        </Stack>
      </MainDashboard>
    </>
  )
}
