import {
  Avatar,
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Link,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { POST, PUT } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

export default function JobCard({
  _id,
  status,
  customer,
  jobNumber,
  invoice,
  update,
  service,
  vehicle,
  package: packageData,
}) {
  const user = useSelector(state => state.value);
  const toast = useToast();

  const markComplete = async id => {
    const payload = {
      status: 'completed',
    };
    if (invoice.length === 0) {
      toast({
        title: 'Please Generate an Invoice First',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      let response = await PUT(`/job/jobStatus/${id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        console.log('Job Marked As Done', response);
        toast({
          title: 'Job Marked As Done',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        update();
      } else {
        toast({
          title: 'Checkout Failed',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const markPaid = async () => {
    let servicepayload = [];

    servicepayload = service.map(val => val?.id?._id);
    packageData?.services.forEach(val => {
      if (val._id) {
        servicepayload.push(val._id);
      }
    });
    const payload = {
      servicesData: servicepayload,
      status: 'paid',
    };
    try {
      let response = await PUT(`/job/jobStatus/${_id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        toast({
          title: 'Job Marked As Paid',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        update();
      } else {
        toast({
          title: 'Checkout Failed',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Stack key={_id} py={6} px={4} borderRadius={6} bg={'#2E3035'}>
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderBottom={'1px solid #484545'}
        paddingBottom={5}
        marginBottom={4}
        position={'relative'}
      >
        <Box
          top={'-33px'}
          px={2}
          py={1}
          borderRadius={8}
          bg={'#2BAF59'}
          color={'#fff'}
          position={'absolute'}
        >
          {/* {console.log(customer?.existingCustomer, 'CHECK ')} */}
          {customer?.existingCustomer ? 'Existing Customer' : 'New Customer'}
          {/* Existing Customer */}
        </Box>
        <Box display={'flex'} gap={3}>
          <Avatar
            name={vehicle?.make}
            bgColor={'primaryGreen.100'}
            color="#1a1a1a"
            src={''}
          />
          <Box className="venbox p1 m3-h">
            <Text>ID: {jobNumber ?? '0'}</Text>
            <Heading as={'h3'}>
              {vehicle?.make} {vehicle?.model}
            </Heading>
          </Box>
        </Box>
        <Stack className="venbox p1 m3-h">
          <Link
            padding={{ md: '10px 15px', '2xl': '10px 50px' }}
            fontSize={{ md: '13px', '2xl': '16px' }}
            bg={'#2BAF59'}
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'100%'}
            textAlign={'center'}
            to={`/customerjobdetail/${_id}`}
            as={ReactLink}
          >
            View Full Details
          </Link>
          {/* {status !== 'paid' && (
            <Button
              padding={{ md: '10px 15px', '2xl': '10px 50px' }}
              fontSize={{ md: '13px', '2xl': '16px' }}
              bg={status === 'pending' ? '#DF2234' : '#E57100'}
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'100%'}
              textAlign={'center'}
              onClick={
                status === 'pending'
                  ? () => markComplete(_id)
                  : () => markPaid(_id)
              }
            >
              {status === 'pending' ? 'Checkout' : 'Pay'}
            </Button>
          )} */}
        </Stack>
      </Stack>
      <Stack className="m5-h">
        <Heading as={'h5'}>Service Details:</Heading>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box className="venbox p1 m3-h">
            <Text>Status</Text>
            <Heading as={'h3'} textTransform={'capitalize'}>
              {status ?? 'Pending'}
            </Heading>
          </Box>
          <Box className="venbox p1 m3-h">
            <Text>Coupon</Text>
            <Heading as={'h3'}>
              {invoice[0]?.discount?.length > 0 &&
              invoice[0]?.discount.some(discount => discount?.type === 'coupon')
                ? `${
                    invoice[0]?.discount.find(
                      discount => discount?.type === 'coupon'
                    )?.price
                  }%`
                : 'No coupon'}
            </Heading>
          </Box>
          <Box className="venbox p1 m3-h">
            <Text>Discount</Text>
            <Heading as={'h3'}>
              {invoice[0]?.discount?.length > 0 &&
              invoice[0]?.discount.some(
                discount => discount?.type === 'discount'
              )
                ? invoice[0]?.discount.find(
                    discount => discount?.type === 'discount'
                  )?.price
                : '0'}
              %
            </Heading>
          </Box>
          <Box className="venbox p1 m3-h">
            <Text>Invoice</Text>
            <Heading as={'h3'}>
              {invoice?.length > 0 ? invoice[0]?.invoiceNumber : '------'}
            </Heading>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}
