import {
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
  Text,
  SimpleGrid,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Image,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { formatDay } from '../../../utilities/helper';
import { ImgUrl } from '../../../utilities/config';
import defaultPic from '../../../assets/images/defaultPic.svg';
import { BsEye } from 'react-icons/bs';

const CloseProcedure = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [procedureData, setProcedureData] = useState();
  const [modalData, setModalData] = useState(null);
  const [closeData, setCloseData] = useState({
    inputDate: '',
    amount: '',
    cardReceipt: '',
    coupon: '',
    cheque: '',
    comment: '',
    cardReceiptImg: [],
    couponImg: [],
    chequeImg: [],
  });
  const [posId, setposId] = useState('');

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!posId) {
      toast({
        title: 'Error',
        description: 'Please select POS.',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
      navigate('/cashopening');
      return;
    }
    const payLoad = {
      inputDate: closeData.inputDate,
      amount: Number(closeData.amount),
      cardReceipt: closeData.cardReceipt,
      coupon: closeData.coupon,
      cheque: closeData.cheque,
      cardReceiptImg: closeData.cardReceiptImg,
      couponImg: closeData.couponImg,
      chequeImg: closeData.chequeImg,
    };
    const formData = new FormData();
    formData.append('inputDate', closeData.inputDate);
    formData.append('amount', Number(closeData.amount));
    formData.append('cardReceipt', closeData.cardReceipt);
    formData.append('coupon', closeData.coupon);
    formData.append('cheque', closeData.cheque);
    closeData.chequeImg.forEach(v => formData.append('cardReceiptImg', v));
    closeData.couponImg.forEach(v => formData.append('couponImg', v));
    closeData.chequeImg.forEach(v => formData.append('chequeImg', v));
    // Check if any value in payload except coupon is null
    const isFormIncomplete = !closeData.inputDate || !closeData.amount;
    if (isFormIncomplete) {
      setIsLoading(false);
      toast({
        description: 'Please fill in all the fields.',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }
    try {
      let response = await POST(
        `/cashDrawer/closeProcedure/${posId}`,
        formData,
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        setIsLoading(false);
        console.log(response?.data);
        setCloseData({
          inputDate: '',
          amount: '',
          cardReceipt: '',
          coupon: '',
          cheque: '',
          comment: '',
          cardReceiptImg: [],
          couponImg: [],
          chequeImg: [],
        });
        toast({
          title: 'Success',
          description: `${response.message}.`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
        getProcedure();
      } else {
        setIsLoading(false);
        toast({
          description: response?.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          duration: 3000,
        });
      }
      const formDataEntries = Array.from(formData.entries());
      console.log(formDataEntries.map(([key, value]) => ({ [key]: value })));
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
      console.log('ERROR', error.message);
    }
  };

  const getProcedure = async () => {
    try {
      let response = await POST(
        `/cashDrawer/view`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (response?.status === 'success') {
        setProcedureData(response?.data);
        console.log(response?.data, 'KAKAKAKAKAKAKAKAKAK');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleImageUpload = (event, type) => {
    const files = Array.from(event.target.files);
    setCloseData(prevState => ({
      ...prevState,
      [type]: files,
    }));
  };

  function handleOpen(item) {
    console.log(item, 'MMMOOODDAAA');

    const { closeProcedureData, uploadPicture } = item;
    const modalData = { ...closeProcedureData, uploadPicture };

    setModalData(modalData);
    onOpen();
  }

  useEffect(() => {
    if (user) {
      getProcedure();
    }
    console.log('ID', location?.state?.id);
    setposId(location?.state?.id);
    setCloseData({ ...closeData, amount: location?.state?.amount });
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Closing Procedures </Heading>
        </Stack>
      </SimpleGrid>
      <Stack px={6} py={12} borderRadius={8} gap={4}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap={6}>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Select Date
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="date"
                  value={closeData.inputDate}
                  onChange={e =>
                    setCloseData({ ...closeData, inputDate: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Cash Amount
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={closeData.amount}
                  onChange={e =>
                    setCloseData({ ...closeData, amount: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Credit Card Receipt
              </FormLabel>
              <Box className="Ifiled" padding={'0px'}>
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={closeData.cardReceipt}
                  onChange={e =>
                    setCloseData({ ...closeData, cardReceipt: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Card Receipt Image
              </FormLabel>
              <Box className="ufiled">
                <Input
                  padding={'6px !important'}
                  color={'#fff'}
                  type="file"
                  multiple
                  accept="image/*"
                  placeholder="Upload Picture"
                  onChange={e => handleImageUpload(e, 'cardReceiptImg')}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap={6}>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Coupons
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  value={closeData.coupon}
                  onChange={e =>
                    setCloseData({ ...closeData, coupon: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Coupon Image
              </FormLabel>
              <Box className="ufiled">
                <Input
                  padding={'6px !important'}
                  color={'#fff'}
                  type="file"
                  multiple
                  accept="image/*"
                  placeholder="Upload Picture"
                  onChange={e => handleImageUpload(e, 'couponImg')}
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Cheque
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={closeData.cheque}
                  onChange={e =>
                    setCloseData({ ...closeData, cheque: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Cheque
              </FormLabel>
              <Box className="ufiled">
                <Input
                  padding={'6px !important'}
                  color={'#fff'}
                  type="file"
                  multiple
                  accept="image/*"
                  placeholder="Upload Picture"
                  onChange={e => handleImageUpload(e, 'chequeImg')}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack className="Ifiled label">
          <Box>
            <FormLabel
              fontWeight={400}
              m={0}
              marginBottom={12}
              color={'#949494'}
              fontSize={'14px'}
            >
              Leave A Comment
            </FormLabel>
            <Input
              height={24}
              value={closeData.comment}
              color={'#fff'}
              onChange={e =>
                setCloseData({ ...closeData, comment: e.target.value })
              }
            />
          </Box>
        </Stack>
        <Stack alignItems={'flex-end'}>
          <Button
            display={'flex'}
            alignItems={'center'}
            justifyItems={'center'}
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Save Job
          </Button>
        </Stack>
        <Divider />
        <Box>
          {procedureData && procedureData.length > 0 ? (
            procedureData
              ?.filter(val => val?.isClosed)
              .map((value, index) => (
                <Stack
                  key={value._id}
                  direction={'row'}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  py={5}
                  borderRadius={6}
                  bg={'#2e3035'}
                  px={4}
                  mb={4}
                  className="att-list"
                >
                  <Box className="m5-h p1" width={'7%'}>
                    <Text>No.</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {index + 1}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'7%'}>
                    <Text>Opened By</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {value?.userId?.fullName}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Date</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {new Date(
                        value?.closeProcedureData?.inputDate
                      ).toLocaleDateString()}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Day</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {formatDay(value?.inputDate)}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Cash Amount</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {value?.closeProcedureData?.amount
                        ? `$${value?.closeProcedureData?.amount}`
                        : 'N/A'}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Credit Card Receipt</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {value?.closeProcedureData?.cardReceipt}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Coupon</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {value?.closeProcedureData?.coupon &&
                      value?.closeProcedureData?.coupon !== ''
                        ? value?.closeProcedureData?.coupon
                        : 'N/A'}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1" width={'10%'}>
                    <Text>Cheque</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {value?.closeProcedureData?.cheque &&
                      value?.closeProcedureData?.cheque !== ''
                        ? value?.closeProcedureData?.cheque
                        : 'N/A'}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Attachment</Text>
                    <Button
                      display={'flex'}
                      borderRadius={50}
                      alignItems={'center'}
                      justifyContent={'center'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      w={'40px'}
                      h={'40px'}
                      color={'#fff'}
                      margin={'auto'}
                      onClick={() => handleOpen(value)}
                    >
                      <BsEye />
                    </Button>
                  </Box>
                </Stack>
              ))
          ) : (
            <Stack className={'bread m5-h'}>
              <Heading as={'h5'}>No POS Closed Yet </Heading>
            </Stack>
          )}
        </Box>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          alignContent={'center'}
          alignItems={'center'}
          bg={'#202125'}
          border={'1px solid #4D4D4D'}
          borderRadius={14}
        >
          <ModalHeader color={'#ffff'}>Attachment</ModalHeader>
          <ModalCloseButton />
          <ModalBody style={{ color: '#ffff' }}>
            <Stack alignItems={'center'}>
              <Box width={'100%'} className="venbox p1 m3-h">
                <Heading as={'h3'}>Opening Attachment</Heading>
                <Box display="flex" overflowX="scroll" maxWidth="100%">
                  <Image
                    draggable="false"
                    src={`${ImgUrl}${modalData?.uploadPicture}`}
                    width="130px"
                    height="100px"
                    alt="cheaque image"
                    px="2"
                  />
                </Box>
              </Box>
              <Box width={'100%'} className="venbox p1 m3-h">
                <Heading as={'h3'}>Cheques</Heading>
                {modalData?.chequeImg && modalData.chequeImg.length > 0 ? (
                  <Box
                    display="flex"
                    overflowX="scroll"
                    maxWidth="100%"
                    sx={{
                      '&::-webkit-scrollbar': {
                        display: 'block', // Show scrollbar
                        height: '6px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    {modalData.chequeImg.map((item, index) => (
                      <Image
                        draggable="false"
                        key={index}
                        src={`${ImgUrl}${item}`}
                        width="130px"
                        height="100px"
                        alt="cheaque image"
                        px="2"
                      />
                    ))}
                  </Box>
                ) : (
                  <Box>
                    <Text style={{ color: '#fff' }}>No images</Text>
                  </Box>
                )}
              </Box>
              <Box width={'100%'} className="venbox p1 m3-h">
                <Heading as={'h3'}>Credit Card Receipt</Heading>
                {modalData?.cardReceiptImg &&
                modalData.cardReceiptImg.length > 0 ? (
                  <Box
                    display="flex"
                    overflowX="scroll"
                    w="100%"
                    sx={{
                      '&::-webkit-scrollbar': {
                        display: 'block', // Show scrollbar
                        height: '6px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    {modalData.cardReceiptImg.map((item, index) => (
                      <Image
                        key={index}
                        draggable="false"
                        src={`${ImgUrl}${item}`}
                        width="130px"
                        height="100px"
                        px="2"
                      />
                    ))}
                  </Box>
                ) : (
                  <Box>
                    <Text style={{ color: '#fff' }}>No images</Text>
                  </Box>
                )}
              </Box>
              <Box width={'100%'}>
                <Text>Coupon Images</Text>

                {modalData?.couponImg && modalData.couponImg.length > 0 ? (
                  <Box
                    display="flex"
                    overflowX="auto"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    sx={{
                      '&::-webkit-scrollbar': {
                        display: 'block', // Show scrollbar
                        height: '6px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                      },
                    }}
                  >
                    {modalData.couponImg.map((item, index) => (
                      <Image
                        key={index}
                        draggable="false"
                        src={`${ImgUrl}${item}`}
                        width="130px"
                        height="100px"
                        // cursor={'pointer'}
                        px="2"
                      />
                    ))}
                  </Box>
                ) : (
                  <Box>
                    <Text style={{ color: '#fff' }}>No images</Text>
                  </Box>
                )}
              </Box>
            </Stack>

            {/* {closeData?.chequeImg ? (
            <Image
              src={`${ImgUrl}${closeData?.chequeImg[0]}` ?? null}
              w="100%"
              h="100%"
              objectFit="cover"
            />  
            
            ) : (
              <Text style={{color:'white'}}>No Attachment Found</Text>
            )} */}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              padding={'10px 50px'}
              bg={'#4D4D4D'}
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              textAlign={'center'}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CloseProcedure;
