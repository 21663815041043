import React, { useEffect, useState } from 'react';
import { GET, POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Heading, Link, SimpleGrid, Stack } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

const UserDetail = () => {
  const user = useSelector(state => state?.value);
  const Params = useParams();
  const [userData, setUserData] = useState(null);

  const getUserData = async () => {
    try {
      let response = await GET(`/users/getUser/${Params.id}`, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        setUserData(response?.data);
        console.log('User responce', response?.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Employee Details /{' '}
            <Link to={'/managereports'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
    </>
  );
};

export default UserDetail;
