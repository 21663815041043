import { Box, Divider, Heading, Link, Stack } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const RecentReports = ({ ReportData }) => {
  const doughnutData = {
    labels: ['Total Sales', 'Fleet Sales'],
    datasets: [
      {
        label: '% of sales',
        data: ReportData ? ReportData : [0, 0],
        backgroundColor: ['#F82A2A', '#46A434'],
        borderColor: ['#F82A2A', '#46A434'],
        borderWidth: 1,
      },
    ],
  };

  const doughnutOptions = {
    padding: '0px',
    responsive: true,
    maintainAspectRatio: true,
    defaultFontSize: '14px',
    plugins: {
      datalabels: {
        color: 'red',
        anchor: 'start',
        align: 'end',
      },
    },
  };

  return (
    <>
      <Stack>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="m4-h">
            <Heading as={'h4'}>Recent Reports</Heading>
          </Stack>
          <Stack>
            <Link as={ReactLink} to={'/reports'} color={'#7D7D7D'}>
              View All
            </Link>
          </Stack>
        </Box>
        <Stack borderRadius={6} bg={'#202125'} gap={3} p={6}>
          <Stack py={4} height={'284px'} display={'flex'} alignItems={'center'}>
            <Doughnut options={doughnutOptions} data={doughnutData} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default RecentReports;
