import React from 'react'
import { Stack } from '@chakra-ui/react'
import MainDashboard from '../../../components/DashNav/MainDashboard'
import JobDetailnew from '../../../components/csr/Job/JobDetailnew'

export default function Jobdetail() {
  return (
    <>
    <MainDashboard>
        <Stack px={4}>
       <JobDetailnew/>
        </Stack>
      </MainDashboard>
    </>
  )
}
