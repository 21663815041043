import React from 'react'
import { Stack } from '@chakra-ui/react'
import MainDashboard from '../../../components/DashNav/MainDashboard'
import ListOfjob from '../../../components/csr/Job/ListOfjob'

export default function ListOfjobs() {
  return (
    <>
     <MainDashboard>
        <Stack px={4}>
         <ListOfjob/>
        </Stack>
      </MainDashboard>
    </>
  )
}
