import React from 'react'
import { Stack } from '@chakra-ui/react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import JobInvoice from '../../../components/Technician/Jobs/JobInvoice';

export default function JobInovice() {
  return (
    <>
    <MainDashboard>
        <Stack px={4}>
       <JobInvoice/>
        </Stack>
      </MainDashboard>
    </>
  )
}
