import {
  Heading,
  Stack,
  Link,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Text,
  Badge,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { GET, POST } from '../../../utilities/ApiProvider';

export default function SaleInvoiceList() {
  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const getinvoiceList = async () => {
    setIsLoading(true);
    try {
      let res = await GET(`/invoice`, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('invoice list', res);
      if (res?.status === 'success') {
        setListData(res?.data);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (user) getinvoiceList();

    return () => {};
  }, []);

  return (
    <>
      <Stack className={'bread m3-h'} mb={8}>
        <Heading as={'h3'}>Sales Invoices</Heading>
      </Stack>

      {/* <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          alignItems={'flex-end'}
          borderBottom={'1px solid #2e3035'}
          pb={8}
          mb={6}
          gap={4}
        >
          <Stack className="Att">
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Start Date
            </FormLabel>
            <Box className="Ifiled">
              <Input type="date" placeholder="Start Date" />
            </Box>
          </Stack>
          <Stack className="Att">
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              End Date
            </FormLabel>
            <Box className="Ifiled">
              <Input type="date" placeholder="End Date" />
            </Box>
          </Stack>
          <Stack>
            <Button
              padding={'25px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
            >
              Search
            </Button>
          </Stack>
        </Stack>

        <TableContainer>
          <Table className="jobin m6-h p2">
            <Thead
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
            >
              <Tr>
                <Th>
                  <Heading as={'h5'}>Vehicle</Heading>
                </Th>
                <Th>
                  <Heading as={'h5'}>Name</Heading>
                </Th>
                <Th>
                  <Heading as={'h5'}>Service’s</Heading>
                </Th>
                <Th>
                  <Heading as={'h5'}>Coupon</Heading>
                </Th>
                <Th>
                  <Heading as={'h5'}>Status</Heading>
                </Th>
                <Th>
                  <Heading as={'h5'}>Invoices</Heading>
                </Th>
                <Th>
                  <Heading as={'h5'}>Assign Technichian</Heading>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Text>Honda Civic (2017)</Text>
                </Td>
                <Td>
                  <Text>Theresa Webb</Text>
                </Td>
                <Td>
                  <Text>maintenance</Text>
                </Td>
                <Td>
                  <Text>#1763121</Text>
                </Td>
                <Td>
                  <Text>In Progress</Text>
                </Td>
                <Td>
                  <Text>N/A</Text>
                </Td>
                <Td>
                  <Text>Brooklyn Simmons</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text>Honda Civic (2017)</Text>
                </Td>
                <Td>
                  <Text>Theresa Webb</Text>
                </Td>
                <Td>
                  <Text>maintenance</Text>
                </Td>
                <Td>
                  <Text>#1763121</Text>
                </Td>
                <Td>
                  <Text>In Progress</Text>
                </Td>
                <Td>
                  <Text>N/A</Text>
                </Td>
                <Td>
                  <Text>Brooklyn Simmons</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text>Honda Civic (2017)</Text>
                </Td>
                <Td>
                  <Text>Theresa Webb</Text>
                </Td>
                <Td>
                  <Text>maintenance</Text>
                </Td>
                <Td>
                  <Text>#1763121</Text>
                </Td>
                <Td>
                  <Text>In Progress</Text>
                </Td>
                <Td>
                  <Text>N/A</Text>
                </Td>
                <Td>
                  <Text>Brooklyn Simmons</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text>Honda Civic (2017)</Text>
                </Td>
                <Td>
                  <Text>Theresa Webb</Text>
                </Td>
                <Td>
                  <Text>maintenance</Text>
                </Td>
                <Td>
                  <Text>#1763121</Text>
                </Td>
                <Td>
                  <Text>In Progress</Text>
                </Td>
                <Td>
                  <Text>N/A</Text>
                </Td>
                <Td>
                  <Text>Brooklyn Simmons</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Stack> */}
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
        <Stack className="m4-h adser" gap={4}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            className="p1"
          >
            <Heading as={'h4'}>List of Invoices</Heading>

            <Text>
              Total Invoices:{' '}
              <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                {listData?.length ?? 0}
              </Text>
            </Text>
          </Stack>
          {listData?.length > 0 ? (
            listData?.map((v, k) => {
              return (
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  py={5}
                  borderRadius={6}
                  bg={'#2e3035'}
                  px={4}
                  className="att-list"
                  key={k}
                >
                  <Box className="m5-h p1">
                    <Text>Job Number</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {v?.jobId?.jobNumber}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Invoice Number</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {v?.invoiceNumber}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Status</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {v?.jobId?.status}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Customer type</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {v?.invoice?.length > 0 ? 'Old' : 'New'}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Vehicle Make</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {v?.vehicleId?.make}
                    </Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Services</Text>
                    <Heading as={'h5'} textTransform={'capitalize'}>
                      {v?.service?.length}
                    </Heading>
                  </Box>

                  <Box className="m5-h p1">
                    <Text>View Detail</Text>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Link
                        display={'flex'}
                        borderRadius={50}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bg={
                          'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        }
                        w={'40px'}
                        h={'40px'}
                        color={'#fff'}
                        margin={'auto'}
                        to={`/invoicedetail/${v?._id}`}
                        as={ReactLink}
                      >
                        <BsEye />
                      </Link>
                    </Stack>
                  </Box>
                  <Box display={'flex'} gap={4}></Box>
                </Stack>
              );
            })
          ) : (
            <Stack className={'bread m5-h'} py={5}>
              <Heading as={'h5'}>No Invoices Found </Heading>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}
