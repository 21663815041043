import React from 'react'
import { Stack } from '@chakra-ui/react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import Techniciandash from '../../../components/Technician/Dashboard/Techniciandash';

export default function Technician() {
  return (
    <MainDashboard>
    <Stack px={4}>
    <Techniciandash/>
    </Stack>
  </MainDashboard>
  )
}
