import React, { createContext } from 'react';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';
import RouteSwitch from './RouteSwitch';
import NotFound from '../views/NotFound';
import Home from '../views/Home';
import Register from '../views/Auth/Register';
import Login from '../views/Auth/Login';
import ForgotPassword from '../views/Auth/ForgotPassword';
import Verification from '../views/Auth/Verification';
import NewPassword from '../views/Auth/NewPassword';
import Vender from '../views/Vender/Vender';
import AddVender from '../views/Vender/AddVender';
import Services from '../views/Service/Services';
import AddServices from '../views/Service/AddServices';
import Employes from '../views/Employess/Employes';
import AddEmployes from '../views/Employess/AddEmployes';
import EmployesDetail from '../views/Employess/EmployesDetail';
import Coupons from '../views/Coupons/Coupons';
import Addcoupons from '../views/Coupons/Addcoupons';
import HomeDashboard from '../views/Dashboard/HomeDashboard.js';
import Venderdetail from '../views/Vender/Venderdetail.js';
import Ownerdetail from '../views/Owners/Ownerdetail.js';
import Setting from '../views/Setting/Setting.js';
import Attendance from '../views/Attendance/Attendance.js';
import MyRequests from '../views/Attendance/MyRequests.js';
import ApplyRequest from '../views/Attendance/ApplyRequest.js';
import Createjob from '../views/Createjob/Createjob.js';
import Job from '../views/Technician/Jobs/Job.js';
import JobDetail from '../views/Technician/Jobs/JobDetail.js';
import JobInovice from '../views/Technician/Jobs/JobInovice.js';
import Technician from '../views/Technician/Dashboard/Technician.js';
import SaleInvoice from '../views/Technician/Sale/SaleInvoice.js';
import ListOfjobs from '../views/csr/Job/ListOfjobs.js';
import Jobdetail from '../views/csr/Job/Jobdetail.js';
import OurOffers from '../views/csr/Offer/OurOffers.js';
import Faqs from '../views/Faq/Faqs.js';
import CsrDasboard from '../views/csr/Dashboard/CsrDasboard.js';
import InvoiceDetail from '../views/Technician/Sale/InvoiceDetail.js';
import CashOpening from '../views/csr/CashDrawer/CashOpening.js';
import CashClosing from '../views/csr/CashDrawer/CashClosing.js';
import Reports from '../views/Reports/Reports.js';
import ManageReport from '../views/Reports/ManageReport.js';
import UserReport from '../views/Reports/UserReport.js';
import ItemReport from '../views/Reports/ItemReport.js';
import Inventory from '../views/Inventory/Dashboard.js';
import ItemAdd from '../views/Inventory/ItemAdd.js';
import DashboardManager from '../views/Manager/DashboardManager.js';
import BlockEmployee from '../views/BlockEmployee/EmployeeBlock.js';
import ReorderList from '../views/Reorder/ReorderList.js';
import DashboardOwner from '../views/Owners/OwnerDashboard.js';
import PackageAdd from '../views/Package/PackageAdd.js';
import AddDiscount from '../views/Discount/AddDiscount.js';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import InvoicePrint from '../components/csr/Job/InvoicePrint.js';

export const UserContext = createContext();

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  let user = JSON.parse(localStorage.getItem('liqteqUser') ?? '{}');
  if (!user.verificationToken) {
    navigate('/login');
    if (!localStorage.getItem('toastDisplayed')) {
      toast({
        title: 'Please Login First',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      localStorage.setItem('toastDisplayed', true);
    }
  }
  return children;
};

export default function AppRoute() {
  // function CheckAuth(params) {
  //   let user = JSON.parse(localStorage.getItem('liqteqUser') ?? '{}')
  //   if (user === null || user === undefined) {
  //     return <Login />
  //   }
  //   return <Home />
  // }

  return (
    <div>
      <Router>
        <RouteSwitch>
          <Route exact path="/" element={<Home />}></Route>
          <Route
            exact
            path="/admindashboard"
            element={
              <PrivateRoute>
                <HomeDashboard />
              </PrivateRoute>
            }
          ></Route>
          <Route exact path="/register" element={<Register />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route
            exact
            path="/forgotpasssword"
            element={<ForgotPassword />}
          ></Route>
          <Route exact path="/verification" element={<Verification />}></Route>
          <Route exact path="/newpassword" element={<NewPassword />}></Route>

          <Route
            exact
            path="/vendors"
            element={
              <PrivateRoute>
                <Vender />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/addvendor"
            element={
              <PrivateRoute>
                <AddVender />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/venderdetail/:id"
            element={
              <PrivateRoute>
                <Venderdetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/ownerdetail/:id"
            element={
              <PrivateRoute>
                <Ownerdetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/services"
            element={
              <PrivateRoute>
                <Services />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/addservices"
            element={
              <PrivateRoute>
                <AddServices />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/employees"
            element={
              <PrivateRoute>
                <Employes />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/addemployes"
            element={
              <PrivateRoute>
                <AddEmployes />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/employedetail/:id"
            element={
              <PrivateRoute>
                <EmployesDetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/attendance"
            element={
              <PrivateRoute>
                <Attendance />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/myrequest"
            element={
              <PrivateRoute>
                <MyRequests />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/applyrequest"
            element={
              <PrivateRoute>
                <ApplyRequest />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/coupons"
            element={
              <PrivateRoute>
                <Coupons />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/addcoupons"
            element={
              <PrivateRoute>
                <Addcoupons />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/setting"
            element={
              <PrivateRoute>
                <Setting />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/faqs"
            element={
              <PrivateRoute>
                <Faqs />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/createjob"
            element={
              <PrivateRoute>
                <Createjob />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/techniciandashboard"
            element={
              <PrivateRoute>
                <Technician />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/jobs"
            element={
              <PrivateRoute>
                <Job />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/jobdetail"
            element={
              <PrivateRoute>
                <JobDetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/jobinvoice/:id"
            element={
              <PrivateRoute>
                <JobInovice />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/saleinvoice"
            element={
              <PrivateRoute>
                <SaleInvoice />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/invoicedetail/:id"
            element={
              <PrivateRoute>
                <InvoiceDetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/csrdashboard"
            element={
              <PrivateRoute>
                <CsrDasboard />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/listofjob"
            element={
              <PrivateRoute>
                <ListOfjobs />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/customerjobdetail/:id"
            element={
              <PrivateRoute>
                <Jobdetail />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/ouroffers"
            element={
              <PrivateRoute>
                <OurOffers />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/cashopening"
            element={
              <PrivateRoute>
                <CashOpening />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/cashclosing"
            element={
              <PrivateRoute>
                <CashClosing />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/managereports"
            element={
              <PrivateRoute>
                <ManageReport />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/userdetail/:id"
            element={
              <PrivateRoute>
                <UserReport />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/itemdetail/:id"
            element={
              <PrivateRoute>
                <ItemReport />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/inventory"
            element={
              <PrivateRoute>
                <Inventory />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/additem"
            element={
              <PrivateRoute>
                <ItemAdd />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/managerdashboard"
            element={
              <PrivateRoute>
                <DashboardManager />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/blockemployee"
            element={
              <PrivateRoute>
                <BlockEmployee />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/reorder"
            element={
              <PrivateRoute>
                <ReorderList />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/ownerdashboard"
            element={
              <PrivateRoute>
                <DashboardOwner />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/createpackage"
            element={
              <PrivateRoute>
                <PackageAdd />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/creatediscount"
            element={
              <PrivateRoute>
                <AddDiscount />
              </PrivateRoute>
            }
          ></Route>
          <Route exact path='/invoicePrint/:id' element={
            <PrivateRoute>
              <InvoicePrint />
            </PrivateRoute>
          }>

          </Route>

          <Route exact path="*" element={<NotFound />}></Route>
        </RouteSwitch>
      </Router>
    </div>
  );
}
