import {
    Box,
    Heading,
    SimpleGrid,
    Stack,
    Link,
    Radio,
    RadioGroup,
    Text,
    Avatar,
    UnorderedList,
    ListItem,
    MenuButton,
    MenuList,
    MenuItem,
    Menu,
    Icon,
    Spinner,
    Button,
    Badge,
  } from '@chakra-ui/react';
  import React, { useEffect, useState } from 'react';
  import { Link as ReactLink, useParams } from 'react-router-dom';
  import L3 from '../../assets/images/data/l3.png';
  import { BsThreeDots } from 'react-icons/bs';
  import { IoIosArrowDown } from 'react-icons/io';
  import {
    empdetaildata,
    epmattendance,
    epmattendanceReq,
  } from '../../assets/data/Data.js';
  import { BiEditAlt } from 'react-icons/bi';
  import { AiOutlineDelete } from 'react-icons/ai';
  import { GET } from '../../utilities/ApiProvider.js';
  import { useSelector } from 'react-redux';


export default function Singleowner() {

    const user = useSelector(state => state.value);
    const Params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [empdetail, setempdetail] = useState('');
    const [issuerbtn, setissuerbtn] = useState('individual');
  
    useEffect(() => {
      if (user) {
        getUserFields();
      }
    }, [user]);
  
    const getUserFields = async () => {
      setIsLoading(true)
      let SingleuserData = await GET(
        `/users/getUser/${Params.id}`,
  
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      console.log('single Owner ' ,SingleuserData);
      setempdetail(SingleuserData?.data);
      setIsLoading(false)
    };
  
    useEffect(() => {
      getUserFields();
    }, []);
  





  return (
    <>
   <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Owner Details
           
          </Heading>
        </Stack>
      </SimpleGrid>
      {
        isLoading ? 
        <Box display={'flex'} height={'80vh'} alignItems={'center'} justifyContent={'center'}>
          <Spinner   color='#fff' />
        </Box> :
        <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
        
        <Stack
          direction={'row'}
          py={6}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          borderBottom={'1px solid #595C64'}
        >
          <Box display={'flex'} gap={3} mb={6}>
            <Avatar name="Kent Dodds" src={L3} />
            <Box className="venbox p1 m3-h">
              <Text>Vendor Name</Text>
              <Heading as={'h3'}>{empdetail?.fullName}</Heading>
            </Box>
          </Box>

          <Stack className="venbox p1 m3-h">
            <Text>Email Address</Text>
            <Heading as={'h3'}>{empdetail?.email}</Heading>
          </Stack>
          <Stack className="venbox p1 m3-h">
            <Text>Member Role</Text>
            <Heading as={'h3'}>{empdetail?.role}</Heading>
          </Stack>
          <Stack className="venbox p1 m3-h">
            <Text>Member Since</Text>
            <Heading as={'h3'}>
              {' '}
              {new Date(empdetail?.createdAt).toLocaleDateString()}
            </Heading>
          </Stack>
          <Stack className="venbox p1 m3-h">
            <Text>Member Status</Text>
            <Badge
                      padding={'10px 20px'}
                      textAlign={'center'}
                      borderRadius={'25px'}
                      bg={
                        empdetail.active == false
                          ? '#f4de152b'
                          : 'rgba(46, 196, 135, 0.16)'
                      }
                      color={empdetail.active == false ? '#F4DE15' : '#2EC487'}
                    >
                      {empdetail.active == false ? 'Inactive' : 'active'}
                    </Badge>
          </Stack>
        </Stack>

      
      </Stack>
      }
      
    </>
  )
}
