import {
  Avatar,
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { ImgUrl } from '../../utilities/config';
import { formatDate } from '../../utilities/helper';

const Management = () => {
  const toast = useToast();
  const {
    isOpen: inStockOpen,
    onOpen: onStockOpen,
    onClose: onStockClose,
  } = useDisclosure();
  const {
    isOpen: inOutOpen,
    onOpen: onOutOpen,
    onClose: onOutClose,
  } = useDisclosure();
  const user = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [employeeName, setEmployeeName] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const getEmployeePerf = async () => {
    try {
      let response = await POST(
        `/reports/employeePerformance`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (response.status === 'success') {
        setEmployeeData(response?.data);
        console.log('Employee responce', response?.data);
        setEmployeeName(
          response?.data
            ?.filter(val => val.employeeType !== 'manager')
            .map(val => val.fullName)
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const getInventory = async () => {
    try {
      let response = await POST(
        `/inventory/view`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (response.status === 'success') {
        setInventoryData(response?.data);
        console.log('Inventory response', response?.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const filteredEmployeeData = employeeData?.filter(val =>
    val?.fullName.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    getEmployeePerf();
    getInventory();
  }, []);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Management Reports </Heading>
        </Stack>
      </SimpleGrid>
      <Stack py={8}>
        <Tabs variant="unstyled">
          <TabList mb={12} gap={6}>
            <Tab
              color={'#fff'}
              px={8}
              bg={'#4D4D4D'}
              borderRadius={6}
              _selected={{
                color: 'white',
                bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
              }}
            >
              Employee Performance
            </Tab>
            <Tab
              color={'#fff'}
              px={8}
              bg={'#4D4D4D'}
              borderRadius={6}
              _selected={{
                color: 'white',
                bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
              }}
            >
              Parts
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Stack
                py={6}
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack className="p1">
                  <Text>
                    Total Employees:{' '}
                    <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                      {filteredEmployeeData?.filter(
                        val => val.employeeType !== 'manager'
                      )?.length ?? 0}
                    </Text>
                  </Text>
                </Stack>
                <Box className="Ifiled">
                  <Input
                    value={searchValue}
                    type="text"
                    onChange={e => setSearchValue(e.target.value)}
                    placeholder="Search For Employee"
                  />
                </Box>
              </Stack>
              {/* <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                pb={3}
              >
                <Text color={'#fff'} opacity={'0.8'}>
                  All Members
                </Text>
                <Stack>
                  <Select
                    opacity={'0.8'}
                    variant="unstyled"
                    placeholder="All Members"
                    color="#fff"
                    onChange={e => setSelectedName(e.target.value)}
                  >
                    {employeeName.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </Stack> */}
              {filteredEmployeeData?.filter(
                val => val.employeeType !== 'manager'
              )?.length > 0 ? (
                filteredEmployeeData
                  ?.filter(val => val.employeeType !== 'manager')
                  .map((val, ind) => (
                    <Stack
                      key={val?._id}
                      direction={'row'}
                      flexWrap={'wrap'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      py={5}
                      borderRadius={6}
                      bg={'#2e3035'}
                      px={4}
                      mb={3}
                      className="att-list"
                    >
                      <Box className="m5-h p1" width={'20%'}>
                        <Stack
                          display={'flex'}
                          direction={'row'}
                          spacing={4}
                          m={0}
                        >
                          <Avatar
                            name={val.fullName}
                            bgColor={'primaryGreen.100'}
                            src={`${ImgUrl}${val?.userId?.profile_picture}`}
                            objectFit={'cover'}
                          />
                          <Stack>
                            <Text as={'h5'} opacity={'0.7'}>
                              ID: 28745
                            </Text>
                            <Text as={'h5'}>{val.fullName}</Text>
                          </Stack>
                        </Stack>
                      </Box>
                      <Box className="m5-h" width={'10%'}>
                        <Text color={'#fff'} opacity={'0.7'}>
                          Attendance{' '}
                        </Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          <Link
                            to={`/employedetail/${val?._id}`}
                            as={ReactLink}
                            color={'#218745'}
                          >
                            View Details
                          </Link>
                        </Heading>
                      </Box>
                      <Box className="m5-h" width={'10%'}>
                        <Text color={'#fff'} opacity={'0.7'}>
                          No. of Jobs
                        </Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {val?.jobCount}
                        </Heading>
                      </Box>
                      <Box className="m5-h" width={'10%'}>
                        <Text color={'#fff'} opacity={'0.7'}>
                          Member Since
                        </Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {formatDate(
                            String(val?.userId?.createdAt).split('T')[0]
                          )}{' '}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1" width={'20%'}>
                        <Button
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          to={`/employedetail/${val?._id}`}
                        >
                          <Link
                            to={`/employedetail/${val?._id}`}
                            as={ReactLink}
                          >
                            View Full Details
                          </Link>
                        </Button>
                      </Box>
                    </Stack>
                  ))
              ) : (
                <Stack className={'bread m5-h'}>
                  <Heading as={'h5'}>No Employees found</Heading>
                </Stack>
              )}
            </TabPanel>
            <TabPanel p={0}>
              <Divider />
              <Tabs variant="unstyled">
                <Stack
                  display={'flex'}
                  direction={'row'}
                  justifyContent={'space-between'}
                  mt={4}
                >
                  <Stack pt={6}>
                    <TabList mb={12} gap={6}>
                      <Tab
                        color={'#fff'}
                        px={8}
                        bg={'#4D4D4D'}
                        borderRadius={6}
                        _selected={{
                          color: 'white',
                          bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                        }}
                      >
                        In Stock
                      </Tab>
                      <Tab
                        color={'#fff'}
                        px={8}
                        bg={'#4D4D4D'}
                        borderRadius={6}
                        _selected={{
                          color: 'white',
                          bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                        }}
                      >
                        Out of Stock
                      </Tab>
                    </TabList>
                  </Stack>
                  {/* <Stack>
                    <Stack
                      display={'flex'}
                      direction={'row'}
                      alignItems={'end'}
                      justifyContent={'space-between'}
                    >
                      <Box color={'#fff'}>
                        <FormLabel
                          fontWeight={400}
                          m={0}
                          color={'#949494'}
                          fontSize={'14px'}
                          pb={2}
                        >
                          To
                        </FormLabel>
                        <Input type="date"></Input>
                      </Box>
                      <Box color={'#fff'}>
                        <FormLabel
                          fontWeight={400}
                          m={0}
                          color={'#949494'}
                          fontSize={'14px'}
                          pb={2}
                        >
                          From
                        </FormLabel>
                        <Input type="date"></Input>
                      </Box>
                      <Box>
                        <Button
                          color={'#fff'}
                          fontWeight={'500'}
                          lineHeight={'inherit'}
                          borderRadius={12}
                          _hover={{
                            color: '#000',
                          }}
                          w={'fit-content'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                        >
                          Search
                        </Button>
                      </Box>
                    </Stack>
                  </Stack> */}
                </Stack>
                <TabPanels>
                  <TabPanel p={0}>
                    <Stack display={'flex'} direction={'row'}>
                      <Box className="m4-h" padding={4}>
                        <Heading as={'h4'} fontSize={10} color={'#fff'}>
                          Total Items Cost: 866,248
                        </Heading>
                      </Box>
                    </Stack>
                    {inventoryData?.filter(val => val?.stock)?.length > 0 ? (
                      inventoryData
                        ?.filter(val => val?.stock)
                        .map((val, ind) => (
                          <Stack
                            key={val?._id}
                            direction={'row'}
                            flexWrap={'wrap'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            py={5}
                            borderRadius={6}
                            bg={'#2e3035'}
                            px={4}
                            mb={3}
                            className="att-list"
                          >
                            <Box className="m5-h" width={'20%'}>
                              <Text
                                padding={'3px 10px'}
                                color={'#2EC487'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                w={'fit-content'}
                                bg={'rgba(46, 196, 135, 0.16)'}
                              >
                                In Stock
                              </Text>
                              <Stack
                                display={'flex'}
                                direction={'row'}
                                spacing={4}
                                m={0}
                              >
                                <Avatar
                                  name={val.name}
                                  src={`${ImgUrl}${val?.uploadPicture}`}
                                  objectFit={'cover'}
                                />
                                <Stack>
                                  <Text as={'h5'} opacity={'0.7'}>
                                    Item Name
                                  </Text>
                                  <Text as={'h5'}>{val?.name}</Text>
                                </Stack>
                              </Stack>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                SKU{' '}
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                <Text>{val?.sku}</Text>
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Sales
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {val?.sales}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Price
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                ${val?.cost}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'15%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Category
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {val?.category?.name}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Number of Used
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {val?.available}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Use Case
                              </Text>
                              <Button
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                bg={
                                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                                }
                                color={'#fff'}
                              >
                                <Link
                                  to={`/itemdetail/${val?.productId}`}
                                  as={ReactLink}
                                >
                                  View
                                </Link>
                              </Button>
                              {/* <Modal isOpen={inStockOpen} onClose={onStockClose}>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalHeader>Modal Title</ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipisicing elit. Accusantium, autem,
                                    consequuntur, doloremque, doloribus
                                    doloremque, doloribus doloribus doloribus
                                    doloribus doloribus doloribus doloribus
                                    doloribus doloribus doloribus doloribus
                                  </ModalBody>

                                  <ModalFooter>
                                    <Button
                                      colorScheme="blue"
                                      mr={3}
                                      onClick={onStockClose}
                                    >
                                      Close
                                    </Button>
                                    <Button variant="ghost">
                                      Secondary Action
                                    </Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal> */}
                            </Box>
                          </Stack>
                        ))
                    ) : (
                      <Stack className={'bread m5-h'} my={4}>
                        <Heading as={'h5'}>No Item in-stock </Heading>
                      </Stack>
                    )}
                  </TabPanel>
                  <TabPanel p={0}>
                    <Stack display={'flex'} direction={'row'}>
                      <Box className="m4-h" padding={4}>
                        <Heading as={'h4'} fontSize={10} color={'#fff'}>
                          Total Items Cost: 866,248
                        </Heading>
                      </Box>
                    </Stack>
                    {inventoryData?.filter(val => !val?.stock).length > 0 ? (
                      inventoryData
                        ?.filter(val => !val?.stock)
                        .map((val, ind) => (
                          <Stack
                            key={val?._id}
                            direction={'row'}
                            flexWrap={'wrap'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            py={5}
                            borderRadius={6}
                            bg={'#2e3035'}
                            px={4}
                            mb={3}
                            className="att-list"
                          >
                            <Box className="m5-h" width={'20%'}>
                              <Text
                                padding={'3px 10px'}
                                color={'#F41515'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                w={'fit-content'}
                                bg={'rgba(244, 21, 21, 0.16)'}
                              >
                                Out Of Stock
                              </Text>
                              <Stack
                                display={'flex'}
                                direction={'row'}
                                spacing={4}
                                m={0}
                              >
                                <Avatar
                                  name={val.name}
                                  src={`${ImgUrl}${val?.uploadPicture}`}
                                  objectFit={'cover'}
                                />
                                <Stack>
                                  <Text as={'h5'} opacity={'0.7'}>
                                    Item Name
                                  </Text>
                                  <Text as={'h5'}>{val?.name}</Text>
                                </Stack>
                              </Stack>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                SKU{' '}
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                <Text>{val?.sku}</Text>
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Sales
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {val?.sales}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Price
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                ${val?.cost}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'15%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Category
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {val?.category?.name}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Number of Used
                              </Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {val?.available}
                              </Heading>
                            </Box>
                            <Box className="m5-h" width={'10%'}>
                              <Text color={'#fff'} opacity={'0.7'}>
                                Use Case
                              </Text>
                              <Button
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                bg={
                                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                                }
                                color={'#fff'}
                              >
                                <Link
                                  to={`/itemdetail/${val?.productId}`}
                                  as={ReactLink}
                                >
                                  View
                                </Link>
                              </Button>
                              {/* <Modal isOpen={inOutOpen} onClose={onOutClose}>
                                <ModalOverlay />
                                <ModalContent>
                                  <ModalHeader>Modal Title</ModalHeader>
                                  <ModalCloseButton />
                                  <ModalBody></ModalBody>

                                  <ModalFooter>
                                    <Button
                                      colorScheme="blue"
                                      mr={3}
                                      onClick={onOutClose}
                                    >
                                      Close
                                    </Button>
                                    <Button variant="ghost">
                                      Secondary Action
                                    </Button>
                                  </ModalFooter>
                                </ModalContent>
                              </Modal> */}
                            </Box>
                          </Stack>
                        ))
                    ) : (
                      <Stack className={'bread m5-h'} my={4}>
                        <Heading as={'h5'}>No Item out of stock </Heading>
                      </Stack>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  );
};

export default Management;
