import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Stack,
  Divider,
  Text,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Image,
  useToast,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { ImgUrl } from '../../../utilities/config';
import defaultPic from '../../../assets/images/defaultPic.svg';
import { formatDay } from '../../../utilities/helper';

const OpenProcedure = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [procedureData, setProcedureData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openData, setOpenData] = useState({
    inputDate: '',
    amount: '',
    uploadPicture: null,
  });
  const [modalData, setModalData] = useState(null);

  const getProcedure = async () => {
    try {
      let response = await POST(
        `/cashDrawer/view`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (response?.status === 'success') {
        setProcedureData(response?.data);
        console.log(response?.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const payLoad = new FormData();
    payLoad.append('inputDate', openData.inputDate);
    payLoad.append('amount', openData.amount);
    payLoad.append('uploadPicture', openData.uploadPicture);
    console.log('Open Payload', payLoad);

    // Check if all fields are filled
    if (!openData.inputDate || !openData.amount) {
      setIsLoading(false);
      toast({
        description: 'Please fill in all fields.',
        status: 'warning',
        position: 'top-right',
        isClosable: true,
      });
      return;
    }

    try {
      let response = await POST(`/cashDrawer/openProcedure`, payLoad, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response?.status === 'success') {
        setIsLoading(false);
        console.log(response?.data);
        setOpenData({
          inputDate: '',
          amount: '',
          uploadPicture: null,
        });
        toast({
          title: 'Success',
          description: `${response.message}.`,
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
        getProcedure();
      } else {
        setIsLoading(false);
        toast({
          description: response?.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          duration: 3000,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
      console.log('ERROR', error.message);
    }
  };

  function handleOpen(modalData) {
    setModalData(modalData);
    onOpen();
  }

  useEffect(() => {
    if (user) {
      getProcedure();
    }
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Opening Procedures </Heading>
        </Stack>
      </SimpleGrid>
      <Stack px={6} py={12} gap={4}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'} gap={6}>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Select Date
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  value={openData.inputDate}
                  onChange={e =>
                    setOpenData({ ...openData, inputDate: e.target.value })
                  }
                  type="date"
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Cash Amount
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  placeholder="Enter Amount"
                  value={openData.amount}
                  onChange={e =>
                    setOpenData({ ...openData, amount: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack w={'100%'} className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Upload Picture*
              </FormLabel>
              <Box className="ufiled">
                <Input
                  padding={'6px !important'}
                  color={'#fff'}
                  type="file"
                  accept="image/*"
                  placeholder="Upload Picture"
                  onChange={e =>
                    setOpenData({
                      ...openData,
                      uploadPicture: e.target.files[0],
                    })
                  }
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Save Job
          </Button>
        </Stack>
        <Divider />
        {procedureData &&
        procedureData.filter(val => !val?.isClosed).length > 0 ? (
          procedureData
            .filter(val => !val?.isClosed)
            .sort((a, b) => new Date(b.inputDate) - new Date(a.inputDate))
            .map((value, index) => (
              <Stack
                key={value._id}
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={4}
                borderRadius={6}
                bg={'#2e3035'}
                px={4}
                className="att-list"
              >
                {console.log(value, 'valueeeeeeeeeee')}

                <Box className="m5-h p1" display={{ md: 'none', xl: 'block' }}>
                  <Text fontFamily={'Orbitron'}>No.</Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {index + 1}
                  </Heading>
                </Box>
                <Box className="m5-h p1">
                  <Text fontFamily={'Orbitron'}>Opened By</Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {value?.userId?.fullName
                      ? `${value?.userId?.fullName}`
                      : 'N/A'}
                  </Heading>
                </Box>
                <Box className="m5-h p1">
                  <Text fontFamily={'Orbitron'}>Date</Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {new Date(value?.inputDate).toLocaleDateString()}
                  </Heading>
                </Box>
                <Box className="m5-h p1" display={{ md: 'none', xl: 'block' }}>
                  <Text fontFamily={'Orbitron'}>Day</Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {formatDay(value?.inputDate)}
                  </Heading>
                </Box>
                <Box className="m5-h p1">
                  <Text fontFamily={'Orbitron'}>Cash Amount</Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {value?.amount ? `$${value?.amount}` : 'N/A'}
                  </Heading>
                </Box>
                <Box className="m5-h p1">
                  <Text fontFamily={'Orbitron'}>Attachment</Text>
                  <Button
                    display={'flex'}
                    borderRadius={50}
                    alignItems={'center'}
                    justifyContent={'center'}
                    p={0}
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    w={'40px'}
                    h={'40px'}
                    color={'#fff'}
                    margin={'auto'}
                    onClick={() => handleOpen(value)}
                  >
                    <BsEye />
                  </Button>
                </Box>
                {user._id === value.userId._id && (
                  <Box className="m5-h p1">
                    <Button
                      padding={'10px 50px'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      onClick={() =>
                        navigate('/cashclosing', {
                          state: { id: value?._id, amount: value?.amount },
                        })
                      }
                    >
                      Close Job
                    </Button>
                  </Box>
                )}
              </Stack>
            ))
        ) : (
          <Stack className={'bread m5-h'}>
            <Heading as={'h5'}>No POS Opened Yet </Heading>
          </Stack>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          alignContent={'center'}
          alignItems={'center'}
          bg={'#202125'}
          border={'1px solid #4D4D4D'}
          borderRadius={14}
        >
          <ModalHeader color={'#ffff'}>Attachment</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalData?.uploadPicture !== 'null' ? (
              <Image
                src={`${ImgUrl}${modalData?.uploadPicture}` ?? defaultPic}
                w="100%"
                h="100%"
                objectFit="cover"
              />
            ) : (
              <Text color={'#ffff'}>No Attachment Found</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              padding={'10px 50px'}
              bg={'#4D4D4D'}
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              textAlign={'center'}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OpenProcedure;
