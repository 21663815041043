import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import AttendanceRequest from '../../components/Attendance/AttendanceRequest';

export default function MyRequests() {
  return (
    <>
       <MainDashboard>
        <Stack px={4}>
       <AttendanceRequest/>
        </Stack>
      </MainDashboard>
    </>
  )
}
