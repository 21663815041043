import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  useToast,
  Spinner,
  ModalFooter,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { venders } from '../../assets/data/Data.js';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DELETE, POST, PUT } from '../../utilities/ApiProvider.js';
import { ImgUrl } from '../../utilities/config.js';
import { BsEye } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';

export default function Allvender() {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.value);
  const [venderlist, setvenderlist] = useState('');
  const [updatedData, setupdateData] = useState({
    name: '',
    contact: '',
    email: '',
    id: '',
  });

  useEffect(() => {
    if (user) {
      getvenderlist();
    }
  }, [user]);

  const getvenderlist = async () => {
    setIsLoading(true);
    console.log('vender Hit');
    let venderData = await POST(
      `/vendor/getVendor`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log('vender Hit', venderData);
    setvenderlist(venderData?.data);
    console.log('vender state', venderlist);
    setIsLoading(false);
  };

  const handleDelete = async id => {
    console.log(id);
    try {
      let res = await DELETE(`/coupon/deleteVendor/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('Vendor delete', res);
      if (res.status === 'success') {
        toast({
          description: res.message,
          status: res.status,
          position: 'top-right',
          duration: 3000,
        });
        getvenderlist();
      }
    } catch (error) {
      console.error('Error deleting Vendor:', error);
    }
  };

  const handleUpdateVendor = async () => {
    const payLoad = {
      name: updatedData.name,
      phone: updatedData.contact,
      email: updatedData.email,
    };
    try {
      let response = await PUT(
        `/vendor/updateVendor/${updatedData.id}`,
        payLoad,
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response.status === 'success') {
        getvenderlist();
        onEditClose();
        setIsLoading(false);
      }
      toast({
        description: response.message,
        status: response.status,
        position: 'top-right',
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <>
        <Modal isOpen={isEditOpen} onClose={onEditClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'100%'}>
            <ModalHeader color={'#fff'}>Edit Details</ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={6}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Vendor Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.name}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Vendor Name"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Contact Number
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.contact}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          contact: e.target.value,
                        })
                      }
                      placeholder="Enter Contact"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Email Address
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.email}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          email: e.target.value,
                        })
                      }
                      placeholder="Enter Email"
                    />
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                isLoading={isLoading}
                onClick={() => {
                  handleUpdateVendor();
                }}
              >
                Update
              </Button>
              <Button colorScheme="red" value={'Cancel'} onClick={onEditClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>All Vendor</Heading>
        </Stack>
        <Stack alignItems={{ base: 'start', xl: 'end' }}>
          <Link
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/addvendor'}
            as={ReactLink}
          >
            Add New Vendor
          </Link>
        </Stack>
      </SimpleGrid>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack>
          <Stack className="p1">
            <Text>
              Total Venders:{' '}
              <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                {venderlist?.length ?? 0}
              </Text>
            </Text>
          </Stack>
          <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
            <SimpleGrid
              columns={{
                base: '1',
                sm: '1',
                md: '1',
                lg: '2',
                xl: '2',
                '2xl': '2',
              }}
              spacingX="40px"
              spacingY="20px"
            >
              {venderlist?.length > 0 ? (
                venderlist?.map((v, e) => {
                  return (
                    <Stack
                      key={e}
                      bg={'#2E3035'}
                      px={4}
                      py={6}
                      borderRadius={6}
                    >
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box display={'flex'} gap={3} mb={6}>
                          <Avatar
                            name={v.name}
                            src={`${ImgUrl}${v.uploadPicture}`}
                          />
                          <Box className="venbox p1 m3-h">
                            <Text>Vendor Name</Text>
                            <Heading as={'h3'}>{v?.name}</Heading>
                          </Box>
                        </Box>
                        <Box display={'flex'} gap={4}>
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'40px'}
                            h={'40px'}
                            color={'#fff'}
                            to={`/venderdetail/${v?._id}`}
                            as={ReactLink}
                          >
                            <BsEye />
                          </Link>
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'40px'}
                            h={'40px'}
                            color={'#fff'}
                            onClick={() => {
                              setupdateData({
                                name: v.name,
                                contact: v.phone,
                                email: v.email,
                                id: v._id,
                              });

                              onEditOpen();
                            }}
                          >
                            <BiEditAlt />
                          </Link>
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#E53E3E'}
                            w={'40px'}
                            h={'40px'}
                            color={'#fff'}
                            onClick={() => handleDelete(v._id)}
                          >
                            <AiOutlineDelete />
                          </Link>
                        </Box>
                      </Stack>
                      <SimpleGrid
                        columns={{
                          base: '1',
                          sm: '1',
                          md: '1',
                          lg: '1',
                          xl: '2',
                          '2xl': '2',
                        }}
                        spacingX="40px"
                        spacingY="20px"
                      >
                        <Stack className="venbox p1 m3-h">
                          <Text>Contact Number</Text>
                          <Heading as={'h3'}>{v?.phone}</Heading>
                        </Stack>
                        <Stack className="venbox p1 m3-h">
                          <Text>Email Address</Text>
                          <Heading as={'h3'}>{v?.email}</Heading>
                        </Stack>
                      </SimpleGrid>
                    </Stack>
                  );
                })
              ) : (
                <Stack className={'bread m5-h'}>
                  <Heading as={'h5'}>No Vendors Found </Heading>
                </Stack>
              )}
            </SimpleGrid>
          </Stack>
        </Stack>
      )}
    </>
  );
}
