import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  Badge,
  Spinner,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { couponlist } from '../../assets/data/Data.js';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { RiCoupon2Line } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { DELETE, POST, PUT } from '../../utilities/ApiProvider.js';
import { ImgUrl } from '../../utilities/config.js';
export default function Couponslist() {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.value);
  const [couponlist, setcouponlist] = useState('');
  const [updatedData, setupdateData] = useState({
    name: '',
    percentage: '',
    active: '',
  });
  useEffect(() => {
    console.log(updatedData);
  }, [updatedData]);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  useEffect(() => {
    if (user) {
      getvenderlist();
    }
  }, [user]);

  const getvenderlist = async () => {
    setIsLoading(true);
    console.log('vender Hit');
    let couponData = await POST(
      `/coupon/getCoupon`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log('coupon Hit', couponData);
    setcouponlist(couponData?.data);
    console.log('coupon state', couponlist);
    setIsLoading(false);
  };

  const handleDelete = async id => {
    console.log(id);
    try {
      let res = await DELETE(`/coupon/deleteCoupon/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('coupon delete', res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
      if (res.status === 'success') {
        getvenderlist();
      }
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  const onUpdateService = async () => {
    setIsLoading(true);
    let data = {
      name: updatedData.name,
      percentage: updatedData.percentage,
      active: updatedData.active,
    };

    try {
      let upres = await PUT(`/coupon/updateCoupon/${updatedData.id}`, data, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('coupon update', upres);
      if (upres.status === 'success') {
        getvenderlist();
        onEditClose();
        setIsLoading(false);
      }
      toast({
        description: upres.message,
        status: upres.status,
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  return (
    <>
      <>
        <Modal isOpen={isEditOpen} onClose={onEditClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'100%'}>
            <ModalHeader color={'#fff'}>Edit Details</ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={6}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Service Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.name}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Service Name"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Percentage
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.percentage}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          percentage: e.target.value,
                        })
                      }
                      placeholder="Enter price"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Status
                  </FormLabel>
                  <Box className="Ifiled icnfiled">
                    <Select
                      color={'#fff'}
                      value={updatedData.active}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          active: e.target.value,
                        })
                      }
                    >
                      <option style={{ display: 'none' }} value="">
                        Select Option
                      </option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </Select>
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                isLoading={isLoading}
                onClick={() => {
                  onUpdateService();
                }}
              >
                Update
              </Button>
              <Button colorScheme="red" value={'Cancel'} onClick={onEditClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>All Coupons</Heading>
        </Stack>
        <Stack alignItems={{ base: 'start', xl: 'end' }}>
          {user?.privileges
            ?.find(privilege => privilege.name === 'Coupons')
            ?.action.includes('create') && (
            <Link
              padding={'10px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              to={'/addcoupons'}
              as={ReactLink}
            >
              Add New Coupons
            </Link>
          )}
        </Stack>
      </SimpleGrid>
      {/* <UnorderedList gap={6} display={'flex'} py={5} listStyleType={'none'}>
        <ListItem>
          <Link
         padding={'10px 50px'}
        bg={'transparent'}
        
         fontWeight={'500'}
         lineHeight={'inherit'}
         color={'#7D7D7D'}
         border={'1px solid #7D7D7D'}
         borderRadius={12}
         _hover={{
           color: '#fff',
           bg : 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
           border : '1px solid #2BAF59'
         }}
         w={'fit-content'}
         to={'/'}
         as={ReactLink}
       >Active</Link>
        </ListItem>
        <ListItem>
          <Link
         padding={'10px 50px'}
        bg={'transparent'}
        color={'#7D7D7D'}
         fontWeight={'500'}
         lineHeight={'inherit'}
         border={'1px solid #7D7D7D'}
         borderRadius={12}
         _hover={{
           color: '#fff',
           bg : 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
           border : '1px solid #2BAF59'
         }}
         w={'fit-content'}
         to={'/'}
         as={ReactLink}
       >Inactive</Link>
        </ListItem>
        
     
       
      </UnorderedList> */}
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack className="list-ser">
          <Stack
            py={6}
            direction={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Stack className="p1">
              <Text>
                Total Coupons:{' '}
                <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                  {couponlist?.length ?? 0}
                </Text>
              </Text>
            </Stack>
            {/* <Stack m={'0 !important'} className="p1">
            <Checkbox
              color={'#7D7D7D'}
              size="md"
              colorScheme="green"
              defaultChecked
            >
              Select All
            </Checkbox>
          </Stack> */}
          </Stack>
          <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
            {couponlist?.length > 0 ? (
              couponlist.map((v, e) => {
                return (
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    py={5}
                    borderBottom={'1px solid #595C64'}
                    key={e}
                  >
                    <Box display={'flex'} gap={3}>
                      <Avatar
                        name={v.name}
                        src={`${ImgUrl}${v.uploadPicture}`}
                      />
                      <Box className="venbox p1 m3-h">
                        <Text>Coupon Name</Text>
                        <Heading as={'h3'}>{v?.name}</Heading>
                      </Box>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Code</Text>
                      <Heading as={'h5'}>{v.couponCode}</Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Percentage</Text>
                      <Heading as={'h5'}>{v.percentage}%</Heading>
                    </Box>
                    <Box
                      className="m5-h p1"
                      display={{ lg: 'block', md: 'none' }}
                    >
                      <Text>Start Time</Text>

                      <Heading as={'h5'}>
                        {' '}
                        {new Date(v?.startTime).toLocaleDateString()}
                      </Heading>
                    </Box>
                    <Box
                      className="m5-h p1"
                      display={{ lg: 'block', md: 'none' }}
                    >
                      <Text>End Time</Text>
                      <Heading as={'h5'}>
                        {new Date(v?.endTime).toLocaleDateString()}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Status</Text>
                      <Badge
                        padding={'10px 20px'}
                        borderRadius={'25px'}
                        bg={
                          v.active == false
                            ? '#f4de152b'
                            : 'rgba(46, 196, 135, 0.16)'
                        }
                        color={v.active === false ? '#F4DE15' : '#2EC487'}
                      >
                        {v.active === false ? 'In-active' : 'active'}
                      </Badge>
                    </Box>
                    <Box display={'flex'} gap={4}>
                      {user?.privileges
                        ?.find(privilege => privilege.name === 'Coupons')
                        ?.action.includes('edit') && (
                        <Link
                          display={'flex'}
                          borderRadius={50}
                          alignItems={'center'}
                          justifyContent={'center'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          w={'30px'}
                          h={'30px'}
                          color={'#fff'}
                          onClick={() => {
                            setupdateData({
                              name: v.name,
                              percentage: v.percentage,
                              active: v.active,
                              id: v._id,
                            });

                            onEditOpen();
                          }}
                        >
                          <BiEditAlt />
                        </Link>
                      )}

                      {user?.privileges
                        ?.find(privilege => privilege.name === 'Coupons')
                        ?.action.includes('delete') && (
                        <Link
                          display={'flex'}
                          borderRadius={50}
                          alignItems={'center'}
                          justifyContent={'center'}
                          bg={'#E53E3E'}
                          w={'30px'}
                          h={'30px'}
                          color={'#fff'}
                          onClick={() => handleDelete(v._id)}
                        >
                          <AiOutlineDelete />
                        </Link>
                      )}
                    </Box>
                  </Stack>
                );
              })
            ) : (
              <Stack className={'bread m5-h'}>
                <Heading as={'h5'}>No Coupons Found </Heading>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
