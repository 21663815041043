import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  useDisclosure,
  DrawerContent,
  BoxProps,
  FlexProps,
  Image,
  UnorderedList,
  ListItem,
  Collapse,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
import {
  FiHome,
  FiCalendar,
  FiCompass,
  FiSettings,
  FiMenu,
} from 'react-icons/fi';
import { BsChat, BsPieChart } from 'react-icons/bs';
import { BiWallet } from 'react-icons/bi';
import { AiOutlineTable } from 'react-icons/ai';
import { IoIosArrowDown } from 'react-icons/io';
import { BsBriefcase, BsExclamationOctagon } from 'react-icons/bs';
import {
  RiCouponLine,
  RiFileList3Line,
  RiInboxArchiveLine,
  RiSettings2Line,
  RiArchiveDrawerLine,
} from 'react-icons/ri';

import { Link as ReactLink } from 'react-router-dom';

import { IconType } from 'react-icons';
import { ReactText } from 'react';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

export default function Sidenav({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state.value);
  const [navlist, setnavlist] = useState('');
  const demoArray = [
    'Home',
    'POS Cash Drawer',
    'List of Jobs',
    'Jobs',
    'Create New Job',
    'Attendance',
    'Services',
    'Inventory',
    'Reorder List',
    'Employees',
    'Coupons',
    'Offer & Discount',
    'Management Reports',
    'Help',
    'Settings',
  ];

  function sortNavList(firstList) {
    // Create a map of demoArray elements to their indices
    const positionMap = new Map();
    demoArray.forEach((item, index) => {
      positionMap.set(item, index);
    });

    // Create a copy of the firstList array to avoid modifying the original array
    const firstListCopy = [...firstList];

    // Sort the copied array based on the position in demoArray
    return firstListCopy.sort((a, b) => {
      const posA = positionMap.get(a.name);
      const posB = positionMap.get(b.name);
      return posA - posB;
    });
  }

  useEffect(() => {
    if (user) {
      // setnavlist(user?.privileges);
      // sortNavList(user?.privileges);
      const sortedList = setnavlist(sortNavList(user?.privileges));
      console.log(sortedList);
    }
  }, [user]);
  // console.log('nav 1st', navlist);
  const icns = {
    Home: FiHome,
    'POS Cash Drawer': RiArchiveDrawerLine,
    Attendance: BsPieChart,
    coupon: RiCouponLine,
    service: RiFileList3Line,
    Settings: RiSettings2Line,
    Help: BsExclamationOctagon,
  };

  return (
    <>
      <Box
        minH={{ base: 'fit-content', lg: '100vh' }}
        bg={useColorModeValue('gray.100', 'gray.900')}
        position={{ base: 'absolute', lg: 'initial' }}
        zIndex={'1'}
        left={'40px'}
        top={'32px'}
        w={{ base: '0px', lg: 'auto' }}
      >
        <SidebarContent
          onClose={() => onClose}
          navlist={navlist}
          display={{ base: 'none', lg: 'block' }}
          icons={icns}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent
              navlist={navlist}
              display={{ base: 'block', lg: 'none' }}
              icons={icns}
              onClose={onClose}
            />
          </DrawerContent>
        </Drawer>
        <MobileNav display={{ base: 'flex', lg: 'none' }} onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p={{ base: '0', md: '4' }}>
          {children}
        </Box>
      </Box>
    </>
  );
}

const SidebarContent = ({ navlist, icons, onClose, ...rest }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [openDropdown, setOpenDropdown] = useState(null);
  const toggleDropdown = index => {
    setOpenDropdown(openDropdown === index ? null : index);
  };
  const demoArray = [
    'Home',
    'POS Cash Drawer',
    'Employees',
    'Management Reports',
    'Jobs',
    'List of Jobs',
    'Create New Job',
    'Inventory',
    'Reorder List',
    'Services',
    'Coupons',
    'Offer & Discount',
    'Settings',
    'Help',
  ];
  console.log(navlist, 'inside  drawer');

  return (
    <Box
      bg={'#202125'}
      w={{ base: 'full', lg: 72 }}
      pos="fixed"
      h="full"
      overflowX={'hidden !important'}
      overflow={'scroll'}
      {...rest}
    >
      <Flex
        py={8}
        w={'100%'}
        alignItems="center"
        mx="8"
        justifyContent="center"
      >
        <Image
          alt={'Logo'}
          src={logo}
          draggable={false}
          justifyContent={'center'}
          alignItems={'center'}
          w={'100px'}
        />
        <CloseButton
          right={0}
          mr={9}
          position={'absolute'}
          color={'#fff'}
          display={{ base: 'flex', lg: 'none' }}
          onClick={onClose}
        />
      </Flex>

      <Stack pl={4}>
        <UnorderedList className="sidemenu" listStyleType={'none'}>
          {navlist?.length > 0 &&
            (() => {
              const renderedNames = new Set();
              return navlist?.map((v, e) => {
                if (renderedNames.has(v.name)) {
                  console.log('inside if condition');
                  return null;
                }
                renderedNames.add(v.name);

                return v.child.length > 0 ? (
                  <ListItem key={e}>
                    <Link as={'a'} onClick={() => toggleDropdown(e)}>
                      <Icon as={AiOutlineTable} />
                      {v?.name}
                      <Icon
                        ml={1}
                        fontSize={'15px !important'}
                        as={IoIosArrowDown}
                      />
                    </Link>
                    <Collapse in={openDropdown === e} animateOpacity>
                      <Box pl={4} mt="4">
                        <UnorderedList
                          className="sidesubmenu"
                          listStyleType={'none'}
                        >
                          {v.child?.map((child, index) => {
                            if (renderedNames.has(child.name)) {
                              return null;
                            }
                            renderedNames.add(child.name);

                            return (
                              <ListItem key={index}>
                                <Link
                                  as={ReactLink}
                                  to={child?.url ?? '/dashboard'}
                                >
                                  {child?.name}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </UnorderedList>
                      </Box>
                    </Collapse>
                  </ListItem>
                ) : (
                  <ListItem key={e}>
                    <Link as={ReactLink} to={v?.url ?? '/dashboard'}>
                      <Icon as={icons[v?.name ?? 'Dashboard'] ?? BsBriefcase} />
                      {v?.name}
                    </Link>
                  </ListItem>
                );
              });
            })()}

          {/* <ListItem>
            <Link as={ReactLink} to={'/dashboard'}>
              <Icon as={FiHome} />
              Home
            </Link>
          </ListItem>
          <ListItem>
            <Link as={'a'} onClick={onToggle}>
              <Icon as={AiOutlineTable} />
              POS Cash Drawer
              <Icon ml={1} fontSize={'15px !important'} as={IoIosArrowDown} />
            </Link>
            <Collapse in={isOpen} animateOpacity>
              <Box pl={4} mt="4">
                <UnorderedList className="sidesubmenu" listStyleType={'none'}>
                  <ListItem>
                    <Link as={ReactLink} to={'/createjob'}>
                      Create New Jobs
                    </Link>
                  </ListItem>
                </UnorderedList>
              </Box>
            </Collapse>
          </ListItem>

          <ListItem>
            <Link as={ReactLink} to={'/employes'}>
              <Icon as={BiWallet} />
              Employees
            </Link>
          </ListItem>

          <ListItem>
            <Link as={ReactLink} to={'/attendance'}>
              <Icon as={BsPieChart} />
              Attendance
            </Link>
          </ListItem>
          <ListItem>
            <Link as={ReactLink} to={'/vender'}>
              <Icon as={RiInboxArchiveLine} />
              Vendors
            </Link>
          </ListItem>

          <ListItem>
            <Link as={ReactLink} to={'/services'}>
              <Icon as={RiFileList3Line} />
              List of Services
            </Link>
          </ListItem>
          <ListItem>
            <Link as={ReactLink} to={'/coupons'}>
              <Icon as={RiCouponLine} />
              Coupons
            </Link>
          </ListItem>
          <ListItem>
            <Link as={ReactLink} to={'/jobs'}>
              <Icon as={BsBriefcase} />
              List of Jobs
            </Link>
          </ListItem>
          <ListItem>
            <Link as={ReactLink} to={'/listofjob'}>
              <Icon as={BsBriefcase} />
              List of Jobs
            </Link>
          </ListItem>
          <ListItem>
            <Link as={ReactLink} to={'/ouroffers'}>
              <Icon as={BsBriefcase} />
              Our Offfers
            </Link>
          </ListItem>
          <ListItem>
            <Link as={ReactLink} to={'/setting'}>
              <Icon as={RiSettings2Line} />
              Settings
            </Link>
          </ListItem> */}
        </UnorderedList>
      </Stack>
    </Box>
  );
};

const NavItem = ({ icon, url, children, ...rest }) => {
  const navigate = useNavigate();

  return (
    <Link
      onClick={() => navigate(url)}
      style={{ textDecoration: 'none', color: '#000' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: '#FFC728',
          color: '#000',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: '#000',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: '35px' }}
      px={{ base: 0, md: 0 }}
      height="auto"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="0px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        color={'white'}
        icon={<FiMenu />}
      />
    </Flex>
  );
};
