import {
  Heading,
  Link,
  SimpleGrid,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
  Avatar,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { ImgUrl } from '../../utilities/config';
import { useSelector } from 'react-redux';
import { DELETE, POST, PUT } from '../../utilities/ApiProvider';
import { formatDate } from '../../utilities/helper';

const Block = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state.value);
  const [listData, setListData] = useState(null);
  const [deleteId, setDeleteId] = useState('');
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (user) {
      getBlockedUsers();
    }
  }, [user]);

  const getBlockedUsers = async () => {
    console.log('Api Hit');
    let listBlocked = await POST(
      `/employees/listBlocked`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    if (listBlocked.status === 'success') {
      setListData(listBlocked?.data);
      console.log(listBlocked.data);
    }
  };

  const handleUnblock = async id => {
    console.log('Api Hit', id);
    let unblock = await PUT(
      `/employees/unBlockUser/${id}`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    if (unblock.status === 'success') {
      console.log(unblock.data);
      getBlockedUsers();
    }
  };

  const handleRemoveUser = async () => {
    console.log('Api Hit', deleteId);
    try {
      let removeUser = await DELETE(`/employees/deleteEmployee/${deleteId}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (removeUser.status === 'success') {
        console.log(removeUser.data);
        getBlockedUsers();
        setDeleteId('');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleBlockUser = async Id => {
    console.log('Api Hit', Id);
    try {
      let blockUser = await PUT(
        `/employees/blockUser/${Id}`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (blockUser.status === 'success') {
        console.log(blockUser.data);
        toast({
          description: blockUser?.message ?? 'Success',
          position: 'top-right',
          isClosable: true,
          status: blockUser?.status,
          duration: 3000,
        });
        getBlockedUsers();
      }
    } catch (error) {
      toast({
        description: 'An Error Occured',
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
      console.log(error.message);
    }
  };

  function openModal(id) {
    setDeleteId(id);
    onOpen();
  }

  const filteredTechnicians = listData?.technician?.filter(val =>
    val?.fullName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const filteredCSR = listData?.CSR?.filter(val =>
    val?.fullName.toLowerCase().includes(searchValue.toLowerCase())
  );

  const filteredManagers = listData?.manager?.filter(val =>
    val?.fullName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Block Employees </Heading>
        </Stack>
      </SimpleGrid>
      <Stack>
        <Tabs variant="unstyled">
          <Stack
            display={'flex'}
            direction={'row'}
            justifyContent={'space-between'}
            w={'full'}
          >
            <TabList gap={6}>
              <Tab
                padding={'10px 50px'}
                color={'#7D7D7D'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                px={8}
                bg={'transparent'}
                border={'1px solid #7D7D7D'}
                _hover={{
                  color: '#fff',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                  border: '1px solid #2BAF59',
                }}
                textTransform={'capitalize'}
                w={'fit-content'}
                borderRadius={12}
                _selected={{
                  color: 'white',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                }}
              >
                Technician
              </Tab>
              <Tab
                padding={'10px 50px'}
                color={'#7D7D7D'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                px={8}
                bg={'transparent'}
                border={'1px solid #7D7D7D'}
                _hover={{
                  color: '#fff',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                  border: '1px solid #2BAF59',
                }}
                textTransform={'capitalize'}
                w={'fit-content'}
                borderRadius={12}
                _selected={{
                  color: 'white',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                }}
              >
                CSR
              </Tab>
              <Tab
                padding={'10px 50px'}
                color={'#7D7D7D'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                px={8}
                bg={'transparent'}
                border={'1px solid #7D7D7D'}
                _hover={{
                  color: '#fff',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                  border: '1px solid #2BAF59',
                }}
                textTransform={'capitalize'}
                w={'fit-content'}
                borderRadius={12}
                _selected={{
                  color: 'white',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                }}
              >
                Manager
              </Tab>
            </TabList>
            <Box className="Ifiled">
              <Input
                value={searchValue}
                type="text"
                onChange={e => setSearchValue(e.target.value)}
                placeholder="Search For Employee"
              />
            </Box>
          </Stack>
          <TabPanels>
            <TabPanel>
              <Stack
                pt={2}
                pb={3}
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack className="p1">
                  <Text>
                    Total Technicians:{' '}
                    <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                      {filteredTechnicians?.length ?? 0}
                    </Text>
                  </Text>
                </Stack>
              </Stack>
              <Stack bg={'#202125'} px={3} py={4} borderRadius={8}>
                {listData?.technician?.length > 0 ? (
                  filteredTechnicians
                    ?.sort((a, b) =>
                      a.active === b.active ? 0 : a.active ? 1 : -1
                    )
                    ?.map(val => (
                      <Stack
                        key={val?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={5}
                        borderRadius={6}
                        bg={'#2e3035'}
                        px={4}
                        mb={3}
                        className="att-list"
                      >
                        <Box className="m5-h" width={'30%'}>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            spacing={4}
                            m={0}
                          >
                            <Avatar
                              name={val.name}
                              src={`${ImgUrl}${val?.profile_picture}`}
                              objectFit={'cover'}
                            />
                            <Stack>
                              <Text as={'h5'} opacity={'0.7'}>
                                ID: {val._id.slice(0, 5)}
                              </Text>
                              <Text as={'h5'}>{val?.fullName}</Text>
                            </Stack>
                          </Stack>
                        </Box>
                        <Box className="m5-h" width={'20%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Total Jobs{' '}
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.jobCount}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'20%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Member Since
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {formatDate(String(val?.createdAt).split('T')[0])}{' '}
                          </Heading>
                        </Box>
                        <Box
                          className="m5-h"
                          display={'flex'}
                          gap={3}
                          width={'20%'}
                        >
                          <Button
                            padding={'10px 50px'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            color={'#fff'}
                            fontWeight={'500'}
                            lineHeight={'inherit'}
                            borderRadius={12}
                            _hover={{
                              color: '#000',
                            }}
                            textAlign={'center'}
                            onClick={() =>
                              navigate(`/employedetail/${val._id}`)
                            }
                          >
                            View Full Detail
                          </Button>
                          <Divider
                            px={1}
                            orientation="vertical"
                            borderColor={'#87928D'}
                          />
                          <Button
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#FF0000'}
                            color={'#fff'}
                            _hover={{ bg: '#FF0000', color: '#202125' }}
                            onClick={
                              val?.active
                                ? () => handleBlockUser(val._id)
                                : () => handleUnblock(val._id)
                            }
                          >
                            {val?.active ? 'Block' : 'Un-Block'}
                          </Button>
                        </Box>
                      </Stack>
                    ))
                ) : (
                  <Text color={'#7D7D7D'} opacity={'0.7'}>
                    No Blocked Technicians
                  </Text>
                )}
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack
                pt={2}
                pb={3}
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack className="p1">
                  <Text>
                    Total CSRs:{' '}
                    <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                      {filteredCSR?.length ?? 0}
                    </Text>
                  </Text>
                </Stack>
              </Stack>
              <Stack bg={'#202125'} px={3} py={4} borderRadius={8}>
                {listData?.CSR?.length > 0 ? (
                  filteredCSR
                    ?.sort((a, b) =>
                      a.active === b.active ? 0 : a.active ? 1 : -1
                    )
                    .map(val => (
                      <Stack
                        key={val?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={5}
                        borderRadius={6}
                        bg={'#2e3035'}
                        px={4}
                        mb={3}
                        className="att-list"
                      >
                        <Box className="m5-h" width={'20%'}>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            spacing={4}
                            m={0}
                          >
                            <Avatar
                              name={val.name}
                              src={`${ImgUrl}${val?.uploadPicture}`}
                              objectFit={'cover'}
                            />
                            <Stack>
                              <Text as={'h5'} opacity={'0.7'}>
                                ID: {val._id.slice(0, 5)}
                              </Text>
                              <Text as={'h5'}>{val?.fullName}</Text>
                            </Stack>
                          </Stack>
                        </Box>
                        <Box className="m5-h" width={'20%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Total Jobs{' '}
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.jobCount}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'20%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Member Since
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {formatDate(String(val?.createdAt).split('T')[0])}{' '}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'20%'} display={'flex'} gap={3}>
                          <Button
                            padding={'10px 50px'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            color={'#fff'}
                            fontWeight={'500'}
                            lineHeight={'inherit'}
                            borderRadius={12}
                            _hover={{
                              color: '#000',
                            }}
                            textAlign={'center'}
                            onClick={() =>
                              navigate(`/employedetail/${val._id}`)
                            }
                          >
                            View Full Detail
                          </Button>
                          <Divider px={1} orientation="vertical" />
                          <Button
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#FF0000'}
                            color={'#fff'}
                            _hover={{ bg: '#FF0000', color: '#202125' }}
                            onClick={
                              val?.active
                                ? () => handleBlockUser(val._id)
                                : () => handleUnblock(val._id)
                            }
                          >
                            {val?.active ? 'Block' : 'Un-Block'}
                          </Button>
                        </Box>
                      </Stack>
                    ))
                ) : (
                  <Text color={'#7D7D7D'} opacity={'0.7'}>
                    No Blocked CSRs
                  </Text>
                )}
              </Stack>
            </TabPanel>
            <TabPanel>
              <Stack
                pt={2}
                pb={3}
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Stack className="p1">
                  <Text>
                    Total Managers:{' '}
                    <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                      {filteredManagers?.length ?? 0}
                    </Text>
                  </Text>
                </Stack>
              </Stack>
              <Stack bg={'#202125'} px={3} py={4} borderRadius={8}>
                {listData?.manager?.length > 0 ? (
                  filteredManagers
                    ?.sort((a, b) =>
                      a.active === b.active ? 0 : a.active ? 1 : -1
                    )
                    .map(val => (
                      <Stack
                        key={val?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={5}
                        borderRadius={6}
                        bg={'#2e3035'}
                        px={4}
                        mb={3}
                        className="att-list"
                      >
                        <Box className="m5-h" width={'20%'}>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            spacing={4}
                            m={0}
                          >
                            <Avatar
                              name={val.name}
                              src={`${ImgUrl}${val?.uploadPicture}`}
                              objectFit={'cover'}
                            />
                            <Stack>
                              <Text as={'h5'} opacity={'0.7'}>
                                ID: {val._id.slice(0, 5)}
                              </Text>
                              <Text as={'h5'}>{val?.fullName}</Text>
                            </Stack>
                          </Stack>
                        </Box>
                        <Box className="m5-h" width={'20%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Total Jobs{' '}
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.jobCount}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'20%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Member Since
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {formatDate(String(val?.createdAt).split('T')[0])}{' '}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'20%'} display={'flex'} gap={3}>
                          <Button
                            padding={'10px 50px'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            color={'#fff'}
                            fontWeight={'500'}
                            lineHeight={'inherit'}
                            borderRadius={12}
                            _hover={{
                              color: '#000',
                            }}
                            textAlign={'center'}
                            onClick={() =>
                              navigate(`/employedetail/${val._id}`)
                            }
                          >
                            View Full Detail
                          </Button>
                          <Divider px={1} orientation="vertical" />
                          <Button
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#FF0000'}
                            color={'#fff'}
                            _hover={{ bg: '#FF0000', color: '#202125' }}
                            onClick={
                              val?.active
                                ? () => handleBlockUser(val._id)
                                : () => handleUnblock(val._id)
                            }
                          >
                            {val?.active ? 'Block' : 'Un-Block'}
                          </Button>
                        </Box>
                      </Stack>
                    ))
                ) : (
                  <Text color={'#7D7D7D'} opacity={'0.7'}>
                    No Blocked Managers
                  </Text>
                )}
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
      <Modal isOpen={isOpen} size={'sm'} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          alignContent={'center'}
          alignItems={'center'}
          bg={'#202125'}
          border={'1px solid #4D4D4D'}
          borderRadius={14}
        >
          <ModalHeader color={'#ffff'}>ARE YOU SURE</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color={'#ffff'}>You want to remove this user?</Text>
            <Stack
              display={'flex'}
              direction={'column'}
              mt={4}
              justifyContent={'center'}
            >
              <Button
                padding={'10px 50px'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                colorScheme="blue"
                onClick={onClose}
              >
                NO
              </Button>
              <Button
                padding={'10px 50px'}
                bg={'#4D4D4D'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                onClick={handleRemoveUser}
              >
                YES
              </Button>
            </Stack>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Block;
