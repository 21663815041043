import {
  Avatar,
  Box,
  Button,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { React, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ImgUrl } from '../../../utilities/config';

export default function JobHistory({ Job }) {
  const navigate = useNavigate();

  return (
    <>
      <Stack>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="m4-h">
            <Heading as={'h4'}>Job History</Heading>
          </Stack>
          <Stack>
            <Link as={ReactLink} to={'/listofjob'} color={'#7D7D7D'}>
              View All
            </Link>
          </Stack>
        </Box>
        <Stack bg={'#202125'} px={5} py={2} pb={3} borderRadius={8}>
          {Job?.filter(val => val?.status === 'paid')?.length > 0 ? (
            Job.filter(val => val?.status === 'paid')
              .slice(0, 4)
              .map((val, e) => {
                return (
                  <Stack
                    direction={'row'}
                    flexWrap={'wrap'}
                    width={'full'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    py={5}
                    borderBottom={'1px solid #595C64'}
                    key={val?._id}
                  >
                    <Box className="m5-h" width={{xl: 'auto', md: '48%'}}>
                      <Stack
                        display={'flex'}
                        direction={'row'}
                        spacing={4}
                        m={0}
                      >
                        <Avatar
                          name={val?.customer?.firstName}
                          src={`${ImgUrl}${val?.uploadPicture}`}
                          objectFit={'cover'}
                        />
                        <Stack>
                          <Text as={'h5'} opacity={'0.7'}>
                            ID: {val._id.slice(0, 5)}
                          </Text>
                          <Text as={'h5'}>{val?.customer?.firstName}</Text>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box className="m5-h" width={{xl: 'auto', md: '48%'}}>
                      <Button
                        variant={'outline'}
                        color={'rgba(255, 255, 255, 0.60)'}
                        padding={'10px 14px'}
                        _hover={{
                          color: 'rgba(255, 255, 255, 0.80)',
                        }}
                        onClick={() =>
                          navigate(`/customerjobdetail/${val?._id}`)
                        }
                      >
                        View Full Details
                      </Button>
                    </Box>
                  </Stack>
                );
              })
          ) : (
            <Box className="m5-h" pt={2} pb={1}>
              <Text as={'h5'}>No Jobs Completed</Text>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
}
