import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  Select,
  SimpleGrid,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { RiAddFill } from 'react-icons/ri';
import axios from 'axios';
import { useDebounce } from '../../utilities/ApiProvider';
import { FaShoppingCart, FaCheck, FaTimes } from 'react-icons/fa';
import Quagga from '@ericblade/quagga2';
import Scanner from './Scanner';

export default function CreateNewJob() {
  const user = useSelector(state => state?.value);
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [scanning, setScanning] = useState(false); // toggleable state for "should render scanner"
  const [cameras, setCameras] = useState([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
  const [cameraId, setCameraId] = useState(null); // id of the active camera device
  const [cameraError, setCameraError] = useState(null); // error message from failing to access the camera
  const [results, setResults] = useState([]); // list of scanned results
  const [torchOn, setTorch] = useState(false); // toggleable state for "should torch be on"
  const scannerRef = useRef(null); // reference to the scanner element in the DOM

  const [vinLoading, setVinLoading] = useState(false);
  const [scannerLoading, setScannerLoading] = useState(false);
  const [vinValue, setVinValue] = useState('noVin');
  const [services, setServices] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [dateTime, setDateTime] = useState({
    date: '',
    time: '',
  });
  const [vehicleDetails, setVehicleDetails] = useState({
    vin: '',
    carLicense: '',
    carMake: '',
    carModel: '',
    carSubModel: '',
    engineSize: '',
    year: '',
    miles: '',
    fleet: 'false',
  });
  const [customerDetails, setCustomerDetails] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    zipCode: '',
    contact: '',
    email: '',
  });
  const [serviceDetail, setServiceDetail] = useState({
    type: '',
    name: '',
    cost: '',
  });
  const [packageData, setpackageData] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const debouncedVIN = useDebounce(vehicleDetails.vin, 300); // Use debounce with 300ms delay

  const handleCreateJob = async () => {
    setIsLoading(true);
    // Create on single array that has both the services and inventory in one
    const totalServices = [
      ...services.map(service => ({ id: service.id, price: service.price })),
      ...inventory.map(item => ({ id: item.id, price: item.price })),
    ];
    const payload = {
      vinNumber: vehicleDetails.vin,
      license: vehicleDetails.carLicense,
      make: vehicleDetails.carMake,
      model: vehicleDetails.carModel,
      subModel: vehicleDetails.carSubModel,
      engineSize: vehicleDetails.engineSize,
      year: vehicleDetails.year,
      miles: vehicleDetails.miles,
      fleet: vehicleDetails.fleet,
      custFirstName: customerDetails.firstName,
      custLastName: customerDetails.lastName,
      custNumber: Number(customerDetails.contact),
      custAddress: customerDetails.address,
      state: customerDetails.city,
      zipCode: customerDetails.zipCode,
      custEmail: customerDetails.email,
      service: totalServices,
      package: selectedPackages.map(pkg => ({ id: pkg.id, price: pkg.price })),

      date: dateTime.date,
    };
    console.log('Create Job Payload', payload);

    // Check if any values inside the payload are null or empty string, excluding license
    const incompleteFields = [];
    Object.entries(payload).forEach(([key, value]) => {
      if (value === null || (value === '' && key !== 'license')) {
        incompleteFields.push(key);
      }
    });

    if (incompleteFields.length > 0) {
      if (incompleteFields.length > 3) {
        toast({
          description: 'Multiple fields are missing',
          position: 'top-right',
          isClosable: true,
          status: 'warning',
          duration: 3000,
        });
      } else {
        const missingFields = incompleteFields.join(', ');
        toast({
          description: `Please fill the following fields: ${missingFields}`,
          position: 'top-right',
          isClosable: true,
          status: 'warning',
          duration: 3000,
        });
      }
      setIsLoading(false);
      return;
    }
    const serMissing = payload.service.some(val => val.id === '');
    if (serMissing) {
      toast({
        description: 'Please select a service',
        position: 'top-right',
        isClosable: true,
        status: 'warning',
        duration: 3000,
      });
      setIsLoading(false);
      return;
    }
    console.log('VVVVVVVVHHHHHHHHHHHHHHHXXXXXXXXX', payload);
    try {
      console.log('Inisde Try');

      let response = await POST(`/job/createJob`, payload, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response?.status === 'success') {
        setIsLoading(false);
        console.log(response?.data);
        setVehicleDetails({
          vin: '',
          carLicense: '',
          carMake: '',
          carModel: '',
          carSubModel: '',
          engineSize: '',
          year: '',
          miles: '',
          fleet: 'false',
        });
        setCustomerDetails({
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          zipCode: '',
          contact: '',
          email: '',
        });
        setServiceDetail({
          type: '',
          name: '',
          cost: '',
        });
        setServices([
          {
            id: '',
            name: '',
            price: '',
            type: '',
          },
        ]);
        toast({
          description: response?.message ?? 'Job Created',
          position: 'top-right',
          isClosable: true,
          status: response?.status,
          duration: 3000,
        });
        if (user?.role === 'technician') {
          navigate('/jobs');
        } else {
          navigate('/listofjob ');
        }
      } else {
        setIsLoading(false);
        console.log(response?.message);
        toast({
          description: response?.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          duration: 3000,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast({
        description: error.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
    }
  };

  const getAllServices = async () => {
    try {
      const response = await POST(
        `/service/getAllService`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      console.log('Response', response);
      if (response.status !== 'success') throw new Error(response.message);
      else {
        setServiceData(response.data);
        return response.data;
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const getInventory = async () => {
    try {
      let res = await POST(
        `/inventory/view`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        setInventoryList(res?.data);
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  const getPackages = async () => {
    setIsLoading(true);
    try {
      let res = await POST(
        `/job/getAllPackage`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        setpackageData(res?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  function getTotalPrice(packageName) {
    let totalPrice = 0;
    for (let i = 0; i < packageData.length; i++) {
      if (packageData[i].name === packageName) {
        for (let j = 0; j < packageData[i].servicesDetails.length; j++) {
          totalPrice += Number(packageData[i].servicesDetails[j].price);
        }
        break;
      }
    }
    return totalPrice;
  }

  const handleAddService = () => {
    setServices([...services, { type: '', name: '', price: '', id: '' }]);
  };

  const handleAddInventory = () => {
    setInventory([...inventory, { name: '', price: '', id: '' }]);
  };

  const handleServiceChange = (index, field, value) => {
    console.log('handleChangeValues', index, field, value);
    const updatedServices = [...services];
    console.log('Updated', updatedServices);
    updatedServices[index][field] = value;

    if (field === 'type') {
      // Clear name and price when type changes
      updatedServices[index].name = '';
      updatedServices[index].price = '';
    }

    if (field === 'name') {
      const selectedCategory = serviceData.find(
        category => category.name === updatedServices[index].type
      );
      const selectedService = selectedCategory?.services?.find(
        service => service.name === value
      );
      updatedServices[index].id = selectedService?._id || '';
      updatedServices[index].price = selectedService?.price || '';
    }

    setServices(updatedServices);
  };

  const handleAddPackage = (index, value) => {
    console.log('handleAddPackage', value);
    const parsedValue = JSON.parse(value);
    console.log(parsedValue, 'parsedValue');
    console.log('Selected Package', selectedPackages);
    const updatedPackages = [...selectedPackages];
    updatedPackages[index] = {
      id: parsedValue.id,
      price: parsedValue.price,
      name: parsedValue.name,
      serviceDetails:
        packageData.find(pkg => pkg._id === parsedValue.id)?.servicesDetails ||
        [],
    };
    setSelectedPackages(updatedPackages);
    console.log(updatedPackages);
  };

  const getServiceDetailsString = serviceDetails => {
    return serviceDetails?.map(service => service?.name).join(' / ') || '';
  };

  const searchByVIN = async () => {
    setVinLoading(true);
    try {
      const response = await axios.get(
        `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vehicleDetails.vin}?format=json`,
        {
          headers: {
            Authorization: `Bearer ${user?.verificationToken}`,
          },
        }
      );
      console.log('VIN Response', response);
      let results = await response.data.Results;
      console.log('VIN Results', results);
      let carMake = results.find(item => item.Variable === 'Make')?.Value || '';
      let carModel =
        results.find(item => item.Variable === 'Model')?.Value || '';
      let year =
        results.find(item => item.Variable === 'Model Year')?.Value || '';
      let engineSize =
        results.find(item => item.Variable === 'Displacement (CC)')?.Value ||
        '';
      console.log('Car Make', carMake, carModel, year, engineSize);
      setVehicleDetails({
        ...vehicleDetails,
        carMake: carMake,
        carModel: carModel,
        year: year,
        engineSize: engineSize,
      });
      setVinLoading(false);
    } catch (error) {
      setVinLoading(false);
      console.log('Error', error);
    }
  };

  const handleInventoryChange = (index, value) => {
    const values = JSON.parse(value);
    const updatedInventory = [...inventory];
    updatedInventory[index].id = values?.id;
    updatedInventory[index].name = values?.name;
    updatedInventory[index].price = values?.price;
    console.log('Updated Inv', updatedInventory);
    setInventory(updatedInventory);
  };

  const handleDeleteService = (index, type, id) => {
    console.log('Triggered', id);

    if (type === 'service') {
      // Remove the service at the specific index
      setServices(services => services.filter((_, i) => i !== index));
    } else if (type === 'inventory') {
      setInventory(inventory => inventory.filter((_, i) => i !== index));
    } else if (type === 'package') {
      setSelectedPackages(selectedPackages =>
        selectedPackages.filter((_, i) => i !== index)
      );
    }
  };

  useEffect(() => {
    const enableCamera = async () => {
      await Quagga.CameraAccess.request(null, {});
    };
    const disableCamera = async () => {
      await Quagga.CameraAccess.release();
    };
    const enumerateCameras = async () => {
      const cameras = await Quagga.CameraAccess.enumerateVideoDevices();
      console.log('Cameras Detected: ', cameras);
      return cameras;
    };
    enableCamera()
      .then(disableCamera)
      .then(enumerateCameras)
      .then(cameras => setCameras(cameras))
      .then(() => Quagga.CameraAccess.disableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
      .catch(err => setCameraError(err));
    return () => disableCamera();
  }, []);

  useEffect(() => {
    if (user) {
      getAllServices();
      getInventory();
      getPackages();
      setDateTime({
        ...dateTime,
        date: new Date().toISOString().split('T')[0],
      });
    }
  }, [user]);

  useEffect(() => {
    if (debouncedVIN !== '') {
      searchByVIN(debouncedVIN);
    }
    if (results) {
      console.log('Full Array', results);
      const filteredResults = results.filter(result => result.length === 17);
      console.log(filteredResults);
      if (filteredResults.length > 0) {
        const firstResult = filteredResults[0];
        setVehicleDetails({
          ...vehicleDetails,
          vin: firstResult,
        });
        setResults([]);
        setScanning(false);
        searchByVIN();
      }
    }
  }, [debouncedVIN, results]);

  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>Create Jobs</Heading>
      </Stack>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {/* <CheckboxGroup
            defaultValue={'noVin'}
            value={vinValue}
            onChange={value => setVinValue(value)}
          >
            <Box display={'flex'} gap={6}>
              <Checkbox value={'vin'} size="md" colorScheme="green">
                <Text color={'#fff'}>Enter VIN</Text>
              </Checkbox>
              <Checkbox value={'noVin'} size="md" colorScheme="green">
                <Text color={'#fff'}>No VIN</Text>
              </Checkbox>
            </Box>
          </CheckboxGroup> */}
          <Box display={'flex'} gap={6}>
            <Stack w={'100%'}>
              <FormLabel
                className="Ifilled"
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Select Date
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  onChange={e => {
                    console.log(e.target.value);
                    setDateTime({ ...dateTime, date: e.target.value });
                  }}
                  type="date"
                  placeholder="Enter Coupon Name"
                />
              </Box>
            </Stack>

            <Stack w={'100%'}>
              <FormLabel
                className="Ifilled"
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Select Time
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  onChange={e =>
                    setDateTime({ ...dateTime, time: e.target.value })
                  }
                  type="time"
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack className="m4-h adser" gap={4}>
          <Heading as={'h4'}>Vehicle Details</Heading>
          <SimpleGrid columns={[2, null, 3]} spacing="40px">
            <Box
              className="Ifiled"
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              {scanning && (
                <div ref={scannerRef}>
                  {/* <video style={{ width: window.innerWidth, height: 480, border: '3px solid orange' }}/> */}
                  <canvas
                    className="drawingBuffer"
                    style={{
                      position: 'absolute',
                      top: '0px',
                      // left: '0px',
                      // height: '100%',
                      // width: '100%',
                    }}
                    width="520"
                    height="380"
                  />
                  {scanning ? (
                    <Scanner
                      scannerRef={scannerRef}
                      cameraId={cameraId}
                      facingMode={'environment'}
                      onDetected={result => setResults([result])}
                    />
                  ) : null}
                </div>
              )}
              <Button
                display={'flex'}
                alignItems={'center'}
                justifyItems={'center'}
                padding={'10px 50px'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                color={'#fff'}
                position={'relative !important'}
                marginTop={scanning ? '20px !important' : '0px'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                width={'100%'}
                _hover={{
                  color: '#000',
                }}
                onClick={() => setScanning(!scanning)}
                isLoading={scannerLoading}
              >
                {!scanning ? 'Scan Vin' : 'Stop Scanning'}
              </Button>
            </Box>
            <Box className="Ifiled">
              <Input
                value={vehicleDetails.vin}
                onChange={e =>
                  setVehicleDetails({ ...vehicleDetails, vin: e.target.value })
                }
                type="text"
                placeholder="Enter VIN"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={vehicleDetails.carLicense}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    carLicense: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter Car License (optional)"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                isDisabled={vinLoading}
                value={vehicleDetails.carMake}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    carMake: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter Car Make"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                isDisabled={vinLoading}
                value={vehicleDetails.carModel}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    carModel: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter Car Model"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={vehicleDetails.carSubModel}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    carSubModel: e.target.value,
                  })
                }
                type="text"
                placeholder="Enter Car Sub Model"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                isDisabled={vinLoading}
                value={vehicleDetails.engineSize}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    engineSize: e.target.value,
                  })
                }
                type="number"
                placeholder="Enter Car Engine Size"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                isDisabled={vinLoading}
                value={vehicleDetails.year}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    year: e.target.value,
                  })
                }
                type="number"
                placeholder="Enter Car Manufacture Year"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={vehicleDetails.miles}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    miles: e.target.value,
                  })
                }
                type="number"
                placeholder="Enter Mileage"
              />
            </Box>
            <Box className="Ifiled icnfiled">
              <Select
                color={'#fff'}
                defaultValue={false}
                onChange={e =>
                  setVehicleDetails({
                    ...vehicleDetails,
                    fleet: e.target.value,
                  })
                }
              >
                <option style={{ display: 'none' }} value="">
                  Fleet
                </option>
                <option value={true}>Fleet</option>
                <option value={false}>No Fleet</option>
              </Select>
            </Box>
          </SimpleGrid>
        </Stack>
        <Stack className="m4-h adser" gap={4}>
          <Heading as={'h4'}>Customer Details</Heading>
          <SimpleGrid columns={[2, null, 3]} spacing="40px">
            <Box className="Ifiled">
              <Input
                value={customerDetails.firstName}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    firstName: e.target.value,
                  })
                }
                type="text"
                placeholder="First Name"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={customerDetails.lastName}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    lastName: e.target.value,
                  })
                }
                type="text"
                placeholder="Last Name"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={customerDetails.address}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    address: e.target.value,
                  })
                }
                type="text"
                placeholder="Address"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={customerDetails.city}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    city: e.target.value,
                  })
                }
                type="text"
                placeholder="State / City"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={customerDetails.zipCode}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    zipCode: e.target.value,
                  })
                }
                type="number"
                placeholder="Zip Code"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={customerDetails.contact}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    contact: e.target.value,
                  })
                }
                type="number"
                placeholder="Contact"
              />
            </Box>
            <Box className="Ifiled">
              <Input
                value={customerDetails.email}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    email: e.target.value,
                  })
                }
                type="email"
                placeholder="Email"
              />
            </Box>
          </SimpleGrid>
        </Stack>
        <Stack className="m4-h adser" gap={4}>
          <Heading as={'h4'}>Services</Heading>
          {services.map((service, index) => (
            <SimpleGrid key={index} columns={[2, null, 3]} spacing="40px">
              <Box className="Ifiled icnfiled">
                <Select
                  color={'#fff'}
                  placeholder="Services Type"
                  value={service.type}
                  onChange={e =>
                    handleServiceChange(index, 'type', e.target.value)
                  }
                >
                  {serviceData?.map(category => (
                    <option key={category.name} value={category.name}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </Box>
              <Box className="Ifiled">
                <Select
                  color={'#fff'}
                  placeholder="Services Name"
                  value={service.name}
                  onChange={e =>
                    handleServiceChange(index, 'name', e.target.value)
                  }
                  disabled={!service.type} // Disable if no type selected
                >
                  {serviceData
                    .find(category => category.name === service.type)
                    ?.services.map(service => (
                      <option key={service._id} value={service.name}>
                        {service.name}
                      </option>
                    ))}
                </Select>
              </Box>
              <Box className="Ifiled">
                <Input
                  isDisabled={true}
                  value={service?.price}
                  type="text"
                  placeholder="Service Cost"
                />
                <Button
                  display={'flex'}
                  borderRadius={50}
                  alignItems={'center'}
                  justifyContent={'center'}
                  bg={'#E53E3E'}
                  w={'20px'}
                  h={'20px'}
                  color={'#fff'}
                  onClick={() =>
                    handleDeleteService(index, 'service', service.id)
                  }
                >
                  <FaTimes />
                </Button>
              </Box>
            </SimpleGrid>
          ))}
          <Button
            padding={'10px 30px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            alignItems={'center'}
            onClick={handleAddService}
          >
            {services.length === 0 ? 'Add Services' : 'Add More Services'}
            <Icon color={'#fff'} pl={2} fontSize={'26px'} as={RiAddFill} />
          </Button>
        </Stack>
        <Stack className="m4-h adser" gap={4}>
          <Heading as={'h4'}>Parts</Heading>
          {inventory.map((inventory, index) => (
            <SimpleGrid key={index} columns={[2, null, 3]} spacing="40px">
              <Box className="Ifiled">
                <Select
                  color={'#fff'}
                  placeholder="Services Name"
                  value={inventory.name}
                  onChange={e => handleInventoryChange(index, e.target.value)}
                >
                  <option style={{ display: 'none' }} value="">
                    Select
                  </option>
                  {inventoryList?.length > 0 &&
                    inventoryList
                      ?.filter(val => val.active)
                      .map(val => (
                        <option
                          value={JSON.stringify({
                            id: val?.productId,
                            price: val?.cost,
                          })}
                        >
                          {val?.name ?? ''}
                        </option>
                      ))}
                </Select>
              </Box>
              <Box className="Ifiled">
                <Input
                  isDisabled={true}
                  value={inventory?.price}
                  type="text"
                  placeholder="Service Cost"
                />
                <Button
                  display={'flex'}
                  borderRadius={50}
                  alignItems={'center'}
                  justifyContent={'center'}
                  bg={'#E53E3E'}
                  w={'20px'}
                  h={'20px'}
                  color={'#fff'}
                  onClick={() =>
                    handleDeleteService(index, 'inventory', inventory?.id)
                  }
                >
                  <FaTimes />
                </Button>
              </Box>
            </SimpleGrid>
          ))}
          <Button
            padding={'10px 30px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            alignItems={'center'}
            onClick={handleAddInventory}
          >
            {inventory.length === 0 ? 'Add Part' : 'Add More Parts'}
            <Icon color={'#fff'} pl={2} fontSize={'26px'} as={RiAddFill} />
          </Button>
        </Stack>
        {packageData?.length > 0 && (
          <Stack className="m4-h adser" gap={4}>
            <Heading as={'h4'}>Package</Heading>
            {selectedPackages.map((pkg, index) => (
              <SimpleGrid key={index} columns={[2, null, 3]} spacing="40px">
                <Box className="Ifiled icnfiled">
                  <Select
                    color={'#fff'}
                    placeholder="Select Package"
                    onChange={e => handleAddPackage(index, e.target.value)}
                  >
                    <option style={{ display: 'none' }} value="">
                      Select
                    </option>
                    {packageData?.map(item => (
                      <option
                        key={item._id}
                        value={JSON.stringify({
                          id: item._id,
                          price: item.cost,
                          name: item.name,
                        })}
                      >
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Box
                  className="Ifiled"
                  border={'1px solid #fff'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  rounded="md"
                >
                  <Tooltip
                    label={getServiceDetailsString(pkg.serviceDetails)}
                    aria-label="Service details"
                    hasArrow
                  >
                    <Box className="m5-h p1">
                      <Text color={'#ffff'} display="inline-block" mr={2}>
                        {pkg?.serviceDetails?.length || 0} services included
                      </Text>
                      <Icon color={'#fff'} as={InfoIcon} />
                    </Box>
                  </Tooltip>
                </Box>
                <Box className="Ifiled">
                  <Input
                    isDisabled={true}
                    type="text"
                    value={pkg?.price || ''}
                    placeholder="Package Cost"
                  />
                  <Button
                    display={'flex'}
                    borderRadius={50}
                    alignItems={'center'}
                    justifyContent={'center'}
                    bg={'#E53E3E'}
                    w={'20px'}
                    h={'20px'}
                    color={'#fff'}
                    onClick={() =>
                      handleDeleteService(index, 'package', pkg?.id)
                    }
                  >
                    <FaTimes />
                  </Button>
                </Box>
              </SimpleGrid>
            ))}
            <Button
              padding={'10px 30px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              alignItems={'center'}
              onClick={() => setSelectedPackages([...selectedPackages, {}])}
            >
              {selectedPackages.length === 0
                ? 'Add Package'
                : 'Add More Packages'}
              <Icon color={'#fff'} pl={2} fontSize={'26px'} as={RiAddFill} />
            </Button>
            {/* <TableContainer>
              <Table className="jobin m6-h p2">
                <Thead
                  bg={
                    'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                  }
                >
                  <Tr>
                    <Th>
                      <Heading as={'h5'}>Package Name</Heading>
                    </Th>
                    <Th>
                      <Heading as={'h5'}>Price</Heading>
                    </Th>
                    <Th>
                      <Heading as={'h5'}>Services Included</Heading>
                    </Th>
                    <Th>
                      <Heading as={'h5'}>Actions</Heading>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {packageData?.map((item, index) => (
                    <Tr key={index}>
                      <Td>
                        <Text>{item?.name}</Text>
                      </Td>
                      <Td>
                        <Text>
                          <span
                            style={{
                              textDecoration: 'line-through',
                              transform: 'rotate(-45deg)',
                              transformOrigin: 'center center',
                              display: 'inline-block',
                            }}
                          >
                            ${getTotalPrice(item?.name)}
                          </span>{' '}
                          ${item?.cost}
                        </Text>
                      </Td>
                      <Td>
                        <Text>
                          {item?.servicesDetails?.map((service, index) => (
                            <span key={index}>
                              {service?.name}
                              {index !== item?.services?.length - 1
                                ? ' / '
                                : ''}
                            </span>
                          ))}
                        </Text>
                      </Td>
                      <Td>
                        <Link
                          display={'flex'}
                          borderRadius={50}
                          alignItems={'center'}
                          justifyContent={'center'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          w={'30px'}
                          h={'30px'}
                          color={'#fff'}
                          margin={'auto'}
                          onClick={() => {
                            console.log('Button clicked');
                            handleAddPackage(item?._id, item?.cost);
                          }}
                        >
                          {selectedPakages.some(val => val.id === item?._id) ? (
                            <FaCheck />
                          ) : (
                            <FaShoppingCart />
                          )}
                        </Link>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer> */}
          </Stack>
        )}
        {/* <Stack className="m4-h adser" gap={4}>
          <Heading as={'h4'}>Invoice History</Heading>
          <Stack>
            <Stack
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={5}
              px={6}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
            >
              <Box className="m5-h ">
                <Heading as={'h5'}>Ref No</Heading>
              </Box>
              <Box className="m5-h ">
                <Heading as={'h5'}>Details</Heading>
              </Box>
              <Box className="m5-h ">
                <Heading as={'h5'}>Issued to</Heading>
              </Box>
              <Box className="m5-h ">
                <Heading as={'h5'}>Date issued</Heading>
              </Box>
              <Box className="m5-h ">
                <Heading as={'h5'}>Amount</Heading>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={5}
              px={6}
              borderBottom={'1px solid #595C64'}
              className="invice-h"
            >
              <Box className="p1">
                <Text>001</Text>
              </Box>

              <Box className="p1">
                <Text>Body Wash</Text>
              </Box>
              <Box className="p1">
                <Text>Google</Text>
              </Box>
              <Box className="p1">
                <Text>July 14, 2023</Text>
              </Box>
              <Box className="p1">
                <Text>$1,245.00</Text>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack className="m4-h adser" gap={4}>
          <Heading as={'h4'}>Recommended Services</Heading>
          <Stack>
            <Stack
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={5}
              px={6}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
            >
              <Box className="m5-h ">
                <Heading as={'h5'}>Recommended Services</Heading>
              </Box>
              <Box className="m5-h ">
                <Heading as={'h5'}>Check</Heading>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={5}
              px={6}
              borderBottom={'1px solid #595C64'}
              className="invice-h"
            >
              <Box className="p1">
                <Text>Engine / Transmission / Clutch</Text>
              </Box>

              <Box className="p1">
                <Checkbox colorScheme="green"></Checkbox>
              </Box>
            </Stack>
          </Stack>
        </Stack> */}
        <Stack alignItems={'flex-end'}>
          <Button
            display={'flex'}
            alignItems={'center'}
            justifyItems={'center'}
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={handleCreateJob}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
