import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import Singlevender from '../../components/Vendor/Singlevender';


export default function Venderdetail() {
  return (
    <MainDashboard>
        <Stack px={4}>
       <Singlevender/>
        </Stack>
      </MainDashboard>
  )
}
