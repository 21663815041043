import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FiUsers } from 'react-icons/fi';
import { BsHouseDoor } from 'react-icons/bs';
import { RiUserSettingsLine } from 'react-icons/ri';
import { BsBag } from 'react-icons/bs';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

export default function Dashdetails({
  ownerCount,
  jobCount,
  inventoryCount,
  customerCount,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Box display={'flex'} gap={6} flexWrap={{md: 'nowrap', xl: 'nowrap'}}>
        <Stack
          w={{ md: '23%', '2xl': '35%' }}
          bg={'#202125'}
          p={6}
          borderRadius={8}
          className="p1"
        >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box
              alignItems={'center'}
              gap={2}
              className="dashd m3-h"
              display={'flex'}
            >
              <Icon fontSize={'25px'} color={'#2BAF59'} as={FiUsers} />
              <Heading as={'h3'}>{ownerCount ? ownerCount : 0}</Heading>
            </Box>
            {/* <AvatarGroup size="md" max={2}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup> */}
          </Stack>
          <Text fontSize={{ md: '13px', '2xl': '16px' }}>Total Employees </Text>
          <Button
            padding={'5px 30px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            _hover={{
              color: '#000',
            }}
            onClick={() => navigate('/employees')}
          >
            View All
          </Button>
        </Stack>
        <Stack
          w={{ md: '23%', '2xl': '35%' }}
          bg={'#202125'}
          p={4}
          borderRadius={8}
          className="p1"
          pb={0}
        >
          <Box
            alignItems={'center'}
            gap={2}
            className="dashd m3-h"
            display={'flex'}
          >
            <Icon fontSize={'25px'} color={'#2BAF59'} as={BsHouseDoor} />
            <Heading as={'h3'}>{inventoryCount ? inventoryCount : 0}</Heading>
          </Box>
          <Text fontSize={{ md: '13px', '2xl': '16px' }}>Total Parts</Text>
          <Button
            padding={'5px 30px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            _hover={{
              color: '#000',
            }}
            onClick={() => navigate('/inventory')}
          >
            View All
          </Button>
        </Stack>
        <Stack
          w={{ md: '23%', '2xl': '35%' }}
          bg={'#202125'}
          p={4}
          borderRadius={8}
          className="p1"
        >
          <Box
            alignItems={'center'}
            gap={2}
            className="dashd m3-h"
            display={'flex'}
          >
            <Icon fontSize={'25px'} color={'#2BAF59'} as={RiUserSettingsLine} />
            <Heading as={'h3'}>{customerCount ? customerCount : 0}</Heading>
          </Box>
          <Text fontSize={{ md: '13px', '2xl': '16px' }}>
            Existing Customers
          </Text>
          <Button
            padding={'5px 30px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            _hover={{
              color: '#000',
            }}
            onClick={() => navigate('/reports')}
          >
            View All
          </Button>
        </Stack>
        <Stack
          w={{ md: '23%', '2xl': '35%' }}
          bg={'#202125'}
          p={4}
          borderRadius={8}
          className="p1"
        >
          <Box
            alignItems={'center'}
            gap={2}
            className="dashd m3-h"
            display={'flex'}
          >
            <Icon fontSize={'25px'} color={'#2BAF59'} as={BsBag} />
            <Heading as={'h3'}>{jobCount ? jobCount : 0}</Heading>
          </Box>
          <Text fontSize={{ md: '13px', '2xl': '16px' }}>
            Total List of jobs
          </Text>
          <Button
            padding={'5px 30px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            _hover={{
              color: '#000',
            }}
            onClick={() => navigate('/listofjob')}
          >
            View All
          </Button>
        </Stack>
      </Box>
    </>
  );
}
