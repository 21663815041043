import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Heading,
  Link,
  ListItem,
  SimpleGrid,
  Stack,
  FormControl,
  Text,
  UnorderedList,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  FormLabel,
  Input,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { DELETE, POST, PUT } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { ImgUrl } from '../../utilities/config';

import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';

const InvDashboard = () => {
  const user = useSelector(state => state.value);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = useDisclosure();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [filterButtons, setFilterButtons] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [invList, setInvList] = useState(null);
  const [selectedCat, setSelectedCat] = useState({});
  const [editCat, setEditCat] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openModalData, setOpenModalData] = useState();
  const [showWarning, setShowWarning] = useState(false);
  const [addCat, setaddCat] = useState({
    name: '',
    uploadPicture: null,
  });

  const getCategory = async () => {
    setIsLoading(true);
    let categoryName = await POST(
      `/inventory/viewCategory`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    // setEditCat(categoryName?.data[0]);
    setFilterButtons(categoryName?.data);
    setSelectedCat(categoryName?.data?.[0]);
    setIsLoading(false);
  };

  const getInvByCat = async () => {
    setIsLoading(true);
    let invList = await POST(
      `/inventory/viewByCategory`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log(invList?.data);
    setInvList(invList?.data);
    setSelectedData(invList?.data?.[0]?.products);
    setIsLoading(false);
  };

  const handleCreateCat = async () => {
    setIsLoading(false);
    if (addCat.name === '') {
      toast({
        title: 'Please Enter Category Name',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('name', addCat.name);
    formData.append('uploadPicture', addCat.uploadPicture);
    try {
      let response = await POST(`/inventory/createCategory`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log(response);
      if (response.status === 'success') {
        toast({
          title: 'Category Created Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setaddCat({
          name: '',
          uploadPicture: null,
        });
        setIsLoading(false);
        onClose();
        await getCategory();
        getInvByCat();
      } else {
        toast({
          title: 'Category Not Created',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Something Went Wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const reorderItem = async id => {
    try {
      let response = await PUT(
        `/inventory/reorder/${id}`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        console.log(response?.data);
        toast({
          title: response?.message,
          status: response?.status,
          duration: 9000,
          isClosable: true,
        });
        getCategory();
        getInvByCat();
      }
    } catch (error) {
      console.log(error.message);
      toast({
        title: 'Something Went Wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleUpdateCat = async item => {
    try {
      if (openModalData?.name === 'Uncategorized') {
        setShowWarning(false);
        toast({
          title: 'Cannot Update Category Name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      let response = await PUT(
        `/inventory/updateCategory/${item?._id}`,
        {
          name: item?.name,
          ...(item?.uploadPicture && {
            uploadPicture: item?.uploadPicture,
          }),
        },
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        console.log(response?.data);
        toast({
          title: response?.message,
          status: response?.status,
          duration: 9000,
          isClosable: true,
        });
        getCategory();
        getInvByCat();
        onCloseEditModal();
      }
    } catch (error) {
      console.log(error.message);
      toast({
        title: 'Something Went Wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const deleteItem = async id => {
    try {
      let response = await DELETE(`/inventory/delete/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        toast({
          description: response.message,
          status: response.status,
          position: 'top-right',
        });
        getInvByCat();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleEditCat = item => {
    console.log(item, 'ITEM FOR UPDATEW');
    setOpenModalData(item);
    onOpenEditModal();
  };

  const handleDeleteCat = async item => {
    try {
      if (item?.name === 'Uncategorized' && selectedData.length > 0) {
        setShowWarning(false);
        toast({
          title: 'Cannot Delete Category',
          description: 'Please remove the parts from the category first',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      let response = await DELETE(`/inventory/deleteCategory/${item?._id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        toast({
          description: response.message,
          status: response.status,
          position: 'top-right',
        });
        getCategory();
        getInvByCat();
        onCloseEditModal();
        setShowWarning(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCloseEditModal = () => {
    onCloseEditModal(false);
    setShowWarning(false);
  };

  useEffect(() => {
    if (user) {
      getCategory();
      getInvByCat();
    }
  }, [user]);

  useEffect(() => {
    if (invList) {
      console.log('Selected Cat', selectedCat);
      const selectedCategory = invList.find(
        item => item.category === selectedCat?.name
      );
      if (selectedCategory) {
        setSelectedData(selectedCategory.products);
      }
    }
  }, [selectedCat]);

  useEffect(() => {
    console.log(selectedCat, 'selexted Cat');
  }, [selectedCat, setSelectedCat]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Parts </Heading>
        </Stack>
        {user?.privileges
          ?.find(privilege => privilege.name === 'Inventory')
          ?.action?.includes('create') && (
          <Stack
            alignItems={{ base: 'start', xl: 'end' }}
            display={'flex'}
            direction={'row'}
            justifyContent={'flex-end'}
          >
            <Link
              padding={'8px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              to={'/additem'}
              as={ReactLink}
            >
              Add New Part
            </Link>
            <Button
              padding={'10px 50px'}
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              textAlign={'center'}
              w={'fit-content'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              onClick={onOpen}
            >
              Add New Category
            </Button>
          </Stack>
        )}
      </SimpleGrid>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        position={'relative'}
      >
        <UnorderedList
          gap={6}
          display={'flex'}
          py={5}
          listStyleType={'none'}
          overflowX={'auto'}
          width={'100%'}
        >
          {filterButtons?.length > 0 &&
            filterButtons.map((val, ind) => (
              <ListItem key={ind}>
                <Button
                  onClick={() => setSelectedCat(val)}
                  padding={'10px 50px'}
                  bg={
                    val?.name === selectedCat?.name
                      ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      : 'transparent'
                  }
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  color={val === selectedCat ? '#fff' : '#7D7D7D'}
                  border={'1px solid #7D7D7D'}
                  borderRadius={12}
                  position="relative"
                  _hover={{
                    color: '#fff',
                    bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                    border: '1px solid #2BAF59',
                  }}
                  textTransform={'capitalize'}
                  w={'fit-content'}
                >
                  {val.name}
                  <Box
                    boxSize="1.5em"
                    bg="yellow.500"
                    borderRadius={50}
                    position={'absolute'}
                    boxShadow={'0 0 10px #2BAF59'}
                    top={-2}
                    right={-1}
                    justifyContent={'center'}
                    alignItems={'center'}
                    display={'flex'}
                    onClick={() => handleEditCat(val)}
                  >
                    <BiEditAlt />
                  </Box>
                </Button>
              </ListItem>
            ))}
        </UnorderedList>
      </Stack>
      <Stack className="list-ser">
        <Stack
          py={1}
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="p1">
            <Text>
              Total Items:{' '}
              <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                {selectedData?.length ?? 0}
              </Text>
            </Text>
          </Stack>
          {/* <Stack m={'0 !important'} className="p1">
            <Checkbox color={'#7D7D7D'} size="md" colorScheme="green">
              Select All
            </Checkbox>
          </Stack> */}
        </Stack>
        <Stack bg={'#202125'} px={3} py={4} borderRadius={8}>
          <Tabs variant="unstyled">
            <TabList mb={6} gap={4}>
              <Tab
                color={'#fff'}
                px={8}
                bg={'#4D4D4D'}
                borderRadius={6}
                _selected={{
                  color: 'white',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                }}
              >
                In Stock
              </Tab>
              <Tab
                color={'#fff'}
                px={8}
                bg={'#4D4D4D'}
                borderRadius={6}
                _selected={{
                  color: 'white',
                  bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                }}
              >
                Out of Stock
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                {selectedData?.filter(val => val.active).length > 0 ? (
                  selectedData
                    ?.filter(val => val.active)
                    .map(val => (
                      <Stack
                        key={val?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={5}
                        borderRadius={6}
                        bg={'#2e3035'}
                        px={4}
                        mb={3}
                        className="att-list"
                      >
                        <Box className="m5-h" width={'25%'}>
                          <Text
                            padding={'3px 10px'}
                            color={val?.stock ? '#2EC487' : '#F41515'}
                            fontWeight={'500'}
                            lineHeight={'inherit'}
                            borderRadius={12}
                            w={'fit-content'}
                            bg={
                              val?.stock
                                ? 'rgba(46, 196, 135, 0.16)'
                                : 'rgba(244, 21, 21, 0.16)'
                            }
                          >
                            {val?.stock ? 'In Stock' : 'Out of Stock'}
                          </Text>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            spacing={4}
                            m={0}
                          >
                            <Avatar
                              name={val.name}
                              src={`${ImgUrl}${val?.uploadPicture}`}
                              objectFit={'cover'}
                            />
                            <Stack>
                              <Text as={'h5'} opacity={'0.7'}>
                                Item Name
                              </Text>
                              <Text as={'h5'}>{val?.name}</Text>
                            </Stack>
                          </Stack>
                        </Box>
                        <Box className="m5-h" width={'15%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            SKU{' '}
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.sku}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'10%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Sales
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.sales}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'10%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Price
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            ${val?.cost}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'10%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            In Stock
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.available}
                          </Heading>
                        </Box>
                        <Box
                          className="m5-h"
                          width={'10%'}
                          display={'flex'}
                          gap={3}
                          alignItems={'center'}
                        >
                          {user?.privileges
                            ?.find(privilege => privilege.name === 'Inventory')
                            ?.action?.includes('edit') && (
                            <Link
                              display={'flex'}
                              borderRadius={50}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              w={'30px'}
                              h={'30px'}
                              color={'#fff'}
                              onClick={() =>
                                navigate('/additem', { state: val })
                              }
                            >
                              <BiEditAlt />
                            </Link>
                          )}
                          {user?.privileges
                            ?.find(privilege => privilege.name === 'Inventory')
                            ?.action?.includes('delete') && (
                            <Link
                              display={'flex'}
                              borderRadius={50}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={'#E53E3E'}
                              w={'30px'}
                              h={'30px'}
                              color={'#fff'}
                              onClick={() => deleteItem(val._id)}
                            >
                              <AiOutlineDelete />
                            </Link>
                          )}
                          {!val?.stock && (
                            <Button
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              color={'#fff'}
                              isDisabled={val?.stock}
                              onClick={() => {
                                reorderItem(val?._id);
                              }}
                            >
                              Re-Order
                            </Button>
                          )}
                        </Box>
                      </Stack>
                    ))
                ) : (
                  <Stack className={'bread m5-h'} padding={3}>
                    <Heading as={'h5'}>No Products Found </Heading>
                  </Stack>
                )}
              </TabPanel>
              <TabPanel p={0}>
                {selectedData?.filter(val => !val.active)?.length > 0 ? (
                  selectedData
                    ?.filter(val => !val.active)
                    ?.map(val => (
                      <Stack
                        key={val?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={5}
                        borderRadius={6}
                        bg={'#2e3035'}
                        px={4}
                        mb={3}
                        className="att-list"
                      >
                        <Box className="m5-h" width={'25%'}>
                          <Text
                            padding={'3px 10px'}
                            color={val?.stock ? '#2EC487' : '#F41515'}
                            fontWeight={'500'}
                            lineHeight={'inherit'}
                            borderRadius={12}
                            w={'fit-content'}
                            bg={
                              val?.stock
                                ? 'rgba(46, 196, 135, 0.16)'
                                : 'rgba(244, 21, 21, 0.16)'
                            }
                          >
                            {val?.stock ? 'In Stock' : 'Out of Stock'}
                          </Text>
                          <Stack
                            display={'flex'}
                            direction={'row'}
                            spacing={4}
                            m={0}
                          >
                            <Avatar
                              name={val.name}
                              src={`${ImgUrl}${val?.uploadPicture}`}
                              objectFit={'cover'}
                            />
                            <Stack>
                              <Text as={'h5'} opacity={'0.7'}>
                                Item Name
                              </Text>
                              <Text as={'h5'}>{val?.name}</Text>
                            </Stack>
                          </Stack>
                        </Box>
                        <Box className="m5-h" width={'15%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            SKU{' '}
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.sku}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'10%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Sales
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.sales}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'10%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Price
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            ${val?.cost}
                          </Heading>
                        </Box>
                        <Box className="m5-h" width={'10%'}>
                          <Text color={'#fff'} opacity={'0.7'}>
                            Number of Used
                          </Text>
                          <Heading as={'h5'} textTransform={'capitalize'}>
                            {val?.available}
                          </Heading>
                        </Box>
                        <Box
                          className="m5-h"
                          width={'15%'}
                          display={'flex'}
                          gap={3}
                          alignItems={'center'}
                        >
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                            onClick={() => navigate('/additem', { state: val })}
                          >
                            <BiEditAlt />
                          </Link>
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#E53E3E'}
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                            onClick={() => deleteItem(val._id)}
                          >
                            <AiOutlineDelete />
                          </Link>
                          {!val?.reorder ? (
                            <Button
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              color={'#fff'}
                              isDisabled={val?.stock}
                              onClick={() => {
                                reorderItem(val?._id);
                              }}
                            >
                              Re-Order
                            </Button>
                          ) : (
                            <Box className="m5-h">
                              <Text
                                color={'#fff'}
                                textAlign={'center'}
                                padding={'5px 10px'}
                                bg={
                                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                                }
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                textTransform={'capitalize'}
                                w={'fit-content'}
                                mb={0}
                              >
                                Reordered
                              </Text>
                            </Box>
                          )}
                        </Box>
                      </Stack>
                    ))
                ) : (
                  <Stack className={'bread m5-h'} padding={3}>
                    <Heading as={'h5'}>No Products Found </Heading>
                  </Stack>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      </Stack>
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'100%'}>
            <ModalHeader color={'#fff'}>Add Category</ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={6}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Category Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={addCat.name}
                      onChange={e =>
                        setaddCat({
                          ...addCat,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Here"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Upload Picture
                  </FormLabel>
                  <Box className="ufiled">
                    <Input
                      pt={0}
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        setaddCat({
                          ...addCat,
                          uploadPicture: e.target.files[0],
                        })
                      }
                    />
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                isLoading={isLoading}
                onClick={() => {
                  handleCreateCat();
                }}
              >
                Create
              </Button>
              <Button colorScheme="red" value={'Cancel'} onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
          <ModalOverlay />
          <ModalContent
            alignContent={'center'}
            alignItems={'center'}
            bg={'#202125'}
            border={'1px solid #4D4D4D'}
            borderRadius={14}
          >
            <ModalHeader color={'#ffff'}>Update Category</ModalHeader>

            <ModalCloseButton color={'#FFFF'} />
            <ModalBody style={{ color: '#ffff' }}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Category Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      disabled={openModalData?.name === 'Uncategorized'}
                      type="text"
                      value={openModalData?.name}
                      onChange={e =>
                        setOpenModalData({
                          ...openModalData,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Here"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Upload Picture
                  </FormLabel>
                  <Box className="ufiled">
                    <Input
                      pt={0}
                      disabled={selectedCat?.name === 'Uncategorized'}
                      type="file"
                      accept="image/*"
                      // onChange={e =>
                      //   setaddCat({
                      //     ...addCat,
                      //     uploadPicture: e.target.files[0],
                      //   })
                      // }
                    />
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter display={'flex'} flexWrap={'wrap'}>
              {showWarning === false ? (
                <Box>
                  <Button
                    mr={3}
                    onClick={() => handleUpdateCat(openModalData)}
                    padding={'15px 25px'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    textAlign={'center'}
                    size={'sm'}
                  >
                    Update
                  </Button>
                  <Button
                    mr={3}
                    onClick={() => setShowWarning(true)}
                    // onClick={() => handleDeleteCat(openModalData?._id)}
                    padding={'15px 25px'}
                    bg={'#E53E3E'}
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    textAlign={'center'}
                    size={'sm'}
                  >
                    Delete Category
                  </Button>
                </Box>
              ) : (
                <Box display={'flex'} flexDirection={'column'} gap={3}>
                  <Text color="white">
                    Are you sure you want to delete this category?
                  </Text>
                  <Button
                    mr={3}
                    onClick={() => handleDeleteCat(openModalData)}
                    padding={'10px 30px'}
                    bg={'#E53E3E'}
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    textAlign={'center'}
                    size={'sm'}
                  >
                    Delete Category
                  </Button>
                </Box>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
};

export default InvDashboard;
