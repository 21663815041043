import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import Singleowner from '../../components/Owner/Singleowner';

export default function Ownerdetail() {
  return (
    <>
       <MainDashboard>
        <Stack px={4}>
        <Singleowner/>
        </Stack>
      </MainDashboard>
    </>
  )
}
