import React from 'react'
import MainDashboard from '../../../components/DashNav/MainDashboard'
import { Stack } from '@chakra-ui/react'
import DetailInvoice from '../../../components/Technician/Sale/DetailInvoice'

export default function InvoiceDetail() {
  return (
    <>
    <MainDashboard>
        <Stack px={4}>
      <DetailInvoice/>
        </Stack>
      </MainDashboard>
    </>
  )
}
