import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import CreateDiscount from '../../components/Discounts/CreateDiscount';
export default function AddDiscount() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <CreateDiscount />
        </Stack>
      </MainDashboard>
    </>
  );
}
