import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import Management from '../../components/Reports/Management';
export default function ManageReport() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <Management />
        </Stack>
      </MainDashboard>
    </>
  );
}
