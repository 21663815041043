import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import AddPackage from '../../components/Package/AddPackage';
export default function PackageAdd() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <AddPackage />
        </Stack>
      </MainDashboard>
    </>
  );
}
