import React from 'react'
import { Stack } from '@chakra-ui/react';
import JobList from '../../../components/Technician/Jobs/JobList';
import MainDashboard from '../../../components/DashNav/MainDashboard';


export default function Job() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
        <JobList/>
        </Stack>
      </MainDashboard>
    </>
  )
}
