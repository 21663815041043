import {
  Heading,
  Stack,
  Link,
  SimpleGrid,
  Button,
  Box,
  Text,
  Badge,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { POST, PUT } from '../../utilities/ApiProvider';
import { useDispatch, useSelector } from 'react-redux';
import { currentAttendance } from '../../reducers/useReducers';

export default function ListAttendance() {
  const toast = useToast();
  const dispatch = useDispatch();
  const user = useSelector(state => state?.value);
  const todayAttendance = useSelector(state => state?.value?.currentAttendance);

  const [checkingInLoader, setCheckingInLoader] = useState(false);
  const [checkingOutLoader, setCheckingOutLoader] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);

  const getAttendanceList = async () => {
    try {
      let response = await POST(
        `/attendance/getAttendance`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (response?.status === 'success') {
        setAttendanceList(response?.data);
        console.log('My List', response?.data);
      }
    } catch (err) {
      toast({
        description: err.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
    }
  };

  const markAttendance = async isCheckingIn => {
    if (isCheckingIn) {
      setCheckingInLoader(true);
      try {
        console.log('CLOCK IN', new Date().toLocaleString());
        let response = await POST(
          `/attendance/clockIn`,
          { clockIn: new Date().toISOString() },
          { authorization: `Bearer ${user?.verificationToken}` }
        );
        if (response?.status === 'success') {
          dispatch(currentAttendance(response?.data));
          getAttendanceList();
        }
        toast({
          description: response?.message,
          position: 'top-right',
          isClosable: true,
          status: response?.status,
          duration: 3000,
        });
      } catch (err) {
        toast({
          description: err.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          duration: 3000,
        });
      }
      setCheckingInLoader(false);
    } else {
      setCheckingOutLoader(true);
      // if (!todayAttendance?._id) {
      //   toast({
      //     description: 'You have not checked in yet',
      //     position: 'top-right',
      //     isClosable: true,
      //     status: 'error',
      //     duration: 3000,
      //   });
      //   setCheckingOutLoader(false);
      //   return;
      // }
      try {
        console.log('CLOCK OUT', new Date().toLocaleString());
        let response = await PUT(
          `/attendance/clockOut/${todayAttendance?._id}`,
          { clockOut: new Date().toISOString() },
          { authorization: `Bearer ${user?.verificationToken}` }
        );
        if (response?.status === 'success') getAttendanceList();
        toast({
          description: response?.message,
          position: 'top-right',
          isClosable: true,
          status: response?.status,
          duration: 3000,
        });
      } catch (err) {
        toast({
          description: err.message,
          position: 'top-right',
          isClosable: true,
          status: 'error',
          duration: 3000,
        });
      }
      setCheckingOutLoader(false);
    }
  };

  function formatTime(isoString) {
    let date = new Date(isoString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let period = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12;

    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} ${period}`;
    return formattedTime;
  }

  useEffect(() => {
    if (user) {
      getAttendanceList();
    }
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'} mb={8}>
          <Heading as={'h3'}>Attendance </Heading>
        </Stack>
        <Stack alignItems={{ base: 'start', xl: 'end' }}>
          <Link
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/myrequest'}
            as={ReactLink}
          >
            My Requests
          </Link>
        </Stack>
      </SimpleGrid>
      <Box display={'flex'} gap={6} pb={6}>
        <Button
          padding={'10px 50px'}
          bg={
            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
          }
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          _hover={{
            color: '#000',
          }}
          w={'fit-content'}
          onClick={() => markAttendance(true)}
          isLoading={checkingInLoader}
        >
          Clock In
        </Button>
        <Button
          padding={'10px 50px'}
          bg={
            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
          }
          color={'#fff'}
          fontWeight={'500'}
          lineHeight={'inherit'}
          borderRadius={12}
          _hover={{
            color: '#000',
          }}
          w={'fit-content'}
          onClick={() => markAttendance(false)}
          isLoading={checkingOutLoader}
        >
          Clock Out
        </Button>
      </Box>
      <Stack bg={'#202125'} px={3} py={4} borderRadius={8}>
        {attendanceList?.length > 0 ? (
          attendanceList?.map((val, ind) => (
            <Stack
              key={val?._id}
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={5}
              borderRadius={6}
              bg={'#2e3035'}
              px={4}
              className="att-list"
            >
              <Box className="m5-h p1">
                <Text>No:</Text>
                <Heading as={'h5'}>{++ind}</Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Date</Text>
                <Heading as={'h5'}>
                  {new Date(val?.Date).toLocaleDateString()}
                </Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Day</Text>

                <Heading as={'h5'}>{val?.Day}</Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Clock In</Text>
                <Heading as={'h5'}>{formatTime(val?.clockIn)}</Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Clock Out</Text>
                <Heading as={'h5'}>
                  {val.clockOut ? formatTime(val?.clockOut) : '--:--:--'}
                </Heading>
              </Box>

              <Box className="m5-h p1">
                <Text>Hours Worked</Text>
                <Heading as={'h5'}>
                  {val?.totalHours ? val?.totalHours : 'Not clocked-out'}
                </Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Status</Text>
                <Badge padding={'10px 20px'} borderRadius={'25px'}>
                  {val?.status}
                </Badge>
              </Box>
            </Stack>
          ))
        ) : (
          <Stack className={'bread m5-h'}>
            <Heading as={'h5'}>No Attendance Marked </Heading>
          </Stack>
        )}
      </Stack>
    </>
  );
}
