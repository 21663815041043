import {
  Heading,
  SimpleGrid,
  Stack,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  Button,
  Text,
  useDisclosure,
  Badge,
  Toast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { BsChatSquareFill } from 'react-icons/bs';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { formatTime } from '../../utilities/helper';

export default function AttendanceRequest() {
  const user = useSelector(state => state?.value);
  const [attendanceList, setAttendanceList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setmodalData] = useState(null);

  const getMyRequestList = async () => {
    try {
      let response = await POST(
        `/attendance/getAttendanceRequest`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        setAttendanceList(response?.data);
      }
      console.log('Attendance', response?.data);
    } catch (err) {
      Toast({
        description: err.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
    }
  };

  function onOpenModal(data) {
    setmodalData(data);
    onOpen();
  }

  useEffect(() => {
    if (user) {
      getMyRequestList();
    }
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'} mb={8}>
          <Heading as={'h3'}>
            My Request /{' '}
            <Link to={'/attendance'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
        <Stack alignItems={{ base: 'start', xl: 'end' }}>
          <Link
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/applyrequest'}
            as={ReactLink}
          >
            Apply Requests
          </Link>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
        {/* <Stack
          direction={'row'}
          flexWrap={'wrap'}
          alignItems={'flex-end'}
          borderBottom={'1px solid #2e3035'}
          pb={8}
          mb={6}
          gap={4}
        >
          <Stack className="Att">
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Start Date
            </FormLabel>
            <Box className="Ifiled">
              <Input type="date" placeholder="Start Date" />
            </Box>
          </Stack>
          <Stack className="Att">
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              End Date
            </FormLabel>
            <Box className="Ifiled">
              <Input type="date" placeholder="End Date" />
            </Box>
          </Stack>
          <Stack>
            <Button
              padding={'25px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
            >
              Search
            </Button>
          </Stack>
        </Stack> */}
        {attendanceList.length > 0 ? (
          attendanceList.map((attendance, index) => (
            <Stack
              key={attendance?._id}
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              py={5}
              borderRadius={6}
              bg={'#2e3035'}
              px={4}
              className="att-list"
            >
              <Box className="m5-h p1">
                <Text>No</Text>
                <Heading as={'h5'}>{++index}</Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Clock Date</Text>
                <Heading as={'h5'}>
                  {new Date(attendance?.clockDate).toLocaleDateString()}
                </Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Clock Time</Text>

                <Heading as={'h5'}>{formatTime(attendance?.clockTime)}</Heading>
              </Box>
              <Box className="m5-h p1">
                <Text>Issue</Text>
                <Heading as={'h5'}>{attendance?.issue}</Heading>
              </Box>

              <Box className="m5-h p1">
                <Text textAlign={'center'}>Status</Text>
                <Badge padding={'10px 20px'} borderRadius={'25px'}>
                  {attendance?.status}
                </Badge>
              </Box>
              <Box className="m5-h p1">
                <Text>View Details</Text>
                <Link
                  display={'flex'}
                  borderRadius={50}
                  alignItems={'center'}
                  justifyContent={'center'}
                  bg={
                    'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                  }
                  w={'40px'}
                  h={'40px'}
                  color={'#fff'}
                  margin={'auto'}
                  onClick={() => onOpenModal(attendance)}
                >
                  <BsChatSquareFill />
                </Link>
              </Box>
            </Stack>
          ))
        ) : (
          <Stack className={'bread m5-h'}>
            <Heading as={'h5'}>No Request Yet </Heading>
          </Stack>
        )}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bgColor={'#7a7a7a96'} />
        <ModalContent
          alignContent={'center'}
          alignItems={'center'}
          bg={'#202125'}
          border={'1px solid #4D4D4D'}
          borderRadius={14}
        >
          <ModalHeader color={'#ffff'}>Issue Description </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text color={'#ffff'}>{modalData?.description}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
