import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import Dashboard from '../../components/Reports/Dashboard';

export default function Reports() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <Dashboard />
        </Stack>
      </MainDashboard>
    </>
  );
}
