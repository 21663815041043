import {
  Box,
  Button,
  Heading,
  Stack,
  Text,
  Link,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { io } from 'socket.io-client';

// const socket = io('liqtec.thewebtestlink.xyz:5401'); // Replace with your server URL
export default function JobList() {
  const toast = useToast();
  const customId = 'job-toast';
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const getJobList = async () => {
    setIsLoading(true);
    try {
      let res = await POST(
        `/job/getJob`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        setListData(res?.all?.pending);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  const assignTechnician = async id => {
    try {
      let res = await POST(
        `/job/assignTechnician/${id}`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        navigate(`/customerjobdetail/${id}`);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  // useEffect(() => {
  //   if (user) getJobList();

  //   // Socket.IO connection established
  //   socket.on('connect', () => {
  //     console.log('Connected to server', socket);
  //   });

  //   // receiving an event
  //   socket.on('jobCreated', value => {
  //     console.log('Job Value', value);
  //     getJobList();
  //     toast({
  //       description: `New Job Available`,
  //       status: 'success',
  //       position: 'top-right',
  //       isClosable: true,
  //       duration: 3000,
  //     });
  //   });

  //   // Clean up on component unmount
  //   return () => {
  //     // Disconnect the socket only when the component unmounts
  //     return () => {
  //       console.log('Disconnecting socket');
  //       socket.disconnect();
  //     };
  //   };
  // }, [user]);

  // useEffect(() => {
  //   let isMounted = true;
  //   // Socket.IO connection established
  //   socket.on('connect', () => {
  //     console.log('Connected to server', socket);
  //   });

  //   // Receiving an event
  //   socket.on('jobCreated', value => {
  //     if (isMounted) {
  //       console.log('Job Value', value);
  //       // Update the listData state, avoiding duplicates
  //       setListData(prevListData => {
  //         const jobExists = prevListData.some(job => job._id === value._id);
  //         if (!jobExists) {
  //           return [value, ...prevListData];
  //         }
  //         return prevListData;
  //       });
  //       if (!toast.isActive(customId)) {
  //         toast({
  //           id: customId,
  //           description: `New Job Available`,
  //           status: 'success',
  //           position: 'top-right',
  //           isClosable: true,
  //           duration: 3000,
  //         });
  //       }
  //     }
  //   });
  //   // Clean up on component unmount
  //   return () => {
  //     isMounted = false;
  //     console.log('Disconnecting socket');
  //     socket.disconnect();
  //   };
  // }, [socket, user]);

  useEffect(() => {
    if (user) {
      getJobList();
    }
  }, [user]);

  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>Jobs</Heading>
      </Stack>
      {isLoading ? (
        <Spinner color="primaryGreen.100" />
      ) : (
        <>
          <Stack bg={'#202125'} px={5} py={7} borderRadius={8} gap={4}>
            <Stack className="m4-h adser" gap={4}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                className="p1"
              >
                <Heading as={'h4'}>List of Available Jobs</Heading>

                <Text>
                  Total Jobs:{' '}
                  <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                    {listData?.filter(val => !val?.assignedTechnician).length ??
                      0}
                  </Text>
                </Text>
              </Stack>

              {listData?.filter(val => !val?.assignedTechnician)?.length > 0 ? (
                listData
                  .filter(val => !val?.assignedTechnician)
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map(val => (
                    <Stack
                      key={val?._id}
                      direction={'row'}
                      flexWrap={'wrap'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      py={5}
                      borderRadius={6}
                      bg={'#2e3035'}
                      px={4}
                      className="att-list"
                    >
                      <Box className="m5-h p1">
                        <Text>Date</Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {new Date(val?.createdAt).toLocaleDateString()}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Job Number</Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {val?.jobNumber ?? '-'}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Name</Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {val?.customer?.firstName ?? '-'}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Customer type</Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {val?.invoice?.length > 0 ? 'Old' : 'New'}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Vehicle Make</Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {val?.vehicle?.make}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Services</Text>
                        <Heading as={'h5'} textTransform={'capitalize'}>
                          {val?.service?.length}
                        </Heading>
                      </Box>

                      <Box className="m5-h p1">
                        <Text>Action</Text>
                        <Stack direction={'row'} alignItems={'center'} gap={4}>
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'40px'}
                            h={'40px'}
                            color={'#fff'}
                            margin={'auto'}
                            // to={'/jobdetail'}
                            to={`/customerjobdetail/${val?._id}`}
                            as={ReactLink}
                          >
                            <BsEye />
                          </Link>
                          <Button
                            display={'flex'}
                            borderRadius={8}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'60px'}
                            h={'40px'}
                            color={'#fff'}
                            margin={'auto'}
                            onClick={() => assignTechnician(val?._id)}
                          >
                            Apply
                          </Button>
                        </Stack>
                      </Box>
                    </Stack>
                  ))
              ) : (
                <Stack className={'bread m5-h'} py={5}>
                  <Heading as={'h5'}>No Jobs Available </Heading>
                </Stack>
              )}
            </Stack>
          </Stack>

          <Stack bg={'#202125'} px={5} py={7} borderRadius={8} gap={4}>
            <Stack className="m4-h adser" gap={4}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                className="p1"
              >
                <Heading as={'h4'}>Active Jobs</Heading>

                <Text>
                  Total Jobs:{' '}
                  <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                    {listData?.filter(
                      val => val?.assignedTechnician?._id === user?._id
                    ).length ?? 0}
                  </Text>
                </Text>
              </Stack>
            </Stack>

            {listData?.filter(val => val?.assignedTechnician?._id === user?._id)
              ?.length > 0 ? (
              listData
                .filter(val => val?.assignedTechnician?._id === user?._id)
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map(val => (
                  <Stack
                    key={val?._id}
                    direction={'row'}
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    py={5}
                    borderRadius={6}
                    bg={'#2e3035'}
                    px={4}
                    className="att-list"
                  >
                    <Box className="m5-h p1">
                      <Text>Date</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {new Date(val?.createdAt).toLocaleDateString()}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Job Number</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.jobNumber ?? '-'}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Name</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.customer?.firstName ?? '-'}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Customer type</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.invoice?.length > 0 ? 'Old' : 'New'}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Vehicle Make</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.vehicle?.make}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1">
                      <Text>Services</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.service?.length}
                      </Heading>
                    </Box>

                    <Box className="m5-h p1">
                      <Text>Action</Text>
                      <Stack direction={'row'} alignItems={'center'} gap={4}>
                        <Link
                          display={'flex'}
                          borderRadius={50}
                          alignItems={'center'}
                          justifyContent={'center'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          w={'40px'}
                          h={'40px'}
                          color={'#fff'}
                          margin={'auto'}
                          // to={'/jobdetail'}
                          to={`/customerjobdetail/${val?._id}`}
                          as={ReactLink}
                        >
                          <BsEye />
                        </Link>
                      </Stack>
                    </Box>
                  </Stack>
                ))
            ) : (
              <Stack className={'bread m5-h'} py={5}>
                <Heading as={'h5'}>No Active Jobs </Heading>
              </Stack>
            )}
          </Stack>
        </>
      )}
    </>
  );
}
