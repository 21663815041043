import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  Input,
  Select,
  Button,
  Icon,
  Link,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiAddFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { BsEye } from 'react-icons/bs';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { GET } from '../../../utilities/ApiProvider';

export default function DetailInvoice() {
  const user = useSelector(state => state.value);
  const Params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [empdetail, setempdetail] = useState('');

  useEffect(() => {
    if (user) {
      getUserFields();
    }
  }, [user]);

  const getUserFields = async () => {
    setIsLoading(true);
    let Invoicedetail = await GET(
      `/invoice/${Params.id}`,

      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );

    setempdetail(Invoicedetail?.data);
    setIsLoading(false);
  };
  
  useEffect(() => {
    console.log('single invoice ', empdetail);
  }, [empdetail]);

  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>Invoice Details</Heading>
      </Stack>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
          <Stack direction={'row'} justifyContent={'space-between'} pb={4}>
            <Stack className="p1 m4-h">
              <Badge
                textAlign={'center'}
                py={2}
                px={6}
                borderRadius={6}
                variant="solid"
                colorScheme={
                  empdetail?.paymentStatus == 'paid' ? 'green' : 'red'
                }
              >
                {empdetail?.paymentStatus}
              </Badge>
            </Stack>
            <Stack direction={'row'} className="p1">
              <Text>
                Date issued:
                <Text as={'span'} color={'#fff'}>
                  {' '}
                  {new Date(empdetail?.createdAt).toLocaleDateString()}
                </Text>
              </Text>
            </Stack>
          </Stack>
          <Stack w={'100%'} className="m4-h">
            <Heading as={'h4'} mb={5}>
              Vehicle Details:
            </Heading>
            <TableContainer>
              <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                <Tbody className="m6-h p2">
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        car Make
                      </Heading>
                    </Td>
                    <Td>
                      <Text>{empdetail?.vehicleId?.make}</Text>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        License
                      </Heading>
                    </Td>
                    <Td>
                      <Text>{empdetail?.vehicleId?.license}</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Vin Number
                      </Heading>
                    </Td>
                    <Td>
                      <Text>{empdetail?.vehicleId?.vinNumber}</Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack direction={'row'} pb={4} borderBottom={'1px solid #2e3035'}>
            <Stack w={'100%'} className="m4-h">
              <Heading as={'h4'} mb={5}>
                From
              </Heading>
              <TableContainer>
                <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                  <Tbody className="m6-h p2">
                    <Tr>
                      <Td>
                        <Heading fontSize={'16px'} color={'#2BAF59'}>
                          CSR Name
                        </Heading>
                      </Td>
                      <Td>
                        <Text>{empdetail?.userId?.fullName}</Text>
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Heading fontSize={'16px'} color={'#2BAF59'}>
                          Email
                        </Heading>
                      </Td>
                      <Td>
                        <Text>{empdetail?.userId?.email}</Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading fontSize={'16px'} color={'#2BAF59'}>
                          Role
                        </Heading>
                      </Td>
                      <Td>
                        <Text>{empdetail?.userId?.role}</Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
            <Stack w={'100%'} className="m4-h">
              <Heading as={'h4'} mb={5}>
                Bill to
              </Heading>
              <TableContainer>
                <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                  <Tbody className="m6-h p2">
                    <Tr>
                      <Td>
                        <Heading fontSize={'16px'} color={'#2BAF59'}>
                          Customer Name
                        </Heading>
                      </Td>
                      <Td>
                        <Text>{empdetail?.customerId?.name}</Text>
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <Heading fontSize={'16px'} color={'#2BAF59'}>
                          Address
                        </Heading>
                      </Td>
                      <Td>
                        <Text>{empdetail?.customerId?.address}</Text>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <Heading fontSize={'16px'} color={'#2BAF59'}>
                          Contact
                        </Heading>
                      </Td>
                      <Td>
                        <Text>{empdetail?.customerId?.number}</Text>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
          {empdetail?.service?.length > 0 &&
            empdetail?.service?.map((v, k) => {
              return (
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  py={5}
                  borderRadius={6}
                  bg={'#2e3035'}
                  px={4}
                  className="att-list"
                  key={k}
                >
                  <Box className="m5-h p1">
                    <Text>Service</Text>
                    <Heading as={'h5'}>{v?.id?.name}</Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Category</Text>
                    <Heading as={'h5'}>{v?.id?.category}</Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Qty</Text>

                    <Heading as={'h5'}>{v?.qty}</Heading>
                  </Box>
                  <Box className="m5-h p1">
                    <Text>Price</Text>
                    <Heading as={'h5'}>${v?.id?.price}</Heading>
                  </Box>

                  <Box display={'flex'} gap={4}></Box>
                </Stack>
              );
            })}

          <Stack w={'100%'} className="p2">
            {/* <Text>All payment are in USD, All fee is upto buyer</Text> */}
            <TableContainer>
              <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                <Tbody className="m6-h p2">
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Total Qty
                      </Heading>
                    </Td>
                    <Td>
                      <Text>{empdetail?.totalQty}</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Heading fontSize={'16px'} color={'#2BAF59'}>
                        Tax 10%:
                      </Heading>
                    </Td>
                    <Td>
                      <Text>${empdetail?.totalPrice}</Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      )}
    </>
  );
}
