import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import ItemDetail from '../../components/Reports/ItemDetail';
export default function ItemReport() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <ItemDetail />
        </Stack>
      </MainDashboard>
    </>
  );
}
