import {
  Avatar,
  Box,
  Heading,
  Link,
  Stack,
  Text,
  Button,
  Divider,
} from '@chakra-ui/react';
import { React, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ImgUrl } from '../../../utilities/config';

export default function ListJobs({ Jobs }) {
  const navigate = useNavigate();
  console.log('JOBS', Jobs);

  return (
    <>
      <Stack>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="m4-h">
            <Heading as={'h4'}>List Of Jobs</Heading>
          </Stack>
          <Stack>
            <Link as={ReactLink} to={'/listofjob'} color={'#7D7D7D'}>
              View All
            </Link>
          </Stack>
        </Box>
        <Stack borderRadius={6} bg={'#202125'} gap={3} p={3}>
          {Jobs?.filter(val => val?.status === 'pending')?.length > 0 ? (
            Jobs.filter(val => val?.status === 'pending')
              .slice(0, 3)
              .map((val, e) => {
                return (
                  <Stack
                    py={2}
                    px={3}
                    borderRadius={6}
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                    bg={'#2E3035'}
                    key={e}
                  >
                    <Stack
                      direction={'row'}
                      width={'full'}
                      justifyContent={'space-between'}
                    >
                      <Box className="m5-h">
                        <Stack
                          display={'flex'}
                          direction={'row'}
                          spacing={4}
                          m={0}
                        >
                          <Avatar
                            name={val.name}
                            src={`${ImgUrl}${val?.uploadPicture}`}
                            objectFit={'cover'}
                          />
                          <Stack>
                            <Text as={'h5'} opacity={'0.7'}>
                              ID: {val.jobNumber}
                            </Text>
                            <Text as={'h5'}>
                              {val?.vehicle?.make}
                              {' '}
                              {val?.vehicle?.model}
                            </Text>
                          </Stack>
                        </Stack>
                      </Box>
                      <Stack className="venbox p1 m3-h">
                        <Button
                          padding={{ md: '10px 15px', '2xl': '10px 50px' }}
                          fontSize={{ md: '13px', '2xl': '16px' }}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          fontWeight={'500'}
                          lineHeight={'inherit'}
                          borderRadius={12}
                          _hover={{
                            color: '#000',
                          }}
                          w={'100%'}
                          textAlign={'center'}
                          onClick={() =>
                            navigate(`/customerjobdetail/${val?._id}`)
                          }
                        >
                          View Full Details
                        </Button>
                      </Stack>
                    </Stack>
                    <Divider py={2} />
                    <Box className="m5-h" pt={2} pb={1}>
                      <Text as={'h5'}>Service Details :</Text>
                    </Box>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      p={0}
                    >
                      <Box className="m5-h p1">
                        <Text>Status</Text>
                        <Heading as={'h5'}>{val?.status}</Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Coupon</Text>
                        <Heading as={'h5'}>
                          {val?.coupon ? val?.coupon : 'No Coupon'}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Discount</Text>
                        <Heading as={'h5'}>{val?.discount ?? '0%'}</Heading>
                      </Box>
                      <Box className="m5-h p1">
                        <Text>Invoice</Text>
                        <Heading as={'h5'}>
                          {val?.invoice[0]?.invoiceNumber ?? '-------'}
                        </Heading>
                      </Box>
                    </Stack>
                  </Stack>
                );
              })
          ) : (
            <Box className="m5-h" pt={2} pb={1}>
              <Text as={'h5'}>No Pending Jobs Found</Text>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
}
