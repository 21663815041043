import {
  Avatar,
  Box,
  Button,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { ImgUrl } from '../../../utilities/config';

const InventoryDetails = ({ Items }) => {
  return (
    <>
      <Stack>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="m4-h">
            <Heading as={'h4'}>Parts</Heading>
          </Stack>
          <Stack>
            <Link as={ReactLink} to={'/inventory'} color={'#7D7D7D'}>
              View All
            </Link>
          </Stack>
        </Box>
        <Stack bg={'#202125'} px={5} py={2} pb={3} borderRadius={8}>
          {Items?.length > 0 ? (
            Items.slice(0, 4).map((val, e) => {
              return (
                <Stack
                  direction={'row'}
                  flexWrap={'wrap'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  py={5}
                  borderBottom={'1px solid #595C64'}
                  key={val?._id}
                >
                  <Box className="m5-h" width={{ xl: '40%', md: '40%' }}>
                    <Stack display={'flex'} direction={'row'} spacing={4} m={0}>
                      <Avatar
                        name={val.name}
                        src={`${ImgUrl}${val?.uploadPicture}`}
                        objectFit={'cover'}
                      />
                      <Stack>
                        <Text
                          as={'h5'}
                          opacity={'0.7'}
                          display={{ lg: 'block', md: 'none' }}
                        >
                          ID: {val._id.slice(0, 5)}
                        </Text>
                        <Text as={'h5'}>{val?.name}</Text>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box
                    className="m5-h p1"
                    width={'10%'}
                    display={{ lg: 'block', md: 'none' }}
                  >
                    <Text>Stocks</Text>
                    <Heading as={'h5'}>{val?.available}</Heading>
                  </Box>
                  <Box
                    className="m5-h p1"
                    width={'10%'}
                    display={{ lg: 'block', md: 'none' }}
                  >
                    <Text>Sales</Text>
                    <Heading as={'h5'}>{val?.sales}</Heading>
                  </Box>
                  <Box
                    className="m5-h"
                    width={{ xl: '30%', md: '40%' }}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Button
                      variant={'outline'}
                      color={'rgba(255, 255, 255, 0.60)'}
                      padding={'6px 7px'}
                      _hover={{
                        color: 'rgba(255, 255, 255, 0.80)',
                      }}
                    >
                      <Link as={ReactLink} to={`/itemdetail/${val?.productId}`}>
                        View Detail
                      </Link>
                    </Button>
                  </Box>
                </Stack>
              );
            })
          ) : (
            <Box className="m5-h" pt={2} pb={1}>
              <Text as={'h5'}>No Items Found</Text>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default InventoryDetails;
