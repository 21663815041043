import {
  Box,
  Button,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { POST, PUT } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { RiAddFill } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

const AddPackage = () => {
  const user = useSelector(state => state?.value);
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [packageDetail, setpackageDetail] = useState({
    name: '',
    price: '',
  });
  const [services, setServices] = useState([]);
  const [editData, seteditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newService, setNewService] = useState(null);
  const [servicesList, setServicesList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);

  const getAllServices = async () => {
    setIsLoading(true);
    try {
      let res = await POST(
        `/service/getAllService`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        const servicesList = res?.data;
        // Flatten the services arrays from each category
        const allServices = servicesList.flatMap(category => category.services);
        console.log(allServices);
        setServicesList(allServices);
      }
    } catch (err) {
      console.log(err.message);
    }
    setIsLoading(false);
  };
  const getInventory = async () => {
    try {
      let res = await POST(
        `/inventory/view`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        console.log('INVENTORY DATA', res?.data);
        setInventoryList(res?.data);
      }
    } catch (err) {
      console.log(err?.message);
    }
  };
  const handleAddService = () => {
    if (newService === null) {
      return;
    }
    setServices([...services, newService]);
    setNewService(null);
  };

  const handleDeleteService = id => {
    const updatedServices = services.filter(service => service.id !== id);
    setServices(updatedServices);
  };

  const handleSavePackage = async () => {
    const payload = {
      name: packageDetail.name,
      cost: Number(packageDetail.price),
      services: services.map(service => service.id),
    };
    console.log('PAYLOAD', payload);
    if (services.length === 0 || !services) {
      toast({
        title: 'No Services or Parts Added',
        status: 'error',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      let response = await POST('/job/createJobPackage', payload, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response?.status === 'success') {
        console.log('Package Created Successfully');
        toast({
          title: 'Package Created Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/ouroffers');
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  const handleUpdatePackage = async () => {
    const payload = {
      name: packageDetail.name,
      cost: Number(packageDetail.price),
      services: services.map(service => service.id),
    };
    console.log('PAYLOAD', payload);
    try {
      let response = await PUT(
        `/job/updateJobPackages/${editData?._id}`,
        payload,
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        console.log('Package Updated Successfully');
        toast({
          title: 'Package Updated Successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        navigate('/ouroffers');
      }
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    if (user) {
      getAllServices();
      getInventory();
      seteditData(location.state);
    }
  }, [user]);

  useEffect(() => {
    if (editData) {
      setpackageDetail({
        name: editData?.name,
        price: editData?.cost,
      });
      setServices(
        editData?.services.map(service => ({
          id: service._id,
          name: service.name,
          type: service.isInventory ? 'inventory' : 'service',
        }))
      );
    }
    console.log('Edit', editData);
  }, [editData]);

  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>Create Package</Heading>
      </Stack>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
        <SimpleGrid
          columns={{
            base: '1',
            sm: '1',
            md: '2',
            lg: '2',
            xl: '2',
            '2xl': '2',
          }}
          spacingX="40px"
          spacingY="20px"
        >
          <Box>
            <Stack my={3} spacing={'30px'}>
              <Box className="Ifiled">
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Enter Package Name
                </FormLabel>
                <Input
                  value={packageDetail.name}
                  onChange={e =>
                    setpackageDetail({
                      ...packageDetail,
                      name: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Enter Name"
                />
              </Box>
              <Box className="Ifiled">
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Enter Price
                </FormLabel>
                <Input
                  value={packageDetail.price}
                  onChange={e =>
                    setpackageDetail({
                      ...packageDetail,
                      price: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Enter Name"
                />
              </Box>
              <>
                <Stack>
                  <Stack mb={4} direction={'row'}>
                    <Stack w={'100%'} className="Ifiled icnfiled">
                      <FormLabel
                        fontWeight={400}
                        m={0}
                        color={'#949494'}
                        fontSize={'14px'}
                      >
                        Select Services
                      </FormLabel>
                      <Box>
                        <Select
                          color={'#fff'}
                          onChange={e =>
                            setNewService(JSON.parse(e.target.value))
                          }
                        >
                          <option style={{ display: 'none' }} value="">
                            Select
                          </option>
                          {servicesList?.length > 0 &&
                            servicesList?.map(val => (
                              <option
                                value={JSON.stringify({
                                  id: val?._id,
                                  name: val?.name,
                                  type: 'service',
                                })}
                              >
                                {val?.name ?? ''}
                              </option>
                            ))}
                        </Select>
                      </Box>
                    </Stack>
                  </Stack>
                  <Button
                    padding={'10px 30px'}
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                    alignItems={'center'}
                    onClick={handleAddService}
                  >
                    Add More Services{' '}
                    <Icon
                      color={'#fff'}
                      pl={2}
                      fontSize={'26px'}
                      as={RiAddFill}
                    />
                  </Button>
                </Stack>
                <Stack>
                  <Stack mb={4} direction={'row'}>
                    <Stack w={'100%'} className="Ifiled icnfiled">
                      <FormLabel
                        fontWeight={400}
                        m={0}
                        color={'#949494'}
                        fontSize={'14px'}
                      >
                        Select Parts
                      </FormLabel>
                      <Box>
                        <Select
                          color={'#fff'}
                          onChange={e =>
                            setNewService(JSON.parse(e.target.value))
                          }
                        >
                          <option style={{ display: 'none' }} value="">
                            Select
                          </option>
                          {inventoryList?.length > 0 &&
                            inventoryList?.map(val => (
                              <option
                                value={JSON.stringify({
                                  id: val?.productId,
                                  name: val?.name,
                                  type: 'inventory',
                                })}
                              >
                                {val?.name ?? ''}
                              </option>
                            ))}
                        </Select>
                      </Box>
                    </Stack>
                  </Stack>
                  <Button
                    padding={'10px 30px'}
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                    alignItems={'center'}
                    onClick={handleAddService}
                  >
                    Add More Items{' '}
                    <Icon
                      color={'#fff'}
                      pl={2}
                      fontSize={'26px'}
                      as={RiAddFill}
                    />
                  </Button>
                </Stack>
              </>
            </Stack>
          </Box>
          <Box>
            <Stack className="m4-h adser" my={3}>
              <Heading as={'h4'}>Selected Services</Heading>
              {services?.length > 0 &&
                services
                  ?.filter(val => val.type === 'service')
                  ?.map(val => (
                    <Stack
                      key={val?.id}
                      padding={'8px 50px'}
                      border={'2px solid #fff'}
                      borderRadius={5}
                      borderColor={'#424242'}
                      bgImage={'none'}
                      bgColor={'transparent'}
                      w={'fit-content'}
                      display={'flex'}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Text
                        fontWeight={'500'}
                        color={'#fff'}
                        lineHeight={'inherit'}
                        textTransform={'capitalize'}
                      >
                        {val?.name}
                      </Text>
                      <Link
                        display={'flex'}
                        borderRadius={50}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bg={'#E53E3E'}
                        w={'20px'}
                        h={'20px'}
                        color={'#fff'}
                        onClick={() => handleDeleteService(val.id)}
                      >
                        <FaTimes />
                      </Link>
                    </Stack>
                  ))}
            </Stack>
            <Stack className="m4-h adser" my={3}>
              <Heading as={'h4'}>Selected Items</Heading>
              {services?.length > 0 &&
                services
                  ?.filter(val => val.type === 'inventory')
                  ?.map(val => (
                    <Stack
                      key={val?.id}
                      padding={'8px 50px'}
                      border={'2px solid #fff'}
                      borderRadius={5}
                      borderColor={'#424242'}
                      bgImage={'none'}
                      bgColor={'transparent'}
                      w={'fit-content'}
                      display={'flex'}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Text
                        fontWeight={'500'}
                        color={'#fff'}
                        lineHeight={'inherit'}
                        textTransform={'capitalize'}
                      >
                        {val?.name}
                      </Text>
                      <Link
                        display={'flex'}
                        borderRadius={50}
                        alignItems={'center'}
                        justifyContent={'center'}
                        bg={'#E53E3E'}
                        w={'20px'}
                        h={'20px'}
                        color={'#fff'}
                        onClick={() => handleDeleteService(val.id)}
                      >
                        <FaTimes />
                      </Link>
                    </Stack>
                  ))}
            </Stack>
          </Box>
        </SimpleGrid>
        <Stack alignItems={'end'}>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            onClick={editData ? handleUpdatePackage : handleSavePackage}
            //   isLoading={checkingOutLoader}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddPackage;
