import {
  Avatar,
  Box,
  Button,
  Heading,
  Link,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';
import { ImgUrl } from '../../utilities/config';
import { useSelector } from 'react-redux';
import { GET, POST } from '../../utilities/ApiProvider';
import { formatDate } from '../../utilities/helper';

const ItemDetail = () => {
  const user = useSelector(state => state.value);
  const Params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [itemDetail, setItemDetail] = useState('');

  const getItemFields = async () => {
    setIsLoading(true);
    try {
      let singleItemData = await GET(`/inventory/${Params.id}/sales`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (singleItemData?.status === 'success') {
        setItemDetail(singleItemData?.data);
        console.log(singleItemData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      getItemFields();
    }
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Product Details /{' '}
            <Link to={'/managereports'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
          <Stack
            direction={'row'}
            py={6}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            borderBottom={'1px solid #2e3035'}
          >
            <Box display={'flex'} gap={3} mb={6}>
              <Avatar
                name={itemDetail.name}
                src={`${ImgUrl}${itemDetail?.uploadPicture}`}
                objectFit={'cover'}
              />
              <Box className="venbox p1 m3-h">
                <Text>Item Name</Text>
                <Heading as={'h3'}>{itemDetail?.name}</Heading>
              </Box>
            </Box>
            <Stack className="venbox p1 m3-h">
              <Text>Stocks</Text>
              <Heading as={'h3'}>{itemDetail?.available}</Heading>
            </Stack>
            <Stack className="venbox p1 m3-h">
              <Text>Price</Text>
              <Heading as={'h3'}>${itemDetail?.cost}</Heading>
            </Stack>
            <Stack className="venbox p1 m3-h">
              <Text> Sales </Text>
              <Heading as={'h3'}>{itemDetail?.sales?.length}</Heading>
            </Stack>
            <Stack className="venbox p1 m3-h">
              <Text>Created At</Text>
              <Heading as={'h3'}>
                {' '}
                {String(itemDetail?.createdAt).split('T')[0]}
              </Heading>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              direction={'row'}
              py={3}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
            >
              <Box className="p1">
                <Text>Total Sales: {itemDetail?.sales?.length ?? 0}</Text>
              </Box>
            </Stack>
            {itemDetail && itemDetail?.sales.length > 0 ? (
              itemDetail?.sales
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((val, index) => (
                  <Stack
                    key={val._id}
                    direction={'row'}
                    flexWrap={'wrap'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    py={4}
                    borderRadius={6}
                    bg={'#2e3035'}
                    px={4}
                    className="att-list"
                  >
                    <Box className="m5-h p1" width={'10%'}>
                      <Text>No.</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {index + 1}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1" width={'20%'}>
                      <Text>Vehicle</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.vehicle?.make}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1" width={'10%'}>
                      <Text>Services</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.service.length}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1" width={'15%'}>
                      <Text>Invoice Number</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {val?.invoice[0]?.invoiceNumber}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1" width={'10%'}>
                      <Text>Date</Text>
                      <Heading as={'h5'} textTransform={'capitalize'}>
                        {formatDate(String(val?.createdAt).split('T')[0])}{' '}
                      </Heading>
                    </Box>
                    <Box className="m5-h p1" width={'20%'}>
                      <Link
                        padding={'10px 50px'}
                        bg={
                          'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        }
                        color={'#fff'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        borderRadius={12}
                        _hover={{
                          color: '#000',
                        }}
                        w={'fit-content'}
                        to={`/customerjobdetail/${val?._id}`}
                        as={ReactLink}
                      >
                        View Full Details
                      </Link>
                    </Box>
                  </Stack>
                ))
            ) : (
              <Stack className={'bread m5-h'}>
                <Heading as={'h5'}>No Sales Found</Heading>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default ItemDetail;
