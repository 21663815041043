import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Button,
  Icon,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { FaRegBell } from 'react-icons/fa';
import { FaCircle } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import Cookies from 'js-cookie';
import { GET } from '../../utilities/ApiProvider';
// const socket = io('161.35.226.73:5410'); // Replace with your server URL
const socket = io('161.35.226.73:5410', {
  path: '/socket.io',
  transports: ['websocket'],
  EIO: 4,
});

export default function LoggedInPic() {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const [notification, setNotification] = useState(null);
  const customId = 'notification-toast';
  const user = useSelector(state => state.value);

  useEffect(() => {
    if (user) {
      // Socket.IO connection established
      socket.on('connect', () => {
        console.log('Connected to server', socket);
      });

      // Receiving an event
      socket.on('sendNotification', value => {
        console.log('Notification Value', value);
        console.log('User Check', value?.sender, user?._id);
        if (value?.sender !== user?._id) {
          if (!toast.isActive(customId)) {
            console.log('NotificationTriggered');
            // Update the listData state, avoiding duplicates
            toast({
              id: customId,
              title: value?.type,
              description: value?.content,
              status: 'success',
              position: 'top-right',
              isClosable: true,
              duration: 3000,
            });
          }
        }
      });
    }

    // Clean up on component unmount
    // return () => {
    //   isMounted = false;
    //   console.log('Disconnecting socket');
    //   socket.disconnect();
    // };
  }, [toast, user]);

  const getAllNotifications = async () => {
    try {
      let res = await GET(`/notification`, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res.status === 'success') {
        setNotification(res.data);
      } else {
        console.log('Failed to get notifications');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (user) {
      getAllNotifications();
    }
    console.log(notification);
  }, [user]);

  // useEffect(() => {
  // console.log('User',user);
  //     if (user) {

  //     }

  //   }, [user]);

  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      gap={{ base: 1, lg: 2, xl: 12 }}
      py={4}
    >
      <Menu>
        <MenuButton
          textAlign={'center'}
          as={Button}
          backgroundColor={'transparent'}
          padding={0}
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
        >
          <Icon as={FaRegBell} color={'#fff'} fontSize={'24px'} />
        </MenuButton>
        <MenuList
          fontSize={14}
          overflowY={'auto'}
          maxWidth={'400px'}
          maxHeight={'280px'}
        >
          {notification?.length > 0 ? (
            notification
              ?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              ?.map((item, index) => (
                <MenuItem
                  key={index}
                  borderBottom={'1px solid #7D7D61'}
                  onClick={() => navigate(item?.url)}
                >
                  <FaCircle size={6} color={'#2BAF59'} />
                  <Text ml={2}>{item.content}</Text>
                </MenuItem>
              ))
          ) : (
            <MenuItem
              borderBottom={
                notification?.length > 0 ? '1px solid #7D7D61' : 'none'
              }
            >
              <FaCircle size={6} color={'#f79e22'} />
              <Text ml={2}>No notifications available</Text>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton
          className="loggedBtn"
          as={Button}
          backgroundColor={'transparent'}
          padding={0}
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
        >
          <Avatar
            size={{ base: 'sm', md: 'md' }}
            fontSize={'12px'}
            src=""
            border={'2px solid #3B3D44'}
            bg={'#3B3D44;'}
            color={'#fff'}
            name={user?.fullName ?? 'John Doe'}
          />
          <Text
            color={'#fff'}
            textTransform={'capitalize'}
            fontWeight={500}
            fontSize={{ base: 13, md: 14 }}
          >
            {user?.fullName ?? 'John Doe'}
          </Text>
          <Icon color={'#fff'} as={FiChevronDown} />
        </MenuButton>
        <MenuList border={'1px solid #3B3D44'} color={'#3B3D44'} fontSize={14}>
          <MenuItem
            onClick={() => {
              localStorage.setItem('LiqteqUser', null);
              Cookies.set('LiqteqUser', null);
              localStorage.clear();

              navigate('/');
            }}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
      <Menu></Menu>
    </Stack>
  );
}
