import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  Checkbox,
  UnorderedList,
  ListItem,
  useToast,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormLabel,
  Input,
  Spinner,
  Toast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { servicesdetails } from '../../assets/data/Data.js';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { DELETE, GET, POST, PUT } from '../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';

export default function ServicesList() {
  const toast = useToast();
  const user = useSelector(state => state.value);
  const [isLoading, setIsLoading] = useState(false);
  const [filterButtons, setFilterButtons] = useState([]);
  const [services, setServices] = useState(null);
  const [selectedCat, setSelectedCat] = useState('maintenance');
  const [createCat, setCreateCat] = useState({
    name: '',
    uploadPicture: null,
  });
  const [updateTab, setupdateTab] = useState();
  const [updatedData, setupdateData] = useState({
    name: '',
    price: '',
    id: '',
  });
  const [showWarning, setShowWarning] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isCatOpen,
    onOpen: onCatOpen,
    onClose: onCatClose,
  } = useDisclosure();

  const {
    isOpen: isEditTabOpen,
    onOpen: onTabOpen,
    onClose: onEditTabClose,
  } = useDisclosure();

  const getUserFields = async () => {
    setIsLoading(true);
    let userFieldsData = await POST(
      `/service/getAllService`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log(userFieldsData);
    setServices(userFieldsData?.data);
    setSelectedData(userFieldsData?.data[0]);
    console.log('Filter', userFieldsData?.data[0]);
    setFilterButtons(userFieldsData?.data);
    setSelectedCat(userFieldsData?.data[0]?.name);
    setIsLoading(false);
  };

  const handleDelete = async id => {
    console.log(id);
    try {
      let res = await DELETE(`/service/deleteService/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log(res);
      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
      if (res.status === 'success') {
        getUserFields();
      }
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  const onUpdateService = async () => {
    setIsLoading(true);
    let data = {
      name: updatedData.name,
      price: updatedData.price.toString(),
    };

    try {
      let upres = await PUT(`/service/updateService/${updatedData.id}`, data, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (upres.status === 'success') {
        getUserFields();
        onEditClose();
        setIsLoading(false);
      }
      toast({
        description: upres.message,
        status: upres.status,
        position: 'top-right',
        duration: 3000,
      });
    } catch (error) {
      console.error('Error deleting service:', error);
    }
  };

  const createCategory = async () => {
    setIsLoading(true);
    if (createCat.name === '') {
      toast({
        title: 'Please Enter Category Name',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('name', createCat.name);
    formData.append('uploadPicture', createCat.uploadPicture);
    try {
      let response = await POST(`/service/createServiceType`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        setIsLoading(false);
        onCatClose();
        setCreateCat({
          name: '',
          uploadPicture: null,
        });
        toast({
          description: 'Category Created Successfully',
          status: 'success',
          position: 'top-right',
          duration: 3000,
        });
        getUserFields();
      } else {
        onCatClose();
        setIsLoading(false);
        setCreateCat({
          name: '',
          uploadPicture: null,
        });
        toast({
          description: 'Something went wrong',
          status: 'error',
          position: 'top-right',
          duration: 3000,
        });
      }
    } catch (error) {
      onCatClose();
      console.log(error);
      setIsLoading(false);
      toast({
        description: 'Something went wrong',
        status: 'error',
        position: 'top-right',
        duration: 3000,
      });
    }
  };

  const handleEditCategory = async id => {
    try {
      if (updateTab.name === 'Uncategorized') {
        setShowWarning(false);
        toast({
          title: 'Cannot Update Category Name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      let response = await PUT(
        `/service/updateCategory/${id}`,
        {
          name: updateTab.name,
        },
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response.status === 'success') {
        toast({
          description: 'Category Updated Successfully',
          status: 'success',
          position: 'top-right',
          duration: 3000,
        });
        getUserFields();
        onEditTabClose();
        setShowWarning(false);
      }
    } catch (error) {
      console.log(error.message);
      toast({
        title: 'Something Went Wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleCategoryDelete = async id => {
    if (updateTab.name === 'Uncategorized' && selectedData.length > 0) {
      setShowWarning(false);
      toast({
        title: 'Cannot Delete Category',
        description: 'Please remove the services from the category first',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      let response = await DELETE(`/service/deleteCategory/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        toast({
          description: 'Category Deleted Successfully',
          status: 'success',
          position: 'top-right',
          duration: 3000,
        });
        getUserFields();
        onEditTabClose();
        setShowWarning(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Something Went Wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleEditService = item => {
    console.log(item, 'ITEM FOR UPDATEW service');
    setupdateTab(item);
    onTabOpen();
  };

  function modalClose() {
    onEditTabClose();
    setShowWarning(false);
  }

  useEffect(() => {
    if (user) {
      getUserFields();
    }
  }, [user]);

  useEffect(() => {
    if (services) {
      setSelectedData(
        services?.find(service => service.name === selectedCat)?.services
      );
      console.log('Selected Data', selectedData);
    }
  }, [selectedCat, selectedData]);

  return (
    <>
      <>
        <Modal isOpen={isEditOpen} onClose={onEditClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'100%'}>
            <ModalHeader color={'#fff'}>Edit Details</ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={6}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Service Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.name}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Service Name"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Price
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.price}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          price: e.target.value,
                        })
                      }
                      placeholder="Enter price"
                    />
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                isLoading={isLoading}
                onClick={() => {
                  onUpdateService();
                }}
              >
                Update
              </Button>
              <Button colorScheme="red" value={'Cancel'} onClick={onEditClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        <Modal isOpen={isCatOpen} onClose={onCatClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'100%'}>
            <ModalHeader color={'#fff'}>Add Service Category</ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={6}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Category Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={createCat.name}
                      onChange={e =>
                        setCreateCat({
                          ...createCat,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Category Name"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Upload Picture
                  </FormLabel>
                  <Box className="ufiled">
                    <Input
                      pt={0}
                      type="file"
                      accept="image/*"
                      onChange={e =>
                        setCreateCat({
                          ...createCat,
                          uploadPicture: e.target.files[0],
                        })
                      }
                    />
                  </Box>
                </Stack>
                {/* <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Price
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.price}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          price: e.target.value,
                        })
                      }
                      placeholder="Enter price"
                    />
                  </Box>
                </Stack> */}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                isLoading={isLoading}
                onClick={() => {
                  createCategory();
                }}
              >
                Create
              </Button>
              <Button colorScheme="red" value={'Cancel'} onClick={onCatClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <>
        <Modal isOpen={isEditTabOpen} onClose={modalClose}>
          <ModalOverlay />
          <ModalContent
            alignContent={'center'}
            alignItems={'center'}
            bg={'#202125'}
            border={'1px solid #4D4D4D'}
            borderRadius={14}
          >
            <ModalHeader color={'#ffff'}>Update Category</ModalHeader>

            <ModalCloseButton color={'#FFFF'} />
            <ModalBody style={{ color: '#ffff' }}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Category Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updateTab?.name}
                      disabled={updateTab?.name === 'Uncategorized'}
                      onChange={e =>
                        setupdateTab({
                          ...updateTab,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Here"
                    />
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter display={'flex'} flexWrap={'wrap'}>
              {showWarning === false ? (
                <Box>
                  <Button
                    mr={3}
                    onClick={() => handleEditCategory(updateTab?._id)}
                    padding={'15px 25px'}
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    alignItems={'center'}
                    justifyContent={'center'}
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    textAlign={'center'}
                    size={'sm'}
                  >
                    Update
                  </Button>
                  <Button
                    mr={3}
                    onClick={() => setShowWarning(true)}
                    // onClick={() => handleCategoryDelete(updateTab?._id)}
                    padding={'15px 25px'}
                    bg={'#E53E3E'}
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    textAlign={'center'}
                    size={'sm'}
                  >
                    Delete Category
                  </Button>
                </Box>
              ) : (
                <Box display={'flex'} flexDirection={'column'} gap={3}>
                  <Text color="white">
                    Are you sure you want to delete this category?
                  </Text>
                  <Button
                    mr={3}
                    onClick={() => handleCategoryDelete(updateTab?._id)}
                    padding={'10px 30px'}
                    bg={'#E53E3E'}
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    textAlign={'center'}
                    size={'sm'}
                  >
                    Delete Category
                  </Button>
                </Box>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>

      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>List of Services</Heading>
        </Stack>
        {user?.privileges
          ?.find(privilege => privilege.name === 'Services')
          ?.action.includes('create') && (
          <Stack
            alignItems={{ base: 'start', xl: 'end' }}
            display={'flex'}
            direction={'row'}
            justifyContent={'flex-end'}
          >
            <Link
              padding={'8px 40px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              to={'/addservices'}
              as={ReactLink}
            >
              Add New Service
            </Link>
            <Button
              padding={'0px 40px'}
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              textAlign={'center'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              onClick={onCatOpen}
            >
              Add New Category
            </Button>
          </Stack>
        )}
      </SimpleGrid>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack>
          <UnorderedList
            gap={6}
            display={'flex'}
            overflowX="auto"
            sx={{
              '&::-webkit-scrollbar': {
                display: 'block', // Show scrollbar
                height: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}
            maxWidth="100%"
            py={5}
            listStyleType={'none'}
          >
            {filterButtons?.length > 0 &&
              filterButtons.map((val, ind) => (
                <ListItem key={ind}>
                  <Button
                    onClick={() => setSelectedCat(val.name)}
                    padding={'10px 50px'}
                    bg={
                      val.name === selectedCat
                        ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        : 'transparent'
                    }
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    color={val.name === selectedCat ? '#fff' : '#7D7D7D'}
                    border={'1px solid #7D7D7D'}
                    borderRadius={12}
                    _hover={{
                      color: '#fff',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                      border: '1px solid #2BAF59',
                    }}
                    textTransform={'capitalize'}
                    w={'fit-content'}
                  >
                    {val?.name}
                    <Box
                      boxSize="1.5em"
                      bg="yellow.500"
                      borderRadius={50}
                      position={'absolute'}
                      boxShadow={'0 0 10px #2BAF59'}
                      top={-2}
                      right={-1}
                      justifyContent={'center'}
                      alignItems={'center'}
                      display={'flex'}
                      onClick={() => handleEditService(val)}
                    >
                      <BiEditAlt />
                    </Box>
                  </Button>
                </ListItem>
              ))}
          </UnorderedList>
          <Stack className="list-ser">
            <Stack
              py={6}
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
            >
              <Stack className="p1">
                <Text>
                  Total Services:{' '}
                  <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                    {selectedData?.length ?? 0}
                  </Text>
                </Text>
              </Stack>
              {/* <Stack m={'0 !important'} className="p1">
               <Checkbox
                 color={'#7D7D7D'}
                 size="md"
                 colorScheme="green"
                 defaultChecked
               >
                 Select All
               </Checkbox>
             </Stack> */}
            </Stack>
            <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
              {selectedData?.length > 0 ? (
                selectedData?.map(val => {
                  return (
                    <Stack
                      direction={'row'}
                      flexWrap={'wrap'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      py={5}
                      borderBottom={'1px solid #595C64'}
                      key={val?._id}
                    >
                      <Box className="m5-h p1" width={{ md: '20%', xl: '30%' }}>
                        <Text>Service Name</Text>
                        <Heading as={'h5'}>{val?.name}</Heading>
                      </Box>
                      <Box className="m5-h p1" width={{ md: '20%', xl: '30%' }}>
                        <Text>Price</Text>
                        <Heading as={'h5'}>${val?.price}</Heading>
                      </Box>
                      <Box className="m5-h p1" width={{ md: '20%', xl: '30%' }}>
                        <Text>Created Date</Text>
                        <Heading as={'h5'}>
                          {new Date(val?.createdAt).toLocaleDateString()}
                        </Heading>
                      </Box>
                      <Box
                        display={'flex'}
                        gap={4}
                      >
                        {user?.privileges
                          ?.find(privilege => privilege.name === 'Services')
                          ?.action.includes('edit') && (
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                            onClick={() => {
                              setupdateData({
                                name: val.name,
                                price: val.price,
                                id: val._id,
                              });

                              onEditOpen();
                            }}
                          >
                            <BiEditAlt />
                          </Link>
                        )}
                        {user?.privileges
                          ?.find(privilege => privilege.name === 'Services')
                          ?.action.includes('edit') && (
                          <Link
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#E53E3E'}
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                            onClick={() => handleDelete(val._id)}
                          >
                            <AiOutlineDelete />
                          </Link>
                        )}
                      </Box>
                    </Stack>
                  );
                })
              ) : (
                <Stack className={'bread m5-h'}>
                  <Heading as={'h5'}>No Services Found </Heading>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}
