import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  FormLabel,
  Input,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiUsers } from 'react-icons/fi';
import { BsHouseDoor } from 'react-icons/bs';
import { RiUserSettingsLine } from 'react-icons/ri';
import { BsBag } from 'react-icons/bs';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

export default function DashboardCsr() {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.value);
  const [home, sethome] = useState('');

  useEffect(() => {
    if (user) {
      getvenderlist();
    }
  }, [user]);

  const getvenderlist = async () => {
    setIsLoading(true);
    console.log('vender Hit');
    let HomeData = await POST(
      `/users/dashboard`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    sethome(HomeData);

    setIsLoading(false);
  };

  useEffect(() => {
    console.log('Technician dashboard data', home);
  }, [home]);

  return (
    <>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack>
          <Stack direction={'row'} gap={6} mb={6}>
            <Stack
              w={'100%'}
              bg={'#202125'}
              p={6}
              borderRadius={8}
              className="p1"
            >
              <Box
                alignItems={'center'}
                gap={2}
                className="dashd m3-h"
                display={'flex'}
              >
                <Icon fontSize={'25px'} color={'#2BAF59'} as={BsHouseDoor} />
                <Heading as={'h3'}>{home?.inventoryCount}</Heading>
              </Box>
              <Text fontSize={{ md: '13px', '2xl': '16px' }}>
                Total Parts
              </Text>
            </Stack>
            <Stack
              w={'100%'}
              bg={'#202125'}
              p={6}
              borderRadius={8}
              className="p1"
            >
              <Box
                alignItems={'center'}
                gap={2}
                className="dashd m3-h"
                display={'flex'}
              >
                <Icon
                  fontSize={'25px'}
                  color={'#2BAF59'}
                  as={RiUserSettingsLine}
                />
                <Heading as={'h3'}>{home?.customerCount}</Heading>
              </Box>
              <Text fontSize={{ md: '13px', '2xl': '16px' }}>
                Existing Customers
              </Text>
            </Stack>
            <Stack
              w={'100%'}
              bg={'#202125'}
              p={6}
              borderRadius={8}
              className="p1"
            >
              <Box
                alignItems={'center'}
                gap={2}
                className="dashd m3-h"
                display={'flex'}
              >
                <Icon fontSize={'25px'} color={'#2BAF59'} as={BsBag} />
                <Heading as={'h3'}>{home?.jobCount}</Heading>
              </Box>
              <Text fontSize={{ md: '13px', '2xl': '16px' }}>
                Total List of jobs
              </Text>
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={8}>
            <Stack w={'100%'}>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
              >
                <Stack className="m4-h">
                  <Heading as={'h4'}>List of Jobs</Heading>
                </Stack>
                <Stack>
                  <Link color={'#7D7D7D'} as={ReactLink} to={'/listofjob'}>
                    View All
                  </Link>
                </Stack>
              </Box>
              <Stack
                borderRadius={6}
                bg={'#202125'}
                gap={2}
                p={6}
                maxHeight={'600px'}
                overflowX={'hidden !important'}
                overflow={'scroll'}
              >
                {home?.jobs?.length > 0 ? (
                  home?.jobs?.slice(0, 10)?.map((e, v) => {
                    return (
                      <Stack
                        key={v}
                        py={4}
                        px={4}
                        borderRadius={6}
                        bg={'#2E3035'}
                      >
                        <Stack
                          direction={'row'}
                          flexWrap={'wrap'}
                          justifyContent={'space-between'}
                          borderBottom={'1px solid #484545'}
                          paddingBottom={3}
                          marginBottom={2}
                        >
                          <Box display={'flex'} gap={3}>
                            <Avatar name="Kent Dodds" src={''} />
                            <Box className="venbox p1 m3-h">
                              <Text>{e?.jobNumber}</Text>
                              <Heading as={'h3'}>{e?.vehicle?.make}</Heading>
                            </Box>
                          </Box>
                          <Stack className="venbox p1 m3-h">
                            <Link
                              padding={{ md: '10px 15px', '2xl': '10px 50px' }}
                              fontSize={{ md: '13px', '2xl': '16px' }}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              color={'#fff'}
                              fontWeight={'500'}
                              lineHeight={'inherit'}
                              borderRadius={12}
                              _hover={{
                                color: '#000',
                              }}
                              w={'100%'}
                              textAlign={'center'}
                              to={`/customerjobdetail/${e?._id}`}
                              as={ReactLink}
                            >
                              View Full Details
                            </Link>
                          </Stack>
                        </Stack>
                        <Stack className="m5-h">
                          <Heading as={'h5'}>Service Details:</Heading>
                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            gap={5}
                          >
                            <Box className="venbox p1 m3-h">
                              <Text>Status</Text>
                              <Heading as={'h3'}>{e?.status}</Heading>
                            </Box>
                            <Box className="venbox p1 m3-h">
                              <Text>Name</Text>
                              <Heading as={'h3'}>
                                {e?.customer?.name ?? '-'}
                              </Heading>
                            </Box>
                            <Box className="venbox p1 m3-h">
                              <Text>Customer Number</Text>
                              <Heading as={'h3'}>
                                {e?.customer?.number ?? '-'}
                              </Heading>
                            </Box>
                            <Box className="venbox p1 m3-h">
                              <Text>Services</Text>
                              <Heading as={'h3'}>{e?.service?.length}</Heading>
                            </Box>
                          </Box>
                        </Stack>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Jobs Found </Heading>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack w={'100%'}>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
              >
                <Stack className="m4-h">
                  <Heading as={'h4'}>Sales Invoices</Heading>
                </Stack>
                {/* <Stack>
              <Link color={'#7D7D7D'} as={ReactLink} to={'/saleinvoice'}>
                View All
              </Link>
            </Stack> */}
              </Box>
              <Stack
                borderRadius={6}
                bg={'#202125'}
                gap={2}
                p={6}
                maxHeight={'600px'}
                overflowX={'hidden !important'}
                overflow={'scroll'}
              >
                {home?.invoices?.length > 0 ? (
                  home?.invoices?.map((v, k) => {
                    return (
                      <Stack
                        key={k}
                        py={6}
                        px={4}
                        borderRadius={6}
                        bg={'#2E3035'}
                      >
                        <Stack
                          direction={'row'}
                          flexWrap={'wrap'}
                          justifyContent={'space-between'}
                        >
                          <Box display={'flex'} gap={3}>
                            <Avatar name={v?.userId?.fullName} />
                            <Box className="venbox p1 m3-h">
                              <Text>Job ID : {v?.jobId?.jobNumber}</Text>
                              <Heading as={'h3'}>{v?.customerId?.name}</Heading>
                            </Box>
                          </Box>
                          <Stack className="venbox p1 m3-h">
                            <Button
                              padding={{ md: '10px 15px', '2xl': '10px 50px' }}
                              fontSize={{ md: '13px', '2xl': '16px' }}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              color={'#fff'}
                              fontWeight={'500'}
                              lineHeight={'inherit'}
                              borderRadius={12}
                              _hover={{
                                color: '#000',
                              }}
                              w={'100%'}
                              textAlign={'center'}
                              onClick={() => navigate(`/jobinvoice/${v?._id}`)}
                            >
                              View
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Invoices Found </Heading>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}
