import {
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Checkbox,
  Box,
  Button,
  Avatar,
  SimpleGrid,
  Icon,
  Image,
  Card,
  CardHeader,
  CardBody,
  List,
  ListItem,
  ListIcon,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormLabel,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { RiCoupon2Line } from 'react-icons/ri';
import Discount from '../../../assets/images/discount.svg';
import { DELETE, POST, PUT } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../../../utilities/helper';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

export default function Offers() {
  const navigate = useNavigate();
  const user = useSelector(state => state?.value);
  const toast = useToast();
  const [activeTab, setactiveTab] = useState('Packages');
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const [packageData, setPackageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedData, setupdateData] = useState({
    name: '',
    percentage: '',
    active: '',
  });

  const getAllServices = async () => {
    try {
      const response = await POST(
        `/job/getAllServices`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      console.log('Package Response', response);
      if (response.status !== 'success') throw new Error(response.message);
      else {
        console.log('Services', response.data);
        setPackageData(response.data[0]);
        return response.data;
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onUpdateService = async () => {
    setIsLoading(true);
    let data = {
      name: updatedData.name,
      percentage: updatedData.percentage.toString(),
      active: updatedData.active.toString(),
    };

    try {
      let upres = await PUT(
        `/discount/updateDiscount/${updatedData.id}`,
        data,
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      console.log('coupon update', upres);
      if (upres.status === 'success') {
        onEditClose();
        getAllServices();
        setIsLoading(false);
      }
      toast({
        description: upres.message,
        status: upres.status,
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    } catch (error) {
      setIsLoading(false);
      console.error('Error editing coupon:', error);
    }
  };

  const handleDeleteDiscount = async id => {
    try {
      setIsLoading(true);
      let response = await DELETE(`/discount/deleteDiscount/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('Delete Response', response);
      if (response.status === 'success') {
        toast({
          description: response.message,
          status: response.status,
          position: 'top-right',
          isClosable: true,
          duration: 3000,
        });
        getAllServices();
      } else {
        toast({
          description: response.message,
          status: response.status,
          position: 'top-right',
          isClosable: true,
          duration: 3000,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDeletePackage = async id => {
    try {
      setIsLoading(true);
      let response = await DELETE(`/job/deleteJobPackage/${id}`, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('Delete Response', response);
      if (response.status === 'success') {
        toast({
          description: response.message,
          status: response.status,
          position: 'top-right',
          isClosable: true,
          duration: 3000,
        });
        getAllServices();
      } else {
        toast({
          description: response.message,
          status: response.status,
          position: 'top-right',
          isClosable: true,
          duration: 3000,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (user) getAllServices();
    // console.log('COUPON DATA', packageData?.Package.Basic[0]);
  }, [user]);

  return (
    <>
      <>
        <Modal isOpen={isEditOpen} onClose={onEditClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'100%'}>
            <ModalHeader color={'#fff'}>Edit Details</ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={6}>
              <Stack className="adser" gap={6}>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Enter Service Name
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.name}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          name: e.target.value,
                        })
                      }
                      placeholder="Enter Service Name"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Percentage
                  </FormLabel>
                  <Box className="Ifiled">
                    <Input
                      type="text"
                      value={updatedData.percentage}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          percentage: e.target.value,
                        })
                      }
                      placeholder="Enter price"
                    />
                  </Box>
                </Stack>
                <Stack>
                  <FormLabel
                    fontWeight={400}
                    m={0}
                    color={'#949494'}
                    fontSize={'14px'}
                  >
                    Status
                  </FormLabel>
                  <Box className="Ifiled icnfiled">
                    <Select
                      color={'#fff'}
                      value={updatedData.active}
                      onChange={e =>
                        setupdateData({
                          ...updatedData,
                          active: e.target.value,
                        })
                      }
                    >
                      <option style={{ display: 'none' }} value="">
                        Select Option
                      </option>
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </Select>
                  </Box>
                </Stack>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                isLoading={isLoading}
                onClick={() => {
                  onUpdateService();
                }}
              >
                Update
              </Button>
              <Button colorScheme="red" value={'Cancel'} onClick={onEditClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'} mb={8}>
          <Heading as={'h3'}>Our Offers </Heading>
        </Stack>

        {user?.privileges
          ?.find(privilege => privilege.name === 'Offer & Discount')
          ?.action.includes('create') && (
          <Stack alignItems={{ base: 'start', xl: 'end' }}>
            <Link
              padding={'10px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              to={
                activeTab === 'Packages'
                  ? '/createpackage'
                  : activeTab === 'Coupons'
                  ? '/addcoupons'
                  : '/creatediscount'
              }
              as={ReactLink}
            >
              {activeTab === 'Packages'
                ? 'Add Package'
                : activeTab === 'Coupons'
                ? 'Add Coupons'
                : 'Add Discount'}
            </Link>
          </Stack>
        )}
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
        <Tabs variant="unstyled">
          <TabList mb={12} gap={6}>
            <Tab
              color={'#fff'}
              px={8}
              bg={'#4D4D4D'}
              borderRadius={6}
              onClick={() => setactiveTab('Packages')}
              _selected={{
                color: 'white',
                bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
              }}
            >
              Packages
            </Tab>
            {/* <Tab
              color={'#fff'}
              px={8}
              bg={'#4D4D4D'}
              borderRadius={6}
              onClick={() => setactiveTab('Coupons')}
              _selected={{
                color: 'white',
                bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
              }}
            >
              Coupons
            </Tab> */}
            <Tab
              color={'#fff'}
              px={8}
              bg={'#4D4D4D'}
              borderRadius={6}
              onClick={() => setactiveTab('Discounts')}
              _selected={{
                color: 'white',
                bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
              }}
            >
              Discounts
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <Stack className="m4-h">
                <Heading as={'h4'} pb={4}>
                  List of Package
                </Heading>
                <SimpleGrid columns={3} spacing={4}>
                  {packageData && packageData?.Package?.length > 0 ? (
                    packageData?.Package?.map((v, ind) => (
                      <Box
                        position={'relative'}
                        key={ind}
                        borderWidth="1px"
                        borderRadius="lg"
                        overflow="hidden"
                        padding={0}
                      >
                        <Box
                          display={'flex'}
                          justifyItems={'center'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                          padding={2}
                          height={12}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          marginBottom={3}
                        >
                          <Heading
                            as="h4"
                            size="md"
                            textAlign={'center'}
                            justifyItems={'center'}
                          >
                            {v.name} ${v.cost}
                          </Heading>
                          {user?.privileges
                            ?.find(
                              privilege => privilege.name === 'Offer & Discount'
                            )
                            ?.action.includes('edit') && (
                            <Link
                              position={'absolute'}
                              right={'40px'}
                              top={'10px'}
                              display={'flex'}
                              borderRadius={50}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              w={'30px'}
                              h={'30px'}
                              color={'#fff'}
                              onClick={() =>
                                navigate('/createpackage', { state: v })
                              }
                            >
                              <BiEditAlt />
                            </Link>
                          )}
                          {user?.privileges
                            ?.find(privilege => privilege.name === 'Coupons')
                            ?.action.includes('delete') && (
                            <Link
                              position={'absolute'}
                              right={'3px'}
                              top={'10px'}
                              display={'flex'}
                              borderRadius={50}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={'#E53E3E'}
                              w={'30px'}
                              h={'30px'}
                              color={'#fff'}
                              onClick={() => handleDeletePackage(v._id)}
                            >
                              <AiOutlineDelete />
                            </Link>
                          )}
                        </Box>
                        <Box padding={4}>
                          <List spacing={3}>
                            {v.services.map((val, ind) => (
                              <ListItem key={ind} color={'#fff'}>
                                {val?.name} - ${val?.price}
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Stack className={'bread m5-h'}>
                      <Heading as={'h5'}>No Package Available </Heading>
                    </Stack>
                  )}
                </SimpleGrid>
              </Stack>
            </TabPanel>
            {/* <TabPanel p={0}>
              <Stack className="m4-h">
                <Heading as={'h4'} pb={4}>
                  List of Coupons
                </Heading>
                <SimpleGrid columns={3} spacing={10}>
                  {packageData && packageData.Coupons.length > 0 ? (
                    packageData.Coupons.map((key, index) => (
                      <Stack
                        key={key?._id}
                        py={6}
                        position={'relative'}
                        px={4}
                        borderRadius={6}
                        bg={'#2E3035'}
                        className="p1"
                      >
                        <Box
                          top={'14px'}
                          right={'14px'}
                          px={2}
                          py={1}
                          borderRadius={8}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          position={'absolute'}
                        >
                          Status: {key?.active ? 'Active' : 'In-Active'}
                        </Box>
                        <Box display={'flex'} alignItems={'center'} gap={3} my={5}>
                          <Image src={Discount} />
                          <Box className="venbox p1 m3-h">
                            <Heading as={'h3'}>{key?.name}</Heading>
                            <Text>{key?.couponCode}</Text>
                          </Box>
                        </Box>
                        <Text pb={2}>
                          Use this Coupon to recive {key?.percentage}% for all
                          services Availability:{' '}
                          {formatDate(String(key?.startTime).split('T')[0])},{' '}
                          {formatTime(key?.startTime)} -{' '}
                          {formatDate(String(key?.endTime).split('T')[0])},
                          {formatTime(key?.endTime)}
                        </Text>
                        <Button
                          padding={{ md: '10px 15px', '2xl': '10px 50px' }}
                          fontSize={{ md: '13px', '2xl': '16px' }}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          fontWeight={'500'}
                          lineHeight={'inherit'}
                          borderRadius={12}
                          _hover={{
                            color: '#000',
                          }}
                          w={'100%'}
                          textAlign={'center'}
                        >
                          Add Coupon
                        </Button>
                      </Stack>
                    ))
                  ) : (
                    <Stack className={'bread m5-h'}>
                      <Heading as={'h5'}>No Coupons Available </Heading>
                    </Stack>
                  )}
                </SimpleGrid>
              </Stack>
            </TabPanel> */}
            <TabPanel p={0}>
              <Stack className="m4-h">
                <Heading as={'h4'} pb={4}>
                  List of Disocunt
                </Heading>
                <SimpleGrid columns={3} spacing={10}>
                  {packageData && packageData.Discount.length > 0 ? (
                    packageData.Discount.map((value, index) => (
                      <Stack
                        key={value._id}
                        py={6}
                        position={'relative'}
                        px={4}
                        borderRadius={6}
                        bg={'#2E3035'}
                        className="p1"
                      >
                        <Box
                          top={'14px'}
                          right={'85px'}
                          px={2}
                          py={1}
                          borderRadius={8}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          position={'absolute'}
                        >
                          Status: {value?.active ? 'Active' : 'In-Active'}
                        </Box>
                        {user?.privileges
                          ?.find(
                            privilege => privilege.name === 'Offer & Discount'
                          )
                          ?.action.includes('edit') && (
                          <Link
                            top={'14px'}
                            right={'45px'}
                            position={'absolute'}
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={
                              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                            }
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                            onClick={() => {
                              setupdateData({
                                name: value.name,
                                percentage: value.percentage,
                                active: value.active,
                                id: value._id,
                              });

                              onEditOpen();
                            }}
                          >
                            <BiEditAlt />
                          </Link>
                        )}
                        {user?.privileges
                          ?.find(privilege => privilege.name === 'Coupons')
                          ?.action.includes('delete') && (
                          <Link
                            position={'absolute'}
                            right={'10px'}
                            top={'14px'}
                            display={'flex'}
                            borderRadius={50}
                            alignItems={'center'}
                            justifyContent={'center'}
                            bg={'#E53E3E'}
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                            onClick={() => handleDeleteDiscount(value._id)}
                          >
                            <AiOutlineDelete />
                          </Link>
                        )}
                        <Box display={'flex'} alignItems={'center'} gap={3}>
                          <Image src={Discount} />
                          <Box className="venbox p1 m3-h">
                            <Heading as={'h3'}>{value?.name}</Heading>
                          </Box>
                        </Box>
                        <Text pb={2}>
                          Use this Coupon to recive {value?.percentage}% for all
                          services Availability:{' '}
                          {formatDate(String(value?.startTime).split('T')[0])},{' '}
                          {formatTime(value?.startTime)} -{' '}
                          {formatDate(String(value?.endTime).split('T')[0])},
                          {formatTime(value?.endTime)}
                        </Text>
                        {/* <Button
                          padding={{ md: '10px 15px', '2xl': '10px 50px' }}
                          fontSize={{ md: '13px', '2xl': '16px' }}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          fontWeight={'500'}
                          lineHeight={'inherit'}
                          borderRadius={12}
                          _hover={{
                            color: '#000',
                          }}
                          w={'100%'}
                          textAlign={'center'}
                        >
                          Add Disocunt
                        </Button> */}
                      </Stack>
                    ))
                  ) : (
                    <Stack className={'bread m5-h'}>
                      <Heading as={'h5'}>No Discounts Available </Heading>
                    </Stack>
                  )}
                </SimpleGrid>
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </>
  );
}
