import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  FormLabel,
  Input,
  Icon,
  Select,
  useToast,
  Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../utilities/ApiProvider';

export default function ApplyAttRequest() {
  const user = useSelector(state => state.value);
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [reqData, setReqData] = useState({
    clockDate: '',
    clockTime: '',
    description: '',
    issue: '',
  });

  const submitReq = async () => {
    setIsLoading(true);
    try {
      let response = await POST(`/attendance/attendanceRequest`, reqData, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response?.status === 'success') {
        setReqData({
          clockDate: '',
          clockTime: '',
          description: '',
          issue: '',
        });
      }
      toast({
        description: response?.message ?? 'Success',
        position: 'top-right',
        isClosable: true,
        status: response?.status,
        duration: 3000,
      });
      setIsLoading(false);
      navigate('/myrequest');
    } catch (err) {
      toast({
        description: err.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Apply Request /{' '}
            <Link to={'/myrequest'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} gap={8} py={12} borderRadius={8}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          className="adser"
          gap={6}
        >
          <Stack w={'100%'}>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Select Date
            </FormLabel>
            <Box className="Ifiled">
              <Input
                type="date"
                value={reqData?.clockDate}
                onChange={e =>
                  setReqData({ ...reqData, clockDate: e.target.value })
                }
                placeholder="Enter Coupon Name"
              />
            </Box>
          </Stack>

          <Stack w={'100%'}>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Select Time
            </FormLabel>
            <Box className="Ifiled">
              <Input
                type="time"
                value={reqData.clockTime}
                onChange={e =>
                  setReqData({ ...reqData, clockTime: e.target.value })
                }
                placeholder="Enter Coupon Name"
              />
            </Box>
          </Stack>
          <Stack w={'100%'} className="Ifiled icnfiled">
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Select Time
            </FormLabel>
            <Box className="Ifiled">
              <Select
                color={'#fff'}
                onChange={e =>
                  setReqData({
                    ...reqData,
                    issue: e.target.value,
                  })
                }
              >
                <option style={{ display: 'none' }} value="">
                  Select Option
                </option>
                <option value="clockIn">clock In</option>
                <option value="clockOut">clock Out</option>
              </Select>
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Stack className="areareq" w={'100%'}>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Issue Description
            </FormLabel>
            <Box className="Ifiled">
              <Textarea
                value={reqData.description}
                onChange={e =>
                  setReqData({ ...reqData, description: e.target.value })
                }
                placeholder="Write Your Issue Description"
              />
            </Box>
          </Stack>
        </Stack>
        <Stack>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={submitReq}
          >
            Submit Request
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
