import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  FormLabel,
  Input,
  Icon,
  Select,
  useToast,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../utilities/ApiProvider';

export default function NewCoupon() {
  const user = useSelector(state => state.value);
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [addcoupon, setaddcoupon] = useState({
    name: '',
    startTime: '',
    endTime: '',
    uploadPicture: '',
    percentage: '',
  });

  const copSubmit = async () => {
    setIsLoading(true);
    // const payload = {
    //   name: addcoupon.name,
    //   startTime: addcoupon.startTime,
    //   endTime: addcoupon.endTime,
    //   uploadPicture: addcoupon.uploadPicture,
    //   percentage: Number(addcoupon.percentage),
    // }
    const formData = new FormData();

    Object.keys(addcoupon).forEach(key => {
      formData.append(key, addcoupon[key]);
    });

    try {
      let res = await POST(`/coupon/createCoupon`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log('add Coupon responce ', res);

      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
      if (res.status === 'success') {
        navigate('/coupons ');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Add New Coupon /{' '}
            <Link to={'/coupons'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} gap={8} py={12} borderRadius={8}>
        <SimpleGrid
          columns={{
            base: '1',
            sm: '1',
            md: '1',
            lg: '1',
            xl: '2',
            '2xl': '2',
          }}
          spacingX="40px"
          spacingY="20px"
        >
          <Stack className="adser" gap={6}>
            <Stack>
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Enter Coupon Name
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="text"
                  value={addcoupon.name}
                  onChange={e =>
                    setaddcoupon({ ...addcoupon, name: e.target.value })
                  }
                  placeholder="Enter Coupon Name"
                />
              </Box>
            </Stack>

            <Stack>
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Enter Coupon percentage
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  value={addcoupon.percentage}
                  onChange={e =>
                    setaddcoupon({ ...addcoupon, percentage: e.target.value })
                  }
                  placeholder="Enter percentage"
                />
              </Box>
            </Stack>
            <Stack direction={'row'} gap={6} justifyContent={'space-between'}>
              <Stack w={'100%'}>
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Start Time
                </FormLabel>
                <Box className="Ifiled">
                  <Input
                    type="date"
                    value={addcoupon.startTime}
                    onChange={e =>
                      setaddcoupon({ ...addcoupon, startTime: e.target.value })
                    }
                    placeholder="Enter Here"
                  />
                </Box>
              </Stack>
              <Stack w={'100%'}>
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  End Time
                </FormLabel>
                <Box className="Ifiled">
                  <Input
                    type="date"
                    value={addcoupon.endTime}
                    onChange={e =>
                      setaddcoupon({ ...addcoupon, endTime: e.target.value })
                    }
                    placeholder="Enter Here"
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Uplaod Picture*
            </FormLabel>
            <Box className="ufiled">
              <Input
                type="file"
                onChange={e =>
                  setaddcoupon({
                    ...addcoupon,
                    uploadPicture: e.target.files[0],
                  })
                }
              />
            </Box>
          </Stack>
        </SimpleGrid>
        <Stack>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={copSubmit}
          >
            Add Coupon
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
