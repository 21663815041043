import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  FormLabel,
  Input,
  Icon,
  Select,
  useToast,
  Toast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';

export default function NewService() {
  const navigate = useNavigate();
  const user = useSelector(state => state.value);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState(null);
  const toast = useToast();
  const [SerInfo, setSerInfo] = useState({
    name: '',
    price: '',
    category: '',
  });

  useEffect(() => {
    if (user) {
      getAllServices();
    }
  }, [user]);

  const SerSubmit = async () => {
    setIsLoading(true);
    try {
      if (
        SerInfo.name === '' ||
        SerInfo.price === '' ||
        SerInfo.category === ''
      ) {
        Toast({
          description: 'All fields are required!',
          status: 'error',
          position: 'top-right',
          duration: 1000,
        });
      } else {
        let res = await POST(`/service/createService`, SerInfo, {
          Authorization: `Bearer ${user?.verificationToken}`,
        });

        console.log('Ser responce', res);
        if (res.status === 'success') {
          toast({
            description: res.message,
            status: res.status,
            position: 'top-right',
            duration: 3000,
          });
          navigate('/services ');
        } else {
          toast({
            description: res.message,
            status: res.status,
            position: 'top-right',
            duration: 3000,
          });
        }
      }
    } catch (err) {
      Toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  const getAllServices = async () => {
    try {
      let userFieldsData = await POST(
        `/service/getAllService`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (userFieldsData.status === 'success') {
        setServices(userFieldsData.data);
      } else {
        console.log('All services', userFieldsData.message);
      }
    } catch (err) {
      console.log('All services', err);
    }
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Add New Service /{' '}
            <Link to={'/services'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
        <Stack className="adser" gap={6} w={'50%'}>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Enter Service Name
            </FormLabel>
            <Box className="Ifiled">
              <Input
                type="text"
                value={SerInfo.name}
                onChange={e => setSerInfo({ ...SerInfo, name: e.target.value })}
                placeholder="Enter Service Name"
              />
            </Box>
          </Stack>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Price
            </FormLabel>
            <Box className="Ifiled">
              <Input
                type="text"
                value={SerInfo.price}
                onChange={e =>
                  setSerInfo({ ...SerInfo, price: e.target.value })
                }
                placeholder="Enter price"
              />
            </Box>
          </Stack>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Select Service Category
            </FormLabel>
            <Box className="Ifiled icnfiled">
              <Select
                color={'#fff'}
                value={SerInfo.category}
                onChange={e =>
                  setSerInfo({ ...SerInfo, category: e.target.value })
                }
              >
                <option style={{ display: 'none' }} value="">
                  Select Option
                </option>
                {services?.length > 0 &&
                  services?.map(service => (
                    <option value={service?.name}>{service?.name}</option>
                  ))}
                {/* <option value="maintenance">Maintenance</option>
                <option value="engine">Engine</option>
                <option value="interior">Interior</option>
                <option value="underbody">Underbody</option>
                <option value="exterior">Exterior</option> */}
              </Select>
            </Box>
          </Stack>
          <Stack>
            <Button
              padding={'10px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              borderRadius={12}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              isLoading={isLoading}
              onClick={SerSubmit}
            >
              Add Service
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
