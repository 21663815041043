import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Link,
  Select,
  SimpleGrid,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { POST, PUT } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';

const AddItem = () => {
  const user = useSelector(state => state.value);
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [stockPriv, setstockPriv] = useState(false);

  const [addItem, setAddItem] = useState({
    itemName: '',
    stockNum: '',
    threshold: '',
    uploadPicture: null,
    category: '',
    sku: '',
    price: '',
  });

  const getCategory = async () => {
    setIsLoading(true);
    let categoryName = await POST(
      `/inventory/viewCategory`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log('Cat Data', categoryName?.data);
    setCategory(
      categoryName?.data.map(category => ({
        id: category?._id,
        name: category?.name,
      }))
    );
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    const payload = {
      name: addItem.itemName,
      cost: addItem.price,
      category: addItem.category,
      uploadPicture: addItem.uploadPicture,
      sku: addItem.sku,
      available: addItem.stockNum,
      threshold: addItem.threshold,
    };

    const formData = new FormData();
    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });
    // formData.append('name', addItem.itemName);
    // formData.append('cost', addItem.price);
    // formData.append('category', addItem.category);
    // formData.append('uploadPicture', addItem.uploadPicture);
    // formData.append('sku', Number(addItem.sku));
    // formData.append('available', Number(addItem.stockNum));
    // formData.append('threshold', Number(addItem.threshold));
    console.log('Payload', payload);
    try {
      setIsLoading(true);
      let response = await POST(`/inventory/createInventory`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log(response);
      if (response.status === 'success') {
        toast({
          title: 'Item Added Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        setAddItem({
          itemName: '',
          stockNum: '',
          threshold: '',
          uploadPicture: '',
          category: '',
          sku: '',
          price: '',
        });
        setIsLoading(false);
        navigate('/inventory');
      } else {
        toast({
          title: 'Item Not Added',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'top-right',
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Something Went Wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleUpdate = async Id => {
    const payload = {
      name: addItem.itemName,
      cost: addItem.price,
      category: addItem.category,
      sku: addItem.sku,
      available: addItem.stockNum,
      threshold: addItem.threshold,
      uploadPicture: addItem.uploadPicture,
    };

    const formData = new FormData();
    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });

    try {
      setIsLoading(true);
      let response = await PUT(`/inventory/update/${Id}`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log(response);
      if (response.status === 'success') {
        toast({
          title: 'Item Updated Successfully',
          status: 'success',
          position: 'top-right',
          duration: 9000,
          isClosable: true,
        });
        setAddItem({
          itemName: '',
          stockNum: '',
          threshold: '',
          uploadPicture: '',
          category: '',
          sku: '',
          price: '',
        });
        setIsLoading(false);
      } else {
        toast({
          title: 'Item Not Updated',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
      }
      navigate('/inventory');
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const hasReorderListPrivilege = user?.privileges
      ?.find(privilege => privilege.name === 'Reorder List')
      ?.action?.includes('create');

    if (user) {
      getCategory();
      if (hasReorderListPrivilege) {
        setstockPriv(true);
      } else {
        setstockPriv(false);
      }
    }

    console.log('LOCATION', location.state);
    setEditData(location.state);
  }, [user]);

  useEffect(() => {
    if (editData) {
      setAddItem({
        itemName: editData.name,
        stockNum: editData.available,
        threshold: editData.threshold ?? 1,
        uploadPicture: editData.uploadPicture,
        category: editData.category,
        sku: editData.sku,
        price: editData.cost,
      });

      // When editing, check for Reorder List privilege again
      const hasReorderListPrivilege = user?.privileges
        ?.find(privilege => privilege.name === 'Reorder List')
        ?.action?.includes('create');
      setstockPriv(hasReorderListPrivilege ?? false);
    } else {
      // When adding a new item, set stockPriv based on Inventory privileges
      const hasInventoryPrivilege = user?.privileges
        ?.find(privilege => privilege.name === 'Inventory')
        ?.action?.includes('create');
      setstockPriv(hasInventoryPrivilege ?? false);
    }

    console.log('Edit Data', editData);
  }, [editData]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Add New Part /{' '}
            <Link to={'/inventory'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
        <SimpleGrid
          borderBottom={'1px solid #595C64'}
          pb={8}
          columns={{
            base: '1',
            sm: '1',
            md: '1',
            lg: '1',
            xl: '2',
            '2xl': '2',
          }}
          spacingX="40px"
          spacingY="20px"
        >
          <Stack className="adser" gap={6}>
            <Stack className="Ifiled label">
              <FormLabel
                className="Ifiled"
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Enter Item Name
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="text"
                  value={addItem.itemName}
                  onChange={e =>
                    setAddItem({ ...addItem, itemName: e.target.value })
                  }
                  placeholder="Enter Name "
                />
              </Box>
            </Stack>
            <Stack display={'flex'} direction={'row'}>
              <Stack className="Ifiled label">
                <FormLabel
                  className="Ifiled label"
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Number of Stock
                </FormLabel>
                <Box className="Ifiled">
                  <Input
                    type="number"
                    disabled={!stockPriv}
                    value={addItem.stockNum}
                    onChange={e =>
                      setAddItem({ ...addItem, stockNum: e.target.value })
                    }
                    placeholder="Enter Number"
                  />
                </Box>
              </Stack>
              <Stack className="Ifiled label">
                <FormLabel
                  className="Ifiled"
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Enter Threshold
                </FormLabel>
                <Box className="Ifiled">
                  <Input
                    type="number"
                    disabled={!stockPriv}
                    value={addItem.threshold}
                    onChange={e =>
                      setAddItem({ ...addItem, threshold: e.target.value })
                    }
                    placeholder="Enter Number"
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack className="Ifiled icnfiled">
              <FormLabel
                className="Ifiled"
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Select Category
              </FormLabel>
              <Select
                color={'#fff'}
                value={addItem.category}
                onChange={e =>
                  setAddItem({ ...addItem, category: e.target.value })
                }
              >
                <option style={{ display: 'none' }} value="">
                  Select Option
                </option>
                {category.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack display={'flex'} direction={'row'}>
              <Stack className="Ifiled label">
                <FormLabel
                  className="Ifiled"
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  SKU Number
                </FormLabel>
                <Box className="Ifiled">
                  <Input
                    type="text"
                    value={addItem.sku}
                    onChange={e =>
                      setAddItem({ ...addItem, sku: e.target.value })
                    }
                    placeholder="Enter Here"
                  />
                </Box>
              </Stack>
              <Stack className="Ifiled label">
                <FormLabel
                  className="Ifiled"
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                >
                  Price
                </FormLabel>
                <Box className="Ifiled">
                  <Input
                    type="number"
                    value={addItem.price}
                    onChange={e =>
                      setAddItem({ ...addItem, price: e.target.value })
                    }
                    placeholder="Enter Here"
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="Ifiled label">
            <FormLabel
              className="Ifiled"
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Upload Picture*
            </FormLabel>
            <Box className="ufiled">
              <Input
                type="file"
                accept="image/*"
                onChange={e =>
                  setAddItem({ ...addItem, uploadPicture: e.target.files[0] })
                }
              />
            </Box>
          </Stack>
        </SimpleGrid>
        <Stack>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={editData ? () => handleUpdate(editData._id) : handleSubmit}
          >
            {editData ? 'Update Item' : 'Add Item'}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddItem;
