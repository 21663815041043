import {
  Avatar,
  Box,
  Button,
  Heading,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  Link,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { POST, PUT } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { ImgUrl } from '../../utilities/config';
import { formatDate } from '../../utilities/helper';
import { useNavigate } from 'react-router-dom';
import { BiEditAlt } from 'react-icons/bi';

const Reorder = () => {
  const toast = useToast();
  const user = useSelector(state => state.value);
  const [filterButtons, setFilterButtons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCat, setSelectedCat] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [invList, setInvList] = useState(null);
  const navigate = useNavigate();

  const getCategory = async () => {
    setIsLoading(true);
    let categoryName = await POST(
      `/inventory/viewCategory`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    // console.log(categoryName);
    setFilterButtons(categoryName?.data.map(category => category?.name));
    setSelectedCat(categoryName?.data[0]?.name);
    setIsLoading(false);
  };

  const getInvByCat = async () => {
    setIsLoading(true);
    let invList = await POST(
      `/inventory/viewByCategory`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log(invList?.data);
    setInvList(invList?.data);
    setSelectedData(invList?.data[0]?.products);
    setIsLoading(false);
  };

  const handleCancelOrder = async id => {
    try {
      let response = await PUT(
        `/inventory/cancelReorder/${id}`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        console.log(response?.data);
        getInvByCat();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleConfirmOrder = async id => {
    try {
      let response = await PUT(
        `/inventory/checkedReorder/${id}`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response.status === 'status') {
        console.log(response?.data);
        getInvByCat();
        toast({
          description: response?.message,
          position: 'top-right',
          isClosable: true,
          status: response?.status,
          duration: 3000,
        });
      }
    } catch (error) {
      console.log(error.message);
      toast({
        description: error.message,
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    if (user) {
      getCategory();
      getInvByCat();
    }
  }, [user]);

  useEffect(() => {
    if (invList) {
      console.log('Selected Cat', selectedCat);
      const selectedCategory = invList.find(
        item => item.category === selectedCat
      );
      if (selectedCategory) {
        setSelectedData(selectedCategory.products);
      }
    }
  }, [selectedCat]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Reorder List</Heading>
        </Stack>
      </SimpleGrid>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        maxWidth={'100%'}
        // overflowX={'hidden'}
      >
        <UnorderedList
          gap={6}
          display={'flex'}
          py={5}
          listStyleType={'none'}
          overflowX={'hidden'}
        >
          {filterButtons?.length > 0 &&
            filterButtons.map((val, ind) => (
              <ListItem key={ind}>
                <Button
                  onClick={() => setSelectedCat(val)}
                  padding={'10px 50px'}
                  bg={
                    val === selectedCat
                      ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      : 'transparent'
                  }
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  color={val === selectedCat ? '#fff' : '#7D7D7D'}
                  border={'1px solid #7D7D7D'}
                  borderRadius={12}
                  _hover={{
                    color: '#fff',
                    bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                    border: '1px solid #2BAF59',
                  }}
                  textTransform={'capitalize'}
                  w={'fit-content'}
                >
                  {val}
                </Button>
              </ListItem>
            ))}
        </UnorderedList>
      </Stack>
      <Stack className="list-ser">
        <Stack
          py={1}
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <Stack className="p1">
            <Text>
              Total Items:{' '}
              <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                {selectedData?.filter(val => val?.reorder)?.length ?? 0}
              </Text>
            </Text>
          </Stack>
          {/* <Stack m={'0 !important'} className="p1">
            <Checkbox color={'#7D7D7D'} size="md" colorScheme="green">
              Select All
            </Checkbox>
          </Stack> */}
        </Stack>
      </Stack>
      <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
        {selectedData?.filter(val => val?.reorder)?.length > 0 ? (
          selectedData
            ?.filter(val => val?.reorder)
            .map((val, index) => (
              <Stack
                direction={'row'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                alignItems={'center'}
                py={5}
                borderBottom={'1px solid #595C64'}
                key={val?._id}
              >
                <Box className="m5-h">
                  <Stack display={'flex'} direction={'row'} spacing={4} m={0}>
                    <Avatar
                      name={val.name}
                      src={`${ImgUrl}${val?.uploadPicture}`}
                      objectFit={'cover'}
                    />
                    <Stack>
                      <Text as={'h5'} opacity={'0.7'}>
                        Item Name
                      </Text>
                      <Text as={'h5'}>{val?.name}</Text>
                    </Stack>
                  </Stack>
                </Box>
                <Box className="m5-h">
                  <Text color={'#fff'} opacity={'0.7'}>
                    SKU{' '}
                  </Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {val?.sku}
                  </Heading>
                </Box>
                <Box className="m5-h">
                  <Text color={'#fff'} opacity={'0.7'}>
                    Sales
                  </Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {val?.sales}
                  </Heading>
                </Box>
                <Box className="m5-h">
                  <Text color={'#fff'} opacity={'0.7'}>
                    Price
                  </Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    ${val?.cost}
                  </Heading>
                </Box>
                <Box className="m5-h">
                  <Text color={'#fff'} opacity={'0.7'}>
                    Date
                  </Text>
                  <Heading as={'h5'} textTransform={'capitalize'}>
                    {formatDate(String(val?.createdAt).split('T')[0])}{' '}
                  </Heading>
                </Box>
                <Box className="m5-h">
                  <Link
                    padding={'10px 50px'}
                    bg={'rgba(47, 54, 51, 0.16)'}
                    color={'#87928D'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                    onClick={() => navigate('/additem', { state: val })}
                  >
                    update
                    {/* <BiEditAlt /> */}
                  </Link>
                </Box>
                {/* <Box className="m5-h">
                  <Button
                    padding={'10px 50px'}
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                    onClick={() => handleConfirmOrder(val?._id)}
                  >
                    Mark Done
                  </Button>
                </Box> */}
              </Stack>
            ))
        ) : (
          <Stack className={'bread m5-h'}>
            <Heading as={'h5'}>No Re-orders Yet </Heading>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default Reorder;
