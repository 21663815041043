import React from 'react'
import MainDashboard from '../../../components/DashNav/MainDashboard'
import { Stack } from '@chakra-ui/react'
import SaleInvoiceList from '../../../components/Technician/Sale/SaleInvoiceList'

export default function SaleInvoice() {
  return (
    <>
     <MainDashboard>
        <Stack px={4}>
       <SaleInvoiceList/>
        </Stack>
      </MainDashboard>
    </>
  )
}
