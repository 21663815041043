import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Grid,
  GridItem,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormLabel,
  Input,
  Select,
  Button,
  Icon,
  Link,
  Badge,
  Avatar,
  useToast,
  Spinner,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Checkbox,
  HStack,
  VStack,
  Divider,
  StackDivider,
} from '@chakra-ui/react';
import html2pdf from 'html2pdf.js';

import React, { useEffect } from 'react';
import { RiAddFill } from 'react-icons/ri';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import { BsGift, BsPlusCircle } from 'react-icons/bs';
import { RiCoupon2Line } from 'react-icons/ri';
import { useState } from 'react';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { FaReceipt, FaShoppingCart } from 'react-icons/fa';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';

export default function JobDetailnew() {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(state => state?.value);
  const [isLoading, setIsLoading] = useState(false);
  const [jobInfo, setJobInfo] = useState(null);
  const [listingProduct, setlistingProducts] = useState(null);
  const [servicesList, setServicesList] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [technicianList, setTechnicianList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [newService, setNewService] = useState(null);
  const [packageData, setpackageData] = useState(null);
  const [recommended, setRecommended] = useState(null);
  const [modalData, setmodalData] = useState(null);
  const [workRecommended, setWorkRecommended] = useState(null);
  const {
    isOpen: isRecommendedOpen,
    onOpen: onRecommendedOpen,
    onClose: onRecommendedClose,
  } = useDisclosure();

  // Function to count the number of similar services between a package and selected services
  function countSimilarServices(packageServices, selectedServices) {
    let count = 0;
    selectedServices.forEach(selectedService => {
      if (packageServices.includes(selectedService)) {
        count++;
      }
    });
    return count;
  }
  // Function to recommend packages
  function recommendPackages(selectedServices, packageData) {
    const recommendedPackages = packageData.map(packageItem => {
      const similarity = countSimilarServices(
        packageItem.services,
        selectedServices
      );
      const hasSimilarService = similarity > 0;
      return {
        ...packageItem,
        similarity: hasSimilarService ? similarity : 0,
        servicesCount: packageItem.services.length,
      };
    });

    recommendedPackages.sort((a, b) => {
      if (a.similarity !== b.similarity) {
        return b.similarity - a.similarity;
      } else {
        // If similarity is the same, prioritize packages with more services
        return b.servicesCount - a.servicesCount;
      }
    });

    return recommendedPackages.slice(0, 3);
  }

  function getTotalPrice(packageName) {
    let totalPrice = 0;
    for (let i = 0; i < packageData.length; i++) {
      if (packageData[i].name === packageName) {
        for (let j = 0; j < packageData[i].servicesDetails.length; j++) {
          totalPrice += Number(packageData[i].servicesDetails[j].price);
        }
        break;
      }
    }
    return totalPrice;
  }

  const getSingleJob = async () => {
    setIsLoading(true);
    try {
      let res = await GET(`/job/getSingleJob/${params?.id}`, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res?.status === 'success') {
        setJobInfo(res?.data);
        setlistingProducts(res?.data?.service?.map(val => val?.id?.name));
        setSelectedService(res?.data?.service);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };
  const getAllServices = async () => {
    setIsLoading(true);
    try {
      let res = await POST(
        `/service/getAllService`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        const servicesList = res?.data;
        // Flatten the services arrays from each category
        const allServices = servicesList.flatMap(category => category.services);
        console.log(allServices, 'ÁLL SERVICES LIST');
        setServicesList(allServices);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  const addService = async () => {
    console.log('addService', newService);
    if (!newService) {
      toast({
        description: 'Please select a service',
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
      return;
    }
    setIsLoading(true);
    try {
      let res = await PUT(
        `/job/updateJobByCSR/${params?.id}`,
        {
          service: [
            {
              id: newService.id,
              price: Number(newService.price),
            },
          ],
        },
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        setNewService(null);
        getSingleJob();
      }
    } catch (err) {
      setNewService(null);
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
  };

  const getAllTechnicians = async () => {
    try {
      let res = await GET(`/job/technician`, {
        authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res?.status === 'success') {
        setTechnicianList(res?.data);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const updateTech = async techId => {
    try {
      let res = await PUT(
        `/job/assignTechnicianByCsr/${params.id}`,
        { technicianId: techId },
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        console.log(res?.data);
        getSingleJob();
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const generateInvoice = async () => {
    console.log(jobInfo?.invoice, 'JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ');
    if (!jobInfo?.assignedTechnician || jobInfo?.assignedTechnician === null) {
      toast({
        description: 'Please assign a Technician',
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
      return;
    }
    try {
      let res = await POST(
        `/invoice/${params.id}/store`,
        { service: jobInfo?.service },
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (res?.status === 'success') {
        // console.log(res?.data, 'HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH');
        navigate(`/jobinvoice/${res?.data?._id}`);
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
  };

  const getInventory = async () => {
    try {
      let res = await POST(
        `/inventory/view`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        setInventoryList(res?.data);
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  const getPackages = async () => {
    setIsLoading(true);
    try {
      let res = await POST(
        `/job/getAllPackage`,
        {},
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        setpackageData(res?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  function handleOpen(item) {
    console.log(item, 'MMMOOODDAAA');
    setmodalData(item);
    onOpen();
  }

  const buyPackage = async (services, Id) => {
    console.log('Pkg Service', services);
    console.log('Selected', selectedService);
    const newServices = selectedService.filter(
      service => !services.includes(service?.id?._id)
    );
    let payload = {
      servicesData: newServices.map(val => ({ id: val?.id?._id })),
      packageId: Id,
    };
    console.log('Payload', payload);
    try {
      let response = await POST(
        `/job/updateJobPackages/${params?.id}`,
        payload,
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (response?.status === 'success') {
        setNewService(null);
        toast({
          description: response?.message,
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 3000,
        });
        getSingleJob();
      }
    } catch (error) {
      setNewService(null);
      console.log(error.message);
    }
  };

  const handleDownload = () => {
    const element = document.getElementById('modal-content');
    if (!element) {
      console.error('Element not found');
      return;
    }

    const options = {
      margin: 0.5,
      filename: 'cash_drawer_report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        unit: 'in',
        orientation: 'portrait',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16,
      },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .catch(err => console.error('Error generating PDF:', err));
  };

  const handlePrint2 = id => {
    // Open the invoice print component in a new window
    window.open(`/invoicePrint/${id}`, '_blank');
  };

  const handlePrint = () => {
    const element = document.getElementById('modal-content');
    const printWindow = window.open('', '_blank');

    // Extract the data from the element (assuming it contains text nodes)
    const data = element.innerText
      .split('\n')
      .filter(line => line !== 'Customer' && line !== 'Vehicle')
      .filter(line => line.trim() !== '');

    console.log('DATAS', data);

    // Map data to respective fields and values
    const vehicleInfo = {
      'Vehicle Make': data[3],
      'Vin No': data[4],
      'Vehicle Model': data[5],
      'Sub Model': data[7],
      'Engine Size': data[9],
      Miles: data[11],
    };

    const customerInfo = {
      'First Name': data[15],
      'Last Name': data[16],
      Address: data[17],
      City: data[22],
      'Zip Code': data[23],
      Contact: data[24],
      Email: data[25],
    };

    // Extract dynamic service data
    let serviceParts = [];
    let i = 27;
    while (data[i] !== 'Recommended Services List' && i < data.length - 4) {
      serviceParts.push({
        'Service/Parts': data[i],
        Quantity: data[i + 3],
        Price: data[i + 4],
      });
      i += 5;
    }

    // Extract tax and total
    const taxAndTotal = {
      'Tax 10%': data[data.length - 3],
      'Total Amount': data[data.length - 1],
    };

    // Generate HTML for the table
    const tableHtml = `
    <div class="section">
      <div class="title">Vehicle</div>
      <div class="content">
        ${Object.keys(vehicleInfo)
          .map(key => `<div>${key}: ${vehicleInfo[key]}</div>`)
          .join('')}
      </div>
    </div>
    <div class="section">
      <div class="title">Customer</div>
      <div class="content">
        ${Object.keys(customerInfo)
          .map(key => `<div>${key}: ${customerInfo[key]}</div>`)
          .join('')}
      </div>
    </div>
     <div class="section">
      <div class="title">Service/Parts</div>
      <table>
        <tr>
          <th>Service/Parts</th>
          <th>Quantity</th>
          <th>Price</th>
        </tr>
        ${serviceParts
          .map(
            service => `
          <tr>
            <td>${service['Service/Parts']}</td>
            <td>${service['Quantity']}</td>
            <td>${service['Price']}</td>
          </tr>`
          )
          .join('')}
      </table>
    </div>
    <div class="section">
      <div class="title">Tax and Total</div>
      <div class="content">
        ${Object.keys(taxAndTotal)
          .map(key => `<div>${key}: ${taxAndTotal[key]}</div>`)
          .join('')}
      </div>
    </div>
  `;

    printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          @media print {
            @page { size: A4; }
            body { margin: 0; padding: 20px; }
            .container { width: 100%; margin: 0 auto; }
            .header, .section { display: flex; justify-content: space-between; }
            .section { margin-top: 20px; }
            .title { background-color: green; color: white; padding: 10px; }
            .content { background-color: lightgrey; padding: 10px; }
            .content div { margin-bottom: 5px; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid black; padding: 5px; text-align: left; }
          }
        </style>
      </head>
      <body>
        <div class="container">
          ${tableHtml}
        </div>
      </body>
    </html>
  `);

    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const markComplete = async id => {
    let recommendedJobs = [];
    if (workRecommended) {
      recommendedJobs = workRecommended.map(item =>
        item?.productId ? item?.productId : item?._id
      );
    } else {
      console.log('workRecommended is null or undefined');
      // Handle this case based on your application logic
    }
    console.log('idsArray', recommendedJobs);
    let servicepayload = [];
    servicepayload = jobInfo?.service.map(val => val?.id?._id);
    jobInfo?.package.forEach(val => {
      val.id?.services.forEach(service => {
        servicepayload.push(service._id);
      });
    });
    const payload = {
      status: 'completed',
      servicesData: servicepayload,
      recommended: recommendedJobs,
    };
    try {
      let response = await PUT(`/job/jobStatus/${id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        toast({
          title: 'Job Marked As Done',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // onRecommendedOpen();
        // Navigate the user to /listofJobs
        // navigate('/jobs');
      } else {
        toast({
          title: 'Checkout Failed',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const markBackPending = async id => {
    const payload = {
      status: 'pending',
    };
    try {
      let response = await PUT(`/job/jobStatus/${id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (response.status === 'success') {
        toast({
          title: 'Job Marked Back To Pending',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // Navigate the user to /listofJobs
        navigate('/listofjob');
      } else {
        toast({
          title: 'Checkout Failed',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const markPaid = async id => {
    // Check if invoice is generated
    const isInvoiceGenerated = jobInfo?.invoice?.length > 0;
    if (isInvoiceGenerated) {
      const payload = {
        status: 'paid',
      };
      try {
        let response = await PUT(`/job/jobStatus/${id}`, payload, {
          Authorization: `Bearer ${user?.verificationToken}`,
        });
        if (response.status === 'success') {
          toast({
            title: 'Job Marked As Paid',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          navigate('/listofjob');
        } else {
          toast({
            title: 'Checkout Failed',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      toast({
        title: 'Invoice not generated',
        status: 'warning',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    if (selectedService && packageData) {
      // console.log('Service List', selectedService);
      let selectedServices = selectedService?.map(val => val?.id?._id);

      setRecommended(recommendPackages(selectedServices, packageData));
      // console.log('Recommended New Array', recommended);
    }
  }, [selectedService, packageData]);

  useEffect(() => {
    if (user) {
      getAllServices();
      getAllTechnicians();
      getInventory();
      getPackages();
      if (params) getSingleJob();
    }
  }, [user, params]);
  // console.log('Job Information', jobInfo);

  const handleCheckboxChange = item => {
    console.log(item, 'ÍIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIIII');
    setWorkRecommended(prevSelectedItems => {
      if (prevSelectedItems && prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter(i => i !== item);
      } else {
        return [...(prevSelectedItems || []), item];
      }
    });
  };
  const handleSave = item => {
    console.log(item, 'sadasssssssssssssssssssssss');
    localStorage.setItem('selectedItems', JSON.stringify(workRecommended));
    onRecommendedClose();
    markComplete(item);
    setTimeout(() => {
      navigate('/jobs');
    }, 2000);
  };
  // console.log(jobInfo, 'jobInfo');
  const productNames = Array.isArray(listingProduct) ? listingProduct : [];

  const filteredServicesList = servicesList.filter(
    item => !productNames.includes(item.name)
  );

  const filteredInventoryList = inventoryList.filter(
    item => !productNames.includes(item.name)
  );

  return (
    <>
      <Stack className={'bread m3-h'} mb={6}>
        <Heading as={'h3'}>View Jobs Details</Heading>
      </Stack>
      {isLoading ? (
        <Spinner color="primaryGreen.100" />
      ) : (
        <Stack bg={'#202125'} px={6} py={12} borderRadius={8} gap={4}>
          <Stack direction={'row'} justifyContent={'space-between'} pb={4}>
            <Stack className="p1 m4-h">
              <Heading as={'h4'}>Status</Heading>

              <Badge
                textAlign={'center'}
                py={2}
                px={4}
                borderRadius={6}
                variant="solid"
                colorScheme="green"
              >
                {jobInfo?.customer?.existingCustomer
                  ? 'Existing Customer'
                  : 'New Customer'}
              </Badge>
            </Stack>
            <Stack className="p1">
              <Text fontFamily={'Orbitron'}>
                Job Posted:
                <Text as={'span'} color={'#fff'} fontFamily={'Orbitron'}>
                  {' '}
                  {new Date(jobInfo?.createdAt).toLocaleDateString()}
                </Text>
              </Text>
            </Stack>
          </Stack>
          <Stack pb={4} direction={'row'} borderBottom={'1px solid #2e3035'}>
            <Stack w={'100%'}>
              <Stack className="m4-h">
                <Heading as={'h4'} mb={5}>
                  Customer Invoice Details
                </Heading>
                <TableContainer>
                  <Table
                    className="tb-ct"
                    w={'50%'}
                    size="sm"
                    colorScheme="teal"
                  >
                    <Tbody className="m6-h p2">
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            First Name
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.customer?.firstName ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Last Name
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.customer?.lastName ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Address
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontFamily={'Orbitron'}>
                            {jobInfo?.customer?.address ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            State / City
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontFamily={'Orbitron'}>
                            {jobInfo?.customer?.state ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Zip Code
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontFamily={'Orbitron'}>
                            {jobInfo?.customer?.zipCode ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Contact
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontFamily={'Orbitron'}>
                            {jobInfo?.customer?.number ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Email
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontFamily={'Orbitron'}>
                            {jobInfo?.customer?.email ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Vin Number
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontFamily={'Orbitron'}>
                            {jobInfo?.vehicle?.vinNumber ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Vehicle Make
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.vehicle?.make ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Vehicle Model
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.vehicle?.model ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Vehicle Sub Model
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.vehicle?.subModel ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Vehicle Manufacture Year
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.vehicle?.year ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Engine Size
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.vehicle?.engineSize ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading
                            fontFamily={'Orbitron'}
                            fontSize={'16px'}
                            color={'#2BAF59'}
                          >
                            Mileage
                          </Heading>
                        </Td>
                        <Td>
                          <Text
                            fontFamily={'Orbitron'}
                            textTransform={'capitalize'}
                          >
                            {jobInfo?.vehicle?.miles ?? ''}
                          </Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
            <Stack direction={'row'} w={'100%'} gap={12}>
              {jobInfo?.coupons && (
                <Stack w={'100%'} className="m5-h">
                  <Heading as={'h5'}>Coupon</Heading>
                  <Box
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    px={6}
                    py={4}
                    borderRadius={6}
                    className="card m4-h"
                  >
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box>
                        <Heading as={'h5'}>Coupon Card</Heading>
                        <Heading as={'h4'}>12% OFF</Heading>
                        <Heading as={'h5'}>Any Purchase</Heading>
                      </Box>
                      <Icon color={'#ffffff69'} fontSize={'55px'} as={BsGift} />
                    </Stack>
                    <Button
                      bg={'transparent'}
                      p={0}
                      color={'#fff'}
                      _hover={{
                        bg: 'transparent',
                        color: '#000',
                      }}
                    >
                      {' '}
                      <Icon
                        color={'#fff'}
                        fontSize={'18px'}
                        as={BsPlusCircle}
                        mr={1}
                      />{' '}
                      Add Coupons
                    </Button>
                  </Box>
                </Stack>
              )}
              {jobInfo?.discount && (
                <Stack w={'100%'} className="m5-h">
                  <Heading as={'h5'}>Discounts</Heading>
                  <Box
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                    px={6}
                    py={4}
                    borderRadius={6}
                    className="card m4-h"
                  >
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box>
                        <Heading as={'h5'}>Limited</Heading>
                        <Heading as={'h4'}>15% OFF</Heading>
                        <Heading as={'h5'}>Coded</Heading>
                      </Box>
                      <Icon
                        color={'#ffffff69'}
                        fontSize={'55px'}
                        as={RiCoupon2Line}
                      />
                    </Stack>
                    <Button
                      bg={'transparent'}
                      p={0}
                      color={'#fff'}
                      _hover={{
                        bg: 'transparent',
                        color: '#000',
                      }}
                    >
                      {' '}
                      <Icon
                        color={'#2BAF59'}
                        fontSize={'18px'}
                        as={BsPlusCircle}
                        mr={1}
                      />{' '}
                      Add Discount
                    </Button>
                  </Box>
                </Stack>
              )}
            </Stack>
          </Stack>
          {/* <Stack direction={'row'} pb={4} borderBottom={'1px solid #2e3035'}>
              <Stack w={'100%'} className="m4-h">
                <Heading as={'h4'} mb={5}>
                  Vehicle Details:
                </Heading>
                <TableContainer>
                  <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                    <Tbody className="m6-h p2">
                      <Tr>
                        <Td>
                          <Heading fontSize={'16px'} color={'#2BAF59'}>
                            Technician Name
                          </Heading>
                        </Td>
                        <Td>
                          <Text>Theresa Webb</Text>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td>
                          <Heading fontSize={'16px'} color={'#2BAF59'}>
                            Address
                          </Heading>
                        </Td>
                        <Td>
                          <Text> xyz , Now York city USA</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading fontSize={'16px'} color={'#2BAF59'}>
                            Contact
                          </Heading>
                        </Td>
                        <Td>
                          <Text>(4230) 3233 532</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
              <Stack w={'100%'} className="m4-h">
                <Heading as={'h4'} mb={5}>
                  Service Details:
                </Heading>
                <TableContainer>
                  <Table className="tb-ct" w={'50%'} size="sm" colorScheme="teal">
                    <Tbody className="m6-h p2">
                      <Tr>
                        <Td>
                          <Heading fontSize={'16px'} color={'#2BAF59'}>
                            Customer Name
                          </Heading>
                        </Td>
                        <Td>
                          <Text>Theresa Webb</Text>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td>
                          <Heading fontSize={'16px'} color={'#2BAF59'}>
                            Address
                          </Heading>
                        </Td>
                        <Td>
                          <Text> xyz , Now York city USA</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Heading fontSize={'16px'} color={'#2BAF59'}>
                            Contact
                          </Heading>
                        </Td>
                        <Td>
                          <Text>(4230) 3233 532</Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack> */}
          {(jobInfo?.assignedTechnician || user?.role !== 'technician') && (
            <Stack
              pb={8}
              direction={'row'}
              borderBottom={'1px solid #2e3035'}
              className="m4-h"
            >
              <Stack w={'100%'} className="m4-h">
                <Heading as={'h4'} mb={5}>
                  Assigned Technician:
                </Heading>
                <SimpleGrid columns={2} spacing={10}>
                  <Box>
                    <Box display={'flex'} mb={3} gap={3}>
                      <Avatar
                        name={jobInfo?.assignedTechnician?.fullName}
                        bgColor={'primaryGreen.100'}
                        src={''}
                      />
                      <Box className="venbox p1 m3-h">
                        <Text fontFamily={'Orbitron'}>
                          {jobInfo?.assignedTechnician?.fullName}
                        </Text>
                        <Heading as={'h3'}>
                          {jobInfo?.assignedTechnician?.email}
                        </Heading>
                      </Box>
                    </Box>
                    {user?.role !== 'technician' &&
                      jobInfo?.status === 'pending' && (
                        // <Button
                        //   padding={'10px 20px'}
                        //   bg={
                        //     'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        //   }
                        //   color={'#fff'}
                        //   fontWeight={'500'}
                        //   lineHeight={'inherit'}
                        //   borderRadius={12}
                        //   _hover={{
                        //     color: '#000',
                        //   }}
                        //   fontSize={'14px'}
                        //   w={'fit-content'}
                        //   alignItems={'center'}
                        // >
                        //   Change Technician
                        // </Button>
                        <Stack direction={'row'} gap="20px">
                          <Text fontFamily={'Orbitron'} color="#fff">
                            Change Technician:{' '}
                          </Text>
                          <Select
                            bgColor={'#fff'}
                            onChange={e => updateTech(e.target.value)}
                          >
                            <option
                              style={{
                                display: 'none',
                                fontFamily: 'Orbitron',
                              }}
                            >
                              Select New Technician
                            </option>
                            {technicianList?.length > 0 &&
                              technicianList?.map(val => (
                                <option value={val?._id}>
                                  {val?.fullName ?? ''}
                                </option>
                              ))}
                          </Select>
                        </Stack>
                      )}
                  </Box>
                </SimpleGrid>
              </Stack>
              {/* <Stack w={'100%'} className="m4-h">
                  <Heading as={'h4'} mb={5}>
                    Assigned Service Area:
                  </Heading>
                  <SimpleGrid columns={2} spacing={10}>
                    <Box>
                      <Box display={'flex'} mb={3} gap={3}>
                        <Avatar name="Kent Dodds" src={''} />
                        <Box className="venbox p1 m3-h">
                          <Text>ID: 28745</Text>
                          <Heading as={'h3'}>Honda Civic</Heading>
                        </Box>
                      </Box>
                      <Button
                        padding={'10px 20px'}
                        bg={
                          'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        }
                        color={'#fff'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        borderRadius={12}
                        _hover={{
                          color: '#000',
                        }}
                        fontSize={'14px'}
                        w={'fit-content'}
                        alignItems={'center'}
                      >
                        Change Technician
                      </Button>
                    </Box>
                  </SimpleGrid>
                </Stack> */}
            </Stack>
          )}
          <Stack pb={8} borderBottom={'1px solid #2e3035'} className="m4-h">
            <Heading mb={5} as={'h4'}>
              List of Products
            </Heading>
            <SimpleGrid pb={8} columns={5} spacing={2}>
              {jobInfo?.service?.map(val => (
                <Box
                  bg={'#2baf598c'}
                  border={'2px solid #2BAF59 '}
                  borderRadius={'25px'}
                  padding={2}
                  textAlign={'center'}
                >
                  <Text color={'#fff'} fontFamily={'Orbitron, sans-serif'}>
                    {val?.id?.name} - ${val?.id?.price}
                  </Text>
                </Box>
              ))}
              {jobInfo?.package.length > 0 &&
                jobInfo?.package?.map((pkg, pkgIndex) => (
                  <Box
                    key={pkgIndex}
                    bg={'#FF7E00'}
                    border={'1px solid #FFFF '}
                    borderRadius={'25px'}
                    padding={2}
                    textAlign={'center'}
                    marginBottom={2}
                  >
                    <Text color={'#fff'} fontFamily={'Orbitron'}>
                      {pkg?.id?.name} - ${pkg?.id?.cost}
                    </Text>
                    <Text color={'#202125'} fontFamily={'Orbitron'}>
                      {pkg?.id?.services?.map((service, index) => (
                        <span key={index}>
                          {service?.name}
                          {index !== pkg?.id?.services?.length - 1 ? ' / ' : ''}
                        </span>
                      ))}
                    </Text>
                  </Box>
                ))}
            </SimpleGrid>
            {jobInfo?.status === 'pending' && jobInfo?.assignedTechnician && (
              <>
                <Stack>
                  <Stack w={'50%'} mb={4} direction={'row'}>
                    <Stack w={'100%'} className="Ifiled icnfiled">
                      <FormLabel
                        fontWeight={400}
                        m={0}
                        color={'#949494'}
                        fontSize={'14px'}
                      >
                        Select Services
                      </FormLabel>
                      <Box>
                        <Select
                          color={'#fff'}
                          onChange={e =>
                            setNewService(JSON.parse(e.target.value))
                          }
                        >
                          <option style={{ display: 'none' }} value="">
                            Select
                          </option>
                          {/* {servicesList?.length > 0 &&
                            servicesList?.map(val => (
                              <option
                                value={JSON.stringify({
                                  id: val?._id,
                                  price: val?.price,
                                })}
                              >
                                {val?.name ?? ''}
                              </option>
                            ))} */}
                          {servicesList?.length > 0 &&
                            servicesList?.map(val => (
                              <option
                                value={JSON.stringify({
                                  id: val?._id,
                                  price: val?.price,
                                  type: 'service',
                                })}
                              >
                                {val?.name ?? ''}
                              </option>
                            ))}
                        </Select>
                      </Box>
                    </Stack>
                  </Stack>
                  {newService?.type === 'service' && (
                    <Button
                      padding={'10px 30px'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      alignItems={'center'}
                      onClick={addService}
                    >
                      Add Services{' '}
                      <Icon
                        color={'#fff'}
                        pl={2}
                        fontSize={'26px'}
                        as={RiAddFill}
                      />
                    </Button>
                  )}
                </Stack>
                <Stack py={4}>
                  <Stack w={'50%'} mb={4} direction={'row'}>
                    <Stack w={'100%'} className="Ifiled icnfiled">
                      <FormLabel
                        fontWeight={400}
                        m={0}
                        color={'#949494'}
                        fontSize={'14px'}
                      >
                        Select Parts
                      </FormLabel>
                      <Box>
                        <Select
                          color={'#fff'}
                          onChange={e =>
                            setNewService(JSON.parse(e.target.value))
                          }
                        >
                          <option style={{ display: 'none' }} value="">
                            Select
                          </option>
                          {inventoryList?.length > 0 &&
                            inventoryList
                              ?.filter(val => val.active)
                              ?.map(val => (
                                <option
                                  value={JSON.stringify({
                                    id: val?.productId,
                                    price: val?.cost,
                                    type: 'part',
                                  })}
                                >
                                  {val?.name ?? ''}
                                </option>
                              ))}
                          {/* {inventoryList?.length > 0 &&
                            inventoryList?.map(val => (
                              <option
                                value={JSON.stringify({
                                  id: val?.productId,
                                  price: val?.cost,
                                })}
                              >
                                {val?.name ?? ''}
                              </option>
                            ))} */}
                        </Select>
                      </Box>
                    </Stack>
                  </Stack>
                  {newService?.type === 'part' && (
                    <Button
                      padding={'10px 30px'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      alignItems={'center'}
                      onClick={addService}
                    >
                      Add Part{' '}
                      <Icon
                        color={'#fff'}
                        pl={2}
                        fontSize={'26px'}
                        as={RiAddFill}
                      />
                    </Button>
                  )}
                </Stack>
                <Stack py={4}>
                  <Stack w={'50%'} mb={4} direction={'row'}>
                    <Stack w={'100%'} className="Ifiled icnfiled">
                      <FormLabel
                        fontWeight={400}
                        m={0}
                        color={'#949494'}
                        fontSize={'14px'}
                      >
                        Select Package
                      </FormLabel>
                      <Box>
                        <Select
                          color={'#fff'}
                          onChange={e =>
                            setNewService(JSON.parse(e.target.value))
                          }
                        >
                          <option style={{ display: 'none' }} value="">
                            Select
                          </option>
                          {packageData?.length > 0 &&
                            packageData?.map(val => (
                              <option
                                value={JSON.stringify({
                                  id: val?._id,
                                  type: 'package',
                                  services: val?.services,
                                  price: val?.cost,
                                })}
                              >
                                {val?.name ?? ''}
                              </option>
                            ))}
                        </Select>
                      </Box>
                    </Stack>
                  </Stack>
                  {newService?.type === 'package' && (
                    <Button
                      padding={'10px 30px'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      alignItems={'center'}
                      onClick={() =>
                        buyPackage(newService?.services, newService?.id)
                      }
                    >
                      Add Package{' '}
                      <Icon
                        color={'#fff'}
                        pl={2}
                        fontSize={'26px'}
                        as={RiAddFill}
                      />
                    </Button>
                  )}
                </Stack>
              </>
            )}
          </Stack>
          {jobInfo?.invoice?.length > 0 && (
            <Stack className="m4-h">
              <Heading mb={5} as={'h4'}>
                Invoice History
              </Heading>
              <TableContainer>
                <Table className="jobin m6-h p2">
                  <Thead
                    bg={
                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                    }
                  >
                    <Tr>
                      <Th>
                        <Heading as={'h5'}>Created Date</Heading>
                      </Th>
                      <Th>
                        <Heading as={'h5'}>Total Services</Heading>
                      </Th>
                      <Th>
                        <Heading as={'h5'}>Qty</Heading>
                      </Th>
                      <Th>
                        <Heading as={'h5'}>Total Price</Heading>
                      </Th>
                      <Th>
                        <Heading as={'h5'}>Reciept</Heading>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {jobInfo?.invoice?.length > 0 &&
                      jobInfo?.invoice?.map(val => (
                        <Tr key={val?._id} fontFamily={'Orbitron'}>
                          <Td>
                            <Text>
                              {new Date(val?.createdAt).toLocaleDateString()}
                            </Text>
                          </Td>
                          <Td>
                            <Text>{val?.service?.length}</Text>
                          </Td>
                          <Td>
                            <Text>{val?.totalQty}</Text>
                          </Td>
                          <Td>
                            <Text>${val?.totalPrice}</Text>
                          </Td>
                          <Td>
                            <Button
                              display={'flex'}
                              borderRadius={50}
                              alignItems={'center'}
                              justifyContent={'center'}
                              bg={
                                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                              }
                              w={'40px'}
                              h={'40px'}
                              color={'#fff'}
                              margin={'auto'}
                              onClick={() => handleOpen(val)}
                            >
                              <FaReceipt />
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          )}
          <>
            {/* {jobInfo?.status === 'pending' &&
              recommended &&
              recommended?.length > 0 && (
                <Stack className="m4-h">
                  <Heading mb={5} as={'h4'}>
                    Package
                  </Heading>
                  <TableContainer>
                    <Table className="jobin m6-h p2">
                      <Thead
                        bg={
                          'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        }
                      >
                        <Tr>
                          <Th>
                            <Heading as={'h5'}>Package Name</Heading>
                          </Th>
                          <Th>
                            <Heading as={'h5'}>Price</Heading>
                          </Th>
                          <Th>
                            <Heading as={'h5'}>Services Included</Heading>
                          </Th>
                          <Th>
                            <Heading as={'h5'}>Actions</Heading>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {recommended?.length > 0 &&
                          recommended
                            ?.sort((a, b) => b.similarity - a.similarity)
                            ?.map((item, index) => (
                              <Tr key={index}>
                                <Td>
                                  <Text>{item?.name}</Text>
                                </Td>
                                <Td>
                                  <Text>
                                    <span
                                      style={{
                                        textDecoration: 'line-through',
                                        transform: 'rotate(-45deg)',
                                        transformOrigin: 'center center',
                                        display: 'inline-block',
                                      }}
                                    >
                                      ${getTotalPrice(item?.name)}
                                    </span>{' '}
                                    ${item?.cost}
                                  </Text>
                                </Td>
                                <Td>
                                  <Text>
                                    {item?.servicesDetails?.map(
                                      (service, index) => (
                                        <span key={index}>
                                          {service?.name}
                                          {index !== item?.services?.length - 1
                                            ? ' / '
                                            : ''}
                                        </span>
                                      )
                                    )}
                                  </Text>
                                </Td>
                                <Td>
                                  <Link
                                    display={'flex'}
                                    borderRadius={50}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    bg={
                                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                                    }
                                    w={'30px'}
                                    h={'30px'}
                                    color={'#fff'}
                                    margin={'auto'}
                                    onClick={() =>
                                      buyPackage(item?.services, item?._id)
                                    }
                                  >
                                    <FaShoppingCart />
                                  </Link>
                                </Td>
                              </Tr>
                            ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Stack>
              )} */}
            <Stack
              direction={'row'}
              textAlign={'right'}
              justifyContent={'flex-end'}
            >
              {user?.role !== 'technician' &&
                jobInfo?.status === 'completed' && (
                  <>
                    {/* <Button onClick={() => navigate('/listofjob')}>
                      {' '}
                      Back To pending
                    </Button> */}
                    <Link
                      display={'block'}
                      padding={'8px 25px'}
                      bg={'#2BAF59'}
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      alignItems={'center'}
                      onClick={generateInvoice}
                    >
                      Generate Invoice
                    </Link>
                  </>
                )}
              {user?.role === 'technician' && jobInfo?.assignedTechnician && (
                <Button
                  padding={'0px 25px'}
                  bg={'#2BAF59'}
                  color={'#fff'}
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  borderRadius={12}
                  _hover={{
                    color: '#000',
                  }}
                  w={'fit-content'}
                  alignItems={'center'}
                  onClick={onRecommendedOpen}
                  // onClick={() => markComplete(jobInfo?._id)}
                >
                  Mark As Done
                </Button>
              )}
              {jobInfo?.status === 'completed' && (
                <Link
                  padding={'8px 25px'}
                  bg={'#DF2234'}
                  color={'#fff'}
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  borderRadius={12}
                  _hover={{
                    color: '#000',
                  }}
                  w={'fit-content'}
                  alignItems={'center'}
                  onClick={() => markBackPending(jobInfo?._id)}
                >
                  Return to Pending
                </Link>
              )}
              {/* {jobInfo?.status === 'completed' &&
                user?.role !== 'technician' && (
                  <Button
                    padding={'0px 25px'}
                    bg={'#2BAF59'}
                    color={'#fff'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    borderRadius={12}
                    _hover={{
                      color: '#000',
                    }}
                    w={'fit-content'}
                    alignItems={'center'}
                    onClick={() => markPaid(jobInfo?._id)}
                  >
                    Mark Paid
                  </Button>
                )} */}
            </Stack>
          </>
        </Stack>
      )}
      <>
        <Modal isOpen={isRecommendedOpen} onClose={onRecommendedClose}>
          <ModalOverlay bgColor={'#7a7a7a96'} />
          <ModalContent bgColor={'#242424'} w={'30%'} maxW={'30%'}>
            <ModalHeader color={'#fff'}>Recommended </ModalHeader>
            <ModalCloseButton color={'#d90c0c'} />
            <ModalBody pb={3}>
              <Stack bg={'#2e3035'} mb={2} px={5} py={8} borderRadius={8}>
                <Text fontSize={'20px'} color={'white'}>
                  Services
                </Text>
                <Box
                  // bgColor={'#7a7a7a96'}
                  // border={'1px solid #2e3035'}
                  // borderRadius={'25px'}
                  overflowY={'auto'}
                  p={4}
                >
                  {servicesList.length > 0 ? (
                    servicesList.map(item => (
                      <Stack
                        key={item?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={2}
                      >
                        <Box className="m5-h p1" width={'50%'}>
                          <Heading as={'h5'} color={'white'}>
                            {item?.name}
                          </Heading>
                        </Box>

                        <Box display={'flex'} gap={4}>
                          <Link
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                          >
                            <Checkbox
                              colorScheme="green"
                              size={'lg'}
                              isChecked={
                                workRecommended
                                  ? workRecommended.includes(item)
                                  : false
                              }
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </Link>
                        </Box>
                      </Stack>
                    ))
                  ) : (
                    <Text color={'white'} textAlign={'center'} py={4}>
                      No services available for Recommendation
                    </Text>
                  )}
                </Box>
              </Stack>
              <Stack bg={'#2e3035'} px={5} py={3} borderRadius={8}>
                <Text fontSize={'20px'} color={'white'}>
                  Parts
                </Text>
                <Box
                  border={'1px solid #2e3035'}
                  overflowY={'auto'}
                  // bgColor={'#7a7a7a96'}
                  h={'100%'}
                  borderRadius={'25px'}
                  p={4}
                >
                  {inventoryList.length > 0 ? (
                    inventoryList.map(item => (
                      <Stack
                        key={item?._id}
                        direction={'row'}
                        flexWrap={'wrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        py={2}
                      >
                        <Box className="m5-h p1" width={'50%'}>
                          <Heading as={'h5'} color={'white'}>
                            {item?.name}
                          </Heading>
                        </Box>

                        <Box display={'flex'} gap={4}>
                          <Link
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            w={'30px'}
                            h={'30px'}
                            color={'#fff'}
                          >
                            <Checkbox
                              colorScheme="green"
                              size={'lg'}
                              isChecked={
                                workRecommended
                                  ? workRecommended.includes(item)
                                  : false
                              }
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </Link>
                        </Box>
                      </Stack>
                    ))
                  ) : (
                    <Text color={'white'} textAlign={'center'} py={4}>
                      No parts available for Recommendation
                    </Text>
                  )}
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                value={'Update'}
                mr={3}
                // isLoading={isLoading}
                // onClick={() => markComplete(jobInfo?._id)}
                onClick={() => handleSave(jobInfo?._id)}
              >
                Continue
              </Button>
              {/* <Button
                colorScheme="red"
                value={'Cancel'}
                onClick={onRecommendedClose}
              >
                Cancel
              </Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isCentered
          onClose={onClose}
          size={'xl'}
          isOpen={isOpen}
          scrollBehavior={'inside'}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent
            bgColor={'#2E3035'}
            color={'white'}
            w={'100%'}
            maxW={'60%'}
          >
            <ModalHeader
              color={'#fff'}
              textAlign={'center'}
              fontFamily={'Orbitron'}
              fontWeight={'500'}
              fontSize={'24px'}
            >
              Customer Invoice
            </ModalHeader>
            <ModalCloseButton color={'#ffff'} />

            <ModalBody
              pb={6}
              bgColor={'#2E3035'}
              color={'white'}
              id="modal-content"
            >
              <Box
                // border="1px solid rgba(255, 255, 255, 0.20)"
                // borderRadius="md"
                p={4}
                mb={4}
              >
                {/*----------------------VEHICLE AND CUSTOMER SECTION------------------  */}
                <Grid
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  borderTopRightRadius={{
                    base: '0px',
                    sm: '0px',
                    lg: '0px',
                    xl: '0px',
                    '2xl': '0px',
                  }}
                  borderTopLeftRadius={{
                    base: '0px',
                    sm: '0px',
                    lg: '0px',
                    xl: '0px',
                    '2xl': '0px',
                  }}
                  h="auto"
                  // borderRadius={'25px'}
                  templateRows="1fr"
                  templateColumns={{
                    base: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                    lg: 'repeat(1, 1fr)',
                    xl: 'repeat(1, 1fr)',
                    '2xl': 'repeat(1, 1fr)',
                  }}
                  gap={6}
                >
                  <GridItem colSpan={1}>
                    <Box
                      borderRight={'1px solid #000'}
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                    >
                      <Text
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'25px'}
                        textAlign={'center'}
                        fontWeight="bold"
                      >
                        Vehicle
                      </Text>
                    </Box>
                    <Grid
                      p={2}
                      fontFamily={'Orbitron'}
                      templateColumns="repeat(4, 1fr)"
                      gap={1}
                      h="80%"
                      mt={2}
                    >
                      <GridItem>
                        <Text fontSize={'15px'} color={'#fff'} opacity={'0.7'}>
                          Vehicle Make:
                        </Text>
                        <Text fontSize={'15px'} color={'#fff'} opacity={'0.7'}>
                          Vin No:
                        </Text>
                        <Text fontSize={'15px'} color={'#fff'} opacity={'0.7'}>
                          Vehicle Model:
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.vehicleId?.make}
                        </Text>
                        <Text
                          fontSize={'12px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.vehicleId?.vinNumber}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.vehicleId?.model}
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          opacity={'0.7'}
                          color={'#fff'}
                        >
                          Sub Model:
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          opacity={'0.7'}
                          color={'#fff'}
                        >
                          Engine Size:
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          opacity={'0.7'}
                          color={'#fff'}
                        >
                          Miles:
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.vehicleId?.subModel}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.vehicleId?.engineSize}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.vehicleId?.miles}
                        </Text>
                      </GridItem>
                    </Grid>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Box
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                    >
                      <Text
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'25px'}
                        textAlign={'center'}
                        fontWeight="bold"
                      >
                        Customer
                      </Text>
                    </Box>
                    <Grid
                      p={2}
                      fontFamily={'Orbitron'}
                      templateColumns="repeat(4, 1fr)"
                      gap={1}
                      h="80%"
                    >
                      <GridItem>
                        <Text fontSize={'15px'} color={'#fff'} opacity={'0.7'}>
                          First Name:
                        </Text>
                        <Text fontSize={'15px'} color={'#fff'} opacity={'0.7'}>
                          Last Name:
                        </Text>
                        <Text fontSize={'15px'} color={'#fff'} opacity={'0.7'}>
                          Address:
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.firstName}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.lastName}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.address}
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                          opacity={'0.7'}
                        >
                          City:
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                          opacity={'0.7'}
                        >
                          Zip Code:
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                          opacity={'0.7'}
                        >
                          Contact:
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                          opacity={'0.7'}
                        >
                          Email:
                        </Text>
                      </GridItem>
                      <GridItem>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.state}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.zipCode}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.number}
                        </Text>
                        <Text
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          {modalData?.customerId?.email}
                        </Text>
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>
                {/*----------------------SERVICE/PARTS AND QTY/PRICE SECTION------------------  */}
                <Grid
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  borderTopRightRadius={'25px'}
                  borderTopLeftRadius={'25px'}
                  h="auto"
                  templateRows="1fr"
                  templateColumns="repeat(12, 1fr)"
                  mt={4}
                >
                  <GridItem colSpan={7}>
                    <Box
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                      borderTopLeftRadius={'25px'}
                    >
                      <Text
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'25px'}
                        textAlign={'center'}
                        fontWeight="bold"
                      >
                        Service/Parts
                      </Text>
                    </Box>
                    <Grid
                      // py={2}
                      fontFamily={'Orbitron'}
                      templateColumns="1fr"
                      gap={2}
                      h="80%"
                      mt={2}
                    >
                      <GridItem w={'100%'} wMax={'100%'}>
                        {modalData?.service.map((service, index) => (
                          <React.Fragment key={index}>
                            <Text
                              // key={service.id._id}
                              p={3}
                              w={'100%'}
                              fontSize={'15px'}
                              textAlign={'left'}
                              color={'#fff'}
                            >
                              {service.id.name}
                            </Text>
                          </React.Fragment>
                        ))}
                        {/* <Text
                          p={3}
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          Body wash & interior
                        </Text>
                        <Divider
                          w={'100%'}
                          borderColor="rgba(255, 255, 255, 0.5)"
                        />
                        <Text
                          p={3}
                          fontSize={'15px'}
                          textAlign={'left'}
                          color={'#fff'}
                        >
                          Civic
                        </Text> */}
                      </GridItem>
                    </Grid>
                  </GridItem>
                  <GridItem
                    colSpan={5}
                    borderLeft={'1px solid rgba(255, 255, 255, 0.5)'}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-around'}
                      // gap={2}
                      h={'37px'}
                      alignItems={'center'}
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                      borderTopRightRadius={'25px'}
                    >
                      <Text
                        py={2}
                        w={'50%'}
                        borderRight={'1px solid rgba(255, 255, 255, 0.5)'}
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'15px'}
                        textAlign={'center'}
                        fontWeight="bold"
                      >
                        Quantity
                      </Text>
                      <Text
                        w={'50%'}
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'15px'}
                        textAlign={'center'}
                        fontWeight="bold"
                      >
                        Price
                      </Text>
                    </Box>
                    <Grid
                      // p={2}
                      fontFamily={'Orbitron'}
                      templateColumns="repeat(2, 1fr)"
                      // gap={2}
                      h="80%"
                    >
                      <GridItem
                        // mt={2}
                        h={'fit-content'}
                        borderRight={'1px solid rgba(255, 255, 255, 0.5)'}
                      >
                        <Box mt={2}>
                          {modalData?.service.map((service, index) => (
                            <React.Fragment key={index}>
                              <Text
                                fontSize={'15px'}
                                textAlign={'left'}
                                color={'#fff'}
                                p={3}
                              >
                                1
                              </Text>
                            </React.Fragment>
                          ))}
                        </Box>
                      </GridItem>
                      <GridItem
                        // mt={2}
                        h={'100%'}
                        borderRight={'1px solid rgba(255, 255, 255, 0.5)'}
                      >
                        <Box mt={2}>
                          {modalData?.service.map((service, index) => (
                            <React.Fragment key={index}>
                              <Text
                                fontSize={'15px'}
                                textAlign={'left'}
                                color={'#fff'}
                                p={3}
                              >
                                ${'  '}
                                {service?.price}
                              </Text>
                            </React.Fragment>
                          ))}
                        </Box>
                      </GridItem>
                    </Grid>
                  </GridItem>
                </Grid>

                {/*----------------------DISCOUNTS/COUPONS AND RECOMMENDED SERVICE SECTION------------------  */}
                <Box
                  mt={4}
                  p={2}
                  w={'100%'}
                  t={4}
                  display={'flex'}
                  templateColumns="repeat(12, 1fr)"
                  // border={'1px solid rgba(255, 255, 255, 0.20)'}
                >
                  <Box
                    w={'58%'}
                    mt={6}
                    m={2}
                    border={'1px solid white'}
                    borderTopLeftRadius={'25px'}
                    borderTopRightRadius={'25px'}
                  >
                    <Box
                      borderTopLeftRadius={'25px'}
                      borderTopRightRadius={'25px'}
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                    >
                      <Text
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'25px'}
                        textAlign={'center'}
                        fontWeight="bold"
                      >
                        Recommended Services List
                      </Text>
                    </Box>
                    <Grid
                      // py={2}
                      fontFamily={'Orbitron'}
                      templateColumns="1fr"
                      gap={2}
                      h="80%"
                      mt={2}
                    >
                      <GridItem w={'100%'} wMax={'100%'}>
                        {modalData?.jobId?.recommendedService?.length > 0 ? (
                          modalData?.jobId?.recommendedService?.map(
                            (service, index) => (
                              <React.Fragment key={index}>
                                <Text
                                  p={3}
                                  w={'100%'}
                                  fontSize={'15px'}
                                  textAlign={'left'}
                                  color={'#fff'}
                                >
                                  {service?.name}
                                </Text>
                                <Divider borderColor="rgba(255, 255, 255, 0.5)" />
                              </React.Fragment>
                            )
                          )
                        ) : (
                          <Text
                            p={3}
                            w={'100%'}
                            fontSize={'15px'}
                            textAlign={'left'}
                            color={'#fff'}
                          >
                            No services recommended.
                          </Text>
                        )}
                      </GridItem>
                    </Grid>
                  </Box>

                  <Box w={'42%'}>
                    <VStack
                      // divider={<StackDivider borderColor="gray.200" />}
                      spacing={2}
                      align="stretch"
                    >
                      <Box
                        h="55px"
                        w={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        border={'1px solid rgba(255, 255, 255, 0.20)'}
                        fontFamily={'Orbitron'}
                      >
                        <Text
                          w={'50%'}
                          fontSize={'20px'}
                          color={'#fff'}
                          py={3}
                          textAlign={'center'}
                          h={'100%'}
                          borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                        >
                          Sub Total
                        </Text>
                        <Text
                          w={'50%'}
                          fontSize={'18px'}
                          color={'#fff'}
                          textAlign={'center'}
                        >
                          ${modalData?.jobId?.totalPrice}
                        </Text>
                      </Box>
                      <Box
                        h="55px"
                        w={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        border={'1px solid rgba(255, 255, 255, 0.20)'}
                        fontFamily={'Orbitron'}
                      >
                        <Text
                          w={'50%'}
                          fontSize={'20px'}
                          color={'#fff'}
                          py={3}
                          textAlign={'center'}
                          h={'100%'}
                          borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                        >
                          Tax 10%
                        </Text>
                        <Text
                          w={'50%'}
                          fontSize={'18px'}
                          color={'#fff'}
                          textAlign={'center'}
                        >
                          ${(modalData?.jobId?.totalPrice * 0.1).toFixed(2)}
                        </Text>
                      </Box>
                      {modalData?.discount?.find(d => d.type === 'coupon') !==
                        undefined &&
                        modalData?.discount?.find(d => d.type === 'coupon') !==
                          null && (
                          <Box
                            h="55px"
                            w={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            border={'1px solid rgba(255, 255, 255, 0.20)'}
                            fontFamily={'Orbitron'}
                          >
                            <Text
                              w={'50%'}
                              fontSize={'20px'}
                              color={'#fff'}
                              py={3}
                              textAlign={'center'}
                              h={'100%'}
                              borderRight={
                                '1px solid rgba(255, 255, 255, 0.20)'
                              }
                            >
                              Coupon
                            </Text>
                            <Text
                              w={'50%'}
                              fontSize={'18px'}
                              color={'#fff'}
                              textAlign={'center'}
                            >
                              {
                                modalData?.discount?.find(
                                  d => d.type === 'coupon'
                                ).price
                              }
                              %
                            </Text>
                          </Box>
                        )}
                      {modalData?.discount?.find(d => d.type === 'discount') !==
                        undefined &&
                        modalData?.discount?.find(
                          d => d.type === 'discount'
                        ) !== null && (
                          <Box
                            h="55px"
                            w={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            border={'1px solid rgba(255, 255, 255, 0.20)'}
                            fontFamily={'Orbitron'}
                          >
                            <Text
                              w={'50%'}
                              fontSize={'20px'}
                              color={'#fff'}
                              py={3}
                              textAlign={'center'}
                              h={'100%'}
                              borderRight={
                                '1px solid rgba(255, 255, 255, 0.20)'
                              }
                            >
                              Discount
                            </Text>
                            <Text
                              w={'50%'}
                              fontSize={'18px'}
                              color={'#fff'}
                              textAlign={'center'}
                            >
                              {
                                modalData?.discount?.find(
                                  d => d.type === 'discount'
                                ).price
                              }
                              %
                            </Text>
                          </Box>
                        )}
                      <Box
                        h="55px"
                        w={'100%'}
                        display={'flex'}
                        alignItems={'center'}
                        border={'1px solid rgba(255, 255, 255, 0.20)'}
                        fontFamily={'Orbitron'}
                      >
                        <Text
                          w={'50%'}
                          fontSize={'20px'}
                          color={'#fff'}
                          py={3}
                          textAlign={'center'}
                          h={'100%'}
                          borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                        >
                          Total
                        </Text>
                        <Text
                          w={'50%'}
                          fontSize={'18px'}
                          color={'#fff'}
                          textAlign={'center'}
                        >
                          ${modalData?.totalPrice}
                        </Text>
                      </Box>
                    </VStack>
                  </Box>
                </Box>
                {/* <Grid
                  mt={4}
                  templateColumns="repeat(12, 1fr)"
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                >
                  <GridItem colSpan={7}></GridItem>
                  <GridItem colSpan={5}>
                    <GridItem mt={4}>

                    </GridItem>
                    <GridItem mt={4}>d</GridItem>
                  </GridItem>
                </Grid> */}
                {/* <HStack justifyContent="space-between"> */}
                {/* <VStack align="start" color={'#FFFFFF'}>
                    <Text fontWeight="bold">Vehicle</Text>
                    <Text>Vehicle Make: {modalData?.vehicleId?.make}</Text>
                    <Text>Model: {modalData?.vehicleId?.model}</Text>
                    <Text>Sub Model: {modalData?.vehicleId?.subModel}</Text>
                    <Text>Engine Size: {modalData?.vehicleId?.engineSize}</Text>
                    <Text>Miles: {modalData?.vehicleId?.miles}</Text>
                  </VStack> */}
                {/* <VStack align="start" color={'#FFFFFF'}>
                    <Text fontWeight="bold">Customer</Text>
                    <Text>First Name: {modalData?.customerId?.firstName}</Text>
                    <Text>Last Name: {modalData?.customerId?.lastName}</Text>
                    <Text>Address: {modalData?.customerId?.address}</Text>
                    <Text>City: {modalData?.customerId?.state}</Text>
                    <Text>Zip Code: {modalData?.customerId?.zipCode}</Text>
                    <Text>Contact: {modalData?.customerId?.number}</Text>
                    <Text>Email: {modalData?.customerId?.email}</Text>
                  </VStack> */}
                {/* </HStack> */}
              </Box>

              {/* <Table className="jobin m6-h p2">
                <Thead
                  bg={
                    'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                  }
                >
                  <Tr>
                    <Th>
                      <Heading as={'h5'}>Service/Part</Heading>
                    </Th>
                    <Th>
                      <Heading as={'h5'}>Quantity</Heading>
                    </Th>
                    <Th>
                      <Heading as={'h5'}>Price</Heading>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {modalData?.service.map(service => (
                    <Tr key={service.id._id}>
                      <Td>
                        <Text>{service.id.name}</Text>
                      </Td>
                      <Td>
                        <Text>{service.qty}</Text>
                      </Td>
                      <Td>
                        <Text>Rs. {service.price}</Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table> */}
            </ModalBody>
            <ModalFooter>
              <Button
                padding={'10px 50px'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                mr={2}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                onClick={() => handlePrint2(modalData?._id)}
              >
                Print
              </Button>
              <Button
                padding={'10px 50px'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                onClick={() => handleDownload()}
              >
                Download
              </Button>
              {/* <Button
                padding={'10px 50px'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                textAlign={'center'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                onClick={onClose}
              >
                Close
              </Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </>
  );
}
