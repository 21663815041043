import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import InvDashboard from '../../components/Inventory/InvDashboard';

export default function Inventory() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <InvDashboard />
        </Stack>
      </MainDashboard>
    </>
  );
}
