import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import UserDetail from '../../components/Reports/UserDetail';
export default function UserReport() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <UserDetail />
        </Stack>
      </MainDashboard>
    </>
  );
}
