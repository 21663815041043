import React from 'react'
import MainDashboard from '../../../components/DashNav/MainDashboard'
import { Stack } from '@chakra-ui/react'
import Offers from '../../../components/csr/Offers/Offers'

export default function OurOffers() {
  return (
    <>
    <MainDashboard>
        <Stack px={4}>
         <Offers/>
        </Stack>
      </MainDashboard>
    </>
  )
}
