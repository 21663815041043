import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  Switch,
  Select,
  Toast,
  Button,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { GET, POST } from '../../utilities/ApiProvider';

export default function NewEmp() {
  const user = useSelector(state => state.value);
  const navigate = useNavigate();
  const toast = useToast();
  const [empfield, setempfield] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addemp, setaddemp] = useState({
    fullName: '',
    email: '',
    phone: '',
    profile_picture: '',
    employeeType: '',
  });
  const [customPrivileges, setCustomPrivileges] = useState([]);
  const [actionSelectedItems, setActionSelectedItems] = useState([]);
  const generalRoles = [
    'Home',
    'Help',
    'Settings',
    'Attendance',
    'List of Jobs',
  ];

  const totalRoles = [
    {
      name: 'csr',
      basicPriv: ['Jobs', 'Services', 'Create New Job', 'POS Cash Drawer'],
    },
    {
      name: 'manager',
      basicPriv: [
        'Jobs',
        'Services',
        'Coupons',
        'Create New Job',
        'Employees',
        'Management Reports',
        'POS Cash Drawer',
        'Offer & Discount',
      ],
    },
    {
      name: 'technician',
      basicPriv: ['Create New Job'],
    },
  ];

  // const customPrivellages = [
  //   {
  //     name: 'Coupons',
  //     _id: '',
  //     actions: ['create', 'read', 'update', 'delete'],
  //   },
  //   {
  //     name: 'Jobs',
  //     _id: '',
  //     actions: ['create', 'read', 'update', 'delete'],
  //   },
  // ];

  // useEffect(() => {
  //   if (user) {
  //     getprivilege();
  //   }
  // }, [user]);

  const getprivilege = async e => {
    console.log('Services Hit');
    setaddemp({ ...addemp, employeeType: e.target.value });
    let empprivilege = await GET(
      `/roles/getPermissionByRole/${e.target.value}`,
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    if (empprivilege?.status === 'success') {
      if (e.target.value === 'technician') {
        setempfield(empprivilege?.data.filter(e => e.module !== 'Jobs'));
      } else {
        setempfield(empprivilege?.data);
      }
      console.log('EMP DATA', empprivilege?.data);

      const selectedRole = totalRoles.find(
        role => role.name === e.target.value
      );
      console.log(selectedRole, 'Selected');

      if (selectedRole) {
        const matchingPrivileges = empprivilege?.data
          .filter(priv => selectedRole.basicPriv.includes(priv.module))
          .map(priv => ({
            name: priv.module,
            id: priv._id,
            url: priv.url,
            action: ['create', 'edit', 'delete', 'view'],
          }));
        console.log('MATCHING', matchingPrivileges);

        setCustomPrivileges(matchingPrivileges);
      }
    }
  };
  // console.log(empfield, 'émployee field');

  function removeActionSelectedItem(givenItem) {
    if (actionSelectedItems.includes(givenItem)) {
      const newArray = actionSelectedItems.filter(
        (item, index) => item !== givenItem
      );
      setActionSelectedItems(newArray);
    } else {
      setActionSelectedItems([...actionSelectedItems, givenItem]);
    }
    console.log(givenItem);
  }

  const EmpSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    Object.keys(addemp).forEach(key => {
      formData.append(key, addemp[key]);
    });
    formData.append('privilege', JSON.stringify(customPrivileges));

    try {
      let res = await POST(`/employees/createEmployees`, formData, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      console.log(res);

      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        duration: 3000,
      });
      if (res.status === 'success') {
        navigate('/employees ');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = (module, action) => {
    setCustomPrivileges(prevState => {
      const index = prevState.findIndex(item => item.name === module.module);
      if (index !== -1) {
        const newActions = prevState[index].action?.includes(action)
          ? prevState[index].action.filter(a => a !== action)
          : [...(prevState[index].action || []), action];
        if (newActions.length === 0) {
          return [
            ...prevState.slice(0, index),
            ...prevState.slice(index + 1),
          ];
        } else {
          return [
            ...prevState.slice(0, index),
            { ...prevState[index], action: newActions },
            ...prevState.slice(index + 1),
          ];
        }
      } else {
        return [
          ...prevState,
          {
            name: module.module,
            id: module._id,
            action: [action],
            url: module.url,
          },
        ];
      }
    });
  };

  const isPrivilegeSelected = (module, action) => {
    return customPrivileges.some(
      priv => priv.name === module && priv.action.includes(action)
    );
  };

  useEffect(() => {
    console.log('Cusome', customPrivileges);
  }, [customPrivileges, setCustomPrivileges]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Add New Employee /{' '}
            <Link to={'/employees'} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
        <SimpleGrid
          borderBottom={'1px solid #595C64'}
          pb={8}
          columns={{
            base: '1',
            sm: '1',
            md: '1',
            lg: '1',
            xl: '2',
            '2xl': '2',
          }}
          spacingX="40px"
          spacingY="20px"
        >
          <Stack className="adser" gap={6}>
            <Stack className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Enter Employee Name
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="text"
                  value={addemp.fullName}
                  onChange={e =>
                    setaddemp({ ...addemp, fullName: e.target.value })
                  }
                  placeholder="Enter  Name "
                />
              </Box>
            </Stack>

            <Stack className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Email Address
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="email"
                  value={addemp.email}
                  onChange={e =>
                    setaddemp({ ...addemp, email: e.target.value })
                  }
                  placeholder="Enter Your Email "
                />
              </Box>
            </Stack>
            <Stack className="Ifiled label">
              <FormLabel
                fontWeight={400}
                m={0}
                color={'#949494'}
                fontSize={'14px'}
              >
                Contact Number
              </FormLabel>
              <Box className="Ifiled">
                <Input
                  type="number"
                  value={addemp.phone}
                  onChange={e =>
                    setaddemp({ ...addemp, phone: e.target.value })
                  }
                  placeholder="Enter Your Phone "
                />
              </Box>
            </Stack>
            <Stack className="Ifiled icnfiled">
              <Select
                color={'#fff'}
                value={addemp.employeeType}
                onChange={e => getprivilege(e)}
              >
                <option style={{ display: 'none' }} value="">
                  Select Option
                </option>
                <option value="manager">Manager</option>
                <option value="technician">Technician</option>
                <option value="csr">
                  Customer service representative (CSR){' '}
                </option>
              </Select>
            </Stack>
          </Stack>
          <Stack className="Ifiled label">
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Upload Picture*
            </FormLabel>
            <Box className="ufiled">
              <Input
                type="file"
                onChange={e =>
                  setaddemp({ ...addemp, profile_picture: e.target.files[0] })
                }
              />
            </Box>
          </Stack>
        </SimpleGrid>
        <Stack py={4}>
          <Box mb={4} className="p1 ">
            <Text fontSize={'18px'}>All Privilege's</Text>
          </Box>
          {/* <Stack direction={'row'} gap={'12'} flexWrap={'wrap'}>
            {empfield?.length > 0 &&
              empfield
                .filter(emp => !generalRoles.includes(emp.module))
                .map((v, e) => {
                  return (
                    <Stack
                      border={'1px solid #595C64'}
                      borderRadius={8}
                      padding={4}
                      w={'40%'}
                      direction={'row'}
                      justifyContent={'space-between'}
                      className="p1"
                      key={e}
                    >
                      <Stack>
                        <Text textTransform={'capitalize'}>{v.module}</Text>
                      </Stack>
                      {v.actions.length > 0 ? (
                        <Accordion allowToggle>
                          <AccordionItem>
                            <AccordionButton outline={'none'}>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              {v.actions.map((action, index) => (
                                <Stack
                                  key={index}
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Stack>
                                    <Text textTransform={'capitalize'}>
                                      {action}
                                    </Text>
                                  </Stack>
                                  <Checkbox
                                    value={action}
                                    onChange={e =>
                                      removeActionSelectedItem(e.target.value)
                                    }
                                    colorScheme="green"
                                  >
                                    {action}
                                  </Checkbox>
                                </Stack>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      ) : (
                        <Checkbox
                          value={v._id}
                          onChange={e =>
                            removeActionSelectedItem(e.target.value)
                          }
                          colorScheme="green"
                        >
                          Checkbox
                        </Checkbox>
                      )}
                    </Stack>
                  );
                })}
          </Stack> */}
          <SimpleGrid columns={3} spacing={10}>
            {empfield?.length > 0 &&
              empfield
                .filter(emp => !generalRoles.includes(emp.module))
                .map((v, e) => {
                  return (
                    <Stack width={'100%'}>
                      <Accordion allowMultiple key={e}>
                        <AccordionItem
                          border={'1px solid #595C64'}
                          borderRadius={8}
                          padding={4}
                          w={'100%'}
                          direction={'row'}
                          justifyContent={'space-between'}
                          className="p1"
                        >
                          <h2>
                            <AccordionButton>
                              <Box as="span" flex="1" textAlign="left">
                                <Text
                                  textTransform={'capitalize'}
                                  fontSize={'18px'}
                                  // style={{ fontSize: '18px' }}
                                >
                                  {v.module}
                                </Text>
                              </Box>
                              <AccordionIcon color={'white'} />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4} w={'100%'}>
                            {v.actions
                              .filter(e => e !== 'list')
                              .map((action, index) => (
                                <Stack
                                  key={index}
                                  direction="row"
                                  alignItems="center"
                                  gap={4}
                                  flexWrap={'wrap'}
                                  justifyContent="space-between"
                                >
                                  <Text textTransform={'capitalize'} flex={1}>
                                    {action}
                                  </Text>
                                  <Spacer />

                                  <Switch
                                    flex={0}
                                    value={action}
                                    isChecked={isPrivilegeSelected(
                                      v.module,
                                      action
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(v, action)
                                    }
                                    colorScheme="green"
                                  />
                                </Stack>
                              ))}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Stack>
                  );
                })}
          </SimpleGrid>
        </Stack>
        <Stack>
          <Button
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            isLoading={isLoading}
            onClick={EmpSubmit}
          >
            Create & Save
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
