import React from 'react'
import { Stack } from '@chakra-ui/react';

import MainDashboard from '../../../components/DashNav/MainDashboard';
import JobDetails from '../../../components/Technician/Jobs/JobDetails';

export default function JobDetail() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
        <JobDetails/>
        </Stack>
      </MainDashboard>
    </>
  )
}
