import {
  Avatar,
  Box,
  Button,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Link,
  Checkbox,
  UnorderedList,
  ListItem,
  Spinner,
  useToast,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { POST } from '../../utilities/ApiProvider.js';
import { useNavigate } from 'react-router-dom';
import { ImgUrl } from '../../utilities/config.js';

export default function EmployesList() {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => state.value);
  const [filterButtons, setFilterButtons] = useState([]);
  const [services, setServices] = useState(null);
  const [selectedCat, setSelectedCat] = useState('CSR');
  const [selectedData, setSelectedData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (user) {
      getUserFields();
    }
  }, [user]);

  const getUserFields = async () => {
    setIsLoading(true);
    console.log('Services Hit');
    let userFieldsData = await POST(
      `/employees/getAllEmployees`,
      {},
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log(userFieldsData);
    setServices(userFieldsData?.data);
    setSelectedData(userFieldsData?.data['CSR']);
    setFilterButtons(Object.keys(userFieldsData?.data));
    setIsLoading(false);
  };

  useEffect(() => {
    if (services) {
      setSelectedData(services[`${selectedCat}`]);
    }
  }, [selectedCat]);

  const filteredData = selectedData?.filter(val =>
    val?.fullName.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>View All Employees</Heading>
        </Stack>
        <Stack alignItems={{ base: 'start', xl: 'end' }}>
          {user?.role !== 'admin' && (
          <Link
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/addemployes'}
            as={ReactLink}
          >
            Add New Employee
          </Link>
          )}
          <Link
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/blockemployee'}
            as={ReactLink}
          >
            Block Employees
          </Link>
        </Stack>
      </SimpleGrid>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack>
          <UnorderedList gap={6} display={'flex'} py={5} listStyleType={'none'}>
            {filterButtons?.length > 0 &&
              filterButtons.map((val, ind) => (
                <ListItem key={ind}>
                  <Button
                    onClick={() => setSelectedCat(val)}
                    padding={'10px 50px'}
                    bg={
                      val === selectedCat
                        ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        : 'transparent'
                    }
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    color={val === selectedCat ? '#fff' : '#7D7D7D'}
                    border={'1px solid #7D7D7D'}
                    borderRadius={12}
                    _hover={{
                      color: '#fff',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                      border: '1px solid #2BAF59',
                    }}
                    textTransform={'capitalize'}
                    w={'fit-content'}
                  >
                    {val}
                  </Button>
                </ListItem>
              ))}
          </UnorderedList>
          <Stack className="list-ser">
            <Stack
              py={6}
              direction={'row'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Stack className="p1">
                <Text>
                  Total {selectedCat}s:{' '}
                  <Text as={'span'} fontWeight={'600'} color={'#2BAF59'}>
                    {filteredData?.length ?? 0}
                  </Text>
                </Text>
              </Stack>
              <Box className="Ifiled">
                <Input
                  value={searchValue}
                  type="text"
                  onChange={e => setSearchValue(e.target.value)}
                  placeholder="Search For Employee"
                />
              </Box>
            </Stack>
            <Stack bg={'#202125'} px={6} py={8} borderRadius={8}>
              <SimpleGrid
                columns={{
                  base: '1',
                  sm: '1',
                  md: '1',
                  lg: '2',
                  xl: '2',
                  '2xl': '2',
                }}
                spacingX="40px"
                spacingY="20px"
              >
                {filteredData?.length > 0 ? (
                  filteredData?.map(val => {
                    return (
                      <Stack
                        key={val?._id}
                        bg={'#2E3035'}
                        px={4}
                        gap={3}
                        py={6}
                        borderRadius={6}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={3}>
                          <Avatar
                            name={val.fullName}
                            src={`${ImgUrl}${val?.profile_picture}`}
                            objectFit={'cover'}
                          />
                          <Box className="venbox p1 m3-h">
                            <Text>Member Name</Text>
                            <Heading as={'h3'}>{val.fullName}</Heading>
                          </Box>
                        </Box>
                        <SimpleGrid
                          columns={{
                            base: '1',
                            sm: '1',
                            md: '1',
                            lg: '1',
                            xl: '2',
                            '2xl': '3',
                          }}
                          spacingX="20px"
                          spacingY="20px"
                        >
                          <Stack className="venbox p1 m6-h">
                            <Text>Member Email</Text>
                            <Heading as={'h5'}>{val.email}</Heading>
                          </Stack>
                          <Stack className="venbox p1 m6-h">
                            <Text>Member Phone</Text>
                            <Heading as={'h5'}>{val.phone}</Heading>
                          </Stack>
                          <Stack className="venbox p1 m3-h">
                            <Text>Member Type</Text>
                            <Heading as={'h3'}>{val.employeeType}</Heading>
                          </Stack>
                        </SimpleGrid>
                        <Button
                          padding={'10px 50px'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          fontWeight={'500'}
                          lineHeight={'inherit'}
                          borderRadius={12}
                          _hover={{
                            color: '#000',
                          }}
                          textAlign={'center'}
                          onClick={() => navigate(`/employedetail/${val._id}`)}
                        >
                          View Detail
                        </Button>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack className={'bread m5-h'}>
                    <Heading as={'h5'}>No Employee Found </Heading>
                  </Stack>
                )}
              </SimpleGrid>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
}
