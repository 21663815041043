import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import ListAttendance from '../../components/Attendance/ListAttendance';



export default function Attendance() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <ListAttendance />
        </Stack>
      </MainDashboard>
    </>
  )
}
