import {
  Avatar,
  AvatarGroup,
  Box,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FiUsers } from 'react-icons/fi';
import { BsHouseDoor } from 'react-icons/bs';
import { RiUserSettingsLine } from 'react-icons/ri';
import { BsBag } from 'react-icons/bs';
import { Link as ReactLink } from 'react-router-dom';

export default function Dashdetails({ownerCount,jobCount,inventoryCount,customerCount}) {
  return (
    <>
      <Box display={'flex'} gap={6} flexWrap={'wrap'}>
        <Stack w={{md : '30%', '2xl' : '35%'}} bg={'#202125'} p={6} borderRadius={8} className="p1">
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Box
              alignItems={'center'}
              gap={2}
              className="dashd m3-h"
              display={'flex'}
            >
              <Icon fontSize={'25px'} color={'#2BAF59'} as={FiUsers} />
              <Heading as={'h3'} >{ownerCount}</Heading>
            </Box>
            {/* <AvatarGroup size="md" max={2}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
            </AvatarGroup> */}
          </Stack>
          <Text fontSize={{md : '13px', '2xl' : '16px'}}>Total Owners</Text>
       
        </Stack>
        <Stack w={'20%'} bg={'#202125'} p={6} borderRadius={8} className="p1">
          <Box
            alignItems={'center'}
            gap={2}
            className="dashd m3-h"
            display={'flex'}
          >
            <Icon fontSize={'25px'} color={'#2BAF59'} as={BsHouseDoor} />
            <Heading as={'h3'}>{inventoryCount}</Heading>
          </Box>
          <Text fontSize={{md : '13px', '2xl' : '16px'}}>Total Parts</Text>
         
        </Stack>
        <Stack w={'20%'}  bg={'#202125'} p={6} borderRadius={8} className="p1">
          <Box 
            alignItems={'center'}
            gap={2}
            className="dashd m3-h"
            display={'flex'}
          >
            <Icon fontSize={'25px'} color={'#2BAF59'} as={RiUserSettingsLine} />
            <Heading as={'h3'}>{customerCount}</Heading>
          </Box>
          <Text fontSize={{md : '13px', '2xl' : '16px'}}>Existing Customers</Text>
          
        </Stack>
        <Stack w={'20%'}  bg={'#202125'} p={6} borderRadius={8} className="p1">
          <Box
            alignItems={'center'}
            gap={2}
            className="dashd m3-h"
            display={'flex'}
          >
            <Icon fontSize={'25px'} color={'#2BAF59'} as={BsBag} />
            <Heading as={'h3'}>{jobCount}</Heading>
          </Box>
          <Text fontSize={{md : '13px', '2xl' : '16px'}}>Total List of jobs</Text>
         
        </Stack>
      </Box>
    </>
  );
}
