import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import HelpWrap from '../../components/Setting/HelpWrap';



export default function Setting() {
  return (
    <>
    <MainDashboard>
      <Stack px={4}>
      <HelpWrap/>
      </Stack>
    </MainDashboard>
  </>
  )
}
