import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tab,
  Table,
  TableCaption,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Toast,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import html2pdf from 'html2pdf.js';

import React, { useEffect, useState } from 'react';
import { BsBag, BsHouseDoor } from 'react-icons/bs';
import { FiUsers } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';
import { Link as ReactLink } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { GET, POST } from '../../utilities/ApiProvider';
import { useSelector } from 'react-redux';
import { ViewIcon } from '@chakra-ui/icons';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = () => {
  const user = useSelector(state => state?.value);
  const [chartData, setChartData] = useState(null);
  const [cashDrawerData, setCashDrawerData] = useState(null);
  const {
    isOpen: isReportOpen,
    onOpen: onReportOpen,
    onClose: onReportClose,
  } = useDisclosure();
  const {
    isOpen: isSalesReportOpen,
    onOpen: onSalesReportOpen,
    onClose: onSalesReportClose,
  } = useDisclosure();
  const {
    isOpen: isSingleSalesReportOpen,
    onOpen: onSingleSalesReportOpen,
    onClose: onSingleSalesReportClose,
  } = useDisclosure();
  const {
    isOpen: isCustomersReportOpen,
    onOpen: onCustomersReportOpen,
    onClose: onCustomersReportClose,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const [dates, setDates] = useState({ from: '', to: '' });
  const [datesSales, setDatesSales] = useState({ from: '', to: '' });
  const [datesCustomers, setDatesCustomers] = useState({ from: '', to: '' });
  const [salesReportData, setSalesReportData] = useState([]);
  const [singleSaleReport, setSingleSaleReport] = useState([]);
  const [customersReportData, setCustomersReportData] = useState([]);
  const handleDateChange = e => {
    const { name, value } = e.target;
    setDates(prevDates => ({ ...prevDates, [name]: value }));
  };
  const handleSalesDateChange = e => {
    const { name, value } = e.target;
    setDatesSales(prevDates => ({ ...prevDates, [name]: value }));
  };
  const handleCustomersDateChange = e => {
    const { name, value } = e.target;
    setDatesCustomers(prevDates => ({ ...prevDates, [name]: value }));
    // onCustomersReportOpen();
  };

  const handleSearchReport = async () => {
    // console.log('sales', datesSales);
    try {
      let res = await GET(
        `/invoice/searchbyDate?from=${datesSales.from}&to=${datesSales.to}`,
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        // console.log('Response received:', res);
        setSalesReportData(res?.data);
      } else {
        console.error('Unexpected response:', res);
      }
    } catch (err) {
      console.error('Error occurred:', err);
      Toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(true);

    onSalesReportOpen();
  };
  const handleCustomerSearchReport = async () => {
    // console.log('customers', datesCustomers);
    // console.log('sales', datesSales);
    try {
      let res = await GET(
        `/reports/customersData?from=${datesCustomers.from}&to=${datesCustomers.to}`,
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        // console.log('Response received:', res);
        setCustomersReportData(res?.data);
      } else {
        console.error('Unexpected response:', res);
      }
    } catch (err) {
      console.error('Error occurred:', err);
      Toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(true);

    onCustomersReportOpen();
  };
  console.log(customersReportData, 'customersReportData');
  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let res = await GET(
        `/cashDrawer/getdrawerbydate?from=${dates.from}&to=${dates.to}`,
        {
          authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (res?.status === 'success') {
        // console.log('Response received:', res);
        setCashDrawerData(res?.data);
      } else {
        console.error('Unexpected response:', res);
      }
    } catch (err) {
      console.error('Error occurred:', err);
      Toast({
        description: err.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
    }
    setIsLoading(false);
    onReportOpen();
    // setTimeout(() => {
    // }, 000);
  };
  // console.log(salesReportData?.service, 'Repirt Data..........');
  // console.log(salesReportData, 'XXXXXXXXXXXXXXXx Data..........');

  const handleOpenSaleReport = item => {
    console.log(item, 'OOOOOOOOOOOOOOOOOOOOOOOOOOOO');
    setSingleSaleReport(item);
    onSingleSalesReportOpen();
  };
  // console.log(singleSaleReport, 'ÇASH-drawerpppppppppppp');
  const handleDownloadSalesReport = item => {
    // console.log(item, 'ÍIITTTEEEMMMMM');
    onSalesReportOpen();
  };
  // console.log(cashDrawerData, 'ÇASH-drawerpppppppppppp');
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sales Report Chart',
      },
    },
  };

  const weeklyLabels = ['MON', 'TUE', 'WED', 'THURS', 'FRI', 'SAT', 'SUN'];
  const monthlyLabels = ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4'];

  const data = {
    labels: weeklyLabels,
    datasets: [
      {
        label: 'Daily Data',
        data: chartData
          ? chartData?.weeklySalesdata
          : [200, 450, 600, 800, 1000, 700, 500],
        backgroundColor: '#88B899',
      },
    ],
  };

  const monthlyData = {
    labels: monthlyLabels,
    datasets: [
      {
        label: 'Weekly Data',
        data: chartData ? chartData?.monthlySalesData : [400, 200, 300, 250],
        backgroundColor: '#88B899',
      },
    ],
  };

  const doughnutData = {
    labels: ['Existing Customers', 'New Customers'],
    datasets: [
      {
        label: '% of customers',
        data: chartData ? chartData?.CustomersWeekly : [67, 21],
        backgroundColor: ['#FF7E00', '#F82A2A'],
        borderColor: ['#FF7E00', '#F82A2A'],
        borderWidth: 1,
      },
    ],
  };

  const doughnutMonthlyData = {
    labels: ['Existing Customers', 'New Customers'],
    datasets: [
      {
        label: '% of customers',
        data: chartData ? chartData?.CustomersMonthly : [58, 42],
        backgroundColor: ['#FF7E00', '#F82A2A'],
        borderColor: ['#FF7E00', '#F82A2A'],
        borderWidth: 1,
      },
    ],
  };

  const getChartData = async () => {
    try {
      let response = await POST(
        `/reports/salesChart`,
        {},
        { authorization: `Bearer ${user?.verificationToken}` }
      );
      if (response?.status === 200) {
        console.log('Data Chart', response?.data);
        setChartData(response?.data);
        // console.log('Data Chart', response?.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  function numberWithCommas(num) {
    return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    if (user) {
      getChartData();
    }
    // console.log('Use Chart', chartData);
  }, [user]);

  const handlePrint = () => {
    const element = document.getElementById('modal-content');
    const printWindow = window.open('', '_blank');

    printWindow.document.write(`
    <html>
      <head>
        <title>Print</title>
        <style>
          @media print {
            @page { size: A4; }
            body { margin: 0; padding: 20px; }
          }
        </style>
      </head>
      <body>${element.innerHTML}</body>
    </html>
  `);

    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  // Inside your component
  const handleDownload = () => {
    const element = document.getElementById('modal-content');
    if (!element) {
      console.error('Element not found');
      return;
    }

    const options = {
      margin: 0.5,
      filename: 'cash_drawer_report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: {
        unit: 'in',
        orientation: 'portrait',
        format: 'a4',
        putOnlyUsedFonts: true,
        floatPrecision: 16,
      },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .catch(err => console.error('Error generating PDF:', err));
  };

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>Reports </Heading>
        </Stack>
        <Stack alignItems={{ base: 'start', xl: 'end' }}>
          <Link
            padding={'10px 50px'}
            bg={
              'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            _hover={{
              color: '#000',
            }}
            w={'fit-content'}
            to={'/managereports'}
            as={ReactLink}
          >
            Management Reports
          </Link>
        </Stack>
      </SimpleGrid>
      <Stack gap={4} py={5}>
        <Box display={'flex'} gap={6} flexWrap={'wrap'}>
          <Stack
            w={{ md: '35%', '2xl': '35%' }}
            bg={'#202125'}
            p={6}
            borderRadius={8}
            className="p1"
          >
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Box
                alignItems={'center'}
                gap={2}
                className="dashd m3-h"
                display={'flex'}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="22"
                  viewBox="0 0 18 22"
                  fill="none"
                >
                  <path
                    d="M5.57143 3.36871H3.28571C2.67951 3.36871 2.09812 3.60283 1.66947 4.01958C1.24082 4.43633 1 5.00156 1 5.59093V18.9243C1 19.5136 1.24082 20.0789 1.66947 20.4956C2.09812 20.9124 2.67951 21.1465 3.28571 21.1465H14.7143C15.3205 21.1465 15.9019 20.9124 16.3305 20.4956C16.7592 20.0789 17 19.5136 17 18.9243V5.59093C17 5.00156 16.7592 4.43633 16.3305 4.01958C15.9019 3.60283 15.3205 3.36871 14.7143 3.36871H12.4286M5.57143 3.36871C5.57143 2.77934 5.81224 2.21411 6.2409 1.79736C6.66955 1.38061 7.25093 1.14648 7.85714 1.14648H10.1429C10.7491 1.14648 11.3304 1.38061 11.7591 1.79736C12.1878 2.21411 12.4286 2.77934 12.4286 3.36871M5.57143 3.36871C5.57143 3.95808 5.81224 4.52331 6.2409 4.94006C6.66955 5.3568 7.25093 5.59093 7.85714 5.59093H10.1429C10.7491 5.59093 11.3304 5.3568 11.7591 4.94006C12.1878 4.52331 12.4286 3.95808 12.4286 3.36871M5.57143 16.702V11.1465M9 16.702V15.5909M12.4286 16.702V13.3687"
                    stroke="#2BAF59"
                    stroke-width="1.51"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Heading as={'h3'}>
                  {numberWithCommas(chartData?.cashDrawer)}
                </Heading>
              </Box>
            </Stack>
            <Stack
              direction={'row'}
              whiteSpace={'nowrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
              gap={'10px'}
            >
              <Text fontSize={{ md: '13px', '2xl': '16px' }} fontWeight={'500'}>
                Cash Drawer Sheet
              </Text>
              {/* <Button
                variant={'outline'}
                color={'rgba(255, 255, 255, 0.60)'}
                padding={'10px 14px'}
                _hover={{
                  color: 'rgba(255, 255, 255, 0.80)',
                }}
              >
                Download Report
              </Button> */}
            </Stack>
          </Stack>
          <Stack w={'20%'} bg={'#202125'} p={6} borderRadius={8} className="p1">
            <Box
              alignItems={'center'}
              gap={2}
              className="dashd m3-h"
              display={'flex'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="22"
                viewBox="0 0 18 22"
                fill="none"
              >
                <path
                  d="M5.57143 3.36871H3.28571C2.67951 3.36871 2.09812 3.60283 1.66947 4.01958C1.24082 4.43633 1 5.00156 1 5.59093V18.9243C1 19.5136 1.24082 20.0789 1.66947 20.4956C2.09812 20.9124 2.67951 21.1465 3.28571 21.1465H14.7143C15.3205 21.1465 15.9019 20.9124 16.3305 20.4956C16.7592 20.0789 17 19.5136 17 18.9243V5.59093C17 5.00156 16.7592 4.43633 16.3305 4.01958C15.9019 3.60283 15.3205 3.36871 14.7143 3.36871H12.4286M5.57143 3.36871C5.57143 2.77934 5.81224 2.21411 6.2409 1.79736C6.66955 1.38061 7.25093 1.14648 7.85714 1.14648H10.1429C10.7491 1.14648 11.3304 1.38061 11.7591 1.79736C12.1878 2.21411 12.4286 2.77934 12.4286 3.36871M5.57143 3.36871C5.57143 3.95808 5.81224 4.52331 6.2409 4.94006C6.66955 5.3568 7.25093 5.59093 7.85714 5.59093H10.1429C10.7491 5.59093 11.3304 5.3568 11.7591 4.94006C12.1878 4.52331 12.4286 3.95808 12.4286 3.36871M5.57143 16.702V11.1465M9 16.702V15.5909M12.4286 16.702V13.3687"
                  stroke="#2BAF59"
                  stroke-width="1.51"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Heading as={'h3'}>{numberWithCommas(chartData?.sales)}</Heading>
            </Box>
            <Text fontSize={{ md: '13px', '2xl': '16px' }}>Total Sales</Text>
          </Stack>
          <Stack w={'20%'} bg={'#202125'} p={6} borderRadius={8} className="p1">
            <Box
              alignItems={'center'}
              gap={2}
              className="dashd m3-h"
              display={'flex'}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="22"
                viewBox="0 0 18 22"
                fill="none"
              >
                <path
                  d="M5.57143 3.36871H3.28571C2.67951 3.36871 2.09812 3.60283 1.66947 4.01958C1.24082 4.43633 1 5.00156 1 5.59093V18.9243C1 19.5136 1.24082 20.0789 1.66947 20.4956C2.09812 20.9124 2.67951 21.1465 3.28571 21.1465H14.7143C15.3205 21.1465 15.9019 20.9124 16.3305 20.4956C16.7592 20.0789 17 19.5136 17 18.9243V5.59093C17 5.00156 16.7592 4.43633 16.3305 4.01958C15.9019 3.60283 15.3205 3.36871 14.7143 3.36871H12.4286M5.57143 3.36871C5.57143 2.77934 5.81224 2.21411 6.2409 1.79736C6.66955 1.38061 7.25093 1.14648 7.85714 1.14648H10.1429C10.7491 1.14648 11.3304 1.38061 11.7591 1.79736C12.1878 2.21411 12.4286 2.77934 12.4286 3.36871M5.57143 3.36871C5.57143 3.95808 5.81224 4.52331 6.2409 4.94006C6.66955 5.3568 7.25093 5.59093 7.85714 5.59093H10.1429C10.7491 5.59093 11.3304 5.3568 11.7591 4.94006C12.1878 4.52331 12.4286 3.95808 12.4286 3.36871M5.57143 16.702V11.1465M9 16.702V15.5909M12.4286 16.702V13.3687"
                  stroke="#2BAF59"
                  stroke-width="1.51"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <Heading as={'h3'}>{numberWithCommas(chartData?.Fleet)}</Heading>
            </Box>
            <Text fontSize={{ md: '13px', '2xl': '16px' }}>
              Total Fleet Sales
            </Text>
          </Stack>
        </Box>
      </Stack>
      <SimpleGrid
        columns={{
          base: '2',
          sm: '1',
          md: '2',
          lg: '2',
          xl: '2',
          '2xl': '2',
        }}
        spacingX="40px"
        spacingY="20px"
      >
        {/* ------------SALES REPORT SECTION START----------------- */}
        <Box height={'auto'}>
          <Stack padding={4}>
            <Tabs variant="solid-rounded" colorScheme="green">
              <Stack
                height={'40px'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Heading as={'h3'} color={'#fff'}>
                  Sales Report
                </Heading>
                <TabList>
                  <Tab>1 Week</Tab>
                  <Tab>1 Month</Tab>
                </TabList>
              </Stack>
              <Box
                mt={5}
                w={'100%'}
                // border={'1px solid white'}
                display={'flex'}
                justifyContent={'flex-start'}
              >
                <Stack
                  w={'90%'}
                  display={'flex'}
                  direction={'row'}
                  gap={3}
                  alignItems={'end'}
                  justifyContent={'space-between'}
                >
                  <Box color={'#fff'} w={'50%'}>
                    <FormLabel
                      fontWeight={400}
                      m={0}
                      color={'#949494'}
                      fontSize={'14px'}
                      pb={2}
                    >
                      From
                    </FormLabel>
                    <Input
                      w={'100%'}
                      type="date"
                      name="from"
                      value={datesSales.from}
                      onChange={handleSalesDateChange}
                    />
                  </Box>
                  <Box color={'#fff'} w={'50%'}>
                    <FormLabel
                      fontWeight={400}
                      m={0}
                      color={'#949494'}
                      fontSize={'14px'}
                      pb={2}
                    >
                      To
                    </FormLabel>
                    <Input
                      type="date"
                      name="to"
                      value={datesSales.to}
                      onChange={handleSalesDateChange}
                    />
                  </Box>
                  <Box>
                    <Button
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      onClick={() => handleSearchReport()}
                    >
                      Search
                    </Button>
                  </Box>
                </Stack>
              </Box>
              <Stack py={2}>
                <TabPanels>
                  <TabPanel bg={'#202125'} rounded={'md'} mt={2}>
                    <Stack
                      py={4}
                      height={'284px'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Bar options={options} data={data} />
                    </Stack>
                    {/* <Button
                      variant={'outline'}
                      color={'rgba(255, 255, 255, 0.60)'}
                      padding={'10px 14px'}
                      width={'full'}
                      _hover={{
                        color: 'rgba(255, 255, 255, 0.80)',
                      }}
                      onClick={() => handleDownloadSalesReport('sales-weekly')}
                    >
                      Download Report
                    </Button> */}
                  </TabPanel>
                  <TabPanel bg={'#202125'} rounded={'md'} mt={2}>
                    <Stack
                      py={4}
                      height={'284px'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Bar options={options} data={monthlyData} />
                    </Stack>
                    {/* <Button
                      variant={'outline'}
                      color={'rgba(255, 255, 255, 0.60)'}
                      padding={'10px 14px'}
                      width={'full'}
                      _hover={{
                        color: 'rgba(255, 255, 255, 0.80)',
                      }}
                      onClick={() => handleDownloadSalesReport('sales-monthly')}
                    >
                      Download Report
                    </Button> */}
                  </TabPanel>
                </TabPanels>
              </Stack>
            </Tabs>
          </Stack>
        </Box>
        {/* ------------SALES REPORT SECTION END----------------- */}
        {/* ------------CUSTOMERS SALES REPORT SECTION------------- */}
        <Box height={'auto'}>
          <Stack padding={4} height={'auto'}>
            <Tabs variant="solid-rounded" colorScheme="green">
              <Stack
                height={'40px'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Heading as={'h3'} color={'#fff'}>
                  Customers
                </Heading>
                <TabList>
                  <Tab>1 Week</Tab>
                  <Tab>1 Month</Tab>
                </TabList>
              </Stack>
              <Box
                mt={5}
                w={'100%'}
                // border={'1px solid white'}
                display={'flex'}
                justifyContent={'flex-start'}
              >
                <Stack
                  w={'90%'}
                  display={'flex'}
                  direction={'row'}
                  gap={3}
                  alignItems={'end'}
                  justifyContent={'space-between'}
                >
                  <Box color={'#fff'} w={'50%'}>
                    <FormLabel
                      fontWeight={400}
                      m={0}
                      color={'#949494'}
                      fontSize={'14px'}
                      pb={2}
                    >
                      From
                    </FormLabel>
                    <Input
                      w={'100%'}
                      type="date"
                      name="from"
                      value={datesCustomers.from}
                      onChange={handleCustomersDateChange}
                    />
                  </Box>
                  <Box color={'#fff'} w={'50%'}>
                    <FormLabel
                      fontWeight={400}
                      m={0}
                      color={'#949494'}
                      fontSize={'14px'}
                      pb={2}
                    >
                      To
                    </FormLabel>
                    <Input
                      type="date"
                      name="to"
                      value={datesCustomers.to}
                      onChange={handleCustomersDateChange}
                    />
                  </Box>
                  <Box>
                    <Button
                      color={'#fff'}
                      fontWeight={'500'}
                      lineHeight={'inherit'}
                      borderRadius={12}
                      _hover={{
                        color: '#000',
                      }}
                      w={'fit-content'}
                      bg={
                        'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                      }
                      onClick={() => handleCustomerSearchReport()}
                    >
                      Search
                    </Button>
                  </Box>
                </Stack>
              </Box>
              <Stack py={2}>
                <TabPanels>
                  <TabPanel bg={'#202125'} rounded={'md'} mt={2}>
                    <Stack
                      py={4}
                      height={'284px'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Doughnut data={doughnutData} />
                    </Stack>
                    {/* <Button
                      variant={'outline'}
                      color={'rgba(255, 255, 255, 0.60)'}
                      padding={'10px 14px'}
                      width={'full'}
                      _hover={{
                        color: 'rgba(255, 255, 255, 0.80)',
                      }}
                      onClick={() =>
                        handleDownloadSalesReport('customers-weekly')
                      }
                    >
                      Download Report
                    </Button> */}
                  </TabPanel>
                  <TabPanel bg={'#202125'} rounded={'md'} mt={2}>
                    <Stack
                      py={4}
                      height={'284px'}
                      display={'flex'}
                      alignItems={'center'}
                    >
                      <Doughnut data={doughnutMonthlyData} />
                    </Stack>
                    {/* <Button
                      variant={'outline'}
                      color={'rgba(255, 255, 255, 0.60)'}
                      padding={'10px 14px'}
                      width={'full'}
                      _hover={{
                        color: 'rgba(255, 255, 255, 0.80)',
                      }}
                      onClick={() =>
                        handleDownloadSalesReport('customers-monthly')
                      }
                    >
                      Download Report
                    </Button> */}
                  </TabPanel>
                </TabPanels>
              </Stack>
            </Tabs>
          </Stack>
        </Box>
        {/* ------------CUSTOMERS SALES REPORT SECTION END------------- */}
      </SimpleGrid>
      <Stack gap={4} py={5}>
        <SimpleGrid
          columns={{
            base: '2',
            sm: '1',
            md: '2',
            lg: '2',
            xl: '2',
            '2xl': '2',
          }}
          spacingX="40px"
          spacingY="20px"
        >
          <Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Heading as={'h3'} color={'#fff'}>
                Cash Drawer Sheet
              </Heading>
              <Text
                padding={'10px 14px'}
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                w={'fit-content'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
              >
                Today's
              </Text>
            </Stack>
          </Box>

          <Box
            w={'100%'}
            // border={'1px solid white'}
            display={'flex'}
            justifyContent={'flex-end'}
          >
            <Stack
              w={'90%'}
              display={'flex'}
              direction={'row'}
              gap={3}
              alignItems={'end'}
              justifyContent={'space-between'}
            >
              <Box color={'#fff'} w={'50%'}>
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                  pb={2}
                >
                  From
                </FormLabel>
                <Input
                  w={'100%'}
                  type="date"
                  name="from"
                  value={dates.from}
                  onChange={handleDateChange}
                />
              </Box>
              <Box color={'#fff'} w={'50%'}>
                <FormLabel
                  fontWeight={400}
                  m={0}
                  color={'#949494'}
                  fontSize={'14px'}
                  pb={2}
                >
                  To
                </FormLabel>
                <Input
                  type="date"
                  name="to"
                  value={dates.to}
                  onChange={handleDateChange}
                />
              </Box>
              <Box>
                <Button
                  color={'#fff'}
                  fontWeight={'500'}
                  lineHeight={'inherit'}
                  borderRadius={12}
                  _hover={{
                    color: '#000',
                  }}
                  w={'fit-content'}
                  bg={
                    'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                  }
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Box>
            </Stack>
          </Box>
        </SimpleGrid>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          alignItems={'center'}
          py={5}
          borderRadius={6}
          bg={'#2e3035'}
          px={4}
          className="att-list"
        >
          <Box className="m5-h p1" display={'flex'}>
            <Stack borderRadius={6} bg={'#16171B'} padding={3}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="22"
                viewBox="0 0 18 22"
                fill="none"
              >
                <path
                  d="M5.57143 3.36871H3.28571C2.67951 3.36871 2.09812 3.60283 1.66947 4.01958C1.24082 4.43633 1 5.00156 1 5.59093V18.9243C1 19.5136 1.24082 20.0789 1.66947 20.4956C2.09812 20.9124 2.67951 21.1465 3.28571 21.1465H14.7143C15.3205 21.1465 15.9019 20.9124 16.3305 20.4956C16.7592 20.0789 17 19.5136 17 18.9243V5.59093C17 5.00156 16.7592 4.43633 16.3305 4.01958C15.9019 3.60283 15.3205 3.36871 14.7143 3.36871H12.4286M5.57143 3.36871C5.57143 2.77934 5.81224 2.21411 6.2409 1.79736C6.66955 1.38061 7.25093 1.14648 7.85714 1.14648H10.1429C10.7491 1.14648 11.3304 1.38061 11.7591 1.79736C12.1878 2.21411 12.4286 2.77934 12.4286 3.36871M5.57143 3.36871C5.57143 3.95808 5.81224 4.52331 6.2409 4.94006C6.66955 5.3568 7.25093 5.59093 7.85714 5.59093H10.1429C10.7491 5.59093 11.3304 5.3568 11.7591 4.94006C12.1878 4.52331 12.4286 3.95808 12.4286 3.36871M5.57143 16.702V11.1465M9 16.702V15.5909M12.4286 16.702V13.3687"
                  stroke="#2BAF59"
                  stroke-width="1.51"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Stack>
            <Stack marginLeft={2}>
              <Heading
                as={'h5'}
                textTransform={'capitalize'}
                textColor={'#2BAF59'}
              >
                Today's
              </Heading>
              <Text color={'#FFFFFF'} textColor={'#FFFFFF'}>
                Cash Drawer Count Sheet
              </Text>
            </Stack>
          </Box>
          {/* <Button
            variant={'outline'}
            color={'rgba(255, 255, 255, 0.60)'}
            padding={'10px 14px'}
            _hover={{
              color: 'rgba(255, 255, 255, 0.80)',
            }}
          >
            Download Report
          </Button> */}
        </Stack>
      </Stack>
      <Modal isOpen={isReportOpen} onClose={onReportClose} bg={'#242424'}>
        <ModalOverlay bgColor={'#7a7a7a96'} />
        <ModalContent
          bgColor={'#242424'}
          color={'white'}
          h={'90%'}
          w={'80%'}
          maxW={'60%'}
        >
          <ModalCloseButton color={'#d90c0c'} />
          <ModalBody pb={3} id="modal-content" bg={'#242424'}>
            <Box h={'20%'} w={'100%'} color={'#fff'} mt={4}>
              <Text fontSize={'24px'} textAlign={'center'}>
                Cash Drawer Report
              </Text>
            </Box>
            <HStack h={'15%'} w={'100%'}>
              <Box h={'100%'} w={'80%'}>
                <HStack h={'100%'} w={'100%'} spacing={5}>
                  <Box
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Text>Tuesday</Text>
                    <Text>Store</Text>
                    {/* <Text>Milawakee</Text> */}
                  </Box>
                  <Box
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Text>12-8-2024</Text>
                    <Text>#0000</Text>
                  </Box>
                </HStack>
              </Box>
              <Box h={'100%'} w={'20%'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  w={'100%'}
                  justifyContent={'flex-end'}
                  ml="auto" // This will push the buttons to the right side
                >
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Print button clicked');
                      handlePrint();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Download button clicked');
                      handleDownload();
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </HStack>
            <VStack w={'100%'}>
              <Text
                fontSize={'20px'}
                color={'white'}
                textAlign={'center'}
                mt={2}
                fontWeight={'bold'}
              >
                {' '}
                Discover Transactions
              </Text>
              <TableContainer
                w={'90%'}
                border={'1px solid white'}
                borderTopRadius={'12px'}
                color={'white'}
              >
                <Table w={'100%'} variant="simple" border={'1px solid white'}>
                  <Thead>
                    <Tr
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                      color={'white'}
                    >
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        {' '}
                        No.
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Card Receipt
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Time
                      </Th>

                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Cheaque
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Coupon
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Amount
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {cashDrawerData?.procedures?.map((item, index) => (
                      <Tr>
                        <Td>{index + 1}</Td>
                        <Td>{item?.cardReceipt}</Td>
                        <Td>
                          {item?.createdAt
                            ? item.createdAt.split('T')[1].split('.')[0].trim()
                            : '-'}
                        </Td>

                        <Td>{item?.cheque ? item.cheque : '----'}</Td>
                        <Td>{item?.coupon ? item.coupon : '----'}</Td>
                        <Td>
                          ${'  '}
                          {item?.amount}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Box
                w={'100%'}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
                gap={2}
                mt={2}
              >
                <Box
                  h="45px"
                  w={'40%'}
                  display={'flex'}
                  alignItems={'center'}
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  fontFamily={'Orbitron'}
                >
                  <Text
                    w={'50%'}
                    fontSize={'15px'}
                    color={'#fff'}
                    py={3}
                    textAlign={'center'}
                    h={'100%'}
                    borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                  >
                    Total
                  </Text>
                  <Text w={'50%'} color={'#fff'} textAlign={'center'}>
                    ${cashDrawerData?.totalAmount}
                    {/* {modalData?.discount?.find(d => d.type === 'coupon').price}% */}
                  </Text>
                </Box>
                {/* <Box
                  h="45px"
                  w={'40%'}
                  display={'flex'}
                  alignItems={'center'}
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  fontFamily={'Orbitron'}
                >
                  <Text
                    w={'50%'}
                    fontSize={'15px'}
                    color={'#fff'}
                    py={3}
                    textAlign={'center'}
                    h={'100%'}
                    borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                  >
                    Time
                  </Text>
                  <Text w={'50%'} color={'#fff'} textAlign={'center'}>
                    {cashDrawerData?.procedure
                      ? cashDrawerData?.procedure?.createdAt[0]
                          .split('T')[1]
                          .split('.')[0]
                          .trim()
                      : '-'}
                  </Text>
                </Box> */}
                {/* <Box
                  h="45px"
                  w={'40%'}
                  display={'flex'}
                  alignItems={'center'}
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  fontFamily={'Orbitron'}
                >
                  <Text
                    w={'50%'}
                    fontSize={'15px'}
                    color={'#fff'}
                    py={3}
                    textAlign={'center'}
                    h={'100%'}
                    borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                  >
                    Deposite
                  </Text>
                  <Text
                    w={'50%'}
                    fontSize={'15px'}
                    color={'#fff'}
                    textAlign={'center'}
                  >
                    $150.00
                  </Text>
                </Box> */}
              </Box>
              <Divider borderColor={'white'} />
              <Box
                w={'100%'}
                display={'flex'}
                // flexDirection={'column'}
                justifyContent={'Center'}
                alignItems={'Center'}
                gap={2}
                mt={2}
              >
                <Text fontSize={'24px'} fontWeight={'bold'} color={'white'}>
                  Deposite Grand Total :
                </Text>
                <Text fontSize={'24px'} fontWeight={'bold'} color={'white'}>
                  ${cashDrawerData?.totalAmount}
                </Text>
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isSingleSalesReportOpen}
        onClose={onSingleSalesReportClose}
        bg={'#242424'}
      >
        {/* {console.log(singleSaleReport, 'GDDDDDDDDDDDDDDDDDDDDDDD')} */}
        <ModalOverlay bgColor={'#7a7a7a96'} />
        <ModalContent
          bgColor={'#242424'}
          color={'white'}
          h={'90%'}
          overflowX={'scroll'}
          w={'70%'}
          maxW={'50%'}
        >
          <ModalCloseButton color={'#d90c0c'} />
          <ModalBody
            pb={3}
            id="modal-content"
            bg={'#242424'}
            h={'90%'}
            color={'white'}
          >
            <Box h={'8%'} w={'100%'} color={'#fff'} mt={4}>
              <Text fontSize={'24px'} textAlign={'center'}>
                Single Sale Report
              </Text>
            </Box>
            <HStack h={'15%'} w={'100%'}>
              <Box h={'100%'} w={'80%'}>
                <HStack h={'100%'} w={'100%'} spacing={5}>
                  <Box
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Text>Date</Text>
                    {/* <Text>Store</Text> */}
                    {/* <Text>Milawakee</Text> */}
                  </Box>
                  <Box
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Text>
                      {singleSaleReport?.date
                        ? singleSaleReport.date.split('T')[0].trim()
                        : '-'}
                    </Text>
                    {/* <Text>#0000</Text> */}
                  </Box>
                </HStack>
              </Box>
              <Box h={'100%'} w={'20%'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  w={'100%'}
                  justifyContent={'flex-end'}
                  ml="auto" // This will push the buttons to the right side
                >
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Print button clicked');
                      handlePrint();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Download button clicked');
                      handleDownload();
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </HStack>
            <VStack w={'100%'}>
              <TableContainer
                w={'90%'}
                border={'1px solid white'}
                borderTopRadius={'12px'}
                color={'white'}
              >
                <Table w={'100%'} variant="simple" border={'1px solid white'}>
                  <Thead>
                    <Tr
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                      color={'white'}
                    >
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        {' '}
                        No.
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Description
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Units
                      </Th>

                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Amount
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {singleSaleReport?.service?.map((item, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{item?.id?.name}</Td>
                        <Td>{item?.qty}</Td>
                        <Td>
                          ${'  '}
                          {item?.price}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <Box
                w={'100%'}
                display={'flex'}
                // flexDirection={'column'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                px={8}
                gap={2}
                mt={2}
              >
                {/* <Box>
                  <TableContainer>
                    <Table
                      variant="simple"
                      size="sm"
                      style={{ borderCollapse: 'collapse' }}
                    >
                      <thead>
                        <Tr>
                          <Th
                            fontFamily="Orbitron"
                            color="#fff"
                            border="none"
                            fontSize="13px"
                          ></Th>
                          <Th
                            fontFamily="Orbitron"
                            color="#fff"
                            fontSize="10px"
                            border="none"
                          >
                            Above $1500
                          </Th>
                          <Th
                            fontFamily="Orbitron"
                            color="#fff"
                            fontSize="10px"
                            border="none"
                          >
                            Total
                          </Th>
                        </Tr>
                      </thead>
                      <Tbody spacing={0}>
                        {[1, 2, 3, 4, 5].map((item, index) => (
                          <Tr key={index}>
                            <Td border="none">Cars</Td>
                            <Td border="none">ac</Td>
                            <Td border="none">aav</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box> */}
                <Box
                  h="45px"
                  w={'40%'}
                  display={'flex'}
                  alignItems={'center'}
                  border={'1px solid rgba(255, 255, 255, 0.20)'}
                  fontFamily={'Orbitron'}
                >
                  <Text
                    w={'50%'}
                    fontSize={'15px'}
                    color={'#fff'}
                    py={3}
                    textAlign={'center'}
                    h={'100%'}
                    borderRight={'1px solid rgba(255, 255, 255, 0.20)'}
                  >
                    Total
                  </Text>
                  <Text w={'50%'} color={'#fff'} textAlign={'center'}>
                    ${'  '}
                    {singleSaleReport?.totalPrice}
                    {/* {modalData?.discount?.find(d => d.type === 'coupon').price}% */}
                  </Text>
                </Box>
              </Box>
              <Divider borderColor={'white'} />
              <Box
                mt={2}
                w={'100%'}
                display={'flex'}
                // flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                px={8}
                gap={2}
              >
                <VStack width={'35%'} gap={0} spacing={0}>
                  <Box
                    h="45px"
                    w={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    // border={'1px solid rgba(255, 255, 255, 0.20)'}
                    fontFamily={'Orbitron'}
                    justifyContent={'space-between'}
                  >
                    <Text>
                      {singleSaleReport?.customerId?.existingCustomer
                        ? 'Existing Customer'
                        : 'New Customer'}
                    </Text>
                    {/* <Text>Customer</Text>
                    <Text>5-29%</Text> */}
                  </Box>
                  {/* <Box
                    h="45px"
                    w={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    // border={'1px solid rgba(255, 255, 255, 0.20)'}
                    fontFamily={'Orbitron'}
                    justifyContent={'space-between'}
                  >
                    <Text>New</Text>
                    <Text>Customer</Text>
                    <Text>5-29%</Text>
                  </Box>
                  <Box
                    h="45px"
                    w={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    // border={'1px solid rgba(255, 255, 255, 0.20)'}
                    fontFamily={'Orbitron'}
                    justifyContent={'space-between'}
                  >
                    <Text>New</Text>
                    <Text>Customer</Text>
                    <Text>5-29%</Text>
                  </Box> */}
                </VStack>
                <Box w="45%">
                  <VStack w="100%" spacing={4}>
                    <Box display="flex" w="100%" flexDirection="column">
                      <Box display="flex" justifyContent="space-between">
                        <Text>Vehicle</Text>
                        <Text>
                          {singleSaleReport?.vehicleId?.make}
                          {'   '}
                          {singleSaleReport?.vehicleId?.model}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        // fontSize="14px"
                        justifyContent="space-between"
                      >
                        <Text>Year</Text>
                        <Text> {singleSaleReport?.vehicleId?.year}</Text>
                      </Box>
                      <Box></Box>
                    </Box>
                    <Box display="flex" w="100%" flexDirection="column">
                      <Box display="flex" justifyContent="space-between">
                        <Text>Engine Size</Text>
                        <Text> {singleSaleReport?.vehicleId?.engineSize}</Text>
                      </Box>
                    </Box>
                  </VStack>
                </Box>
              </Box>
              {/* <Box w={'100%'} display={'flex'} justifyContent={'center'} mt={2}>
                <TableContainer
                  w={'90%'}
                  border={'1px solid white'}
                  borderTopRadius={'12px'}
                  color={'white'}
                >
                  <Table w={'100%'} variant="simple" border={'1px solid white'}>
                    <Thead>
                      <Tr
                        bg={
                          'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                        }
                        color={'white'}
                      >
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          {' '}
                          No.
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Description
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Links
                        </Th>

                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          %
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Amount
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Amount
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {cashDrawerData?.procedures?.map((item, index) => (
                        <Tr>
                          <Td>{index + 1}</Td>
                          <Td>{item?.cardReceipt}</Td>
                          <Td>
                            {item?.createdAt
                              ? item.createdAt
                                  .split('T')[1]
                                  .split('.')[0]
                                  .trim()
                              : '-'}
                          </Td>

                          <Td>{item?.cheque ? item.cheque : '----'}</Td>
                          <Td>{item?.coupon ? item.coupon : '----'}</Td>
                          <Td>
                            ${'  '}
                            {item?.amount}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box> */}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* ------------CUSTOMERS SALES REPORT Nested Modal SECTION Start------------- */}
      <Modal
        isOpen={isSalesReportOpen}
        onClose={onSalesReportClose}
        bg={'#242424'}
      >
        <ModalOverlay bgColor={'#7a7a7a96'} />
        <ModalContent
          bgColor={'#242424'}
          color={'white'}
          h={'90%'}
          overflowX={'scroll'}
          w={'70%'}
          maxW={'50%'}
        >
          <ModalCloseButton color={'#d90c0c'} />
          <ModalBody pb={3} id="modal-content" bg={'#242424'} color={'white'}>
            <Box h={'8%'} w={'100%'} color={'#fff'} mt={4}>
              <Text fontSize={'24px'} textAlign={'center'}>
                Daily Sales Report
              </Text>
            </Box>
            <HStack h={'15%'} w={'100%'}>
              {/* <Box h={'100%'} w={'80%'}>
                <HStack h={'100%'} w={'100%'} spacing={5}>
                  <Box
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Text>Tuesday</Text>
                    <Text>Store</Text>
                    <Text>Milawakee</Text>
                    </Box>
                  <Box
                    h={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}>
                    {console.log(salesReportData, 'DDDDDDDDDDDDDDDDDDDDDDDDD')}
                    <Text>{salesReportData?.date}</Text>
                    <Text>#0000</Text>
                  </Box>
                </HStack>
              </Box> */}
              <Box h={'100%'} w={'20%'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  w={'100%'}
                  justifyContent={'flex-end'}
                  ml="auto" // This will push the buttons to the right side
                >
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Print button clicked');
                      handlePrint();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Download button clicked');
                      handleDownload();
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </HStack>
            <VStack w={'100%'}>
              <TableContainer
                w={'90%'}
                border={'1px solid white'}
                borderTopRadius={'12px'}
                color={'white'}
              >
                <Table w={'100%'} variant="simple" border={'1px solid white'}>
                  <Thead>
                    <Tr
                      bg={
                        'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                      }
                      color={'white'}
                    >
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        {' '}
                        No.
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Date
                      </Th>
                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Units
                      </Th>

                      <Th
                        fontFamily={'Orbitron'}
                        color={'#fff'}
                        fontSize={'13px'}
                      >
                        Amount
                      </Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {salesReportData?.map(
                      (item, index) => (
                        // report?.service?.map((item, index) => (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>
                            {' '}
                            {item?.createdAt
                              ? item.createdAt
                                  .split('T')[1]
                                  .split('.')[0]
                                  .trim()
                              : '-'}
                          </Td>
                          <Td>{item?.totalQty}</Td>
                          <Td>
                            ${'  '}
                            {item?.totalPrice}
                          </Td>
                          <Td>
                            <ViewIcon
                              onClick={() => handleOpenSaleReport(item)}
                            />
                          </Td>
                        </Tr>
                      )
                      // ))
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* ------------CUSTOMERS SALES REPORT Nested Modal SECTION END------------- */}
      <Modal
        isOpen={isCustomersReportOpen}
        onClose={onCustomersReportClose}
        bg={'#242424'}
      >
        <ModalOverlay bgColor={'#7a7a7a96'} />
        <ModalContent
          bgColor={'#242424'}
          color={'white'}
          h={'90%'}
          overflowX={'scroll'}
          w={'70%'}
          maxW={'50%'}
        >
          <ModalCloseButton color={'#d90c0c'} />
          <ModalBody pb={3} id="modal-content" bg={'#242424'} color={'white'}>
            <Box h={'8%'} w={'100%'} color={'#fff'} mt={4}>
              <Text fontSize={'24px'} textAlign={'center'}>
                Daily Customers Report
              </Text>
            </Box>
            <HStack h={'15%'} w={'100%'}>
              <Box h={'100%'} w={'80%'}></Box>
              <Box h={'100%'} w={'20%'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  w={'100%'}
                  justifyContent={'flex-end'}
                  ml="auto" // This will push the buttons to the right side
                >
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Print button clicked');
                      handlePrint();
                    }}
                  >
                    Print
                  </Button>
                  <Button
                    color={'#fff'}
                    fontWeight={'500'}
                    bg={
                      'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                    }
                    onClick={() => {
                      // console.log('Download button clicked');
                      handleDownload();
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </HStack>
            <VStack w={'100%'} align={'flex-start'}>
              <Box display={'flex'} gap={2}>
                <Text fontSize={'24px'} fontWeight={'bold'} color={'white'}>
                  New Customers :
                </Text>
                <Text fontSize={'24px'} fontWeight={'bold'} color={'white'}>
                  {customersReportData?.newCustomersCount}
                </Text>
              </Box>
              {customersReportData?.newCustomersCount > 0 ? (
                <TableContainer
                  w={'100%'}
                  border={'1px solid white'}
                  borderTopRadius={'12px'}
                  color={'white'}
                >
                  <Table
                    w={'100%'}
                    variant="simple"
                    size={'sm'}
                    border={'1px solid white'}
                  >
                    <Thead>
                      <Tr
                        bg={
                          'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                        }
                        color={'white'}
                      >
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          {' '}
                          No.
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Name
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Email
                        </Th>

                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Contact
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {customersReportData?.newCustomers?.map((item, index) => (
                        <Tr>
                          <Td>{index + 1}</Td>
                          <Td>
                            {item?.firstName}
                            {'  '} {item?.lastName}
                          </Td>
                          <Td>{item?.email}</Td>

                          <Td>{item?.number ? item.number : '----'}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Text fontSize={'14px'} fontWeight={'bold'} color={'white'}>
                  No New Customers
                </Text>
              )}
              <Box display={'flex'} gap={2}>
                <Text fontSize={'24px'} fontWeight={'bold'} color={'white'}>
                  Existing Customers :
                </Text>
                <Text fontSize={'24px'} fontWeight={'bold'} color={'white'}>
                  {customersReportData?.existingCustomersCount}
                </Text>
              </Box>
              {customersReportData?.existingCustomersCount > 0 ? (
                <TableContainer
                  w={'100%'}
                  border={'1px solid white'}
                  borderTopRadius={'12px'}
                  color={'white'}
                >
                  <Table
                    w={'100%'}
                    variant="simple"
                    size={'sm'}
                    border={'1px solid white'}
                  >
                    <Thead>
                      <Tr
                        bg={
                          'linear-gradient(90.11deg, #2BAF59 -1.34%, #1D723B 99.92%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))'
                        }
                        color={'white'}
                      >
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          {' '}
                          No.
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Name
                        </Th>
                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Email
                        </Th>

                        <Th
                          fontFamily={'Orbitron'}
                          color={'#fff'}
                          fontSize={'13px'}
                        >
                          Contact
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {customersReportData?.existingCustomers?.map(
                        (item, index) => (
                          <Tr>
                            <Td>{index + 1}</Td>
                            <Td>
                              {item?.firstName}
                              {'  '} {item?.lastName}
                            </Td>
                            <Td>{item?.email}</Td>

                            <Td>{item?.number ? item.number : '----'}</Td>
                          </Tr>
                        )
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Text fontSize={'14px'} fontWeight={'bold'} color={'white'}>
                  No Existing Customers
                </Text>
              )}
              <Divider borderColor={'white'} />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Dashboard;
