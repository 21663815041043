import React from 'react';
import MainDashboard from '../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import Reorder from '../../components/Reorder/Reorder';
export default function ReorderList() {
  return (
    <>
      <MainDashboard>
        <Stack px={4}>
          <Reorder />
        </Stack>
      </MainDashboard>
    </>
  );
}
