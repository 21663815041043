import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  Link,
  Radio,
  RadioGroup,
  Text,
  Avatar,
  UnorderedList,
  ListItem,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  Icon,
  Spinner,
  Button,
  Badge,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Switch,
  Spacer,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  AccordionItem,
  Accordion,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useParams } from 'react-router-dom';
import L3 from '../../assets/images/data/l3.png';
import { BsThreeDots } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import {
  empdetaildata,
  epmattendance,
  epmattendanceReq,
} from '../../assets/data/Data.js';
import { BiEditAlt } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { GET, POST, PUT } from '../../utilities/ApiProvider.js';
import { useSelector } from 'react-redux';
import { formatDay, formatTime, formatTime2 } from '../../utilities/helper.js';

export default function SingleEmp() {
  const user = useSelector(state => state.value);
  const Params = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [empdetail, setempdetail] = useState('');
  const [customPrivileges, setCustomPrivileges] = useState([]);
  const [empfield, setempfield] = useState('');
  const [issuerbtn, setissuerbtn] = useState('individual');

  useEffect(() => {
    if (user) {
      getUserFields();
    }
  }, [user]);

  const generalRoles = [
    'Home',
    'Help',
    'Settings',
    'Attendance',
    'List of Jobs',
  ];

  const getUserFields = async () => {
    setIsLoading(true);
    let SingleuserData = await GET(
      `/employees/getSingleEmployee/${Params.id}`,
      {
        Authorization: `Bearer ${user?.verificationToken}`,
      }
    );
    console.log(SingleuserData);
    if (SingleuserData.status === 'success') {
      setCustomPrivileges(SingleuserData?.data?.privilege);
      setempdetail(SingleuserData?.data);
      await getprivilege(SingleuserData?.data?.employeeType);
      setIsLoading(false);
    }
  };

  const acceptRequest = async id => {
    setIsLoading(true);
    let payload = {
      status: 'accepted',
    };
    try {
      let res = await PUT(`/attendance/acceptRequest/${id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res?.status === 'success') {
        console.log(res?.data);
        getUserFields();
        setIsLoading(false);
        toast({
          description: res.message,
          status: res.status,
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const rejectRequest = async id => {
    setIsLoading(true);
    let payload = {
      status: 'rejected',
    };
    try {
      let res = await PUT(`/attendance/rejectRequest/${id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res?.status === 'success') {
        console.log(res?.data);
        getUserFields();
        setIsLoading(false);
        toast({
          description: res.message,
          status: res.status,
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleBlockUser = async () => {
    let Id = Params.id;
    console.log('Api Hit', Id);
    try {
      let blockUser = await PUT(
        `/employees/blockUser/${Id}`,
        {},
        {
          Authorization: `Bearer ${user?.verificationToken}`,
        }
      );
      if (blockUser.status === 'success') {
        console.log(blockUser.data);
        toast({
          description: blockUser?.message ?? 'Success',
          position: 'top-right',
          isClosable: true,
          status: blockUser?.status,
          duration: 3000,
        });
        getUserFields();
      }
    } catch (error) {
      toast({
        description: 'An Error Occured',
        position: 'top-right',
        isClosable: true,
        status: 'error',
        duration: 3000,
      });
      console.log(error.message);
    }
  };

  const isPrivilegeSelected = (module, action) => {
    return customPrivileges.some(
      priv => priv.name === module && priv.action.includes(action)
    );
  };

  const handleCheckboxChange = (module, action) => {
    setCustomPrivileges(prevState => {
      const index = prevState.findIndex(item => item.name === module.module);
      if (index !== -1) {
        const newActions = prevState[index].action?.includes(action)
          ? prevState[index].action?.filter(a => a !== action)
          : [...(prevState[index].action || []), action];
        if (newActions.length === 0) {
          return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
        } else {
          return [
            ...prevState.slice(0, index),
            { ...prevState[index], action: newActions },
            ...prevState.slice(index + 1),
          ];
        }
      } else {
        return [
          ...prevState,
          {
            name: module.module,
            _id: module._id,
            action: [action],
            url: module.url,
          },
        ];
      }
    });
  };

  const getprivilege = async type => {
    let empprivilege = await GET(`/roles/getPermissionByRole/${type}`, {
      Authorization: `Bearer ${user?.verificationToken}`,
    });
    if (empprivilege?.status === 'success') {
      if (type === 'technician') {
        setempfield(empprivilege?.data?.filter(e => e.module !== 'Jobs'));
      } else {
        setempfield(empprivilege?.data);
      }
      console.log('EMP DATA', empprivilege?.data);
      console.log('EMP FIELD', empdetail?.privilege);

      const commonPrivileges = empdetail.privilege?.filter(priv =>
        empprivilege.data.some(mod => mod.module === priv.name)
      );

      console.log('Selected', commonPrivileges);

      // const selectedRole = empdetail?.privilege.find(
      //   role => role.name === type
      // );
      // console.log(selectedRole, 'Selected');

      // if (selectedRole) {
      //   const matchingPrivileges = empprivilege?.data
      //     .filter(priv => selectedRole.basicPriv.includes(priv.module))
      //     .map(priv => ({
      //       name: priv.module,
      //       id: priv._id,
      //       url: priv.url,
      //       action: ['create', 'edit', 'delete', 'view'],
      //     }));
      //   console.log('MATCHING', matchingPrivileges);

      //   setCustomPrivileges(matchingPrivileges);
      // }
    }
  };

  const updatePrivs = async () => {
    try {
      let payload = {
        email: empdetail?.email,
        fullName: empdetail?.fullName,
        phone: empdetail?.phone.toString(),
        employeeType: empdetail?.employeeType,
        privilege: customPrivileges.map(privilege => ({
          name: privilege.name,
          id: privilege._id,
          url: privilege.url,
          action: privilege.action,
        })),
      };
      let res = await PUT(`/employees/updateEmployees/${Params.id}`, payload, {
        Authorization: `Bearer ${user?.verificationToken}`,
      });
      if (res?.status === 'success') {
        console.log(res?.data);
        toast({
          description: res.message,
          status: res.status,
          position: 'top-right',
          duration: 3000,
          isClosable: true,
        });
        getUserFields();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    console.log('Cusome', customPrivileges);
  }, [customPrivileges, setCustomPrivileges]);

  useEffect(() => {
    if (user) getUserFields();
  }, [user]);

  return (
    <>
      <SimpleGrid
        columns={{ base: '1', sm: '1', md: '1', lg: '1', xl: '2', '2xl': '2' }}
        spacingX="40px"
        spacingY="20px"
      >
        <Stack className={'bread m3-h'}>
          <Heading as={'h3'}>
            Employee Details /{' '}
            <Link to={-1} as={ReactLink} color={'#2BAF59'}>
              Back
            </Link>
          </Heading>
        </Stack>
      </SimpleGrid>
      {isLoading ? (
        <Box
          display={'flex'}
          height={'80vh'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Spinner color="#fff" />
        </Box>
      ) : (
        <Stack bg={'#202125'} px={6} py={12} borderRadius={8}>
          <Stack
            direction={'row'}
            pb={6}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Stack>
              <Heading
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                padding={2}
                fontSize={'14px'}
                color={'#fff'}
                borderRadius={6}
                as={'h5'}
              >
                Type: {empdetail?.employeeType}
              </Heading>
            </Stack>
            {empdetail?.active === false ? (
              <Stack>
                <Heading
                  bg={'#FF0000'}
                  padding={2}
                  fontSize={'14px'}
                  color={'#fff'}
                  borderRadius={6}
                  as={'h5'}
                >
                  Employee Blocked
                </Heading>
                {/* <Menu>
                <MenuButton>
                  <Icon fontSize={'40px'} color={'#2BAF59'} as={BsThreeDots} />
                </MenuButton>
                <MenuList bg={'#545454'} border={'0'}>
                  <MenuItem
                    _hover={{
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                    }}
                    _focus={{
                      bg: '#545454',
                    }}
                    color={'#fff'}
                  >
                    <Link as={ReactLink} to={'/'}>
                      Block
                    </Link>
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                    }}
                    color={'#fff'}
                  >
                    <Link as={ReactLink} to={'/'}>
                      Remove
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu> */}
              </Stack>
            ) : (
              <Stack>
                <Button
                  alignItems={'center'}
                  justifyContent={'center'}
                  bg={'#FF0000'}
                  color={'#fff'}
                  display={'flex'}
                  _hover={{ bg: '#202125', border: '1px solid #FF0000' }}
                  onClick={handleBlockUser}
                >
                  Block Employee
                </Button>
              </Stack>
            )}
          </Stack>
          <Stack
            direction={'row'}
            py={6}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            borderBottom={'1px solid #2e3035'}
          >
            <Box display={'flex'} gap={3} mb={6}>
              <Avatar name="Kent Dodds" src={L3} />
              <Box className="venbox p1 m3-h">
                <Text>Employee Name</Text>
                <Heading as={'h3'}>{empdetail?.fullName}</Heading>
              </Box>
            </Box>

            <Stack className="venbox p1 m3-h">
              <Text>Email Address</Text>
              <Heading as={'h3'}>{empdetail?.email}</Heading>
            </Stack>
            <Stack className="venbox p1 m3-h">
              <Text>Contact Details</Text>
              <Heading as={'h3'}>{empdetail?.phone}</Heading>
            </Stack>
            <Stack className="venbox p1 m3-h">
              <Text>Member Since</Text>
              <Heading as={'h3'}>
                {' '}
                {new Date(empdetail?.createdAt).toLocaleDateString()}
              </Heading>
            </Stack>
          </Stack>

          <Stack
            direction={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            alignItems={'end'}
          >
            <Stack>
              <UnorderedList
                gap={6}
                display={'flex'}
                py={5}
                listStyleType={'none'}
              >
                <ListItem>
                  <Button
                    padding={'10px 50px'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    color={issuerbtn === 'individual' ? '#fff' : '#EDF2F7'}
                    border={'1px solid #7D7D7D'}
                    borderRadius={12}
                    _hover={{
                      color: '#fff',

                      border: '1px solid #2BAF59',
                    }}
                    w={'fit-content'}
                    bg={
                      issuerbtn === 'individual'
                        ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        : '#202125'
                    }
                    onClick={() => setissuerbtn('individual')}
                  >
                    All Jobs
                  </Button>
                </ListItem>

                <ListItem>
                  <Button
                    padding={'10px 50px'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    border={'1px solid #7D7D7D'}
                    borderRadius={12}
                    _hover={{
                      color: '#fff',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                      border: '1px solid #2BAF59',
                    }}
                    w={'fit-content'}
                    color={issuerbtn === 'attendance' ? '#fff' : '#EDF2F7'}
                    bg={
                      issuerbtn === 'attendance'
                        ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        : '#202125'
                    }
                    onClick={() => setissuerbtn('attendance')}
                  >
                    Attendance
                  </Button>
                </ListItem>

                <ListItem>
                  <Button
                    padding={'10px 50px'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    border={'1px solid #7D7D7D'}
                    borderRadius={12}
                    _hover={{
                      color: '#fff',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                      border: '1px solid #2BAF59',
                    }}
                    w={'fit-content'}
                    color={issuerbtn === 'company' ? '#fff' : '#EDF2F7'}
                    bg={
                      issuerbtn === 'company'
                        ? 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                        : '#202125'
                    }
                    onClick={() => setissuerbtn('company')}
                  >
                    Privilege's
                  </Button>
                </ListItem>
              </UnorderedList>
            </Stack>
          </Stack>

          {issuerbtn === 'individual' ? (
            <Stack className="epmt1">
              <Stack
                direction={'row'}
                py={6}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
              >
                <Box className="p1">
                  <Text>Total Jobs: {empdetail?.job?.length ?? 0}</Text>
                </Box>
              </Stack>

              {empdetail?.job?.map((v, e) => {
                return (
                  <Stack gap={6} key={e}>
                    <Stack
                      direction={'row'}
                      flexWrap={'wrap'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      py={6}
                      bg={'#2E3035'}
                      borderRadius={6}
                      px={3}
                    >
                      <Box display={'flex'} gap={3} width={'30%'}>
                        <Avatar name="Kent Dodds" src="" />
                        <Box className="venbox p1 m3-h">
                          <Text>Customer Name</Text>
                          <Heading as={'h3'}>
                            {v.customer?.firstName} {v.customer?.lastName}
                          </Heading>
                        </Box>
                      </Box>
                      <Box className="m5-h p1" width={'10%'}>
                        <Text>Status</Text>
                        <Heading as={'h5'}>{v.status}</Heading>
                      </Box>
                      <Box className="m5-h p1" width={'10%'}>
                        <Text>Coupon</Text>
                        <Heading as={'h5'}>
                          {v?.invoice[0]?.discount?.length > 0 &&
                          v?.invoice[0]?.discount.some(
                            discount => discount?.type === 'coupon'
                          )
                            ? `${
                                v?.invoice[0]?.discount.find(
                                  discount => discount?.type === 'coupon'
                                )?.price
                              }%`
                            : 'No coupon'}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1" width={'10%'}>
                        <Text>Discount</Text>
                        <Heading as={'h5'}>
                          {v?.invoice[0]?.discount?.length > 0 &&
                          v?.invoice[0]?.discount.some(
                            discount => discount?.type === 'discount'
                          )
                            ? v?.invoice[0]?.discount.find(
                                discount => discount?.type === 'discount'
                              )?.price
                            : '0'}
                          %
                        </Heading>
                      </Box>
                      <Box className="m5-h p1" width={'10%'}>
                        <Text>Invoice</Text>
                        <Heading as={'h5'}>
                          {v?.invoice?.length > 0
                            ? v?.invoice[0]?.invoiceNumber
                            : '------'}
                        </Heading>
                      </Box>
                      <Box className="m5-h p1" width={'20%'}>
                        <Link
                          padding={'10px 50px'}
                          bg={
                            'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                          }
                          color={'#fff'}
                          fontWeight={'500'}
                          lineHeight={'inherit'}
                          borderRadius={12}
                          _hover={{
                            color: '#000',
                          }}
                          textAlign={'center'}
                          to={`/customerjobdetail/${v?._id}`}
                          as={ReactLink}
                        >
                          View Full Details
                        </Link>
                      </Box>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          ) : issuerbtn === 'company' ? (
            <Stack py={4} gap={8}>
              <SimpleGrid columns={3} spacing={10}>
                {empfield
                  ?.filter(emp => !generalRoles.includes(emp.module))
                  ?.map((v, e) => {
                    return (
                      <Stack width={'100%'}>
                        <Accordion allowMultiple key={e}>
                          <AccordionItem
                            border={'1px solid #595C64'}
                            borderRadius={8}
                            padding={4}
                            w={'100%'}
                            direction={'row'}
                            justifyContent={'space-between'}
                            className="p1"
                          >
                            <h2>
                              <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                  <Text
                                    textTransform={'capitalize'}
                                    fontSize={'18px'}
                                    // style={{ fontSize: '18px' }}
                                  >
                                    {v.module}
                                  </Text>
                                </Box>
                                <AccordionIcon color={'white'} />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} w={'100%'}>
                              {v.actions
                                ?.filter(e => e !== 'list')
                                ?.map((action, index) => (
                                  <Stack
                                    key={index}
                                    direction="row"
                                    alignItems="center"
                                    gap={4}
                                    flexWrap={'wrap'}
                                    justifyContent="space-between"
                                  >
                                    <Text textTransform={'capitalize'} flex={1}>
                                      {action}
                                    </Text>
                                    <Spacer />

                                    <Switch
                                      flex={0}
                                      value={action}
                                      isChecked={isPrivilegeSelected(
                                        v.module,
                                        action
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(v, action)
                                      }
                                      colorScheme="green"
                                    />
                                  </Stack>
                                ))}
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
              <Button
                padding={'10px 50px'}
                bg={
                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                }
                color={'#fff'}
                fontWeight={'500'}
                lineHeight={'inherit'}
                borderRadius={12}
                _hover={{
                  color: '#000',
                }}
                w={'fit-content'}
                onClick={updatePrivs}
              >
                Update Privileges
              </Button>
            </Stack>
          ) : (
            <Stack className="epmt1">
              <Stack
                direction={'row'}
                py={6}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
              >
                <Box className="p1">
                  <Text>
                    Total Attendance: {empdetail?.attendance?.length ?? 0}
                  </Text>
                </Box>
              </Stack>
              <Tabs variant="unstyled">
                <TabList gap={6}>
                  <Tab
                    padding={'10px 50px'}
                    color={'#7D7D7D'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    px={8}
                    bg={'transparent'}
                    border={'1px solid #7D7D7D'}
                    _hover={{
                      color: '#fff',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                      border: '1px solid #2BAF59',
                    }}
                    textTransform={'capitalize'}
                    w={'fit-content'}
                    borderRadius={12}
                    _selected={{
                      color: 'white',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                    }}
                  >
                    All Attendance
                  </Tab>
                  <Tab
                    padding={'10px 50px'}
                    color={'#7D7D7D'}
                    fontWeight={'500'}
                    lineHeight={'inherit'}
                    px={8}
                    bg={'transparent'}
                    border={'1px solid #7D7D7D'}
                    _hover={{
                      color: '#fff',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                      border: '1px solid #2BAF59',
                    }}
                    textTransform={'capitalize'}
                    w={'fit-content'}
                    borderRadius={12}
                    _selected={{
                      color: 'white',
                      bg: 'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))',
                    }}
                  >
                    Attendance Requests
                  </Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {empdetail?.attendance?.length > 0 ? (
                      empdetail?.attendance?.map((v, e) => (
                        <Stack gap={6} key={e}>
                          <Stack
                            direction={'row'}
                            flexWrap={'wrap'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            py={5}
                            borderRadius={6}
                            bg={'#2e3035'}
                            px={4}
                            mb={4}
                            className="att-list"
                          >
                            <Box className="m5-h p1">
                              <Text>No.</Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {e + 1}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Date</Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {new Date(v?.Date).toLocaleDateString()}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Day</Text>
                              <Heading as={'h5'}>{v?.Day}</Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Clock In</Text>
                              <Heading as={'h5'}>
                                {formatTime2(v?.clockIn)}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Clock Out</Text>
                              <Heading as={'h5'}>
                                {v.clockOut
                                  ? formatTime2(v?.clockOut)
                                  : '--:--:--'}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Hours Worked</Text>
                              <Heading as={'h5'}>
                                {v?.totalHours
                                  ? v?.totalHours
                                  : 'Not clocked-out'}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Status</Text>
                              <Badge
                                padding={'10px 20px'}
                                borderRadius={'25px'}
                                bg={
                                  v?.status === 'On Time'
                                    ? 'rgba(46, 196, 135, 0.16)'
                                    : 'rgba(244, 21, 21, 0.16)'
                                }
                                color={
                                  v?.status === 'On Time'
                                    ? '#2EC487'
                                    : '#F41515'
                                }
                              >
                                {v?.status}
                              </Badge>
                            </Box>
                          </Stack>
                        </Stack>
                      ))
                    ) : (
                      <Stack className={'bread m5-h'} py={5}>
                        <Heading as={'h5'}>No Attendance Record Found</Heading>
                      </Stack>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {empdetail?.attendRequest?.length > 0 ? (
                      empdetail?.attendRequest?.map((v, e) => (
                        <Stack gap={6} key={e}>
                          <Stack
                            direction={'row'}
                            flexWrap={'wrap'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            py={5}
                            borderRadius={6}
                            bg={'#2e3035'}
                            px={4}
                            mb={4}
                            className="att-list"
                          >
                            {' '}
                            <Box className="m5-h p1">
                              <Text>No.</Text>
                              <Heading as={'h5'} textTransform={'capitalize'}>
                                {e + 1}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Start Time</Text>
                              <Heading as={'h5'}>
                                {formatTime2(v?.clockTime)}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Day</Text>
                              <Heading as={'h5'}>
                                {formatDay(v?.clockDate)}
                              </Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Issue Type</Text>
                              <Heading as={'h5'}>{v?.issue}</Heading>
                            </Box>
                            <Box className="m5-h p1">
                              <Text>Issue Description</Text>
                              <Button
                                padding={'10px 50px'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                color={'#fff'}
                                borderRadius={12}
                                _hover={{
                                  color: '#fff',
                                }}
                                w={'fit-content'}
                                bg={
                                  'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                                }
                                onClick={onOpen}
                              >
                                View
                              </Button>
                            </Box>
                            {v?.status === 'pending' ? (
                              <Box className="m5-h p1">
                                <Text>Actions</Text>
                                <Stack direction={'row'} alignItems={'center'}>
                                  <Button
                                    padding={'10px 50px'}
                                    fontWeight={'500'}
                                    lineHeight={'inherit'}
                                    color={'#fff'}
                                    border={'1px solid #7D7D7D'}
                                    borderRadius={12}
                                    _hover={{
                                      color: '#fff',
                                      border: '1px solid #2BAF59',
                                    }}
                                    w={'fit-content'}
                                    bg={
                                      'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
                                    }
                                    onClick={() => acceptRequest(v?._id)}
                                  >
                                    Accept
                                  </Button>
                                  <Button
                                    padding={'10px 50px'}
                                    fontWeight={'500'}
                                    lineHeight={'inherit'}
                                    color={'#EDF2F7'}
                                    border={
                                      '1px solid rgba(255, 255, 255, 0.60)'
                                    }
                                    borderRadius={12}
                                    _hover={{
                                      color: '#fff',
                                      border: '1px solid #2BAF59',
                                    }}
                                    w={'fit-content'}
                                    bg={'#2e3035'}
                                    onClick={() => rejectRequest(v?._id)}
                                  >
                                    Reject
                                  </Button>
                                </Stack>
                              </Box>
                            ) : (
                              <Box className="m5-h p1">
                                <Text>Status</Text>
                                <Heading
                                  as={'h5'}
                                  padding={'3px 10px'}
                                  color={
                                    v?.status === 'accepted'
                                      ? '#2EC487'
                                      : '#F41515'
                                  }
                                  fontWeight={'500'}
                                  lineHeight={'inherit'}
                                  borderRadius={12}
                                  w={'fit-content'}
                                  bg={
                                    v?.status === 'accepted'
                                      ? 'rgba(46, 196, 135, 0.16)'
                                      : 'rgba(244, 21, 21, 0.16)'
                                  }
                                >
                                  {v?.status}
                                </Heading>
                              </Box>
                            )}
                          </Stack>
                          <Modal isOpen={isOpen} onClose={onClose}>
                            <ModalOverlay bgColor={'#7a7a7a96'} />
                            <ModalContent
                              alignContent={'center'}
                              alignItems={'center'}
                              bg={'#202125'}
                              border={'1px solid #4D4D4D'}
                              borderRadius={14}
                            >
                              <ModalHeader color={'#ffff'}>
                                Issue Description{' '}
                              </ModalHeader>
                              <ModalCloseButton />
                              <ModalBody>
                                <Text color={'#ffff'}>{v?.description}</Text>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  colorScheme="green"
                                  mr={3}
                                  onClick={onClose}
                                >
                                  Close
                                </Button>
                              </ModalFooter>
                            </ModalContent>
                          </Modal>
                        </Stack>
                      ))
                    ) : (
                      <Stack className={'bread m5-h'} py={5}>
                        <Heading as={'h5'}>No Attendance Request Found</Heading>
                      </Stack>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          )}
          {/* ===tab div=== */}
        </Stack>
      )}
    </>
  );
}
