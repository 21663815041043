import {
  Stack,
  Box,
  Image,
  Text,
  Heading,
  Icon,
  Checkbox,
  FormLabel,
  Input,
  Button,
  Link,
  useToast,
} from '@chakra-ui/react';
import Go from '../../assets/images/icon/go.png';
import Fb from '../../assets/images/icon/fb.png';
import { AiOutlineUser, AiOutlineLock } from 'react-icons/ai';
import { BsEyeSlash, BsEye, BsBag } from 'react-icons/bs';
import { GoMail } from 'react-icons/go';
import React, { useEffect, useState } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import Authlogo from './Authlogo';
import { POST } from '../../utilities/ApiProvider';

export default function NewPassform() {
  const toast = useToast();
  const navigate = useNavigate();
  const [showpass, setshowpass] = useState(false);
  const [reshowpass, setreshowpass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newpass, setnewpass] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  useEffect(() => {
    (async () => {
      let localEmail = localStorage.getItem('forgotemail');
      setnewpass({
        ...newpass,
        email: localEmail,
      });
      console.log('email', newpass);
    })();
  }, []);

  useEffect(() => {
    console.log(newpass);
  }, [newpass]);

  const EmpSubmit = async () => {
    setIsLoading(true);

    try {
      let res = await POST(`/users/changePassword`, newpass);
      console.log('add vender responce ', res);

      toast({
        description: res.message,
        status: res.status,
        position: 'top-right',
        isClosable: true,
        duration: 3000,
      });
      if (res.status === 'success') {
        navigate('/login');
      }
    } catch (err) {
      toast({
        description: err.message,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 1000,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Stack pl={6} pr={'35%'}>
        <Authlogo />
        <Stack className="m3-h p1" mb={6}>
          <Heading as={'h3'}>New Password</Heading>
          <Text>Set new password and login</Text>
        </Stack>
        <Stack className="regwarp" gap={4}>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#949494'} as={AiOutlineLock} />
              <Input
                type={showpass ? 'text' : 'password'}
                value={newpass.password}
                onChange={e =>
                  setnewpass({ ...newpass, password: e.target.value })
                }
                placeholder="Enter Password"
              />
              <Button onClick={() => setshowpass(!showpass)}>
                <Icon color={'#949494'} as={showpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </Stack>
          <Stack>
            <FormLabel
              fontWeight={400}
              m={0}
              color={'#949494'}
              fontSize={'14px'}
            >
              Re-type Password
            </FormLabel>
            <Box className="Ifiled">
              <Icon fontSize={'25px'} color={'#949494'} as={AiOutlineLock} />
              <Input
                type={reshowpass ? 'text' : 'password'}
                value={newpass.confirmPassword}
                onChange={e =>
                  setnewpass({ ...newpass, confirmPassword: e.target.value })
                }
                placeholder="Enter Re-type Password"
              />
              <Button onClick={() => setreshowpass(!reshowpass)}>
                <Icon color={'#949494'} as={reshowpass ? BsEye : BsEyeSlash} />
              </Button>
            </Box>
          </Stack>

          <Stack gap={14}>
            <Button
              padding={'10px 50px'}
              bg={
                'var(--grdient-green, linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #2BAF59 -1.34%, #1D723B 99.92%))'
              }
              color={'#fff'}
              fontWeight={'500'}
              lineHeight={'inherit'}
              _hover={{
                color: '#000',
              }}
              w={'fit-content'}
              isLoading={isLoading}
              onClick={EmpSubmit}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
